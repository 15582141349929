import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { GetTermAndConditionApi } from "../../services/Collection";

export default function TermAndCondition() {
  const aqlutstorage = process.env.REACT_APP_IMAGE_BASEURL;
  const containerProfile = process.env.REACT_APP_CONTAINER_PROFILE;
  
  const [term, setTerm] = useState();
  const language = useSelector((state) => state?.selectLang);
  const restaurantDetails = useSelector(
    (state) => state?.restaurentSlice?.restaurentData?.Menu?.Restaurent
  );
  const logo_restaurent =
    aqlutstorage + containerProfile + restaurantDetails?.logo;

  const handleTerm = async () => {
    let res = await GetTermAndConditionApi();

    if (res?.status === 200) {
      console.log("2-000", res?.data);
      if (language?.lang === "en") {
        setTerm(res?.data?.[0]?.terms_and_condition_english);
      } else {
        setTerm(res?.data?.[0]?.terms_and_condition_arabic);
      }
    }
  };
  console.log(term, "term and condiotn");

  useEffect(() => {
    handleTerm();
  }, []);
  return (
    <TermAndConditionWrapper>
      {/* <div className="logoDiv">
        <i onClick={() => navigate(-1)}>
          <img src={logo_restaurent} alt="snapay" />
        </i>
      </div> */}
      <div
        dir={language?.direction}
        dangerouslySetInnerHTML={{ __html: term }}
      ></div>
    </TermAndConditionWrapper>
  );
}

const TermAndConditionWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;

  .logoDiv {
    display: flex;
    justify-content: center;
  }
  h1 {
    color: #171717;
    text-align: center;
    font-family: DM Sans;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 20px 0;
  }

  p {
    color: #171717;
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 8px 0;
    span {
      // font-size: 17px;
      font-weight: 700;
      color: #66433f;
    }
  }
`;
