/* eslint-disable react-hooks/exhaustive-deps */
import {
  Autocomplete,
  CircleF,
  GoogleMap,
  MarkerF,
  useLoadScript,
} from "@react-google-maps/api";
import React, { Fragment, useCallback, useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import Geocode from "react-geocode";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { CurrentLocationBlueIcon } from "../../Utils/Images";

let loc = {
  lat: 25.354826,
  lng: 51.183884,
};

const circleOption = {
  strokeColor: "transparent",
  strokeOpacity: 1,
  strokeWeight: 2,
  fillColor: "#F55A2C",
  fillOpacity: 0.3,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  zIndex: 1,
};

const containerStyle = {
  width: "100%",
  height: "100%",
};

const MapComponent = ({
  setAddressLocation,
  setLocationLatLong,
  locationLatLong,
}) => {
  const [searchResult, setSearchResult] = useState();
  const [currentLoc, setCurrentLoc] = useState(loc);
  const [showMarker, setShowMarker] = useState(false);

  const autocompleteRef = useRef();
  const googleMapApiKey = process.env.REACT_APP_MAP_APIKEY;

  useEffect(() => {
    if (locationLatLong.lat !== "") {
      setShowMarker(true);
      setCurrentLoc(locationLatLong);
    }
  }, [locationLatLong]);

  const { isLoaded, loadError } = useLoadScript({
    id: "google-map-script",
    googleMapsApiKey: googleMapApiKey,
    libraries: ["places"],
  });

  const getMaxDeliveryDetails = useSelector(
    (state) => state?.restaurentSlice?.restaurentData?.Menu?.Restaurent
  );

  // const getPos = () => {
  //   try {
  //     const getCurrentLocation = async () => {
  //       if (window.navigator.geolocation) {
  //         navigator.geolocation.getCurrentPosition(showPosition);
  //       }
  //     };

  //     getCurrentLocation();
  //   } catch (error) {
  //     toast.error(error);
  //   }
  // };

  const onLoad = (autocomplete) => {
    setSearchResult(autocomplete);
  };

  const onPlaceChanged = () => {
    if (searchResult != null) {
      const place = searchResult.getPlace();
      let city, state, country;
      for (let i = 0; i < place.address_components.length; i++) {
        for (let j = 0; j < place.address_components[i].types.length; j++) {
          // eslint-disable-next-line default-case
          switch (place.address_components[i].types[j]) {
            case "locality":
              city = place.address_components[i].long_name;
              break;
            case "administrative_area_level_1":
              state = place.address_components[i].long_name;
              break;
            case "country":
              country = place.address_components[i].long_name;
              break;
          }
        }

        let addressByLocation = {
          city: city,
          state: state,
          country: country,
          fullAddress: place?.formatted_address,
        };
        setAddressLocation(addressByLocation);
      }
      let coordinate = {
        lat: place.geometry.location.lat() || 0,
        lng: place.geometry.location.lng() || 0,
      };
      setCurrentLoc(coordinate);
      setLocationLatLong(coordinate);
    } else {
    }
  };

  function showError(error) {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        alert("Location access permission is denied");
        break;
      case error.POSITION_UNAVAILABLE:
        console.log("Location information is unavailable.");
        break;
      case error.TIMEOUT:
        console.log("The request to get user location timed out.");
        break;
      case error.UNKNOWN_ERROR:
        console.log("An unknown error occurred.");
        break;
      default: {
        console.log("An error occurred.");
      }
    }
  }

  function showPosition(position) {
    setShowMarker(true);
    let currentLocation = {
      lat: position.coords.latitude || 25.354826,
      lng: position.coords.longitude || 51.183884,
    };
    getChangeLoation(currentLocation);
    setLocationLatLong(currentLocation);
    setCurrentLoc({ ...currentLocation });
  }

  var options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

  const getCurrentLocation = useCallback(() => {
    if (navigator.geolocation)
      navigator.geolocation.getCurrentPosition(
        showPosition,
        showError,
        options
      );
  }, [navigator.geolocation.getCurrentPosition]);

  const setCurrentLocation = () => {
    autocompleteRef.current.value = null;
    getCurrentLocation();
  };

  const getChangeLoation = (e) => {
    Geocode.setApiKey(googleMapApiKey);
    Geocode.fromLatLng(e.lat, e.lng).then(
      (response) => {
        const address = response.results[0].formatted_address;
        let city, state, country;
        for (
          let i = 0;
          i < response.results[0].address_components.length;
          i++
        ) {
          for (
            let j = 0;
            j < response.results[0].address_components[i].types.length;
            j++
          ) {
            // eslint-disable-next-line default-case
            switch (response.results[0].address_components[i].types[j]) {
              case "locality":
                city = response.results[0].address_components[i].long_name;
                break;
              case "administrative_area_level_1":
                state = response.results[0].address_components[i].long_name;
                break;
              case "country":
                country = response.results[0].address_components[i].long_name;
                break;
            }
          }
        }

        let addressByLocation = {
          city: city,
          state: state,
          country: country,
          fullAddress: address,
        };
        setAddressLocation(addressByLocation);
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const handleOnDragEnd = (e) => {
    let currentLocation = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    setLocationLatLong(currentLocation);
    getChangeLoation(currentLocation);
  };

  useEffect(() => {
    getCurrentLocation();
  }, []);

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }
  return (
    <>
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={currentLoc}
          zoom={12}
        >
          <Fragment key="marker">
            <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
              <InputSection
                ref={autocompleteRef}
                type="text"
                placeholder="Search Place"
              />
            </Autocomplete>
            <CurrentLocationBtn
              data-tooltip="Current-location"
              onClick={setCurrentLocation}
            >
              {/* <Icons name="currentLocation" color="#1b8ad9" /> */}
              <img alt="" src={CurrentLocationBlueIcon} />
            </CurrentLocationBtn>
            {showMarker && (
              <MarkerF
                draggable={true}
                position={currentLoc}
                onDragEnd={handleOnDragEnd}
              />
            )}
            <CircleF
              center={{
                lat: getMaxDeliveryDetails?.latitude,
                lng: getMaxDeliveryDetails?.longitude,
              }}
              radius={getMaxDeliveryDetails?.max_delivery_distance * 1000}
              options={circleOption}
            />
          </Fragment>
        </GoogleMap>
      ) : (
        <>Loading...</>
      )}
    </>
  );
  // </LoadScript>
};

export default React.memo(MapComponent);

const InputSection = styled.input`
  box-sizing: border-box;
  font-size: 16px;
  padding: 0 12px;
  border: 1px solid transparent;
  border-radius: 2px;
  height: 40px;
  width: calc(100% - 400px);
  position: absolute;
  left: 250px;
  top: 10px;
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
  outline: none;
  text-overflow: ellipses;
  min-width: 200px;
  text-align: center;

  @media (max-width: 610px) {
    width: calc(100% - 80px);
    left: 10px;
    min-width: 90px;
  }
`;

const CurrentLocationBtn = styled.div`
  position: absolute;
  background: white;
  padding: 4px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  border-radius: 2px;
  right: 10px;
  top: 38%;
  cursor: pointer;
`;
