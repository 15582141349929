import React from "react";
import { useSelector } from "react-redux";
import IntlMessage from "../../Utils/IntlMessage";
import { FeedbackBillDetail } from "./FeedbackBillDetail";

export default function FeedBackBillDetail({ data }) {
  // const IsUnifiedUser = useSelector((state) => state?.userAuth?.data);
  const IsUnifiedUser = useSelector((state) => state?.isUnifiedSlice);
  const customTheme = useSelector((state) => state?.theme?.branding);

  let totalRedeemedPoints;
  let totalAmount;

  if (data?.loyalty_points_status && IsUnifiedUser?.is_aura_user) {
    if (data?.total >= data?.loyalty_points) {
      totalRedeemedPoints = data?.loyalty_points;
      totalAmount = data?.total - data?.loyalty_points;
    } else if (data?.loyalty_points > data?.total) {
      totalRedeemedPoints = data?.total;
      totalAmount = 0;
    }
  } else if (!data?.loyalty_points_status && IsUnifiedUser?.is_aura_user) {
    totalRedeemedPoints = data?.loyalty_points;
    totalAmount = data?.total;
  } else {
    totalRedeemedPoints = 0;
    totalAmount = 0;
  }

  const dir = useSelector((state) => state?.selectLang?.direction);

  function AmountWithDecimalValue(x) {
    let n = Number(x).toFixed(2);
    if (n) {
      let res = n.split(".");
      if (res) {
        if (Number(res[1]) > 0) {
          return n;
        } else {
          return res[0];
        }
      }
    }
  }

  const handleTotalPayable = () => {
    let total = Number(data?.total);
    let deliveryFee = Number(data?.delivery_fee)
    return total + deliveryFee;
  }

  return (
    <FeedbackBillDetail dir={dir}>
      <div className="bill-details">
        <h1 style={{color: customTheme?.theme_color}} >
          <IntlMessage id="payment.button.orderdetails" />
        </h1>
        <div className="charges">
          <h2>
            {" "}
            <IntlMessage id="cart.itemtotal" />:{" "}
          </h2>
          <h4>QAR {AmountWithDecimalValue(data?.total)}</h4>
        </div>
        {/* <div className="charges">
          <h2>
            <IntlMessage id="cart.taxesandcharges" /> :{" "}
          </h2>
          <h4>
            QAR{" "}
            {AmountWithDecimalValue(
              Number(data?.service_tax) + Number(data?.vat)
            )}
          </h4>
        </div> */}
        <div className="charges">
          <h2>
            <IntlMessage id="cart.discount" /> :{" "}
          </h2>
          <h4>- QAR {AmountWithDecimalValue(data?.discount)}</h4>
        </div>

        {data?.loyalty_points_status && (
          <div className="charges">
            <h2>
              <IntlMessage id="cart.redeemedPoints" /> :
            </h2>
            <h4>QAR {AmountWithDecimalValue(totalRedeemedPoints)}</h4>
          </div>
        )}

        {data?.order_type == "Delivery" && (
          <div className="charges">
            <h2>
              <IntlMessage id="cart.deliveryFee" />
            </h2>
            <h4>QAR {AmountWithDecimalValue(data?.delivery_fee)}</h4>
          </div>
        )}

        {IsUnifiedUser?.is_aura_user && data?.payment_status == "2" ? (
          <>
            <div className="charges">
              <h2>
                <IntlMessage id="payment.partialamount.paidamount" />
              </h2>
              <h4>QAR {AmountWithDecimalValue(totalAmount)}</h4>
            </div>

            <div className="charges">
              <h2>
                <IntlMessage id="cart.totalpayable" />
              </h2>
              <h4>QAR 0</h4>
            </div>
          </>
        ) : (
          <div className="charges">
            <h2>
              <IntlMessage id="cart.totalpayable" />
            </h2>
            <h4>
              QAR{" "}
              {AmountWithDecimalValue(handleTotalPayable())}
            </h4>
          </div>
        )}
      </div>
    </FeedbackBillDetail>
  );
}
