import React from 'react'
import styled from 'styled-components';
import { Header } from '../../components';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PaymentFailedIcon } from '../../Utils/Icons';

export default function PaymentFailed() {
    const direction = useSelector((state) => state.selectLang.direction);
    const customTheme = useSelector((state) => state?.theme?.branding);
    const navigate = useNavigate();
    const getAlreadyScanedID = useSelector((state) => state.StoreForMultiUsers);
    const currentActive =
        getAlreadyScanedID?.getScannedQrcodeAndRestuarent?.slice(-1);

    return (
        <>
            <Header redirect={"/payment"} />
            <PaymentSuccessStyle dir={direction} customTheme={customTheme} >
                <div style={{ textAlign: "center" }}>
                    {/* <img src='/assets/images/paymentSuccess.png' alt='' style={{ width: "280px", marginTop: "60px" }} /> */}

                    <PaymentFailedIcon />
                    <h6>Payment Failed, Oops!</h6>
                    <button onClick={() => navigate(`/?qrcodeid=${currentActive?.[0]?.qrcodeid}`)} >CONTINUE</button>
                </div>
            </PaymentSuccessStyle>
        </>
    )
}

const PaymentSuccessStyle = styled.div`

width:100%;
height:80vh;
display:flex;
align-items:center;
justify-content:center;
  svg path {
    color: ${({ customTheme }) => customTheme.theme_color};
  }

  h6 {
    font-family: "Jost";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 27px;

    letter-spacing: 0.03em;

    color: #000000;
    margin-top: 60px;
  }

  p {
    margin-top: 14px;
    font-family: "Jost";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.03em;

    color: #7a7a7a;
  }
  button {
    font-family: "Jost";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: ${({ theme }) => theme?.paymentButtonColor};
    padding: 12px 29px;
    background: ${({ customTheme }) => `linear-gradient(45deg, ${customTheme?.button_primary_color}, ${customTheme?.button_secondary_color})`};
    background: ;
    border-radius: 6.7806px;
    border: none;

    width: 130px;
    margin: 30px 0px;
  }
`;

