import styled from "styled-components";

import React, { useEffect } from "react";

import { useSelector } from "react-redux";
import { Header } from "../../components";
import { PaymentSuccessIcon } from "../../Utils/Icons";
import { useLocation, useNavigate } from "react-router-dom";
import { getPaymentStatus, internalWebHook } from "../../services/Collection";
import { useState } from "react";
import loadingLock from "../../lotties/loadingLock.json";
import Lottie from "react-lottie";

function PaymentSuccess() {
  const direction = useSelector((state) => state.selectLang.direction);
  const customTheme = useSelector((state) => state?.theme?.branding);
  const navigate = useNavigate();
  const getAlreadyScanedID = useSelector((state) => state.StoreForMultiUsers);
  const currentActive =
    getAlreadyScanedID?.getScannedQrcodeAndRestuarent?.slice(-1);
  const RestuarantDetails = useSelector(
    (state) => state?.restaurentSlice?.restaurentData
  );
  const location = useLocation();
  // const [isWebhookCalled, setIsWebhookCalled] = useState(null);
  const [loading, setLoading] = useState(true);

  console.log(location, "locationnnnnn");

  const navigateUser = () => {
    if (RestuarantDetails?.QrCodeGroup?.group_type === "Dine In") {
      navigate(
        `/${currentActive?.[0]?.qrcodeid}-${currentActive?.[0]?.restaurentid}/aqlutpaymentsuccessful`
      );
    } else {
      navigate(`/${currentActive?.[0]?.qrcodeid}/user-feedback`);
    }
  };

  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: loadingLock,
  };

  const paymentStatus = async (paymentId) => {
    let req = {
      Key: paymentId,
      KeyType: "PaymentId",
    };

    let res = await getPaymentStatus(req);
    // transtion status 0 -> new transtion / 1 -> pending transation / 2 -> completed transtaion / 3 -> failed transtion
    // webhook status !== 1 ( didn't call from myfaatoorah)
    if (res?.data?.webhook_status == 1) {
      console.log(res, "resssssssssssss");
      setLoading(false);
      //webhook called by myFatoorah
    } else {
      let requestPayload = {
        InvoiceId: res?.data?.InvoiceId,
        TransactionStatus: res?.data?.transaction_status,
      };
      updateWebhook(requestPayload)
      // setIsWebhookCalled(requestPayload);
      setLoading(false);
      //webhook called by NodeJS
    }
  };

  const updateWebhook = async (payload) => {
    let req = {
      Data: payload,
    };
    await internalWebHook(req);
    // navigateUser();
  };

  useEffect(() => {
    if (location?.search) {
      let paymentId = location?.search?.split("&")?.[0]?.split("=")?.[1];
      setTimeout(() => {
        paymentStatus(paymentId);
      }, 6000);
    }
  }, []);


  if (loading) {
    return <PaymentWrapper>
       <Lottie options={defaultOptions}
              height={320}
              width={320}
              />
      <p>Your transaction is in-progress</p>
      <h1>Please don't refresh the page!</h1>
    </PaymentWrapper>;
  }

  return (
    <>
      <Header redirect={"/payment"} />
      <PaymentSuccessStyle dir={direction} customTheme={customTheme}>
        <div style={{ textAlign: "center" }}>
          {/* <img src='/assets/images/paymentSuccess.png' alt='' style={{ width: "280px", marginTop: "60px" }} /> */}

          <PaymentSuccessIcon />
          <h6>Payment Success, Yayy!</h6>
          <button
            onClick={navigateUser}
          >
            CONTINUE
          </button>
        </div>
      </PaymentSuccessStyle>
    </>
  );
}

export default PaymentSuccess;

const PaymentWrapper = styled.div`
  width:100%;
  height:100vh;
  background: #fff;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #505052;
   p {
    font-weight: 400;
    font-size: 22px;
    line-height: 32px;
   }
   h1 {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
   }
`;

const PaymentSuccessStyle = styled.div`
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  svg path {
    color: ${({ customTheme }) => customTheme.theme_color};
  }

  h6 {
    font-family: "Jost";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 27px;

    letter-spacing: 0.03em;

    color: #000000;
    margin-top: 60px;
  }

  p {
    margin-top: 14px;
    font-family: "Jost";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.03em;

    color: #7a7a7a;
  }
  button {
    font-family: "Jost";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: ${({ theme }) => theme?.paymentButtonColor};
    padding: 12px 29px;
    background: ${({ customTheme }) =>
      `linear-gradient(45deg, ${customTheme?.button_primary_color}, ${customTheme?.button_secondary_color})`};
    background: ;
    border-radius: 6.7806px;
    border: none;

    width: 130px;
    margin: 30px 0px;
  }
`;
