import React from 'react';

export default function NotFound(){
    return (
        <>
        <div style={NotFoundPage}>
    	<div style={fofNotFound}>
        		<h1>404</h1><br/>
                <h1 style={h1tag}>Page Not Found</h1>
    	</div>
</div>
        </>
        )
}
const NotFoundPage={
    display: 'table',
    width: '100%',
    height: '100vh',
    textAlign: 'center'
}
const fofNotFound={
    display: "table-cell",
    verticalAlign: "middle"
}

const h1tag={
    fontSize: "50px",
    display: "inline-block",
    paddingRight: "12px",
}