import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import styled from "styled-components";
import { splitBill, wayToPayBillType } from "../../store/orderPayment";
import { ThemeButton } from "../../style/SnapayGobalStyle";
import { CrossDrawerIcon } from "../../Utils/Icons";
import { addCommaToNumbers } from "../../Utils/CommanFunctions";
import IntlMessage from "../../Utils/IntlMessage";
import { useIntl } from "react-intl";

const PayCustomAmount = ({ Close }) => {
  const customTheme = useSelector((state) => state?.theme?.branding);
  const spiltBill = useSelector(
    (state) => state?.orderInformationSlice?.splitBill
  );
  const splitBillPay = Math.floor(spiltBill?.total);
  const [input, setInput] = useState(
    spiltBill?.type === "CustomAmount" ? splitBillPay : ""
  );
  const dispatch = useDispatch();
  const order = useSelector(
    (state) => state?.orderInformationSlice?.orderDeails
  );
  const langDir = useSelector((state) => state?.selectLang);
  const intl = useIntl();

  const handleInput = (e) => {
    let pay = parseInt(e?.target?.value);
    setInput(pay);
  };

  let obj = {
    type: "CustomAmount",
    total: parseInt(input).toFixed(2),
  };

  const handleConfirm = () => {
    if (Number(order?.total) >= input) {
      Close();
      dispatch(splitBill(obj));
      dispatch(wayToPayBillType("SplitTheBill"));
    } else {
      toast.warn("Amount is not greater than your bill");
    }
  };

  return (
    <PayCustomAmountStyled dir={langDir?.direction}>
      <div className="heading">
        <div className="innerHeadingWrapper">
          <span>
            <IntlMessage id="Snapay.CustomAmountHeading" />
          </span>
          <span className="total">
            <IntlMessage id="Snapay.CustomAmountDueHeading" />{" "}
            {addCommaToNumbers(order?.total)} <IntlMessage id="Snapay.QAR" />
          </span>
        </div>
        <i onClick={Close}>
          <CrossDrawerIcon />
        </i>
      </div>
      <input
        type="number"
        placeholder={intl.formatMessage({
          id: "Snapay.CustomAmountPlaceholder",
        })}
        value={input}
        onChange={handleInput}
      />
      <ThemeButton
        button_text_color={customTheme?.button_text_color}
        button_primary_color={customTheme?.button_primary_color}
        button_secondary_color={customTheme?.button_secondary_color}
        onClick={handleConfirm}
      >
        <IntlMessage id="Snapay.DivideEquallyConfirm" />
      </ThemeButton>
    </PayCustomAmountStyled>
  );
};

export default PayCustomAmount;

const PayCustomAmountStyled = styled.div`
  padding: 25px 20px 30px 20px;

  .heading {
    color: ${({ textColor }) => textColor};
    font-family: "DM Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.42px;
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    i {
      cursor: pointer;
    }
  }

  .innerHeadingWrapper {
    display: flex;
    align-items: center;
    gap: 15px;

    .total {
      font-weight: 900;
      font-size: 13px;
    }

    @media (max-width: 380px) {
      flex-direction: column;
      align-items: start;
    }
  }

  input {
    width: 100%;
    padding: 14px 13px;
    border-radius: 6px;
    border: 1px solid #5c5c5c;
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    color: #646464;
    font-family: "DM Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    margin: 10px 0 30px 0;
  }

  .dueAmount {
    font-weight: 900;
    margin-bottom: 30px;
  }
`;
