import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import "../AlertScreen/AlertScreen.scss";

export default function AlertScreen() {
  const aqlutstorage = process.env.REACT_APP_IMAGE_BASEURL;
  const containerProfile = process.env.REACT_APP_CONTAINER_PROFILE;
  const containerMenuBranding = process.env.REACT_APP_CONTAINER_MENUBRANDING;

  const navigate = useNavigate();
  const getAlreadyScanedID = useSelector((state) => state.StoreForMultiUsers);
  const currentActive =
    getAlreadyScanedID?.getScannedQrcodeAndRestuarent?.slice(-1);

  const currentID = currentActive?.[0]?.qrcodeid;

  const { restaurentData } = useSelector(({ restaurentSlice }) => ({
    ...restaurentSlice,
  }));

  // const styling = restaurentData?.Menu?.Restaurent?.MenuPageBranding;
  const styling = useSelector((state) => state?.theme?.branding);
  const restaurent_data = restaurentData?.Menu?.Restaurent;

  //style
  const background_image = styling?.display_image;
  const restaurent_logo = restaurent_data?.logo;

  let background_img = aqlutstorage + containerMenuBranding + background_image;
  let logo_restaurent = aqlutstorage + containerProfile + restaurent_logo;

  const location = useLocation();
  const path = location.pathname;
  const handleRetry = () => {
    navigate(`/${currentID}/addfoodlist`);
  };

  return (
    <>
      {path === "/alertscreen" && (
        <section className="alert-screen">
          <div className="alert-seleceted-card">
            <div className="alert-content-wrapper">
              <header>
                Restaurant is not part of Aqlut network, please call the waiter
              </header>
            </div>
          </div>
        </section>
      )}

      {(path === "/closed" || path === "/retry") && (
        <div
          className="SelectWrapper"
          style={{ backgroundImage: `url(${background_img})` }}
        >
          <div className="SelectCard" style={{ height: "auto" }}>
            <div className="ContentWrapper">
              <img
                src={logo_restaurent}
                alt="logo"
                className="closedResImage"
              />
              <header>{`Sorry, ${restaurent_data?.name} is closed at the moment`}</header>
              <button
                style={{
                  background: `linear-gradient(91.33deg,${styling?.review_primary_button_colour} 0%,${styling?.review_secondary_button_colour} 100%)`,
                }}
                type="button"
                onClick={handleRetry}
              >
                RETRY
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
