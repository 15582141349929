import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import langSlice from "../store/selectlang";
import userSlice from "../store/userLoginReducer";
import orderInformationSlice from "./orderPayment.js";
import chatHistorySlice from "./ChatHistory.js";
import selectItemSlice from "./selectItem.js";
import cartSlice from "./Cart";
import menuSlice from "./Menu";
import recommendedItemSlice from "./RecommentedItem.js";
import notificationsResult from "./Notifications";
import restaurentSlice from "./restaurenDetail";
import posStatus from "./posStatus";
import MultiUserSlice from "./MultiUserStore";
import isUnifiedUser from "./UnifiedUser/UnifiedUser";
import avaiableItemInPosSlice from "./ItemPOS";
import deliverySlice from "./DeliveryModule/DeliverySlice";
import themeSlice from "./AppTheme.js";

const reducers = combineReducers({
  notificationsResult: notificationsResult,
  restaurentSlice: restaurentSlice,
  selectLang: langSlice,
  userAuth: userSlice,
  orderInformationSlice: orderInformationSlice,
  chatHistorySlice: chatHistorySlice,
  menuSlice: menuSlice,
  recommendedItemSlice: recommendedItemSlice,
  selectItemSlice: selectItemSlice,
  cartSlice: cartSlice,
  isUnifiedSlice: isUnifiedUser,
  posSlice: posStatus,
  StoreForMultiUsers: MultiUserSlice,
  PosItem: avaiableItemInPosSlice,
  deliveryModule: deliverySlice,
  theme: themeSlice
});

const persistConfig = {
  key: "aqlut1.8",
  storage,
  blacklist: ["notificationsResult", "posSlice"],
};

const persistedReducer = persistReducer(persistConfig, reducers);
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);
