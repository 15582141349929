import React, { useState } from "react";
import { useSelector } from "react-redux";
import { ChevronDownIcon } from "../../Utils/Icons";
import styled from "styled-components";
import Item from "../AddFoodList/Item";

export default function SubSection({ subData, toggleDrawer }) {
  const Direction = useSelector((state) => state?.selectLang?.direction);
  const [isSubSectionOpen, setSubSectionOpen] = useState(false);
  const customTheme = useSelector((state) => state?.theme?.branding);


  console.log(subData, "subDatasubData");
  return (
    <>
      <div
        className="sub-section"
        onClick={() => setSubSectionOpen(!isSubSectionOpen)}
      >
        <p>
          {Direction === "ltr"
            ? subData?.section_name
            : subData?.ar_section_name}
        </p>
        <ChevronDownIcon />
      </div>
      {isSubSectionOpen && (
        <>
          {subData?.Items?.map((item, key) => (
                 <SubSectionItemWrapper boxBackground={customTheme?.placeholder_box_color} >
              <Item
                identifyLastItemOfSection={
                  subData?.Items?.length - 1 === key && true
                }
                key={key + 3}
                item={item}
                toggleDrawer={() => toggleDrawer(item)}
              />
            </SubSectionItemWrapper>
          ))}
        </>
      )}
    </>
  );
}

const SubSectionItemWrapper = styled.div`
  width: 100%;
  min-height: 176px;
  max-height:100%;
  padding: 12px 16px;
//   background:red;
  margin:12px 0;
  box-shadow: 0 3px 8px 4px rgb(0 0 0 / 15%);
  border-radius:8px;
  background: ${({boxBackground})=> boxBackground};
`;
