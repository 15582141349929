import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { specialNotes } from "../../store/selectItem";
import IntlMessage from "../../Utils/IntlMessage";
import { RightArrow, SpecialNoteIcon } from "../../Utils/Icons";

function SpecialNotes({ special_notes, direction, inputNone, colorStyle }) {
  const dispatch = useDispatch();
   const customTheme = useSelector((state) => state?.theme?.branding);

  function handleNote(val) {
    dispatch(specialNotes(val));
  }

  return (

    <SpecialNoteWrapper dir={direction} style={{ background: customTheme?.background_color }}>
      <div onClick={special_notes} className="QuantitiyWrapper">
        <div className="QunatityContainer">
          {/* <img src={SpecialNoteIcon} alt="" /> */}
          <i>
            <SpecialNoteIcon color={customTheme?.theme_color} />
          </i>

          <h1 style={{ color: customTheme?.theme_color }} className="QuantityHeading">
            {" "}
            <IntlMessage id="AddItemDrawer.specialnotes" />
          </h1>
        </div>
        <div className="ArrowIcon">
          <div>
          <RightArrow color={customTheme?.theme_color}/>
          </div>
        </div>
      </div>

      <div style={{ display: inputNone }} className="Special-Note">
        <textarea
          onChange={(e) => handleNote(e.target.value)}
          placeholder={
            direction === "ltr"
              ? "Enter the special notes to the restaurant"
              : " أدخل الملاحظات الخاصة بالمطعم"
          }
        ></textarea>
      </div>
    </SpecialNoteWrapper>
  );
}

export default SpecialNotes;

export const SpecialNoteWrapper = styled.div`
  background: white;
  padding-bottom: 10px;

  .QuantitiyWrapper {
    width: 95%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
  }
  .FreeItemName {
    padding-left: 29px;
    span {
      display: flex;
      font-family: Poppins;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;

      span {
        margin-top: 1.6px;
      }
    }
    p {
      margin: 0;
      color: #7b7f91 !important;
      margin-right: 5px;
    }
  }
  .QunatityContainer {
    display: flex;
    gap: 7px;
    p {
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #fc2b6e;
    }
    .QuantityHeading {
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;

      color: #fc2b6e;
      ${"" /* margin-top: 0; */}
    }
  }

  .QuantityMainWrapper button:hover {
    cursor: pointer;
  }
  .QuantityMainWrapper {
    display: flex;
    width: 78px;
    height: 33px;
    justify-content: space-between;
    // background-color: white;
    background: #ffffff;
    border: 0.5px solid rgba(36, 36, 36, 0.3);
    border-radius: 30px;
    margin-top: 5px;

    button {
      border: none;
      background: none;
      color: #fc2b6e;
      font-size: 22px;
      ${"" /* width: 20px */}
    }
    p {
      color: #202020;
      margin: 5px;
      margin-top: 6.7px;
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .FreeItemContainer {
    display: inline-grid;
    p {
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #fc2b6e;
    }
    .QuantityHeading {
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;

      color: #fc2b6e;
      ${"" /* margin-top: 0; */}
    }
  }
  .freeItemHeader {
    display: flex;
    margin-bottom: -11px;
  }
  .ArrowIcon {
    div {
      ${"" /* margin-top: 10px; */}
      width: 30px;
    }
    img {
      width: 12px;
    }
  }
  .Special-Note {
    background: #f5f5f5;
    border: 1px solid #f5f5f5;
    border-radius: 10px;
    width: 90%;
    margin: auto;

    textarea {
      width: 82vw;
      background: #f5f5f5;
      border: none;
      height: 80px;
      border-radius: 10px;
      box-shadow: 20px;
      padding-top: 10px;
      padding-left: 25px;
      opacity: 1 !important;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #202020;
      :focus {
        outline: none;
      }
    }
  }
`;
