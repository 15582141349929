/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useSelector } from "react-redux";

import { Header } from "../../components";
import "../UserFeedback/UserFeedback.scss";
import FeedBackReceiptDetail from "../ReceiptDetail/FeedbackReceiptDetail";
import { NavLink, useNavigate } from "react-router-dom";
import moment from "moment";
import {
  getOrderReceipt,
  onceArrived,
  sendEmailReceipts,
} from "../../services/Collection";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import {
  EmailSvgIcon,
  GivefeedbackSvg,
  ViewRecieptIcon,
} from "../../Utils/Icons";
import styled from "styled-components";
import IntlMessage from "../../Utils/IntlMessage";
import { useIntl } from "react-intl";

export default function UserFeedback() {
  const { restaurentData } = useSelector(
    ({ notificationsResult, restaurentSlice }) => ({
      ...notificationsResult,
      ...restaurentSlice,
    })
  );
  const userlang = useSelector((state) => state.selectLang.direction);
  const [emailBlog, activeEmailBlog] = useState("none");
  const [receiptBlog, activeReceiptBlog] = useState("none");
  const [active, updateActive] = useState("");
  const Navigate = useNavigate();
  const isOrder = useSelector(
    (state) => state?.orderInformationSlice?.orderDeails
  );
  const intl = useIntl();

  const [orderDetail, setOrderDetail] = useState([]);
  const [loading, setLoading] = useState(false);
  const [arrivedOnLoading, setArrivedOnLoading] = useState(false);
  const [emailID, setEmailID] = useState(null);
  const getAlreadyScanedID = useSelector((state) => state.StoreForMultiUsers);
  const currentActive =
    getAlreadyScanedID?.getScannedQrcodeAndRestuarent?.slice(-1);
  const customTheme = useSelector((state) => state?.theme?.branding);

  console.log(isOrder, "orderDetails");

  const currentID = currentActive?.[0]?.qrcodeid;

  const handleEmail = (e) => {
    updateActive(e);
    activeEmailBlog("block");
  };
  const handleReceipt = async (e) => {
    setLoading(true);
    let res = await getOrderReceipt(isOrder?.id);
    if (res.status === 200) {
      setOrderDetail(res.data);
      updateActive(e);
      activeEmailBlog("none");
      activeReceiptBlog("block");
      setLoading(false);
    } else {
      toast.error(res.response.data.message);
      setLoading(false);
    }
  };
  const handleFeedback = (e) => {
    updateActive(e);
    Navigate("/feedback");
  };

  const sendReceiptOnEmail = async () => {
    const regex =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailID) {
      if (emailID.match(regex)) {
        let req = {
          order_id: isOrder.id,
          email: emailID,
          orderDateTime: moment
            .unix(Number(isOrder.order_date))
            .format("DD-MM-YYYY hh:mm:ss"),
        };

        let res = await sendEmailReceipts(req);
        if (res.status === 200) {
          toast.info(res.message, { theme: "colored" });
        } else {
          toast.error(res.response.data.message, { theme: "colored" });
        }
      } else {
        toast.error("Please enter your valid email id", { theme: "colored" });
      }
    } else {
      toast.error("Please enter email id", { theme: "colored" });
    }
  };

  console.log("restaurentData", restaurentData);

  const handlePhoneNumber = () => {
    let RestaurentNumber =
      restaurentData?.Menu?.Restaurent?.RestaurentPhones?.[0]?.country_code +
      restaurentData?.Menu?.Restaurent?.RestaurentPhones?.[0]?.phone_number;

    return RestaurentNumber;
  };

  const handleArrivedOnce = async () => {
    setArrivedOnLoading(true);
    let requestedPayload = {
      order_id: isOrder?.id,
    };

    console.log(restaurentData, "restaurentData");

    let res = await onceArrived(requestedPayload);
    if (res?.status === 200) {
      console.log(res, "ressssssssssss");
      setArrivedOnLoading(false);
      toast.success(res?.message);
    } else {
      setArrivedOnLoading(false);
      toast.error(
        res?.response?.data?.message ||
        res?.message ||
        res?.error ||
        "Something went wrong"
      );
    }
  };

  const ShowFieldValue = ({ field, value }) => {
    return (
      <div style={{ margin: "10px 0" }}>
        <label style={{ color: customTheme?.header_color }}>
          <IntlMessage id={field} />:{" "}
        </label>
        <span style={{ color: customTheme?.text_color }}>
          {value ? value : "---"}
        </span>
        <br />
      </div>
    );
  };

  return (
    <>
      <Header />
      {isOrder?.order_type === "Takeaway" && (
        <InstructionsForTakewayOrder
          style={{ background: `${customTheme?.theme_color}40` }}
        >
          <header style={{ color: customTheme?.header_color }}>
            <IntlMessage id="userfeedback.button.instructions" />
          </header>
          <div>
            <p style={{ color: customTheme?.theme_color }}>
              <IntlMessage id="userfeedback.button.callThisNumberToTakeOrder" />
              (
              <a
                style={{ color: customTheme?.theme_color }}
                href={`tel: ${handlePhoneNumber()}`}
              >
                {restaurentData?.Menu?.Restaurent?.RestaurentPhones?.[0]
                  ?.country_code +
                  restaurentData?.Menu?.Restaurent?.RestaurentPhones?.[0]
                    ?.phone_number}
              </a>
              )
            </p>
            <h6 style={{ color: customTheme?.header_color }}>OR</h6>
            {arrivedOnLoading ? (
              <button
                style={{
                  color: customTheme?.button_text_color,
                  background: `linear-gradient(91.33deg,${customTheme?.button_primary_color} 0%,${customTheme?.button_secondary_color} 100%)`,
                }}
              >
                <CircularProgress size={18} color="inherit" />
              </button>
            ) : (
              <button
                onClick={handleArrivedOnce}
                style={{
                  color: customTheme?.button_text_color,
                  border: `1px solid ${customTheme?.theme_color}`,
                  background: `linear-gradient(91.33deg,${customTheme?.button_primary_color} 0%,${customTheme?.button_secondary_color} 100%)`,
                }}
              >
                <IntlMessage id="userfeedback.button.onceYouArrive" />
              </button>
            )}
          </div>
        </InstructionsForTakewayOrder>
      )}

      {isOrder?.order_type === "Delivery" && isOrder?.Address && (
        <DeliveryAddressWrapper
          style={{ background: `${customTheme?.theme_color}40` }}
        >
          <header style={{ color: customTheme?.header_color }}>
            {/* <IntlMessage id="Order.orderDeatils.customerAddress" /> */}
            Delivery Address Info
          </header>
          <AddressWrapper>
            <ShowFieldValue
              field="Order.orderDeatils.houseNumber"
              value={isOrder?.Address["house_number"]}
            />
            <ShowFieldValue
              field="Order.orderDeatils.street"
              value={isOrder?.Address["street"]}
            />
            <ShowFieldValue
              field="Order.orderDeatils.city"
              value={isOrder?.Address["city"]}
            />
            <ShowFieldValue
              field="Order.orderDeatils.country"
              value={isOrder?.Address["country"]}
            />
            {/* <div className="trackOrderBtnWrapper">
              <div>
                <a
                  target="_blank"
                  href={`http://maps.google.com/maps?q=${lat},${long}+(My+Point)&z=14&ll=${lat},${long}`}
                >
                  <button>Customer Location</button>
                </a>
              </div>
            </div> */}
          </AddressWrapper>
        </DeliveryAddressWrapper>
      )}

      <section className="parent-user-feedback" dir={userlang}>
        <section className="user-feedback">
          <div className="user-inner-feedback">
            <ViewUserFeedback
              buttonPrimary={customTheme?.button_primary_color}
              buttonSecondary={customTheme?.button_secondary_color}
              buttonTextColor={customTheme?.button_text_color}
              themeColor={customTheme?.theme_color}
            >
              <div
                className={
                  "view-receipt " + (active === "view_receipt" ? "active" : "")
                }
                onClick={(e) => handleReceipt("view_receipt")}
              >
                <ViewRecieptIcon className="icon" />
                <label>
                  <IntlMessage id="userfeedback.button.viewreceipt" />
                </label>
              </div>
              <div
                className={
                  "view-receipt " + (active === "emailBlog" ? "active" : "")
                }
                onClick={(e) => handleEmail("emailBlog")}
              >
                {/* <img src={EmailFeedbackIcon} alt="user-feedback" /> */}
                <EmailSvgIcon className="icon" />
                <label>
                  {" "}
                  <IntlMessage id="userfeedback.button.email" />
                </label>
              </div>

              <div
                className={
                  "view-receipt" + (active === "feedback" ? "active" : "")
                }
                onClick={(e) => {
                  handleFeedback("feedback");
                }}
              >
                <GivefeedbackSvg className="icon" />
                <label>
                  <IntlMessage id="userfeedback.button.givefeedback" />
                </label>
              </div>
            </ViewUserFeedback>
          </div>
        </section>

        {/* email sections start here for above tabs */}
        <section className="email-detail" style={{ display: emailBlog }}>
          <div className="right-item">
            <input
              style={{
                background: customTheme?.background_color,
                border: `1px solid ${customTheme?.theme_color}`,
                color: customTheme?.text_color,
              }}
              type="text"
              value={intl.formatMessage({
                id: "userfeedback.button.email",
              })}
              readOnly
            />
          </div>
          <div className="left-item">
            <input
              style={{
                background: customTheme?.background_color,
                border: `1px solid ${customTheme?.theme_color}`,
                color: customTheme?.text_color,
              }}
              type="text"
              value={intl.formatMessage({
                id: "userfeedback.email.meassage",
              })}
              readOnly
            />
          </div>

          <div className="right-item">
            <input
              style={{
                background: customTheme?.background_color,
                border: `1px solid ${customTheme?.theme_color}`,
                color: customTheme?.text_color,
              }}
              type="email"
              value={intl.formatMessage({
                id: "userfeedback.email.placeholder",
              })}
              onChange={(e) => setEmailID(e.target.value)}
            />
          </div>
          <div className="sendEmailBtnWrapper">
            <button
              className="sendEmailBtn"
              onClick={sendReceiptOnEmail}
              style={{
                color: customTheme?.button_text_color,
                background: `linear-gradient(91.33deg,${customTheme?.button_primary_color} 0%,${customTheme?.button_secondary_color} 100%)`,
              }}
            >
              <IntlMessage id="userfeedback.email.button.send" />
            </button>
          </div>
        </section>
        {/* end of email section for above tabs */}

        {/* view receipt tabs start here for above tabs links */}

        <section className="view-receipt-blog" style={{ display: receiptBlog }}>
          <div className="inner-receipt-blog">
            <input
              style={{
                background: customTheme?.background_color,
                border: `1px solid ${customTheme?.theme_color}`,
              }}
              type="text"
              placeholder={intl.formatMessage({
                id: "userfeedback.button.viewreceipt",
              })}
              readOnly
              className="custom-receipt-blog"
            />
            <span>{moment(new Date()).format("hh:mm a")}</span>
          </div>
          {loading ? (
            <div
              style={{
                width: "100%",
                height: "50px",
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress color="inherit" />
            </div>
          ) : (
            <FeedBackReceiptDetail orderDetail={orderDetail} />
          )}
        </section>

        {/* end of receipt for above links */}

        <FeedBackBottomLinks dir={userlang} className="feedback-bottom-links">
          <NavLink
            className="link"
            to={`/${currentID}/addfoodlist`}
            style={{ color: customTheme?.theme_color }}
          >
            <IntlMessage id="userfeedback.button.gobacktothemenu" />
          </NavLink>
        </FeedBackBottomLinks>
      </section>
    </>
  );
}

const ViewUserFeedback = styled.div`
  margin: 20px 0px 0px;

  .view-receipt {
    display: flex;
    background: ${({ buttonPrimary, buttonSecondary }) => `linear-gradient(45deg, ${buttonPrimary}, ${buttonSecondary})`};
    border-radius: 6.38417px;
    padding: 10px 10px 10px 10px;
    margin: 0px 0px 10px;
    .icon {
      color: ${({ buttonTextColor }) => buttonTextColor};
    }
  }

  .view-receipt label {
    margin: 6px 10px 0px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 1px;
    color: ${({ buttonTextColor }) => `${buttonTextColor}`};
  }

  .view-receipt.active {
    background: ${({ themeColor }) => themeColor};
    cursor: pointer;
    label {
      color: ${({ buttonTextColor }) => buttonTextColor};
    }
    .icon {
      color: ${({ buttonTextColor }) => buttonTextColor};
    }
  }
`;

const AddressWrapper = styled.div`
  margin: 10px 0;
  label {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 17px;
    letter-spacing: 0.08em;
    color: #000000;
  }
  span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 17px;
    letter-spacing: 0.08em;
    color: rgba(0, 0, 0, 0.6);
    margin: 0 10px;
  }
  .trackOrderBtnWrapper {
    margin-top: 20px;
    button {
      padding: 10px 20px;
      font-size: 15px;
      font-family: "Montserrat";
      background: #17ce17;
      border-radius: 10px;
      color: #fff;
    }
  }
`;

const DeliveryAddressWrapper = styled.div`
  width: 100 %;
  background: #fff2f6;
  padding: 10px 20px;

  header {
    margin: 6px 10px 0px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 1px;
    color: #282828;
  }
}`;

const InstructionsForTakewayOrder = styled.div`
width: 100 %;
background: #fff2f6;
padding: 10px 20px;

  header {
  margin: 6px 10px 0px;
  font - family: "Poppins";
  font - style: normal;
  font - weight: 500;
  font - size: 15px;
  line - height: 16px;
  text - align: center;
  letter - spacing: 1px;
  color: #282828;
}

  div {
  display: flex;
  flex - direction: column;
  justify - content: center;
}

  p {
  margin: 6px 10px 0px;
  font - family: "Poppins";
  font - style: normal;
  font - weight: 500;
  font - size: 15px;
  line - height: 16px;
  letter - spacing: 1px;
  color: #fc2b6e;
  text - align: center;
    a {
    // color: #fc2b6e;
  }
}

  h6 {
  margin: 6px 10px 0px;
  font - family: "Poppins";
  font - style: normal;
  font - weight: 500;
  font - size: 15px;
  line - height: 16px;
  text - align: center;
  letter - spacing: 1px;
  color: #282828;
}

  button {
  background: #fff2f6;
  border: 1px solid #fc2b6e;
  border - radius: 6.38417px;
  padding: 10px 10px 10px 10px;
  margin: 6px 10px 0px;
  font - family: "Poppins";
  font - style: normal;
  font - weight: 500;
  font - size: 15px;
  line - height: 16px;
  text - align: center;
  letter - spacing: 1px;
  color: #fc2b6e;
}
`;

const FeedBackBottomLinks = styled.div`
width: 100 %;
margin: 0px 0px 50px 0px;

  .link {
  font - family: "Poppins";
  font - style: normal;
  font - weight: 400;
  font - size: 12px;
  line - height: 16px;
  margin - right: ${({ dir }) => dir === "ltr" && "23px"};
  margin - left: ${({ dir }) => dir === "rtl" && "23px"};
  float: ${({ dir }) => dir === "ltr" && "right"};
  float: ${({ dir }) => dir === "rtl" && "left"};
  text - align: center;
  text - decoration - line: underline;
  color: #fc2b6e;
}
`;
