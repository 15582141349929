import { createSlice } from "@reduxjs/toolkit";

export const isUnifiedSlice = createSlice({
  name: "isUnifiedUser",

  initialState: {
    is_aura_user: false,
    isRedeemPoints: true,
    orderType: "Delivery",
    UnifiedCustomerInfo: null,
    unifiedNotification: [],
    is_UnifiedLoader: false,
  },

  reducers: {
    unifiedOrderType: (state, action) => {
      if (action?.payload) {
        state.orderType = action.payload;
      }
    },
    loginWithAuraGetCustomerInfo: (state, action) => {
      if (action.payload) {
        state.UnifiedCustomerInfo = action.payload;
      }
    },
    redeemLoyalityPoints: (state, action) => {
      if (action) {
        state.isRedeemPoints = action.payload;
      }
    },
    unifiedNotificationPersist: (state, action) => {
      if (action.payload) {
        state.unifiedNotification = action.payload;
      }
    },

    checkAndUpdateUser: (state, action) => {
      if (action) {
        state.is_aura_user = action.payload;
      }
    },

    handleUnifiedLoader: (state, action) => {
      if (action) {
        state.is_UnifiedLoader = action.payload;
      }
    },

    unifiedMarkAsRead: (state, action) => {
      if (action.payload) {
        state.unifiedNotification.filter((ele) => {
          ele?.id == action.payload && state.unifiedNotification.pop();
        });
      }
    },
  },
});

export const {
  unifiedOrderType,
  loginWithAuraGetCustomerInfo,
  redeemLoyalityPoints,
  unifiedNotificationPersist,
  unifiedMarkAsRead,
  checkAndUpdateUser,
  handleUnifiedLoader,
} = isUnifiedSlice.actions;

export default isUnifiedSlice.reducer;
