// DEV AND STAGE
export const aqlutstorage = "https://aqlutstorage.blob.core.windows.net/";

// PRODUCTION
// export const aqlutstorage = "https://aqlutstoragepr.blob.core.windows.net/";

// AQLUT PRODUCTION
// export const aqlutstorage = "https://aqlutprodstoragmain.blob.core.windows.net/";

export const containerProfile = "profile/";

export const containerMenu = "menu/";

export const containerItem = "item/";

export const containerSection = "section/";

export const containerMenuBranding = "menubranding/";

export const containerAdvertisment = "banners/"

// test