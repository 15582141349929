import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import styled from "styled-components";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";

import {
  deleteCartItem,
  updateCart,
  updateCartItemQty,
  updateCartRecommendedItemQty,
} from "../../services/Collection";
import {
  addData,
  changeFreeItemStatus,
  decrementQty,
  decrementRecommendedQty,
  incrementQty,
  incrementRecommendedQty,
} from "../../store/Cart";
import IntlMessage from "../../Utils/IntlMessage";
import { useNavigate } from "react-router-dom";
import { AddIcons, DecrementIcons, DeleteIcon } from "../../Utils/Icons";

function CartItem({ el, indicator, cartId, currency, isCartModified }) {
  const aqlutstorage = process.env.REACT_APP_IMAGE_BASEURL;
  const containerItem = process.env.REACT_APP_CONTAINER_ITEM;

  const [isdisabled, setdisabled] = useState(false);
  const [isdisabledRecomended, setdisabledRecomended] = useState(false);

  const {
    isFreeItem,
    cartDiscounts,
    cartData,
    cartFreeItem,
    isOverallDiscount,
    discount,
    cartTotalDiscount,
    restaurentData,
  } = useSelector(({ cartSlice, restaurentSlice }) => ({
    ...cartSlice,
    ...restaurentSlice,
  }));
  const dir = useSelector((state) => state.selectLang.direction);
  const dispatch = useDispatch();
  // const getAlreadyScanedID = useSelector((state) => state.StoreForMultiUsers);
  // const currentID = getAlreadyScanedID?.workStation?.slice(-1)?.[0]?.id;

  const getAlreadyScanedID = useSelector((state) => state.StoreForMultiUsers);
  const currentActive =
    getAlreadyScanedID?.getScannedQrcodeAndRestuarent?.slice(-1);

  const currentID = currentActive?.[0]?.qrcodeid;
  const navigate = useNavigate();
  const customTheme = useSelector((state) => state?.theme?.branding);

  const isDeliveryEnable = useSelector((state) => state?.deliveryModule);

  //Check if item quatanity changes to apply discount

  const applyDiscount = (quantity, updatedprice) => {
    if (cartDiscounts?.length > 0 && !isOverallDiscount) {
      console.log(cartDiscounts, isOverallDiscount, "applyyyyyyyyyyyyyyyyyyy");
      cartDiscounts?.map((udx) => {
        if (
          quantity >= udx?.minimum_quantity &&
          el?.item_id === udx?.offer_item_id
        ) {
          //Recalulate the store
          let temp_discount = parseInt(udx?.discount);
          //Getting add to cart addons
          if (el?.Item?.AddToCartAddOns?.length > 0) {
            let totalAddOnsPrice = 0;
            for (let i = 0; i < el?.Item?.AddToCartAddOns.length; i++) {
              totalAddOnsPrice =
                totalAddOnsPrice +
                Number(el?.Item?.AddToCartAddOns[i].addon_price);
            }
            let final_price = parseFloat(
              Number(updatedprice) + Number(totalAddOnsPrice) * Number(quantity)
            );

            let discount_added = Number(
              parseFloat((final_price * temp_discount) / 100).toFixed(2)
            );
            //send info into to database
            let obj = {
              type: "discount",
              cart_id: el?.id,
              item_id: udx?.id,
              qty: quantity,
              offer_item_id: udx?.offer_item_id,
              discount: discount_added,
            };
            console.log(obj, "totallllllllllll objectttttttttt");
            // update
            updateDiscount(obj);
            // isCartModified();
          } else {
            let price = el?.price * quantity;
            //Price after add on added
            let discount_added = Number(
              parseFloat(
                (Number(updatedprice) * Number(temp_discount)) / 100
              ).toFixed(2)
            );
            //send info into to database
            let obj = {
              type: "discount",
              cart_id: el?.id,
              item_id: udx?.id,
              qty: quantity,
              offer_item_id: udx?.offer_item_id,
              discount: discount_added,
            };
            updateDiscount(obj);
          }
        }
        // If condition not satisfied then discount will removed
        else if (
          udx?.minimum_quantity - 1 === quantity &&
          el?.item_id === udx?.offer_item_id
        ) {
          // setdisabled(true);

          //send info into to database
          let obj = {
            type: "discount",
            cart_id: el?.id,
            item_id: udx.id,
            qty: quantity,
            discount: 0,
          };
          // update
          updateDiscount(obj);
        }
      });
    }
  };

  const updateDiscount = async (payload) => {
    //Calling add discount API
    const res = await updateCart({ ...payload, qr_code_id: currentID });
    console.log(res, "ressssssssssssssssssssssponse");
    if (res.status === 200) {
      setdisabled(false);

      let tempCartObject;
      let tempCartArray = [];
      for (let i = 0; i < cartData.length; i++) {
        tempCartObject = { ...cartData[i], updatedAt: null, createdAt: null };
        tempCartArray.push(tempCartObject);
      }

      let tempResponseObject;
      let tempResponseArray = [];
      for (let i = 0; i < res?.data.length; i++) {
        tempResponseObject = {
          ...res?.data[i],
          updatedAt: null,
          createdAt: null,
        };
        tempResponseArray.push(tempResponseObject);
      }

      if (JSON.stringify(tempCartArray) !== JSON.stringify(tempResponseArray)) {
        console.log("1499999999999999");
        dispatch(addData(res?.data));
      }
      // dispatch(overAllDiscount([]));
    } else {
      setdisabled(false);
    }
  };

  //Add Free item to cart when condition satisfied
  const UpdateCartData = async (payload) => {
    console.log(payload, "payloadddddddddd");
    //Calling api to add free item in the cart

    const res = await updateCart({ ...payload, qr_code_id: currentID });

    if (res.status === 200) {
      //Changing the status of free item in cart

      let tempCartObject;
      let tempCartArray = [];
      for (let i = 0; i < cartData.length; i++) {
        tempCartObject = { ...cartData[i], updatedAt: null, createdAt: null };
        tempCartArray.push(tempCartObject);
      }

      let tempResponseObject;
      let tempResponseArray = [];
      for (let i = 0; i < res?.data.length; i++) {
        tempResponseObject = {
          ...res?.data[i],
          updatedAt: null,
          createdAt: null,
        };
        tempResponseArray.push(tempResponseObject);
      }

      if (JSON.stringify(tempCartArray) !== JSON.stringify(tempResponseArray)) {
        console.log("17222222222222222222222");
        dispatch(addData(res?.data));
      }
      // dispatch(overAllDiscount([]));
      setdisabled(false);
    } else {
      setdisabled(false);
    }
  };

  // Add Free item to cart when condition satisfied
  useEffect(() => {
    console.log(isOverallDiscount, "isOverallDiscount");
    if (cartFreeItem?.length > 0 && !isOverallDiscount) {
      // eslint-disable-next-line array-callback-return
      cartFreeItem?.map((udx) => {
        const qty = parseInt(el?.qty);
        console.log(qty, "cartFreeItem qty", udx?.quantity);
        if (qty >= udx?.quantity && el?.item_id === udx?.offer_item_id) {
          // setdisabled(true);
          let payload = {
            type: "freeitems",
            status: "1",
            item_id: udx?.offer_item_id,
            free_item_id: udx?.free_item_id,
            qty: el?.qty,
            cart_id: el?.id,
          };
          dispatch(changeFreeItemStatus(true));
          // alert(187)
          UpdateCartData(payload);

          // setdisabled(false);
        }
        //If Item quantity is less than free eligibilty
        else if (
          udx?.quantity - 1 === el?.qty &&
          isFreeItem &&
          el?.item_id === udx?.offer_item_id
        ) {
          // setdisabled(true);
          let offerItem = {};
          // eslint-disable-next-line array-callback-return
          cartData.map((idx) => {
            if (idx.item_id === udx.free_item_id) {
              offerItem = idx.id;
              let payload = {
                type: "freeitems",
                status: "2",
                item_id: udx.offer_item_id,
                qty: el?.qty,
                free_item_id: udx.free_item_id,
                cart_id: offerItem,
              };
              dispatch(changeFreeItemStatus(false));
              UpdateCartData(payload);
              // setdisabled(false);
            }
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [el.qty]);

  // React time check for cart item  single discount
  // useEffect(() => {
  //   //Checking if cart discount is applicable
  //   cartDiscounts?.map((udx) => {
  //     const qty = parseInt(el?.qty);
  //     if (qty === udx?.minimum_quantity && el?.item_id === udx?.offer_item_id) {
  //       //Recalulate the store
  //       let temp_discount = parseInt(udx?.discount);
  //       //Getting add to cart addons
  //       if (el?.Item?.AddToCartAddOns?.length > 0) {
  //         const { addon_price } = Object.assign(
  //           {},
  //           ...el?.Item?.AddToCartAddOns
  //         );

  //         //Price after add on added
  //         let price = el?.price * el?.qty;

  //         let final_price = parseFloat(price + parseInt(addon_price * el?.qty));

  //         let discount_added = parseFloat(
  //           (final_price * temp_discount) / 100
  //         ).toFixed(2);
  //         //send info into to database
  //         let obj = {
  //           type: "discount",
  //           cart_id: el?.id,
  //           item_id: udx?.id,
  //           qty: el?.qty,
  //           offer_item_id: udx?.offer_item_id,
  //           discount: discount_added,
  //         };
  //         // update
  //         updateDiscount(obj);
  //       } else {
  //         //Getting  Price
  //         let price = el?.price * el?.qty;
  //         //Price after add on added
  //         let discount_added = parseFloat(
  //           (price * temp_discount) / 100
  //         ).toFixed(2);
  //         //send info into to database
  //         let obj = {
  //           type: "discount",
  //           cart_id: el?.id,
  //           item_id: udx?.id,
  //           qty: el?.qty,
  //           offer_item_id: udx?.offer_item_id,
  //           discount: discount_added,
  //         };
  //         // update
  //         updateDiscount(obj);
  //       }
  //     }
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // useEffect(()=> {
  //   if(cartTotalDiscount.length  > 0){

  //   }
  // },[])

  //Handle increment function for cart items
  const handleIncrement = (id, ItemId, type) => {
    updateCartItem(ItemId, type, id);
  };
  const handleDecrement = (id, ItemId, type) => {
    if (!isdisabled) {
      if (el.qty > 1) {
        updateCartItem(ItemId, type, id);
      }
    }
  };
  const updateCartItem = async (ItemId, type, id) => {
    // type 1 = increment & type 2 = decrement
    let obj = {
      type,
      qr_code_id: currentID,
      item_id: ItemId.toString(),
      cart_id: cartId.toString(),
    };
    const res = await updateCartItemQty(obj);
    if (res.status === 200) {
      if (type === "1") {
        let object = {
          id: id,
          price: res?.data?.price,
          qty: res?.data?.qty,
        };
        dispatch(incrementQty(object));
        setdisabled(false);

        const isDiscount = cartDiscounts.filter(
          (idx) => ItemId === idx.offer_item_id
        );
        if (isDiscount?.length > 0) {
          let updatedqty = parseInt(res?.data?.qty);
          let updatedprice = parseInt(res?.data?.price);
          // let quantity = parseInt(qty + 1);
          applyDiscount(updatedqty, updatedprice);
        }
      } else if (type === "2") {
        let object = {
          id: id,
          price: res?.data?.price,
          qty: res?.data?.qty,
        };
        dispatch(decrementQty(object));
        setdisabled(false);

        const isDiscount = cartDiscounts.filter(
          (idx) => ItemId === idx.offer_item_id
        );
        if (isDiscount?.length > 0) {
          let updatedqty = parseInt(res?.data?.qty);
          let updatedprice = parseInt(res?.data?.price);
          // let quantity = parseInt(qty + 1);
          applyDiscount(updatedqty, updatedprice);
        }
      }
    } else {
      setdisabled(false);
    }
  };

  const updateRCartItem = async (ItemId, type) => {
    let obj = {
      type,
      qr_code_id: currentID,
      item_id: ItemId.toString(),
      cart_id: cartId.toString(),
    };

    let res = await updateCartRecommendedItemQty(obj);
    if (res.status === 200) {
      setdisabledRecomended(false);
      if (type === "1") {
        dispatch(incrementRecommendedQty(ItemId));
      } else if (type === "2") {
        dispatch(decrementRecommendedQty(ItemId));
      }
    } else {
      setdisabledRecomended(false);
      toast.error(res.message);
    }
  };

  const handleRecommendedIncrement = (id, type) => {
    updateRCartItem(id, type);
  };

  const handleRecommendedDecrement = async (id, type) => {
    if (!isdisabledRecomended) {
      if (el.count > 1) {
        setdisabledRecomended(true);
        updateRCartItem(id, type);
      } else {
        setdisabledRecomended(false);
        toast.error("Quantity can't be less than 1");
      }
    }
  };
  //Hnadle delete items from cart
  const handleDeleteCartItem = async (cart_id, recommended_item_id) => {
    //Onject to send to backend
    let obj1 = {
      qr_code_id: currentID,
      cart_id: cart_id,
      recommended_item_id,
    };
    //Calling the delete function
    await deleteItem(obj1);

    //Deleting from the server
    async function deleteItem(obj1) {
      //Calling the API
      let res = await deleteCartItem(obj1);
      if (res.status === 200) {
        //Inform parent component
        isCartModified();
        dispatch(addData(res?.data));
        // handleLoader(false);
        toast.success("Item has been deleted succesfully");
      } else if (res.status === 404) {
        navigate(`/${currentID}/addfoodlist`);
      } else {
        // handleLoader(false);
        toast.error(res.message);
      }
    }
  };

  // const updateStoreWithUpdatedValuesFromDB = async () => {
  //   // setLoading(true);
  //   let qrcode = "";

  //   if (restaurentData?.QrCodeGroup?.group_type === "Dine In") {
  //     qrcode = "Dine In";
  //   } else {
  //     if (isDeliveryEnable?.orderType === "takeaway") {
  //       qrcode = "Takeaway";
  //     } else {
  //       qrcode = "Delivery";
  //     }
  //   }

  //   let res = await viewCart(currentID, qrcode);
  //   if (res.status === 200) {
  //     dispatch(addData(res?.data));
  //     // setLoaded(true);
  //     // setLoading(false);
  //   } else if (res.status === 404) {
  //     // setLoaded(true);
  //     // setLoading(false);
  //     dispatch(addData([]));
  //   } else {
  //     // setLoaded(true);
  //     // setLoading(false);
  //     dispatch(addData([]));
  //     // toast.error();
  //   }
  // };

  useEffect(() => {
    // This is for item price discount for first render
    if (cartDiscounts?.length > 0) applyDiscount(el?.qty, el?.price);
  }, []);

  return (
    <>
      <MainWrapper boxBackground={customTheme?.placeholder_box_color} >
        <CartItemParent dir={dir}>
          <div className="orderFoodList">
            <img src={aqlutstorage + containerItem + el?.Item?.image} alt="" />
          </div>
          <div className="orderFoodDetails">
            <h5 style={{color: customTheme?.header_color}} >{dir === "ltr" ? el?.Item?.name : el?.Item?.ar_name}</h5>

            {el?.Item?.AddToCartAddOns?.length > 0 && (
              <h6 style={{color: customTheme?.text_color}} >
                <IntlMessage id="AddItemDrawer.addon" /> :{"  "}
                {dir === "ltr"
                  ? el?.Item?.AddToCartAddOns.map((addOn, key) => {
                      return key === 0
                        ? addOn?.OptionItemModule?.name
                        : ", " + addOn?.OptionItemModule?.name;
                    })
                  : el?.Item?.AddToCartAddOns.map((addOn, key) => {
                      return key === 0
                        ? addOn?.OptionItemModule?.ar_name
                        : ", " + addOn?.OptionItemModule?.ar_name;
                    })}
              </h6>
            )}

            <div className="PriceAndQuantity">
              {indicator === "r" ? (
                <p style={{color: customTheme?.text_color}} >
                  {currency} {el?.recommended_price}
                </p>
              ) : (
                <p style={{color: customTheme?.text_color}} >
                  {el?.price > 0 ? el?.currency : ""}{" "}
                  {el?.price > 0 ? el?.price : "Free"}
                </p>
              )}

              <div style={{ border: `1px solid ${customTheme?.theme_color}` }}>
                {indicator === "r"
                  ? el?.recommended_price > 0 && (
                      <i
                        style={{ display: "flex", alignItems: "center" }}
                        disabled={isdisabledRecomended ? "true" : ""}
                        onClick={() => {
                          setdisabledRecomended(true);
                          handleRecommendedDecrement(
                            el.recommended_item_id,
                            "2"
                          );
                        }}
                      >
                        <DecrementIcons color={customTheme?.theme_color} />
                      </i>
                      // <img
                      //   src={DecrementButton}
                      //   className="operation"
                      //   alt="Decrement"
                      //   disabled={isdisabledRecomended ? "true" : ""}
                      //   onClick={() => {
                      //     setdisabledRecomended(true);
                      //     handleRecommendedDecrement(
                      //       el.recommended_item_id,
                      //       "2"
                      //     );
                      //   }}
                      // />
                    )
                  : el?.price > 0 && (
                      <i
                        style={{ display: "flex", alignItems: "center" }}
                        disabled={!isdisabled ? true : false}
                        onClick={() => {
                          setdisabled(true);
                          handleDecrement(el.id, el.item_id, "2");
                        }}
                      >
                        <DecrementIcons color={customTheme?.theme_color} />
                      </i>

                      // <img
                      //   src={DecrementButton}
                      //   className="operation"
                      //   alt="Decrement"
                      //   disabled={!isdisabled ? true : false}
                      //   onClick={() => {
                      //     setdisabled(true);
                      //     handleDecrement(el.id, el.item_id, "2");
                      //   }}
                      // />
                    )}

                {el?.price > 0 && (
                  <input
                    type="number"
                    value={parseInt(el.qty)}
                    readOnly="readonly"
                    min={1}
                  />
                )}

                {el?.recommended_price > 0 && (
                  <input
                    type="number"
                    value={parseInt(el.count)}
                    readOnly="readonly"
                    min={1}
                  />
                )}

                {indicator === "r"
                  ? el?.recommended_price > 0 && (
                      <i
                        style={{ display: "flex", alignItems: "center" }}
                        disabled={isdisabledRecomended ? "true" : ""}
                        onClick={() => {
                          setdisabledRecomended(true);
                          if (!isdisabledRecomended) {
                            handleRecommendedIncrement(
                              el.recommended_item_id,
                              "1"
                            );
                          }
                        }}
                      >
                        <AddIcons color={customTheme?.theme_color} />
                      </i>

                      // <img
                      //   src={AddIcon}
                      //   className="operation"
                      //   alt="Increment"
                      //   disabled={isdisabledRecomended ? "true" : ""}
                      //   onClick={() => {
                      //     setdisabledRecomended(true);
                      //     if (!isdisabledRecomended) {
                      //       handleRecommendedIncrement(
                      //         el.recommended_item_id,
                      //         "1"
                      //       );
                      //     }
                      //   }}
                      // />
                    )
                  : el?.price > 0 && (
                      <i
                        style={{ display: "flex", alignItems: "center" }}
                        disabled={isdisabled ? "true" : ""}
                        onClick={() => {
                          setdisabled(true);
                          if (!isdisabled) {
                            handleIncrement(el.id, el.item_id, "1");
                          }
                        }}
                      >
                        <AddIcons color={customTheme?.theme_color} />
                      </i>

                      // <img
                      //   src={AddIcon}
                      //   className="operation"
                      //   alt="Increment"
                      //   disabled={isdisabled ? "true" : ""}
                      //   onClick={() => {
                      //     setdisabled(true);
                      //     if (!isdisabled) {
                      //       handleIncrement(el.id, el.item_id, "1");
                      //     }
                      //   }}
                      // />
                    )}
              </div>
            </div>
          </div>

          <div className="deleteIcon">
            {indicator === "r"
              ? el.recommended_price > 0 && (
                  <i
                    onClick={() =>
                      handleDeleteCartItem(cartId, el.recommended_item_id)
                    }
                  >
                    <DeleteIcon color={customTheme?.theme_color} />
                  </i>
                  // <img
                  //   src={require("../../assets/Delete.png")}
                  //   alt=""
                  //   onClick={() =>
                  //     handleDeleteCartItem(cartId, el.recommended_item_id)
                  //   }
                  // />
                )
              : el.price > 0 && (
                  <i onClick={() => handleDeleteCartItem(cartId, -1)}>
                    <DeleteIcon color={customTheme?.theme_color} />
                  </i>

                  // <img
                  //   src={require("../../assets/Delete.png")}
                  //   alt=""
                  //   onClick={() => handleDeleteCartItem(cartId, -1)}
                  // />
                )}
          </div>
        </CartItemParent>

        {(el?.special_notes || el?.ar_special_notes) && (
          <SpecialNoteWrapper>
            <div className="special-Note">
              <p style={{ color: customTheme?.text_color }}>
                {dir != "rtl" ? el?.special_notes : el?.ar_special_notes}
              </p>
            </div>
          </SpecialNoteWrapper>
        )}
      </MainWrapper>

      {cartFreeItem?.length > 0 &&
        cartFreeItem?.map((udx, index) =>
          el.price > 0 &&
          el?.qty < udx?.quantity &&
          el.item_id === udx.offer_item_id ? (
            <>
              <div style={{ display: "flex" }} dir={dir}>
                <LocalOfferIcon sx={{ marginRight: "5px", color: "green" }} />
                <p key={index} className="freeItem">
                  <IntlMessage id="OfferMessage.AddMinimum" /> {udx.quantity}{" "}
                  <IntlMessage id="OfferMessage.QtyAvailFree" />{" "}
                  {udx?.freeItemDetails?.name}
                </p>
              </div>
            </>
          ) : (
            ""
          )
        )}

      {cartDiscounts?.length > 0 &&
        cartDiscounts !== undefined &&
        !isOverallDiscount &&
        discount === 0 &&
        cartDiscounts?.map(
          (udx, index) =>
            el.price > 0 &&
            el?.item_id === udx?.offer_item_id && (
              <div style={{ display: "flex" }} dir={dir}>
                <LocalOfferIcon sx={{ marginRight: "5px", color: "green" }} />
                <p className="freeItem">
                  <IntlMessage id="OfferMessage.AddMinimum" />{" "}
                  {udx.minimum_quantity}{" "}
                  <IntlMessage id="OfferMessage.QtyAvail" /> {udx.discount}%{" "}
                  <IntlMessage id="OfferMessage.Discount" />
                </p>
              </div>
            )
        )}
    </>
  );
}

export default CartItem;

const MainWrapper = styled.div`
  width: 100%;
  padding:12px;
  box-shadow: 0 3px 8px 4px rgb(0 0 0 / 15%);
  border-radius: 8px;
  background: ${({boxBackground})=> boxBackground};
`;

const CartItemParent = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  // margin-bottom: 10px;

  .deleteIcon {
    position: absolute;
    top: 0px;
    left: ${({ dir }) => dir === "rtl" && 0};
    right: ${({ dir }) => dir === "ltr" && 0};
  }

  .orderFoodList {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: ${({ dir }) => dir === "ltr" && "20px"};
    margin-left: ${({ dir }) => dir === "rtl" && "20px"};
    img {
      width: 76px;
      height: 74px;
      border-radius: 8px;
      object-fit: cover;
    }
  }

  .orderCounter {
    padding: 0px 10px 0px;
    display: flex;
    position: absolute;
    top: 60px;
    border: 0.506329px solid #e1e1e1;
    border-radius: 5.06329px;
    background: #fff;
    .operation {
      width: 10px;
      height: 2px;
      :hover {
        cursor: pointer;
      }
    }

    input {
      display: block;
      width: 20px;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #212529;
      border: 0px;
      margin: 0px 3px;
      text-align: center;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border-radius: 0.25rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      :focus {
        outline: none;
      }
    }
  }
  .orderFoodDetails {
    min-width:190px;
    width: 80%;
    h5 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #202020;
      display: inline-flex;
      padding: 0 0 10px 0;
    }
    p {
      font-family: "Jost";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 22px;
      color: #707070;
      text-transform: uppercase;
    }
    h6 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #7b7f91;
      padding: 5px 0;
    }

    .PriceAndQuantity {
      display: flex;
      justify-content: space-between;
      p {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #202020;
      }
      div {
        display: flex;
        justify-content: space-between;
        width: 62px;
        height: 24px;
        background: #ffffff;
        border: 2px solid #fc2b6e;
        border-right-color: #cb0034;
        border-radius: 16px;
        padding: 2px 5px;

        input {
          text-align: center;
          width: 16px;
          border-style: none;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          color: #202020;
        }

        button {
          border-style: none;
        }

        img {
          width: 10px;
        }
      }
    }
  }
`;

const SpecialNoteWrapper = styled.div`
  margin-bottom: 15px;
  border-bottom: 1px solid #e9e2e2;

  .special-Note {
    font-family: "Poppins";
    color: #979797;
    font-size: 15px;

    @media (min-width: 550px) {
      height: 100px;
      overflow-y: scroll;
    }
  }
`;
