import React from "react";
import "../ThankYou/ThankYou.scss";
import thankIcon from "../../assets/thanks.png";

import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import IntlMessage from "../../Utils/IntlMessage";

export default function ThankYou() {
  const getAlreadyScanedID = useSelector((state) => state.StoreForMultiUsers);
  const currentActive =
    getAlreadyScanedID?.getScannedQrcodeAndRestuarent?.slice(-1);
  const currentID = currentActive?.[0]?.qrcodeid;
  return (
    <>
      <NavLink to={`/${currentID}/addfoodlist`}>
        <img
          className="crossIcon"
          src="/assets/images/blackCrossIcon.svg"
          alt=""
        />
      </NavLink>
      <div className="thank-you">
        <img src={thankIcon} alt="Thanks" />
        <h6>
          <IntlMessage id="thanks.thanksForRating.title" />
        </h6>
      </div>
    </>
  );
}
