/* eslint-disable react/jsx-no-target-blank */
import { CircularProgress } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";

import {
  getOrderDetails,
  getUpdateOrderDetails,
  syncOrderPayemnt,
  syncPOS,
} from "../../services/Collection";
import { addOrderDetails } from "../../store/orderPayment";
import { unifiedOrderType } from "../../store/UnifiedUser/UnifiedUser";
import { LoadingWapper } from "../../style/GobalStyle";
import { OrderIcon } from "../../Utils/Icons";
import { OrderDetailChatIcon } from "../../Utils/Images";
import ChatOption from "../ChatOption";
import ChatResponse from "../ChatResponse";
import FeedBackBillDetail from "../FeedBackBillDetail";
import { Header } from "../header";
import SelectedItem from "../SelectedItem";

export const UnifiedOrdersDetails = () => {
  const location = useLocation().state;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getAlreadyScanedID = useSelector((state) => state.StoreForMultiUsers);
  const currentActive =
    getAlreadyScanedID?.getScannedQrcodeAndRestuarent?.slice(-1);

  const currentID = currentActive?.[0]?.qrcodeid;
  const SingleorderDetails = useSelector(
    (state) => state?.orderInformationSlice?.orderDeails
  );

  const RestuarantDetails = useSelector(
    (state) => state?.restaurentSlice?.restaurentData
  );
  const [fetchingDataLoader, setFetchingDataLoader] = useState(false);
  const [fetchingDetails, setFetchingDeatils] = useState([]);
  const [showDetails, setShowDetails] = useState(false);

  const [pageLoader, setPageLoader] = useState(false);

  const restaurantLocation = useSelector(
    (state) => state?.restaurentSlice?.restaurentData?.Menu?.Restaurent
  );

  const unifiedNotification = useSelector(
    (state) => state?.isUnifiedSlice?.unifiedNotification
  );

  const updateOrderDetails = async () => {
    setPageLoader(true);
    let payload = {
      order_id: SingleorderDetails?.id || null,
      qr_code_id: location?.qr_code_id,
    };

    let res = await getUpdateOrderDetails(payload);
    if (res.status === 200) {
      dispatch(addOrderDetails(res?.data));
      setPageLoader(false);
    } else {
      toast.error(res?.message);
      setPageLoader(false);
    }
  };

  const OrderDetails = async () => {
    setFetchingDataLoader(true);
    // if (OrderDetail) var { id } = OrderDetail;
    setShowDetails(false);
    let res = await getOrderDetails(SingleorderDetails?.id);
    if (res.status === 200) {
      setFetchingDeatils(res.data);
      setFetchingDataLoader(false);
      setShowDetails(true);
    } else {
      toast.error(res.message, { theme: "colored" });
      setFetchingDataLoader(false);
      setShowDetails(false);
    }
  };
  const syncposAndsendValuestoDB = async (response) => {
    let req = {
      restaurent_id: RestuarantDetails?.restaurent_id,
      qr_code_id: RestuarantDetails?.id,
    };
    let res = await syncPOS(req);
    if (res.status === 200) {
      // dispatch(authSucess(response));
      console.log("pos sync");
    } else {
      // dispatch(authSucess(response));

      console.log("pos sync error");
    }
  };

  const syncOrderPaymentinDB = async () => {
    let req = {
      restaurent_id: RestuarantDetails?.restaurent_id,
      qr_code_id: RestuarantDetails?.id,
    };

    let res = await syncOrderPayemnt(req);
    if (res.status === 200) {
      // dispatch(authSucess(response));

      console.log("order payemnt sync");
    } else {
      // dispatch(authSucess(response));

      console.log("order payemnt sync error");
    }
  };

  const handleOrderDetailAction = () => {
    syncposAndsendValuestoDB();
    syncOrderPaymentinDB();
    if (SingleorderDetails?.id) {
      OrderDetails();
    }
  };

  const ShowPaymentButton = () => {
    if (SingleorderDetails != null) {
      if (SingleorderDetails?.payment_status !== "2") {
        if (SingleorderDetails?.mode_of_payment === "5") {
          return false;
        }
        if (SingleorderDetails?.mode_of_payment === "6") {
          return false;
        }
        if (SingleorderDetails?.payment_status === "3") {
          return false;
        }
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const handlePayButton = () => {
    navigate(`/${currentID}/unified-payment`, { state: SingleorderDetails });
    dispatch(unifiedOrderType(SingleorderDetails?.order_type));
  };
  useEffect(() => {
    if (SingleorderDetails?.id) {
      updateOrderDetails();
    }
  }, []);
  return pageLoader ? (
    <div
      style={{
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center ",
        height: "100vh",
      }}
    >
      <CircularProgress color="inherit" />
    </div>
  ) : (
    <OrderDetailWrapper>
      <div className="">
        <Header />
      </div>
      <div className="orderDetailsBody">
        {/* {(SingleorderDetails?.payment_status !== "2" ||
          (SingleorderDetails?.mode_of_payment !== "5" ??
            SingleorderDetails?.mode_of_payment !== "6")) && (
          <ChatOption
            img={OrderDetailChatIcon}
            icon={<OrderIcon className="icon" />}
            handleChatOption={handlePayButton}
            name={"Pay"}
          />
        )} */}

        {ShowPaymentButton() && (
          <ChatOption
            img={OrderDetailChatIcon}
            icon={<OrderIcon className="icon" />}
            handleChatOption={handlePayButton}
            name={"Pay"}
          />
        )}

        <ChatOption
          img={OrderDetailChatIcon}
          icon={<OrderIcon className="icon" />}
          handleChatOption={handleOrderDetailAction}
          name={"Order Detail"}
        />

        {SingleorderDetails?.order_type == "Takeaway" && (
          <a
            style={{ width: "100%", textDecoration: "none" }}
            target="_blank"
            href={`http://maps.google.com/maps?q=${restaurantLocation?.latitude},${restaurantLocation?.longitude}+(My+Point)&z=14&ll=${restaurantLocation?.latitude},${restaurantLocation?.longitude}`}
          >
            <ChatOption
              img={OrderDetailChatIcon}
              icon={<OrderIcon className="icon" />}
              // handleChatOption={handleRestaurantLocation}
              name={"Restaurant Location"}
            />
          </a>
        )}
      </div>

      {fetchingDataLoader ? (
        <LoadingWapper>
          <CircularProgress sx={{ color: "#fc2b6e", my: 2 }} />
        </LoadingWapper>
      ) : showDetails ? (
        <div className="billDetailsWrapper">
          <SelectedItem data={[fetchingDetails]} />
          <FeedBackBillDetail data={fetchingDetails} />
        </div>
      ) : (
        <></>
      )}
      {unifiedNotification.length > 0 &&
        unifiedNotification?.map(
          (order) =>
            order?.id == location?.id && (
              <ChatResponse message={order?.message} />
            )
        )}
    </OrderDetailWrapper>
  );
};

const OrderDetailWrapper = styled.div`
  width: 100%;
  height: 100%;

  .orderDetailsBody {
    margin-top: 30px;
  }

  .billDetailsWrapper {
    padding: 0px 20px;
    margin-bottom: 50px;
  }
`;
