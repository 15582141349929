import styled from "styled-components";

export const LoadingWapper = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoaderWithAnimation = styled.div`
  width: 100%;
  height: 100vh;
  display: block;
  text-align: center;
  background: #ffffff;
  position: relative;

  .aminationLogo {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
  }
`;