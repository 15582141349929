import moment from "moment";

export const CalculateTime = (dateActive, open, close) => {
  let currentTime = moment().format("HH:mm:ss");
  let startTime = moment(currentTime, "HH:mm:ss");
  let endTime = moment(close, "HH:mm:ss");
  let openTime = moment(open, "HH:mm:ss");

  // calculate total duration
  var duration =
    dateActive === "today"
      ? moment.duration(endTime.diff(startTime))
      : moment.duration(endTime.diff(openTime));

  // duration in hours
  var hours = parseInt(duration.asHours());

  var hours1 =
    dateActive === "today"
      ? parseInt(currentTime.substring(0, 2))
      : parseInt(openTime.format("HH:mm:ss").substring(0, 2));

  let timeArray = [];
  for (let i = 0; i < hours; i++) {
    hours1 = hours1 + 1;
    timeArray.push(hours1);
  }
  return timeArray;
};
