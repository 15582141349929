import { createGlobalStyle } from "styled-components";

export const PaymentListing = createGlobalStyle`
   .payment-listing{
        margin: 10px 20px;
        background: #FCFDFE;
        border: 1px solid #F0F1F7;
        border-radius: 8px;
        padding: 11px;

        .paymentButton{
            display: flex;
            justify-content: center;
            button {
                font-family: 'Jost';
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                letter-spacing: 0.03em;
                text-transform: uppercase;
                color: ${({ theme }) => theme.paymentButtonColor};
                padding: 12px 29px;
                background: ${({ theme }) => theme.paymentButtonBg};
                border-radius: 6.7806px;
                border: none;
                position: relative;
                margin-top: 12px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
`;
