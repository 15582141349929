import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

function BoatMessage({ message, time, type, messagePart1, messagePart2 }) {
  const direction = useSelector((state) => state?.selectLang?.direction);
  const resturantDetails = useSelector((state) => state?.restaurentSlice?.restuarentProfileInfo);
  const customTheme = useSelector((state) => state?.theme?.branding);

  return (
    <ChatResponseStyle dir={direction}  >
      {type === "beginningMessage" ? (
        <div className="col-12" style={{background: customTheme?.background_color , border:`1px solid ${customTheme?.theme_color}`}}>
          <label style={{color: customTheme?.text_color}} >
            <span>{messagePart1}</span>
            <span>{resturantDetails?.name},</span>
            <span>{messagePart2}</span>
          </label>
          <h6>{time}</h6>
        </div>
      ) : (
        <div className="col-12">
          <label>{message}</label>
          <h6>{time}</h6>
        </div>
      )}
    </ChatResponseStyle>
  );
}

export default BoatMessage;

const ChatResponseStyle = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px 20px;
  justify-content: end;

  .col-12 {
    background: ${({ theme }) => theme?.UserChatBg};
    border-radius: 16px;
    padding: 8px 11px;
    width: 70%;
    max-width: 500px;
  }

  label {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
    text-align: left;
  }
  h6 {
    font-family: "Jost";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: ${({ dir }) => (dir === "ltr" ? "right" : "left")};
    color: #a4a3a3;
  }
`;
