import React, { useCallback, useState } from "react";
import SelectedItem from "../../components/SelectedItem";
import Tip from "../../components/Tip";
import IntlMessage from "../../Utils/IntlMessage";
import { Button, CircularProgress, Drawer } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Header } from "../../components";
import { useEffect } from "react";
import { LoadingWapper } from "../../style/GobalStyle";
import {
  ChangeModeOfPayment,
  addOrderCarDetails,
  addOrderOfficeRoomDetails,
  getOrderDetails,
  paymentOnSkipcash,
  orderPaymentStatus,
} from "../../services/Collection";
import { toast } from "react-toastify";
import { BillDetailStyle } from "../../components/BillDetail/BillDetail";
import { AmountWithDecimalValue } from "../../Utils/CommanFunctions";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ArrowIcon, CreditCardIcon, WatchIcon } from "../../Utils/Icons";
import { updateTip } from "../../store/orderPayment";
import PayWithCreditCard from "../../app/components/PayWithCreditCard";
import ApplePay from "../../app/components/ApplePay";
import Bowser from "bowser";

export default function PrePayment() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fetchingDataLoader, setFetchingDataLoader] = useState(true);
  const { orderDeails } = useSelector(({ orderInformationSlice }) => ({
    ...orderInformationSlice,
  }));
  const RestaurentTheme = useSelector((state) => state?.theme?.branding);
  const RestuarantDetails = useSelector(
    (state) => state?.restaurentSlice?.restaurentData?.Menu?.Restaurent
  );
  const RestuarantDelivery = useSelector(
    (state) => state?.restaurentSlice
  );
  const [redirectPaymentGatewayURL, setRedirectPaymentGatewayURL] =
    useState(null);
  const checkQrCodeType = useSelector(
    (state) => state?.restaurentSlice?.restaurentData?.QrCodeGroup?.group_type
  );
  const getAlreadyScanedID = useSelector((state) => state.StoreForMultiUsers);
  const currentActive =
    getAlreadyScanedID?.getScannedQrcodeAndRestuarent?.slice(-1);
  const currentID = currentActive?.[0]?.qrcodeid;
  const [openCarDetail, setOpenCarDetail] = useState(false);
  const [openOfficeRoomDetail, setOpenOfficeRoomDetail] = useState(false);
  const AuthDetails = useSelector((state) => state?.userAuth?.data);
  const Direction = useSelector((state) => state?.selectLang?.direction);
  const selectedType = useSelector((state) => state?.deliveryModule);
  const OrderID = useLocation()?.state?.id;
  const restaurentData = useSelector(
    (state) => state?.restaurentSlice?.restaurentData
  );
  const customTheme = useSelector((state) => state?.theme?.branding);

  const orderDetails = useSelector(
    (state) => state?.orderInformationSlice?.orderDeails
  );
  const [carDetail, setCarDetail] = useState({
    numberPlate: "",
    type: "",
    color: "",
  });
  const [officeRoomDetail, setOfficeRoomDetail] = useState({
    floorNumber: "",
    officeRoomNumber: "",
  });
  const navigate = useNavigate();
  const [creditCardDrawer, setCreditCardDrawer] = useState();
  let platform = Bowser?.parse(window?.navigator?.userAgent);
  const orderTip = useSelector((state) => state?.orderInformationSlice?.tip);
  const orderSplit = useSelector(
    (state) => state?.orderInformationSlice?.splitBill
  );
  const paymentMode = useSelector(
    (state) => state?.orderInformationSlice?.payBillType
  );
  const isPaymentDisable = useSelector(
    (state) =>
      state?.restaurentSlice?.restaurentData?.Menu?.Restaurent?.payment_enable
  );
  const [openDrawer, setOpenDrawer] = useState(false);
  const selectedDeliveryType = useSelector((state) => state?.deliveryModule);

  const MessageUI = () => {
    let msg = "";
    if (RestuarantDetails?.message_delivery_en && Direction == "ltr") {
      msg = RestuarantDetails?.message_delivery_en;
    }
    if (RestuarantDetails?.message_delivery_ar && Direction == "rtl") {
      msg = RestuarantDetails?.message_delivery_ar;
    }
    return (
      msg && (
        <MessageForUser color={customTheme?.button_primary_color}>
          <WatchIcon /> {msg}
        </MessageForUser>
      )
    );
  };

  var tipAmount = 0;
  const sendValues = (tips) => {
    if (tips.value !== "") {
      tipAmount = tips.value;
    } else {
      tipAmount = 0;
    }
  };

  const HandleApplePay = useCallback(() => {
    if (platform?.os?.name === "iOS") {
      return <ApplePay />;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderTip, orderSplit, paymentMode]);

  const addingCarDetails = async () => {
    setLoading(true);
    let req = {
      order_id: orderDeails ? orderDeails.id : null,
      car_number: carDetail?.numberPlate ? carDetail?.numberPlate : "",
      car_type: carDetail?.type ? carDetail?.type : "",
      car_colour: carDetail?.color ? carDetail?.color : "",
      floor_number: officeRoomDetail?.floorNumber
        ? officeRoomDetail?.floorNumber
        : "",
      room_number: officeRoomDetail?.officeRoomNumber
        ? officeRoomDetail?.officeRoomNumber
        : "",
    };
    let res = await addOrderCarDetails(req);
    if (res.status === 200) {
      skipcashPayment();
    } else {
      setLoading(false);
      toast.error(res.error || res.response.data.message);
    }
  };

  const OrderConfirmedForCashOnDelivery = async () => {
    let obj = {
      payment_method: selectedType?.payment_method,
      // 1 - Online 2 - COD
      order_id: orderDetails?.id,
      address_id: selectedType?.selectedAddress,
      mode_of_payment: 5,
    };
    let res = await orderPaymentStatus(obj);
    if (res.status === 200) {
      if (selectedType?.payment_method == "1") {
        skipcashPayment(true);
      } else {
        toast.info(res.message, { theme: "colored" });
        navigate(`/${currentID}/pre-payment-order-details`);
      }
      setLoading(false);
    } else {
      toast.error(res.error || res.response.data.message);
      setLoading(false);
    }
  };

  const addingOfficeRoomDetails = async () => {
    setLoading(true);
    let req = {
      order_id: orderDeails ? orderDeails.id : null,
      car_number: carDetail?.numberPlate ? carDetail?.numberPlate : "",
      car_type: carDetail?.type ? carDetail?.type : "",
      car_colour: carDetail?.color ? carDetail?.color : "",
      floor_number: officeRoomDetail?.floorNumber
        ? officeRoomDetail?.floorNumber
        : "",
      room_number: officeRoomDetail?.officeRoomNumber
        ? officeRoomDetail?.officeRoomNumber
        : "",
    };
    let res = await addOrderCarDetails(req);
    if (res.status === 200) {
      if (
        restaurentData?.QrCodeGroup?.group_type === "Takeaway" &&
        selectedType?.orderType === "delivery"
      ) {
        // Send true parameter in case of delivery online paymnent
        OrderConfirmedForCashOnDelivery();
      } else {
        OrderConfirmedForCashOnArrival();
      }
    } else {
      setLoading(false);
      toast.error(res.error || res.response.data.message);
    }
  };

  const checkingQrcodewithCarDetails = () => {
    if (checkQrCodeType === "Takeaway") {
      if (RestuarantDetails?.enable_office_details) {
        // alert("reqiued");

        if (
          officeRoomDetail?.floorNumber !== "" &&
          officeRoomDetail?.officeRoomNumber !== ""
        ) {
          if (
            carDetail?.numberPlate === "" &&
            carDetail?.type === "" &&
            carDetail?.color === ""
          ) {
            addingCarDetails();
            // alert('office reuied... car optional')
          } else {
            if (carDetail?.numberPlate === "") {
              toast.error("Please enter number plate");
            } else if (carDetail?.type === "") {
              toast.error("Please enter car type");
            } else if (carDetail?.color === "") {
              toast.error("Please enter car colour");
            } else {
              // alert('bothhh reuied..')
              addingCarDetails();
            }
          }
        } else {
          if (officeRoomDetail?.floorNumber === "") {
            toast.error("Please enter floor number");
          } else if (officeRoomDetail?.officeRoomNumber === "") {
            toast.error("Please enter office/room number");
          }
        }
      } else {
        if (
          carDetail?.numberPlate === "" &&
          carDetail?.type === "" &&
          carDetail?.color === ""
        ) {
          skipcashPayment();
        } else {
          if (carDetail?.numberPlate === "") {
            toast.error("Please enter number plate");
          } else if (carDetail?.type === "") {
            toast.error("Please enter car type");
          } else if (carDetail?.color === "") {
            toast.error("Please enter car colour");
          } else {
            addingCarDetails();
          }
        }
      }
    } else {
      skipcashPayment();
    }
  };

  const cashOnArrival = () => {
    let flag = 0;
    // if (
    //   carDetail?.numberPlate !== "" ||
    //   carDetail?.type !== "" ||
    //   carDetail?.color !== "" ||
    //   officeRoomDetail?.floorNumber !== "" ||
    //   officeRoomDetail?.officeRoomNumber !== ""
    // ) {
    //   if (
    //     carDetail?.numberPlate !== "" ||
    //     carDetail?.type !== "" ||
    //     carDetail?.color !== ""
    //   ) {
    //     flag++;
    //     if (carDetail?.numberPlate === "") {
    //       toast.error("Please enter number plate");
    //     } else if (carDetail?.type === "") {
    //       toast.error("Please enter car type");
    //     } else if (carDetail?.color === "") {
    //       toast.error("Please enter car colour");
    //     } else {
    //       flag--;
    //     }
    //   }

    //   if (
    //     officeRoomDetail?.floorNumber !== "" ||
    //     officeRoomDetail?.officeRoomNumber !== ""
    //   ) {
    //     flag++;
    //     if (officeRoomDetail?.floorNumber === "") {
    //       toast.error("Please enter floor number");
    //     } else if (officeRoomDetail?.officeRoomNumber === "") {
    //       toast.error("Please enter office/room number");
    //     } else {
    //       flag--;
    //     }
    //   }
    //   if (flag === 0) addingOfficeRoomDetails();
    // } else {
    //   OrderConfirmedForCashOnArrival();
    //   //DIRECT CALLING API FOR CONFORIMG ORDER
    // }

    if (RestuarantDetails?.enable_office_details) {
      // alert("reqiued");

      if (
        officeRoomDetail?.floorNumber !== "" &&
        officeRoomDetail?.officeRoomNumber !== ""
      ) {
        if (
          carDetail?.numberPlate === "" &&
          carDetail?.type === "" &&
          carDetail?.color === ""
        ) {
          addingOfficeRoomDetails();
          // alert('office reuied... car optional')
        } else {
          if (carDetail?.numberPlate === "") {
            toast.error("Please enter number plate");
          } else if (carDetail?.type === "") {
            toast.error("Please enter car type");
          } else if (carDetail?.color === "") {
            toast.error("Please enter car colour");
          } else {
            // alert('bothhh reuied..')
            addingOfficeRoomDetails();
          }
        }
      } else {
        if (officeRoomDetail?.floorNumber === "") {
          toast.error("Please enter floor number");
        } else if (officeRoomDetail?.officeRoomNumber === "") {
          toast.error("Please enter office/room number");
        }
      }
    } else {
      if (
        carDetail?.numberPlate === "" &&
        carDetail?.type === "" &&
        carDetail?.color === ""
      ) {
        OrderConfirmedForCashOnArrival();
      } else {
        if (carDetail?.numberPlate === "") {
          toast.error("Please enter number plate");
        } else if (carDetail?.type === "") {
          toast.error("Please enter car type");
        } else if (carDetail?.color === "") {
          toast.error("Please enter car colour");
        } else {
          addingOfficeRoomDetails();
        }
      }
    }
  };

  const cashOnDelivery = () => {
    OrderConfirmedForCashOnDelivery();
    // if (RestuarantDetails?.enable_office_details) {
    //   if (
    //     officeRoomDetail?.floorNumber !== "" &&
    //     officeRoomDetail?.officeRoomNumber !== ""
    //   ) {
    //     if (
    //       carDetail?.numberPlate === "" &&
    //       carDetail?.type === "" &&
    //       carDetail?.color === ""
    //     ) {
    //       addingOfficeRoomDetails();
    //       // alert('office reuied... car optional')
    //     } else {
    //       if (carDetail?.numberPlate === "") {
    //         toast.error("Please enter number plate");
    //       } else if (carDetail?.type === "") {
    //         toast.error("Please enter car type");
    //       } else if (carDetail?.color === "") {
    //         toast.error("Please enter car colour");
    //       } else {
    //         // alert('bothhh reuied..')
    //         addingOfficeRoomDetails();
    //       }
    //     }
    //   } else {
    //     if (officeRoomDetail?.floorNumber === "") {
    //       toast.error("Please enter floor number");
    //     } else if (officeRoomDetail?.officeRoomNumber === "") {
    //       toast.error("Please enter office/room number");
    //     }
    //   }
    // } else {
    //   if (
    //     carDetail?.numberPlate === "" &&
    //     carDetail?.type === "" &&
    //     carDetail?.color === ""
    //   ) {
    //     OrderConfirmedForCashOnDelivery();
    //   } else {
    //     if (carDetail?.numberPlate === "") {
    //       toast.error("Please enter number plate");
    //     } else if (carDetail?.type === "") {
    //       toast.error("Please enter car type");
    //     } else if (carDetail?.color === "") {
    //       toast.error("Please enter car colour");
    //     } else {
    //       addingOfficeRoomDetails();
    //     }
    //   }
    // }
  };

  const OrderConfirmedForCashOnArrival = async () => {
    let req = {
      order_id: orderDeails ? orderDeails.id : null,
      mode_of_payment: 7,
    };
    let res = await ChangeModeOfPayment(req);
    if (res.status === 200) {
      // setRedirectPaymentGatewayURL(res?.data?.resultObj);
      navigate(`/${currentID}/user-feedback`);
      setLoading(false);
    } else {
      setLoading(false);
      toast.error(res.error || res.response.data.message);
    }
  };

  const skipcashPayment = async (isDelivery) => {
    setOpenDrawer(true);
    setLoading(false);
    // setLoading(true);
    // let req = {
    //   order_id: orderDeails ? orderDeails.id : null,
    //   customer_id: AuthDetails?.id,
    //   restaurent_id: AuthDetails?.restaurent_id,
    //   amount: isDelivery
    //     ? Number(RestuarantDetails?.delivery_fee) +
    //       Number(data?.[0]?.sub_total) -
    //       (Number(data?.[0]?.partial_payment_amount) +
    //         Number(data?.[0]?.specific_payment_amount) +
    //         Number(data?.[0]?.discount))
    //     : Number(data?.[0]?.sub_total) -
    //       (Number(data?.[0]?.partial_payment_amount) +
    //         Number(data?.[0]?.specific_payment_amount) +
    //         Number(data?.[0]?.discount)),
    //   // uid: orderidRef,
    //   tip: tipAmount,
    //   //full- 1 , partia; - 2 , spec - 3
    //   type: "1",
    // };
    // let res = await paymentOnSkipcash(req);
    // if (res.status === 200) {
    //   setRedirectPaymentGatewayURL(res?.data?.resultObj);
    //   setLoading(false);
    // } else {
    //   setLoading(false);
    //   toast.error(res.error || res.response.data.message);
    // }
  };

  const openSkipCashPaymentGateway = () => {
    if (redirectPaymentGatewayURL) {
      window.open(redirectPaymentGatewayURL?.payUrl, "_self");
    }
  };

  const OrderDetails = async () => {
    setFetchingDataLoader(true);

    let res = await getOrderDetails(OrderID || orderDeails?.id);
    if (res.status === 200) {
      let data = res.data;
      setData([data]);
      setFetchingDataLoader(false);
    }
  };

  const handleSubmit = () => {
    setLoading(true);
    if (
      orderDeails?.mode_of_payment == 8 ||
      orderDeails?.mode_of_payment == 9
    ) {
      if (
        restaurentData?.QrCodeGroup?.group_type === "Takeaway" &&
        selectedType?.orderType === "delivery"
      ) {
        cashOnDelivery();
      } else {
        cashOnArrival();
      }
    } else {
      if (
        restaurentData?.QrCodeGroup?.group_type === "Takeaway" &&
        selectedType?.orderType === "delivery"
      ) {
        cashOnDelivery();
      } else {
        checkingQrcodewithCarDetails();
      }
    }
  };

  useEffect(() => {
    openSkipCashPaymentGateway();
  }, [redirectPaymentGatewayURL]);

  useEffect(() => {
    OrderDetails();
  }, []);

  if (fetchingDataLoader) {
    return (
      <LoadingWapper style={{ height: "90vh" }}>
        <CircularProgress sx={{ color: "rgb(252, 43, 110)", my: 2 }} />
      </LoadingWapper>
    );
  }

  return (
    <>
      <Header />

      {fetchingDataLoader && data?.length > 0 ? (
        <LoadingWapper style={{ height: "90vh" }}>
          <CircularProgress sx={{ color: "rgb(252, 43, 110)", my: 2 }} />
        </LoadingWapper>
      ) : (
        <div
          className="payment-listing"
          style={{
            background: customTheme?.background_color,
            border: `1px solid ${customTheme?.theme_color}`,
          }}
        >
          <SelectedItem data={data} />

          <BillDetailStyle dir={Direction} style={{ margin: "10px 0" }}>
            <div
              className="bill-details"
              style={{
                background: customTheme?.background_color,
                border: `1px solid ${customTheme?.theme_color}`,
              }}
            >
              <h1 style={{ color: customTheme?.theme_color }}>
                {" "}
                <IntlMessage id="cart.billdetails.title" />{" "}
              </h1>

              <div className="charges">
                <h2 style={{ color: customTheme?.header_color }}>
                  <IntlMessage id="cart.itemtotal" /> :{" "}
                </h2>
                <h4 style={{ color: customTheme?.text_color }}>
                  QAR{" "}
                  {data?.[0]?.sub_total
                    ? AmountWithDecimalValue(data?.[0]?.sub_total)
                    : 0}
                </h4>
              </div>

              {selectedType?.orderType === "delivery" && (
                <div className="charges">
                  <h2 style={{ color: customTheme?.header_color }}>
                    {/* <IntlMessage id="cart.itemtotal" /> :{" "} */}
                    Delivery charges :
                  </h2>
                  <h4 style={{ color: customTheme?.text_color }}>
                    QAR{" "}
                    {AmountWithDecimalValue(
                      Number(RestuarantDelivery?.delivery_fee)
                    )}
                  </h4>
                </div>
              )}

              {data?.[0]?.discount > 0 && (
                <div className="charges">
                  <h2 style={{ color: customTheme?.header_color }}>
                    {/* <IntlMessage id="cart.itemtotal" /> :{" "} */}
                    Discount :
                  </h2>
                  <h4 style={{ color: customTheme?.text_color }}>
                    QAR {AmountWithDecimalValue(Number(data?.[0]?.discount))}
                  </h4>
                </div>
              )}

              <div className="charges">
                <h2 style={{ color: customTheme?.header_color }}>
                  <IntlMessage id="cart.totalpayable" /> :{" "}
                </h2>
                <h4 style={{ color: customTheme?.text_color }}>
                  QAR{" "}
                  {selectedType?.orderType === "delivery"
                    ? AmountWithDecimalValue(
                        Number(RestuarantDelivery?.delivery_fee) +
                          Number(data?.[0]?.sub_total) -
                          (Number(data?.[0]?.specific_payment_amount) +
                            Number(data?.[0]?.partial_payment_amount) +
                            Number(data?.[0]?.discount))
                      )
                    : AmountWithDecimalValue(
                        Number(data?.[0]?.sub_total) -
                          (Number(data?.[0]?.specific_payment_amount) +
                            Number(data?.[0]?.partial_payment_amount) +
                            Number(data?.[0]?.discount))
                      )}
                </h4>
              </div>
            </div>
          </BillDetailStyle>

          {checkQrCodeType === "Takeaway" &&
            selectedType?.orderType !== "delivery" && (
              <>
                {RestuarantDetails?.enable_car_details && (
                  <CarDetailWrapper>
                    <CarOptional
                      onClick={() => setOpenCarDetail(!openCarDetail)}
                      isOpen={openCarDetail}
                      color={customTheme?.theme_color}
                    >
                      <p style={{ color: customTheme?.theme_color }}>
                        Car Details (Optional)
                      </p>
                      <ArrowIcon width={25} height={25} color={"red"} />
                    </CarOptional>

                    {openCarDetail && (
                      <CarDetails>
                        <input
                          placeholder="Car Plate Number"
                          onChange={(e) =>
                            setCarDetail({
                              numberPlate: e.target.value.toUpperCase(),
                              type: carDetail?.type,
                              color: carDetail?.color,
                            })
                          }
                        />
                        <input
                          placeholder="Car Type"
                          onChange={(e) =>
                            setCarDetail({
                              numberPlate: carDetail?.numberPlate,
                              type: e.target.value.toUpperCase(),
                              color: carDetail?.color,
                            })
                          }
                        />
                        <input
                          placeholder="Car Colour"
                          onChange={(e) =>
                            setCarDetail({
                              numberPlate: carDetail?.numberPlate,
                              type: carDetail?.type,
                              color: e.target.value.toUpperCase(),
                            })
                          }
                        />
                      </CarDetails>
                    )}
                  </CarDetailWrapper>
                )}

                {RestuarantDetails?.enable_office_details && (
                  <CarDetailWrapper>
                    <CarOptional
                      onClick={() =>
                        setOpenOfficeRoomDetail(!openOfficeRoomDetail)
                      }
                      isOpen={openOfficeRoomDetail}
                      color={customTheme?.theme_color}
                    >
                      <p style={{ color: customTheme?.theme_color }}>
                        Office/Room Details (Required)
                      </p>
                      <ArrowIcon
                        width={25}
                        height={25}
                        color={"rgb(252, 43, 110)"}
                      />
                    </CarOptional>

                    {openOfficeRoomDetail && (
                      <CarDetails>
                        <input
                          placeholder="Floor number"
                          onChange={(e) =>
                            setOfficeRoomDetail({
                              floorNumber: e.target.value.toUpperCase(),
                              officeRoomNumber:
                                officeRoomDetail?.officeRoomNumber,
                            })
                          }
                        />
                        <input
                          placeholder="Office/room number"
                          onChange={(e) =>
                            setOfficeRoomDetail({
                              floorNumber: officeRoomDetail?.floorNumber,
                              officeRoomNumber: e.target.value.toUpperCase(),
                            })
                          }
                        />
                      </CarDetails>
                    )}
                  </CarDetailWrapper>
                )}
              </>
            )}

          {orderDeails?.mode_of_payment != 8 &&
            orderDeails?.mode_of_payment != 9 && (
              // selectedType?.payment_method != 2 &&
              <>
                <Tip sendValues={sendValues} />
              </>
            )}

          {selectedDeliveryType?.orderType == "delivery" && MessageUI()}

          {!isPaymentDisable && (
            <>
              <ProceedPayBtn
                type="button"
                onClick={handleSubmit}
                customTheme={customTheme}
              >
                {loading ? (
                  <CircularProgress
                    sx={{ color: customTheme?.button_text_color, my: 2 }}
                  />
                ) : (
                  "Place Order"
                )}
              </ProceedPayBtn>
            </>
          )}
        </div>
      )}
      {creditCardDrawer && (
        <Drawer
          anchor={"bottom"}
          open={creditCardDrawer}
          className="mui-item-wrapper"
          disableSwipeToOpen
        >
          <PayWithCreditCard Close={() => setCreditCardDrawer(false)} />
        </Drawer>
      )}
      {openDrawer && (
        <Drawer
          anchor={"bottom"}
          open={openDrawer}
          className="mui-item-wrapper"
          disableSwipeToOpen
          onClose={() => setOpenDrawer(false)}
        >
          <div
            className="paymentButton"
            style={{ display: "grid", gap: "20px", padding: "20px" }}
          >
            {orderDeails?.mode_of_payment == 8 ||
            orderDeails?.mode_of_payment == 9 ? (
              ""
            ) : (
              <div style={{ width: "100%", marginTop: "12px" }}>
                <HandleApplePay />
              </div>
            )}
            {data?.[0]?.order_status == "9" ||
            data?.[0]?.order_status == "6" ? (
              ""
            ) : (
              <ProceedPayBtn
                type="button"
                // onClick={checkingQrcodewithCarDetails}
                onClick={() => setCreditCardDrawer(true)}
                customTheme={customTheme}
              >
                {orderDeails?.mode_of_payment == 8 ||
                orderDeails?.mode_of_payment == 9 ? (
                  // selectedType?.payment_method != 2
                  "Confirm"
                ) : (
                  <>
                    <CreditCardIcon color={customTheme?.button_text_color} />
                    <IntlMessage id="payment.button.pay" />
                  </>
                )}
              </ProceedPayBtn>
            )}
          </div>
        </Drawer>
      )}
    </>
  );
}

const CarDetails = styled.div`
  padding: 24px 19px;

  input {
    width: 100%;
    border-radius: 50px;
    border: 0.879px solid #d2d2d3;
    background: #fff;
    box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0);
    display: flex;
    align-items: center;
    padding: 14px 22px;
    margin: 10px 0;
    color: #7b7f91;
    font-size: 14px;
    font-family: Poppins;
    text-transform: uppercase;

    ::placeholder {
      text-transform: capitalize;
    }
  }
`;

const CarOptional = styled.div`
  width: 100%;
  border-radius: 100px;
  border: 0.5px solid #7b7f91ba;
  background: #fff;
  color: #f52c6b;
  font-size: 16px;
  font-family: Poppins;
  display: flex;
  align-items: center;
  padding: 14px 22px;
  justify-content: space-between;
  svg {
    width: 12px;
    fill: ${({ color }) => color};
    transform: ${({ isOpen }) => (isOpen ? "rotate(270deg)" : "rotate(90deg)")};
    transition: opacity 211ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      transform 141ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
`;

const CarDetailWrapper = styled.div`
  width: 100%;
  // height: 200px;
  border-radius: 27px;
  background: #f8f8fa;
  // background: #979797;
  margin: 10px 0;
`;

const ProceedPayBtn = styled.button`
  width: 100%;
  border: 1px solid transparent;
  height: 52px;
  color: ${({ customTheme }) => customTheme?.button_text_color};
  background: ${({ customTheme }) =>
    `linear-gradient(91.33deg,${customTheme?.button_primary_color} 0%,${customTheme?.button_secondary_color} 100%)`};
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MessageForUser = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  margin: 10px 0;
  font-size: 12px;
  font-weight: 500;
  color: ${({ color }) => color};
`;
