import React from "react";
import "../../style/FoodModel.scss";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { FoodModalDefault } from "../../Utils/Images";
import { RightArrow } from "../../Utils/Icons";

const FoodModal = ({ onClose, selectedItem, colorStyle }) => {
  const aqlutstorage = process.env.REACT_APP_IMAGE_BASEURL;
  const containerItem = process.env.REACT_APP_CONTAINER_ITEM;
  
  const { direction } = useSelector(
    ({ selectLang, selectItemSlice }) => ({ ...selectLang, ...selectItemSlice })
  );
  const customTheme = useSelector((state) => state?.theme?.branding);

  return (
    <div className="FoodContainer" dir={direction}>
      <div className="FoodWrapper">
        <img
          src={
            selectedItem?.image
              ? aqlutstorage + containerItem + selectedItem?.image
              : FoodModalDefault
          }
          className="selectedItembg blur"
          alt=""
        />
        <img
          src={
            selectedItem?.image
              ? aqlutstorage + containerItem + selectedItem?.image
              : FoodModalDefault
          }
          className="selectedItembg"
          alt=""
        />
        <CloseIcon
          dir={direction}
          onClick={onClose}
        >
          {/* <img src={require("../../assets/close_icon.png")} alt="" /> */}
          <RightArrow color={customTheme?.theme_color} />
        </CloseIcon>
        {/* <QarWrapper>
          <span
            className="QarPrice"
            style={{ color: customTheme?.theme_color }}
          >
            QAR{" "}
            {checkingShowingPrice(selectedItem?.ItemPrices[0]?.pos_item_price)
              ? selectedItem?.ItemPrices[0]?.pos_item_price
              : selectedItem?.ItemPrices[0]?.price}
          </span>
        </QarWrapper> */}
      </div>

      <FoodDetailsContainer
        style={{ background: customTheme?.background_color }}
      >
        <FoodDetailsWrapper dir={direction}>
          <div style={{ maxWidth: "80%" }}>
            <h2 style={{ color: customTheme?.header_color }}>
              <b>
                {direction === "ltr" ? selectedItem.name : selectedItem.ar_name}
              </b>
            </h2>
          </div>

          {selectedItem.mark_section_as_new ? (
            <img
              className="icon1"
              src={require("../../assets/batch1.png")}
              alt=""
            ></img>
          ) : (
            ""
          )}
          {selectedItem.mark_section_as_signature ? (
            <img
              className="icon2"
              src={require("../../assets/batch.png")}
              alt=""
            ></img>
          ) : (
            ""
          )}
          <p style={{ color: customTheme?.text_color }}>
            {direction === "ltr" ? selectedItem.desc : selectedItem.ar_desc}
          </p>
        </FoodDetailsWrapper>
      </FoodDetailsContainer>
    </div>
  );
};

export { FoodModal };

// const FoodDetailsWrapper = styled.div`
//   width: 95%;
//   margin: 0 auto;
//   padding-bottom: 15px;
//   position: relative;

//   h5 {
//     color: #ffffff;
//     font-family: "Jost";
//     font-style: normal;
//     font-weight: 500;
//     font-size: 18px;
//     line-height: 50px;
//     display: inline-flex;
//   }

//   p {
//     font-family: "Jost";
//     font-style: normal;
//     font-weight: 400;
//     font-size: 16px;
//     line-height: 23px;
//     color: rgba(255, 255, 255, 0.6);
//   }

//   .icon1 {
//     position: absolute;
//     top: 11px;
//     right: ${({ dir }) => dir === "ltr" && "27px"};
//     left: ${({ dir }) => dir === "rtl" && "26px"};
//   }

//   .icon2 {
//     position: absolute;
//     top: 11px;
//     right: ${({ dir }) => dir === "ltr" && "0px"};
//     left: ${({ dir }) => dir === "rtl" && "0px"};
//   }
// `;

export const QarWrapper = styled.div`
  width: 87px;
  height: 35px;
  background: #ffffff;
  border-radius: 15px 0px 0px 0px;
  opacity: 0.8;
  position: absolute;
  right: 0px;
  bottom: 0;
  text-align: center;

  .QarPrice {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 35px;
    letter-spacing: 0.02em;
    color: #fc2b6e;
  }
`;

export const FoodDetailsContainer = styled.div`
  background-color: white;
`;

const FoodDetailsWrapper = styled.div`
  width: 95%;
  margin: 0 auto;
  padding: 15px 0;
  position: relative;

  h2 {
    color: #202020;
    font-family: "DM Sans", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 33px;
    line-height: 50px;
    margin: 0;
    display: inline-flex;
  }

  p {
    font-family: "Nunito Sans", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 157%;
    margin: 0;
    color: #858992;
  }

  .icon1 {
    position: absolute;
    top: 11px;
    right: ${({ dir }) => dir === "ltr" && "35px"};
    left: ${({ dir }) => dir === "rtl" && "35px"};
  }

  .icon2 {
    position: absolute;
    top: 11px;
    right: ${({ dir }) => dir === "ltr" && "10px"};
    left: ${({ dir }) => dir === "rtl" && "10px"};
  }
`;

const CloseIcon = styled.div`
  position: absolute;
  width: 45px;
  height: 45px;
  left: ${({ dir }) => dir === "ltr" && "20px"};
  right: ${({ dir }) => dir === "rtl" && "31px"};
  top: 20px;
  background:#fff;
  border-radius:16px;
  display:flex;
  align-items:center;
  justify-content:center;
  z-index: 3;

  svg {
    width:15px;
    height:15px;
    transform: rotate(180deg);
  }
`;
