import React, { useState } from 'react';
import "../CartWithDiscount/CartWithDiscount.scss"
import { Header, RecommendedItem } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { restartChat } from '../../store/ChatHistory';

export default function CartWithDiscount() {
    let cartItems = [{
        name: 'Jamies Super Corn',
        price: "QAR 541",
        addOn: 'Add On : Chicken',
        id: '0',
        value: 1
    },
    {
        name: 'Jamies Super Corn',
        price: "QAR 541",
        addOn: 'Add On : Chicken',
        id: '1',
        value: 1
    },
    {
        name: 'Jamies Super Corn',
        price: "QAR 541",
        addOn: 'Add On : Chicken',
        id: '2',
        value: 1

    }]
    const navigate = useNavigate();
    const [cartItem, setCartItem] = useState(cartItems);
    const userlang = useSelector((state) => state.selectLang.direction);

    let incNum = (value, id) => {
        if (value < 10) {

            const index = cartItem.findIndex(x => x.id === id);

            cartItem[index].value = value + 1;

            setCartItem([...cartItem]);

        }
    };
    let decNum = (value, id) => {
        if (value > 1) {
            const index = cartItem.findIndex(x => x.id === id);
            cartItem[index].value = value - 1;
            setCartItem([...cartItem]);

        }
    }
    //Dont delete it


    const dispatch = useDispatch()
    const handleMakePayament = () => {
        dispatch(restartChat([]))
        navigate("/payment")
    }

    return (
        <>
            <Header />
            <section className="cartwithdiscount" dir={userlang}>
                <div className="order-Foodstyle"><h4>Your Order Details</h4></div>
                <div className='order-detail'>
                    {cartItem.map((el, key) => (

                        <div key={key} className="order-MainContent">
                            <div className="order-FoodList">
                                <img src="/static/media/food.740a12a09af04cff8ff3.png" />

                                <div className='order-counter'>
                                    <button className="minus" type='button' onClick={() => decNum(el.value, el.id)}>-</button>
                                    <input type="text" className="custom-form-control" value={el.value} readOnly='readonly' />
                                    <button className="plus" type='button' onClick={() => incNum(el.value, el.id)}>+</button>
                                </div>
                            </div>
                            <div className="order-FoodDetails">
                                <h5>{el.name}</h5>
                                <p>{el.price}</p>
                                <h6>{el.addOn}</h6>
                            </div>
                            <div className='delete-icon'>
                                <img src={require('../../assets/Delete.png')} />
                            </div>
                        </div>
                    ))}
                </div>
            </section>
            <section className='bill-cart-detail' dir={userlang}>
                <p>Bill Details</p>
                <div className='inner-bill-detail'>
                    <div className='left-bill-detail'>Item Total :</div>
                    <div className='right-bill-detail'>QAR 500</div>
                    <div className='left-bill-detail'>Taxes & Charges :</div>
                    <div className='right-bill-detail'>QAR 10</div>
                    <div className='left-bill-detail'>Discount :</div>
                    <div className='right-bill-detail'>- QAR 10</div>
                </div>
                <div className='total-inner-bill-detail'>
                    <div className='left-bill-detail'>Total Payable :</div>
                    <div className='right-bill-detail'>QAR 510</div>
                </div>
            </section>

            <RecommendedItem />


            {/* footer component start here */}
            <div className='ItemWrapper' dir={userlang}>
                <div className='ItemDetails'>
                    <img src={require('../../assets/Bag.png')} alt="" />
                    <div className='Itemturn'>
                        <p>Items</p>
                        <h5>QAR 30</h5>
                    </div>
                </div>
                <div className='ViewItem'>
                    <button type="button" onClick={handleMakePayament}>PLACE ORDER</button>
                </div>
            </div>
            {/* end of footer component */}
        </>
    )
}