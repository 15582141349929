import LoginBackground from "../assets/Background.png";
import RightArrow from "../assets/NewFigma/RightArrow.svg";
import BackgroundCover from "../assets/NewFigma/backgroundCover.png";
import SearchIcon from "../assets/NewFigma/SearchIcon.svg";
import MessageIcon from "../assets/NewFigma/MessageIcon.svg";
import GoBackIcon from "../assets/NewFigma/GoBack.svg";
import SignatureIcon from "../assets/NewFigma/SignatureIcon.svg";
import MarkAsSignatureIcon from "../assets/NewFigma/MarkAsSignatureIcon.svg";
import AddIcon from "../assets/NewFigma/AddButton.svg";
import AddOnIcon from "../assets/NewFigma/AddOnIcon.svg";
import AllergiesIcon from "../assets/NewFigma/AllergiesIcon.svg";
import DollarIcon from "../assets/NewFigma/DollarIcon.svg";
import FreeItemIcon from "../assets/NewFigma/FreeItemIcon.svg";
import RecommendedItemIcon from "../assets/NewFigma/RecommendedItemIcon.svg";
import QuantityIcon from "../assets/NewFigma/QuantityIcon.svg";
import SpecialNoteIcon from "../assets/NewFigma/SpecialNoteIcon.svg";
import DecrementButton from "../assets/NewFigma/DecrementButton.svg";
import PayChatIcon from "../assets/NewFigma/PayChatIcon.svg";
import CallTheWaiterIcon from "../assets/NewFigma/CallTheWaiterIcon.svg";
import OrderDetailChatIcon from "../assets/NewFigma/OrderDetailChatIcon.svg";
import EmailFeedbackIcon from "../assets/NewFigma/EmailFeedbackIcon.svg";
import GiveFeedbackIcon from "../assets/NewFigma/GiveFeedbackIcon.svg";
import ReceiptFeedbackIcon from "../assets/NewFigma/ReceiptFeedbackIcon.svg";
import FoodModalDefault from "../assets/NewFigma/FoodModalDefault.png";
import SendChat from "../assets/NewFigma/SendChat.svg";
import MenuIcon from "../assets/NewFigma/MenuList.svg";
import CurrentLocationIcon from "../assets/NewFigma/CurrentLocationIcon.svg";
import AddressBackBtn from "../assets/NewFigma/AddressBackBtn.svg";
import LocationPlaceholderIcon from "../assets/NewFigma/LocationPlaceholderIcon.svg";
import YourLocationIcon from "../assets/NewFigma/YourLocationIcon.svg";
import CODIcon from "../assets/NewFigma/COD.svg";
import PARIcon from "../assets/NewFigma/PAR.svg";
import OnlinePaymentIcon from "../assets/NewFigma/OnlinePayment.svg";
import CurrentLocationBlueIcon from "../assets/NewFigma/currentLocationBlue.svg";
import DeleteIcon from "../assets/NewFigma/DeleteIcon.svg";
import DeleteCrossIcon from "../assets/NewFigma/DeleteCrossIcon.svg";
// import SmileTip1 from "../assets/smileSvg1.svg";
import SmileTip10 from "../assets/SmileTip10.svg";
import SmileTip25 from "../assets/SmileTip25.svg";
import SmileTip50 from "../assets/SmileTip50.svg";
import SmileTip100 from "../assets/SmileTip100.svg";
import customTip from "../assets/customTip.svg";
import SadTip0 from "../assets/SadTip0.svg";
import Map from "../assets/NewFigma/MapIcon.svg";

import Coverscan from "../assets/NewFigma/scan.png";
import Coverorder from "../assets/NewFigma/order.png";
import Coverpayment from "../assets/NewFigma/payment.png";

import TipBackground from "../assets/tipheaderback.png";
import AqlutLogo from "../assets/NewFigma/aqlutLogo.png";
import AqlutGif from "../assets/NewFigma/aqlutgiflogo.gif";

import SnapayGrayBackground from "../assets/NewFigma/graybackground.png";

import VisaPayIcon from "../assets/NewFigma/visa.png";
import MasterCardPayIcon from "../assets/NewFigma/mastercard.png";
import ApplePayIcon from "../assets/NewFigma/applepay.png";
import GooglePayIcon from "../assets/NewFigma/googlepay.png";
import AmexPayIcon from "../assets/NewFigma/amex.png";

import ButtonPatter from "../assets/NewFigma/ButtonPattern.png";

import SectionPatter from "../assets/NewFigma/sectionPattern.png";

import MushroomIcon from "../assets/NewFigma/mushroom.png";
import CapcicumIcon from "../assets/NewFigma/capcicum.png";
import CabbageIcon from "../assets/NewFigma/cabbage.png";

export {
  MushroomIcon,
  CapcicumIcon,
  CabbageIcon,
  SectionPatter,
  ButtonPatter,
  LoginBackground,
  RightArrow,
  BackgroundCover,
  SearchIcon,
  MessageIcon,
  GoBackIcon,
  SignatureIcon,
  MarkAsSignatureIcon,
  AddIcon,
  AddOnIcon,
  AllergiesIcon,
  DollarIcon,
  FreeItemIcon,
  RecommendedItemIcon,
  QuantityIcon,
  SpecialNoteIcon,
  DecrementButton,
  PayChatIcon,
  CallTheWaiterIcon,
  OrderDetailChatIcon,
  ReceiptFeedbackIcon,
  EmailFeedbackIcon,
  GiveFeedbackIcon,
  FoodModalDefault,
  SendChat,
  MenuIcon,
  CurrentLocationIcon,
  AddressBackBtn,
  LocationPlaceholderIcon,
  YourLocationIcon,
  CODIcon,
  PARIcon,
  OnlinePaymentIcon,
  CurrentLocationBlueIcon,
  DeleteIcon,
  DeleteCrossIcon,
  SmileTip10,
  SmileTip25,
  SmileTip50,
  SmileTip100,
  customTip,
  SadTip0,
  Map,
  Coverscan,
  Coverorder,
  Coverpayment,
  TipBackground,
  AqlutLogo,
  AqlutGif,
  SnapayGrayBackground,
  VisaPayIcon,
  MasterCardPayIcon,
  ApplePayIcon,
  GooglePayIcon,
  AmexPayIcon
};
