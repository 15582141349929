import React from "react";
import { useSelector } from "react-redux";
import IntlMessage from "../../Utils/IntlMessage";
import { BillDetailStyle } from "./BillDetail";

function BillDetailPayment({ type, data }) {
  const direction = useSelector((state) => state.selectLang.direction);
  const customTheme = useSelector((state) => state?.theme?.branding);


  function AmountWithDecimalValue(x) {
    let n = Number(x).toFixed(2);
    if (n) {
      let res = n.split(".");
      if (res) {
        if (Number(res[1]) > 0) {
          return n;
        } else {
          return res[0];
        }
      }
    }
  }

  return (
    <>
      {data && (
        <BillDetailStyle dir={direction}  >
          <div className="bill-details" style={{background: customTheme?.background_color , border:`1px solid ${customTheme?.theme_color}`}} >
            
            <h1 style={{color: customTheme?.header_color}} >
              <IntlMessage id="cart.billdetails.title" />
            </h1>
            <div className="charges">
              <h2 style={{color: customTheme?.header_color}} >
                <IntlMessage id="cart.itemtotal" /> :{" "}
              </h2>
              <h4 style={{color: customTheme?.text_color}} >
                QAR{" "}
                {data?.[0]?.sub_total
                  ? AmountWithDecimalValue(data?.[0]?.sub_total)
                  : 0}
              </h4>
            </div>
            {/* <div className="charges">
              <h2>
                <IntlMessage id="cart.taxesandcharges" /> :{" "}
              </h2>
              <h4 style={{color: customTheme?.text_color}} >
                QAR{" "}
                {data?.[0]?.service_tax + data?.[0]?.vat
                  ? AmountWithDecimalValue(Number(data?.[0]?.service_tax) + Number(data?.[0]?.vat))
                  : 0}
              </h4>
            </div> */}
            {data?.[0]?.discount >= 0 && (
              <div className="charges">
                <h2 style={{color: customTheme?.header_color}} >
                  <IntlMessage id="cart.discount" /> :{" "}
                </h2>
                <h4 style={{color: customTheme?.text_color}} >
                  - QAR{" "}
                  {data?.[0]?.discount
                    ? AmountWithDecimalValue(data?.[0]?.discount)
                    : 0}
                </h4>
              </div>
            )}

            {data?.[0]?.sub_total && (
              <div className="charges">
                <h2 style={{color: customTheme?.header_color}} >
                  <IntlMessage id="cart.fullPaymentMode" /> :
                </h2>
                <h4 style={{color: customTheme?.text_color}} >
                  QAR{" "}
                  {data?.[0]?.sub_total && data?.[0]?.order_status === "6"
                    ? AmountWithDecimalValue(Number(data?.[0]?.sub_total) -
                      (Number(data?.[0]?.partial_payment_amount) +
                        Number(data?.[0]?.specific_payment_amount)))
                    : 0}
                </h4>
              </div>
            )}

            {data?.[0]?.partial_payment_amount >= 0 && (
              <div className="charges">
                <h2 style={{color: customTheme?.header_color}} >
                  <IntlMessage id="cart.partialPayment" /> :
                </h2>
                <h4 style={{color: customTheme?.text_color}} >QAR {AmountWithDecimalValue(data?.[0]?.partial_payment_amount)}</h4>
              </div>
            )}

            {data?.[0]?.specific_payment_amount >= 0 && (
              <div className="charges">
                <h2 style={{color: customTheme?.header_color}} >
                  <IntlMessage id="cart.specificPayment" /> :
                </h2>
                <h4 style={{color: customTheme?.text_color}} >QAR {AmountWithDecimalValue(data?.[0]?.specific_payment_amount)}</h4>
              </div>
            )}

            <div className="charges">
              <h2 style={{color: customTheme?.header_color}} >
                <IntlMessage id="payment.partialamount.tip" /> :{" "}
              </h2>
              <h4 style={{color: customTheme?.text_color}} >QAR {data?.[0]?.tip ? Number(data?.[0]?.tip) : 0}</h4>
            </div>

            <div className="charges">
              <h2 style={{color: customTheme?.header_color}} >
                <IntlMessage id="cart.totalpayable" /> :{" "}
              </h2>

              {data?.[0]?.order_status === "6" ? (
                <h4 style={{color: customTheme?.text_color}} >0</h4>
              ) : (
                <h4 style={{color: customTheme?.text_color}} >
                  QAR{" "}

                  {AmountWithDecimalValue(Number(data?.[0]?.sub_total) -
                    (Number(data?.[0]?.specific_payment_amount) +
                      Number(data?.[0]?.partial_payment_amount) +
                      Number(data?.[0]?.discount)))}
                </h4>

              )}
            </div>
          </div>
        </BillDetailStyle>
      )}
    </>
  );
}

export default BillDetailPayment;
