import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import {
  Humburger,
  LaunchGlobe,
  LaunchMenuIcon,
  LaunchPayBillIcon,
  PaybillLoader,
  PaybillRefresh,
  RightArrow,
} from "../../Utils/Icons";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getCheckDetails,
  getUpdateOrderDetails,
  PosSyncStatus,
  syncOrderPayemnt,
  syncPOS,
} from "../../services/Collection";
import { addOrderDetails, updateTip } from "../../store/orderPayment";
import { addCommaToNumbers } from "../../Utils/CommanFunctions";
import IntlMessage from "../../Utils/IntlMessage";
import { getQrCodeDetails } from "../../services/Collection";
import { addRestaurentData } from "../../store/restaurenDetail";
import { InitialScan } from "../../store/userLoginReducer";
import { PosIsReachable } from "../../store/posStatus";
import { toast } from "react-toastify";
import { LoaderWithAnimation } from "../../style/GobalStyle";
import SwitchAccount from "../guest/SwitchAccount";
import { langSuccess } from "../../store/selectlang";
import Sidenav from "../../components/Sidenav/Sidenav";
import { AqlutLogo } from "../../Utils/Images";

export default function Launch() {
  const aqlutstorage = process.env.REACT_APP_IMAGE_BASEURL;
  const containerProfile = process.env.REACT_APP_CONTAINER_PROFILE;
  const containerMenuBranding = process.env.REACT_APP_CONTAINER_MENUBRANDING;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentLocation = useLocation()?.pathname;
  const [activeTab, setActiveTab] = useState(null);
  const [message, setMessage] = useState("");
  const [isPOSEnable, setIsPOSEnable] = useState(false);
  const getAlreadyScanedID = useSelector((state) => state.StoreForMultiUsers);
  const currentActive =
    getAlreadyScanedID?.getScannedQrcodeAndRestuarent?.slice(-1);
  const [loading, setLoading] = useState(true);
  const [menuLoading, setMenuLoading] = useState(true);
  const OrderDetails = useSelector(
    (state) => state?.orderInformationSlice?.orderDeails
  );
  const name = window?.location?.search?.substring(1)?.split("=")?.[0];

  const restaurantDetails = useSelector(
    (state) => state?.restaurentSlice?.restaurentData?.Menu?.Restaurent
  );
  const qrcodeType = useSelector(
    (state) => state.restaurentSlice.restaurentData?.QrCodeGroup?.group_type
  );
  const logo_restaurent =
    aqlutstorage + containerProfile + restaurantDetails?.logo;
  const customTheme = useSelector((state) => state?.theme?.branding);
  let LaunchBackground =
    aqlutstorage + containerMenuBranding + customTheme?.display_image;

  const [alertMessage, setAlertMessage] = useState(false);

  const langDir = useSelector((state) => state?.selectLang);

  const isposreachable = useSelector(
    (state) => state?.posSlice?.posIsReachable
  );
  const isPaymentDisable = useSelector(
    (state) =>
      state?.restaurentSlice?.restaurentData?.Menu?.Restaurent?.payment_enable
  );
  const POSisEnable = useSelector((state) => state?.posSlice?.isEnable);
  const isAuthenticated = useSelector((state) => state?.userAuth);
  const [openSidenav, setOpenSidenav] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const handleSelectedLanguage = (lang) => {
    if (lang === "en") {
      let obj = {
        lang: "en",
        direction: "ltr",
      };
      dispatch(langSuccess(obj));
    } else if (lang === "ar") {
      let obj = {
        lang: "ar",
        direction: "rtl",
      };
      dispatch(langSuccess(obj));
    }
  };

  const handleMenuButton = (active) => {
    setActiveTab(active);
    setTimeout(() => {
      if (!currentLocation?.includes("authuser-launch")) {
        navigate(
          `/${currentActive?.[0]?.qrcodeid}-${currentActive?.[0]?.restaurentid}/${active}`
        );
      } else {
        if (active === "menu") {
          navigate(`/${currentActive?.[0]?.qrcodeid}/addfoodlist`);
        } else {
          navigate(
            `/${currentActive?.[0]?.qrcodeid}-${currentActive?.[0]?.restaurentid}/${active}`
          );
        }
      }
    }, 500);
  };

  const updateOrderDetails = async (qrcode) => {
    let payload = {
      order_id: null,
      qr_code_id: qrcode,
    };

    let res = await getUpdateOrderDetails(payload);
    if (res?.status === 200) {
      const total =
        res?.data?.total -
        (res?.data?.partial_payment_amount +
          res?.data?.specific_payment_amount);

      dispatch(addOrderDetails({ ...res?.data, total }));
      setLoading(false);
      setMenuLoading(false);
    } else {
      dispatch(addOrderDetails(null));
      // toast.error(res.error || res.response.data.message, { theme: "colored" });
      setLoading(false);
      setMenuLoading(false);
    }
  };

  const syncOrderPaymentinDB = async (qrcode, restaurantid) => {
    let req = {
      restaurent_id: restaurantid,
      qr_code_id: qrcode,
    };

    await syncOrderPayemnt(req);

    updateOrderDetails(qrcode);
    console.log("order payemnt sync");
  };

  const firstsyncposAndsendValuestoDB = async (qrcode, restaurantid) => {
    setMenuLoading(true);
    setLoading(true);
    let req = {
      restaurent_id: restaurantid,
      qr_code_id: qrcode,
    };
    let res = await syncPOS(req);
    if (res.status === 200) {
      updateOrderDetails(qrcode);
      setIsPOSEnable(true);
      dispatch(PosIsReachable(true));
    } else {
      if (res?.response?.data?.status === 400) {
        setIsPOSEnable(false);
        updateOrderDetails(qrcode);
      }
      if (res?.response?.data?.status === 500) {
        dispatch(PosIsReachable(false));
        dispatch(addOrderDetails(null));
      }
      setLoading(false);
      setMenuLoading(false);
      console.log("pos sync error");
    }
  };

  const firstsyncOrderPaymentinDB = async (qrcode, restaurantid) => {
    let req = {
      restaurent_id: restaurantid,
      qr_code_id: qrcode,
    };
    await syncOrderPayemnt(req);
    firstsyncposAndsendValuestoDB(qrcode, restaurantid);

    console.log("order payemnt sync");
  };

  const syncposAndsendValuestoDB = async (qrcode, restaurantid) => {
    setMenuLoading(true);
    setLoading(true);
    let req = {
      restaurent_id: restaurantid,
      qr_code_id: qrcode,
      // type: 1,
    };
    let res = await syncPOS(req);
    if (res.status === 200) {
      syncOrderPaymentinDB(qrcode, restaurantid);
      setIsPOSEnable(true);
      dispatch(PosIsReachable(true));
    } else {
      if (res?.response?.data?.status === 400) {
        setIsPOSEnable(false);
        updateOrderDetails(qrcode);
      }
      if (res?.response?.data?.status === 500) {
        dispatch(PosIsReachable(false));
        dispatch(addOrderDetails(null));
      }
      setLoading(false);
      setMenuLoading(false);
      console.log("pos sync error");
    }
  };

  const MenuListing = async (id) => {
    setMenuLoading(true);
    let params = new URLSearchParams();
    params.append("qr_code_id", id);

    let response = await getQrCodeDetails(params.toString());

    if (response.status === 200) {
      dispatch(addRestaurentData(response?.data));
      setMenuLoading(false);
      dispatch(InitialScan(true));
    } else if (response?.status === 400) {
      setMenuLoading(false);
      let mesg =
        "Something went wrong! Make sure you have internet connectivity.";
      setMessage(mesg);
      toast.error(response.message);
    } else {
      let msg =
        "Menu or  sections not found for Restaurents please try with another QR CODE";
      setMessage(msg);
      setMenuLoading(false);
    }
  };

  const checkingIsQuickOrderShow = () => {
    if (restaurantDetails?.disable_order) {
      return false;
    } else {
      if (restaurantDetails?.pos_integration_status) {
        if (isposreachable) {
          if (restaurantDetails?.disable_order_for_unsyncing) {
            return false;
          } else {
            return true;
          }
        } else {
          return false;
        }
      } else {
        return true;
      }
    }
  };

  const checkingIsPayBillShow = useCallback(() => {
    if (isPaymentDisable) {
      return false;
    } else if (restaurantDetails?.pre_payment && qrcodeType == "Dine In") {
      return false;
    } else {
      if (POSisEnable) {
        if (isposreachable) {
          return true;
        }
        return false;
      } else {
        return true;
      }
    }
  }, [POSisEnable, isposreachable]);

  const chekcingOrderDetails = async (restaurantid, qrcode) => {
    setLoading(true);
    let req = {
      restaurent_id: restaurantid,
      qr_code_id: qrcode,
    };
    let res = await getCheckDetails(req);
    if (res.status === 200) {
      if (res?.message === "Order not found") {
        syncposAndsendValuestoDB(qrcode, restaurantid);
        return;
      } else {
        if (res?.data?.DUE_AMOUNT == 0) {
          firstsyncOrderPaymentinDB(qrcode, restaurantid);
          return;
        }
        if (res?.data?.TranferCheck > 0) {
          syncposAndsendValuestoDB(qrcode, restaurantid);
          return;
        }
        if (res?.data?.DUE_AMOUNT != OrderDetails?.total) {
          syncposAndsendValuestoDB(qrcode, restaurantid);
          return;
        }
      }
      setLoading(false);
      setIsPOSEnable(true);
      dispatch(PosIsReachable(true));
    } else {
      if (res?.response?.data?.status === 400) {
        dispatch(PosIsReachable(false));
        dispatch(addOrderDetails(null));
      }
      if (res?.response?.data?.status === 500) {
        setIsPOSEnable(false);
        updateOrderDetails(qrcode);
      }
      setLoading(false);
      setMenuLoading(false);
    }
  };

  const updatePosSyncStatus = async () => {
    let res = await PosSyncStatus();
    if (res.status === 200) {
      console.log("PosSyncStatus");
    } else {
      console.log("PosSyncStatus error");
    }
  };

  useEffect(() => {
    if (restaurantDetails?.tip_selector) {
      dispatch(updateTip(null));
    } else {
      dispatch(
        updateTip({ type: 1, percentage: 0, tip: 0, isCustomOpen: false })
      );
    }
    if (!currentLocation?.includes("authuser-launch")) {
      if (name !== "qrcodeid") {
        let msg = "Please scan Qr CODE again";
        setLoading(false);
        setMessage(msg);
        return;
      }
    }

    let useEffectResponse = setTimeout(() => {
      if (currentActive?.[0]?.qrcodeid && currentActive?.[0]?.restaurentid) {
        chekcingOrderDetails(
          currentActive?.[0]?.restaurentid,
          currentActive?.[0]?.qrcodeid
        );
        updatePosSyncStatus();
      }
    }, 500);

    return () => clearTimeout(useEffectResponse);
  }, [currentActive?.[0]?.qrcodeid, refresh]);

  // if (menuLoading) {
  //   return (
  //     <Backdrop
  //       sx={{
  //         color: "#FC2B6E",
  //         zIndex: (theme) => theme.zIndex.drawer + 1,
  //       }}
  //       open={menuLoading}
  //     >
  //       {message !== "" ? (
  //         <h1
  //           style={{
  //             fontSize: "20px",
  //             margin: "50px",
  //           }}
  //         >
  //           {message}
  //         </h1>
  //       ) : (
  //         <LandingLoder style={{ background: customTheme?.background_color }}>
  //           <CircularProgress sx={{ color: customTheme?.theme_color }} />
  //           <p style={{ color: customTheme?.theme_color, marginTop: "5px" }}>
  //             Processing...
  //           </p>
  //         </LandingLoder>
  //       )}
  //     </Backdrop>
  //   );
  // }

  return (
    <LaunchWrapper
      background={LaunchBackground}
      orderText={OrderDetails}
      theme_color={customTheme?.theme_color}
    >
      {openSidenav && (
        <Sidenav
          open={openSidenav}
          onClose={() => setOpenSidenav(false)}
          applogo={restaurantDetails?.logo}
          phoneNumber={
            restaurantDetails?.country_code + restaurantDetails?.phone_number
          }
        />
      )}
      <HumburgerWrapper>
        <span onClick={() => setOpenSidenav(!openSidenav)}>
          <Humburger color={customTheme?.theme_color} />
        </span>
      </HumburgerWrapper>

      <BlackLayer />
      {alertMessage && (
        <SwitchAccount
          open={alertMessage}
          handleClose={() => setAlertMessage(false)}
          // loadingSwitchAccount={loadingSwitchAccount}
          // handleCustomerVerification={handleCustomerVerification}
          parent={"guestuser"}
        />
      )}
      {message ? (
        <AlertInfo>
          <h1>{message}</h1>
        </AlertInfo>
      ) : (
        <>
          <section className="upper-section">
            <img src={logo_restaurent} alt="snapay" />
          </section>
          <section className="lower-section" dir={langDir?.direction}>
            <>
              {checkingIsPayBillShow() && (
                <div
                  onClick={() => {
                    Number(OrderDetails?.total) > 0 &&
                      handleMenuButton("paybill");
                  }}
                  className="menu billmenu"
                  style={{
                    color: customTheme?.button_text_color,
                    background: `linear-gradient(91.33deg,${customTheme?.button_primary_color} 0%,${customTheme?.button_secondary_color} 100%)`,
                    padding: `${!OrderDetails ? "10px 20px" : "14px 20px"}`,
                  }}
                >
                  {/* <img src={ButtonPatter} alt="" /> */}
                  <div className="billwrap">
                    <div className="paybillRefresh">
                      <div>
                        <LaunchPayBillIcon size={32} />
                        <span>
                          <IntlMessage id="Snapay.LaunchBtnPayBill" />
                        </span>
                      </div>
                      {!OrderDetails && !loading && (
                        <span className="billText">
                          <IntlMessage id="Snapay.LaunchBillText" />
                        </span>
                      )}
                    </div>
                    <div>
                      {loading ? (
                        <i className="Loading">
                          <PaybillLoader
                            color={customTheme?.button_text_color}
                          />
                        </i>
                      ) : OrderDetails?.total > 0 ? (
                        <>
                          <span>
                            <span className="currency">
                              <IntlMessage id="Snapay.QAR" />
                            </span>
                            {addCommaToNumbers(OrderDetails?.total)}
                          </span>
                          <i>
                            <RightArrow
                              color={customTheme?.button_text_color}
                            />
                          </i>
                        </>
                      ) : (
                        <i
                          className="refresh"
                          onClick={() => setRefresh(!refresh)}
                        >
                          <PaybillRefresh
                            color={customTheme?.button_text_color}
                          />
                        </i>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </>

            {restaurantDetails?.view_menu && (
              <div
                onClick={() => handleMenuButton("menu")}
                className="menu"
                style={{
                  color: customTheme?.button_text_color,
                  background: `linear-gradient(91.33deg,${customTheme?.button_primary_color} 0%,${customTheme?.button_secondary_color} 100%)`,
                  padding: `${!OrderDetails ? "17px 20px" : "14px 20px"}`,
                }}
              >
                {/* <img src={ButtonPatter} alt="" /> */}
                <div>
                  <LaunchMenuIcon size={32} />
                  <span>
                    <IntlMessage id="Snapay.LaunchBtnViewMenu" />
                  </span>
                </div>

                <div>
                  <i>
                    <RightArrow color={customTheme?.button_text_color} />
                  </i>
                </div>
              </div>
            )}

            {checkingIsQuickOrderShow() && (
              <div
                onClick={() => {
                  !isAuthenticated?.isOtpVerified
                    ? setAlertMessage(true)
                    : handleMenuButton("menu");
                }}
                className="menu"
                style={{
                  color: customTheme?.button_text_color,
                  background: `linear-gradient(91.33deg,${customTheme?.button_primary_color} 0%,${customTheme?.button_secondary_color} 100%)`,
                  padding: `${!OrderDetails ? "17px 20px" : "14px 20px"}`,
                }}
              >
                {/* <img src={ButtonPatter} alt="" /> */}
                <div>
                  <LaunchMenuIcon size={32} />
                  <span>
                    <IntlMessage id="Snapay.LaunchBtnQuickOrder" />
                  </span>
                </div>
                <div>
                  <i>
                    <RightArrow color={customTheme?.button_text_color} />
                  </i>
                </div>
              </div>
            )}

            <main className="info-section">
              <div
                style={{ display: "flex", gap: "10px", alignItems: "center" }}
              >
                <SelectWrapper theme={customTheme?.button_primary_color}>
                  <LaunchGlobe />
                  <select
                    onChange={(e) => handleSelectedLanguage(e.target.value)}
                    value={langDir?.lang}
                    style={{
                      color: "#696969",
                      background: "fff",
                      outline: "none",
                    }}
                  >
                    <option value="en">En</option>
                    <option value="ar">Ar</option>
                  </select>
                </SelectWrapper>

                <img src={AqlutLogo} alt="snapay" className="footerIcon" />
              </div>
              <p
                style={{
                  color: "black",
                }}
              >
                <IntlMessage id="Snapay.LaunchYouAccept" />{" "}
                <span onClick={() => navigate("/termsconditions")}>
                  <IntlMessage id="Snapay.LaunchTermAndCondition" />
                </span>
                <a href="https://aqlut.net/" target="_blank">
                  <b>
                    <IntlMessage id="Snapay.LaunchVisitWebsite" />
                  </b>
                </a>
              </p>
            </main>
          </section>
        </>
      )}
    </LaunchWrapper>
  );
}

const HumburgerWrapper = styled.div`
  position: absolute;
  top: 15px;
  left: 10px;
  z-index: 2;
  svg {
    width: 28px;
    height: 28px;
  }
`;

const LandingLoder = styled(LoaderWithAnimation)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const AlertInfo = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  h1 {
    text-shadow: 4px 2px #66433f;
    color: #fff;
    text-align: right;
    font-family: DM Sans;
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    @media (max-width: 430px) {
      font-size: 7vw;
    }
  }
`;

const LaunchWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100dvh;
  background: ${({ background }) => `url(${background})`};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 38px 0px 0px 0px;
  background-size: cover;
  background-repeat: no-repeat;

  .upper-section {
    z-index: 9;
    img {
      width: 133px;
      height: 133px;
      object-fit: contain;
    }
  }
  .lower-section {
    z-index: 9;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .menu {
      background: #fff;
      color: #67433f;
      /* border: 2px solid #67433f; */
      box-shadow: 0px 0px 20px #67433f;
      position: relative;
      /* span {
        color: #67433f;
      } */
    }
    .billmenu {
      display: flex;
      flex-direction: column;
      /* padding: ${({ orderText }) => !orderText && "14px 20px"}; */
    }
    .billText {
      font-size: 10px;
      line-height: 14px;
      font-weight: 500;
    }
    .billwrap {
      display: flex;
      justify-content: space-between;
      align-itens: center;
    }

    > div {
      margin: 0 20px;
      width: calc(100% - 40px);
      display: flex;
      justify-content: space-between;
      /* align-items: center; */
      border-radius: 40px;
      position: relative;

      img {
        position: absolute;
        inset: 0;
      }

      div {
        display: flex;
        align-items: center;
        gap: 8px;
      }
      span {
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.25px;
        display: flex;
        align-items: center;
        gap: 5px;
      }
      .currency {
        font-size: 12px;
        font-weight: 400;
      }
    }

    .info-section {
      width: 100%;
      background: rgb(255 255 255 / 60%);
      /* background: rgb(0 0 0 / 20%); */
      border-radius: 30px 30px 0 0;
      padding: 24px 30px;
      margin-top: 20px;
      display: flex;
      gap: 16px;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      a {
        display: block;
        color: ${({ theme_color }) => theme_color};
        margin: 5px 0;
        cursor: pointer;
        font-size: 13px;
        font-weight: 500 !important;
      }

      img {
        height: 70px;
      }

      p {
        font-family: DM Sans;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.5px;
        text-align: center;
        /* padding: 10px 0; */

        span {
          color: ${({ theme_color }) => theme_color};
          font-size: 13px;
          font-weight: 500;
        }
      }

      select {
        /* background: transparent; */
        border: 1px solid white;
        border-radius: 8px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        /* color: #000; */
      }
    }
  }
  .Loading {
    width: 26px;
    height: 26px;
    svg {
      animation: rotate2Sm 1.5s linear infinite;
    }
    @keyframes rotate2Sm {
      to {
        transform: rotate(360deg);
      }
    }
  }
  .refresh {
    cursor: pointer;
    height: 32px;
  }
  .footerIcon {
    width: 40px !important;
    height: 24px !important;
  }
  .paybillRefresh {
    flex-direction: column;
    align-items: start !important;
    gap: 0 !important;
  }
`;

const BlackLayer = styled.div`
  position: absolute;
  inset: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgb(0 0 0 / 40%) 100%);
`;

const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  color: #696969;
  background-color: #fff;
  padding: 8px 14px;
  border: ${({ theme }) => `1px solid ${theme}`};
  border-radius: 100px;
  width: "67px";
  overflow: hidden;
`;
