import { createSlice } from "@reduxjs/toolkit";

export const notificationSlice = createSlice({
  name: "notificationsResult",
  initialState: {
    message: "",
    markedAsRead: true,
    // unifiedNotification: [],
  },

  //This is SelectLang reducers.

  reducers: {
    addNotifications: (state, action) => {
      if (action.payload) {
        state.message = action.payload;
        state.markedAsRead = false;
      } else {
        state.message = "";
      }
    },
    readNotifaiction: (state, action) => {
      if (action.payload) {
        state.markedAsRead = true;
      } else {
        state.markedAsRead = false;
      }
    },
  },
});

//This is Selectlang actions.

export const { addNotifications, readNotifaiction } =
  notificationSlice.actions;
export default notificationSlice.reducer;
