import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import BillDetail from "../../components/BillDetail";
import SelectSpecificDish from "../../components/SelectSpecificDish";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import {
  getOrderDetails,
  paymentOnSkipcash,
  verifyPosCheckStatus,
} from "../../services/Collection";
import { LoadingWapper } from "../../style/GobalStyle";
import ChatResponse from "../../components/ChatResponse";
import IntlMessage from "../../Utils/IntlMessage";
import Tip from "../../components/Tip";

function PayForSpecificDish() {
  const [paymentArr, setPaymentArr] = useState([]);
  const [calPercentageValue, setCalPercentageValue] = useState(0);
  const [calPriceWithPercentageValue, setCalPriceWithPercentageValue] =
    useState(0);
  const OrderDetail = useSelector(
    (state) => state.orderInformationSlice.orderDeails
  );
  // const RestaurentTheme = useSelector(
  //   (state) =>
  //     state?.restaurentSlice?.restaurentData?.Menu?.Restaurent?.MenuPageBranding
  // );
  // const RestaurentTheme = useSelector((state) => state?.theme?.branding);
  const customTheme = useSelector((state) => state?.theme?.branding);

  const [loading, setLoading] = useState(false);
  const [redirectPaymentGatewayURL, setRedirectPaymentGatewayURL] =
    useState(null);
  const AuthDetails = useSelector((state) => state?.userAuth?.data);
  var tipAmount = 0;
  const sendValues = (tips) => {
    if (tips.value !== "") {
      tipAmount = tips.value;
    } else {
      tipAmount = 0;
    }
  };
  var today = new Date();
  var time = today.getHours() + ":" + today.getMinutes();

  const [fetchingDataLoader, setFetchingDataLoader] = useState(false);
  const [data, setData] = useState([]);

  const OrderDetails = async () => {
    setFetchingDataLoader(true);
    if (OrderDetail) var { id } = OrderDetail;

    let res = await getOrderDetails(id);
    if (res.status === 200) {
      // logic([res.data]);
      setData([res.data]);
      setFetchingDataLoader(false);
    }
  };

  const getSelectedItem = (checked, e) => {
    if (checked) {
      setPaymentArr([...paymentArr, e]);
    } else {
      const getReamining = paymentArr.filter(
        (object) => object.item_id !== e.item_id
      );
      setPaymentArr(getReamining);
    }
  };

  // const CalaculatePercentage = () => {
  //   if (data?.[0]) {
  //     const { vat, service_tax, sub_total } = data?.[0];
  //     // let tax = vat + service_tax;
  //     let tax = 10+10;
  //     let getTaxValueInPercentage = (tax * 100) / sub_total;
  //     setCalPercentageValue(getTaxValueInPercentage);
  //   }
  // };
  // const CalaculateTotalPriceWithTax = () => {
  //   let TotalPriceWithTaxArray = paymentArr?.map(
  //     (item) =>
  //       Number(item.sub_total) +
  //       Number((item.sub_total / 100) * calPercentageValue)
  //   );

  //   if (TotalPriceWithTaxArray?.length > 0) {
  //     let val = TotalPriceWithTaxArray?.reduce((a, b) => a + b);
  //     setCalPriceWithPercentageValue(val);
  //   }
  // };

  const delayForPayment = () => {
    setLoading(true);
    setTimeout(() => {
      posCheckStatus();
    }, 6000);
  };

  const posCheckStatus = async () => {
    let params = new URLSearchParams();
    params.append("order_id", OrderDetail ? OrderDetail.id : null);
    let res = await verifyPosCheckStatus(params.toString());
    if (res.status === 200) {
      skipcashPayment();
    } else {
      toast.error(res?.response?.data?.message || res?.message || res?.error, {
        theme: "colored",
      });
      setLoading(false);
    }
  };

  const skipcashPayment = async () => {
    let tax = data?.[0]?.vat + data?.[0]?.service_tax;
    let specificPayment = [];
    let totalAmount = 0;
    let discount = Number(data?.[0]?.discount) / data?.[0]?.OrderItems.length;
    paymentArr?.map((el) => {
      let singleItemPriceWithTaxAndDiscount = 0;
      singleItemPriceWithTaxAndDiscount = (el.sub_total * tax) / 100;
      totalAmount =
        totalAmount +
        (el.sub_total + singleItemPriceWithTaxAndDiscount - discount);
      specificPayment.push({
        order_item_id: el?.id,
        amount:
          Number(el.sub_total) +
          Number((el.sub_total / 100) * calPercentageValue),
      });
    });

    let req = {
      order_id: OrderDetail ? OrderDetail.id : null,
      customer_id: AuthDetails?.id,
      restaurent_id: AuthDetails?.restaurent_id,
      tip: tipAmount,
      //full- 1 , partia; - 2 , spec - 3
      amount: totalAmount,
      type: "3",
      payment_items_details: specificPayment,
    };
    let res = await paymentOnSkipcash(req);
    if (res.status === 200) {
      setRedirectPaymentGatewayURL(res?.data?.resultObj);
      setLoading(false);
    } else {
      setLoading(false);
      toast.error(res.error || res.response.data.message);
    }
  };

  const validate = async () => {
    if (paymentArr?.length > 0) {
      delayForPayment();
    } else {
      toast.error("Please select a item for payment", { theme: "colored" });
    }
  };
  //test comment

  const openSkipCashPaymentGateway = () => {
    if (redirectPaymentGatewayURL) {
      window.open(redirectPaymentGatewayURL?.payUrl, "_self");
    }
  };

  useEffect(() => {
    OrderDetails();
  }, []);

  useEffect(() => {
    openSkipCashPaymentGateway();
  }, [redirectPaymentGatewayURL]);

  // useEffect(() => {
  //   CalaculateTotalPriceWithTax();
  // }, [paymentArr]);

  // useEffect(() => {
  //   CalaculatePercentage();
  // }, [
  //   calPercentageValue,
  //   calPriceWithPercentageValue,
  //   fetchingDataLoader,
  //   data?.[0],
  // ]);

  return (
    <>
      <ChatResponse
        message={<IntlMessage id="payment.pay.method.specificdishes" />}
        time={time}
      />

      {fetchingDataLoader && data?.length > 0 ? (
        <LoadingWapper>
          <CircularProgress sx={{ color: customTheme?.theme_color, my: 2 }} />
        </LoadingWapper>
      ) : (
        <div className="payment-listing" style={{background: customTheme?.background_color , border:`1px solid ${customTheme?.theme_color}`}}>
          {data?.[0]?.OrderItems?.map((ele, index) =>
            ele?.payment_status === "2" ? (
              <SelectSpecificDish paid={true} data={ele} />
            ) : (
              <SelectSpecificDish
                data={ele}
                getSelectedItem={getSelectedItem}
              />
            )
          )}
          <BillDetail type={"SpecificDish"} data={data} />
          <Tip sendValues={sendValues} />
          <div className="paymentButton">
            {loading ? (
              <CircularProgress sx={{ color: customTheme?.theme_color, my: 2 }} />
            ) : data[0]?.order_status === "5" ||
              data[0]?.order_status === "6" ? (
              ""
            ) : (
              <>
                <button
                  onClick={validate}
                  style={{
                    background: `linear-gradient(91.33deg,${customTheme?.button_primary_color} 0%,${customTheme?.button_secondary_color} 100%)`,
                  }}
                >
                  <IntlMessage id="payement.button.makepayment" />
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default PayForSpecificDish;
