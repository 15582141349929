import React from "react";
import { useSelector } from "react-redux";
import IntlMessage from "../../Utils/IntlMessage";

import { BillDetailStyle } from "./../BillDetail/BillDetail";

function CartBilling({
  type,
  totalPayable,
  tax,
  // discount,
  subtotalPrice,
  loyalityPoints,
}) {
  // eslint-disable-next-line no-unused-vars
  const { restaurentData, cardValuesSendToDB, discount, delivery_fee } =
    useSelector(({ restaurentSlice, cartSlice }) => ({
      ...restaurentSlice,
      ...cartSlice,
    }));
  console.log(discount, "discountttttttttt00");

  const direction = useSelector((state) => state.selectLang.direction);
  const isDeliveryEnable = useSelector((state) => state?.deliveryModule);
  const customTheme = useSelector((state) => state?.theme?.branding);

  const IsUnifiedUser = useSelector((state) => state?.isUnifiedSlice);

  const isRedeemPoints = useSelector(
    (state) => state?.isUnifiedSlice?.isRedeemPoints
  );

  let totalRedeemedPoints;
  let totalAmount;

  if (isRedeemPoints && IsUnifiedUser?.is_aura_user) {
    if (restaurentData?.QrCodeGroup?.group_type === "Takeaway") {
      if (isDeliveryEnable?.orderType === "delivery") {
        if (
          parseFloat(totalPayable) + parseFloat(delivery_fee) >=
          loyalityPoints
        ) {
          totalRedeemedPoints = loyalityPoints;
          totalAmount =
            parseFloat(totalPayable) +
            parseFloat(delivery_fee) -
            loyalityPoints;
        } else if (
          loyalityPoints >
          parseFloat(totalPayable) + parseFloat(delivery_fee)
        ) {
          totalRedeemedPoints =
            parseFloat(totalPayable) + parseFloat(delivery_fee);
          totalAmount = 0;
        }
      }
    } else {
      if (parseFloat(totalPayable) >= loyalityPoints) {
        totalRedeemedPoints = loyalityPoints;
        totalAmount = parseFloat(totalPayable) - loyalityPoints;
      } else if (loyalityPoints > parseFloat(totalPayable)) {
        totalRedeemedPoints = parseFloat(totalPayable);
        totalAmount = 0;
      }
    }
  }

  function AmountWithDecimalValue(x) {
    let n = Number(x).toFixed(2);
    if (n) {
      let res = n.split(".");
      if (res) {
        if (Number(res[1]) > 0) {
          return n;
        } else {
          return res[0];
        }
      }
    }
  }

  return (
    <BillDetailStyle dir={direction}>
      <div
        className="bill-details"
        style={{ background: `${customTheme?.placeholder_box_color}` }}
      >
        <h1 style={{ color: customTheme?.theme_color }}>
          <IntlMessage id="cart.billdetails.title" />
        </h1>
        <div className="charges">
          <h2 style={{ color: customTheme?.header_color }}>
            <IntlMessage id="cart.itemtotal" /> :{" "}
          </h2>
          <h4 style={{ color: customTheme?.text_color }}>
            QAR {subtotalPrice ? AmountWithDecimalValue(subtotalPrice) : 0}
          </h4>
        </div>

        {/* <div className="charges">
          <h2>
            <IntlMessage id="cart.taxesandcharges" /> :{" "}
          </h2>
          <h4>QAR {tax ? tax.toFixed(2) : 0}</h4>
        </div> */}

        {/* {restaurentData?.QrCodeGroup?.group_type === "Takeaway" &&
          isDeliveryEnable?.orderType === "delivery" && delivery_fee > 0 && (
            <div className="charges">
              <h2 style={{ color: customTheme?.header_color }}>
                {" "}
                <IntlMessage id="cart.deliveryFee" /> :{" "}
              </h2>
              <h4 style={{ color: customTheme?.text_color }}>
                QAR {delivery_fee ? AmountWithDecimalValue(delivery_fee) : 0}
              </h4>
            </div>
          )} */}

        {isRedeemPoints && IsUnifiedUser?.is_aura_user && (
          <div className="charges">
            <h2 style={{ color: customTheme?.header_color }}>
              <IntlMessage id="cart.redeemedPoints" /> :{" "}
            </h2>
            <h4 style={{ color: customTheme?.text_color }}>
              {" "}
              QAR {AmountWithDecimalValue(totalRedeemedPoints)}
            </h4>
          </div>
        )}

        {discount !== "0.00" && discount !== 0 && (
          <div className="charges">
            <h2 style={{ color: customTheme?.header_color }}>
              {" "}
              <IntlMessage id="cart.discount" /> :{" "}
            </h2>
            <h4 style={{ color: customTheme?.text_color }}>
               QAR {discount ? AmountWithDecimalValue(discount) : 0}
            </h4>
          </div>
        )}

        {type === "Full" ||
        type === "SpecificDish" ||
        type === "OrderDetail" ? (
          <>
            <div className="charges">
              <h2 style={{ color: customTheme?.header_color }}>
                <IntlMessage id="cart.totalpayable" /> :{" "}
              </h2>
              {isRedeemPoints && IsUnifiedUser?.is_aura_user ? (
                <h4 style={{ color: customTheme?.text_color }}>
                  QAR {AmountWithDecimalValue(totalAmount)}
                </h4>
              ) : (
                <h4 style={{ color: customTheme?.text_color }}>
                  {restaurentData?.QrCodeGroup?.group_type === "Takeaway" &&
                  isDeliveryEnable?.orderType === "delivery" ? (
                    <>
                      QAR{" "}
                      {totalPayable
                        ? AmountWithDecimalValue(
                            // parseFloat(totalPayable) + parseFloat(delivery_fee)
                            parseFloat(totalPayable)
                          )
                        : 0}
                    </>
                  ) : (
                    <>
                      QAR{" "}
                      {totalPayable ? AmountWithDecimalValue(totalPayable) : 0}
                    </>
                  )}
                </h4>
              )}
            </div>
          </>
        ) : (
          type === "Partial" && (
            <>
              <div className="charges">
                <h2 className="bold">Total Amount : </h2>
                <h4 className="bold">QAR 2000</h4>
              </div>
              <div className="charges">
                <h2>Paid Amount : </h2>
                <h4>QAR 500</h4>
              </div>
              <div className="charges">
                <h2>Pending Amount : </h2>
                <h4>QAR 1500</h4>
              </div>
              <h2>Please enter the amount you want to make</h2>
              <div className="paymentAmount">
                <input type="text" placeholder="500" />
                <h3>/ QAR 1500</h3>
              </div>
            </>
          )
        )}
      </div>
    </BillDetailStyle>
  );
}

export default CartBilling;
