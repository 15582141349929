import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import IntlMessage from "../../Utils/IntlMessage";

export default function FeedBackReceiptDetail({ orderDetail }) {
  const direction = useSelector((state) => state.selectLang.direction);
  const {
    total,
    service_tax,
    discount,
    sub_total,
    vat,
    partial_payment_amount,
    specific_payment_amount,
    order_status,
    payment_status,
    loyalty_points,
    tip,
  } = orderDetail;
  const tax_charges = service_tax + vat;

  // const isUnified = useSelector((state) => state?.userAuth?.data);
  const isUnified = useSelector((state) => state?.isUnifiedSlice);
  const customTheme = useSelector((state) => state?.theme?.branding);

  const isRedeemPoints = useSelector(
    (state) => state?.isUnifiedSlice?.isRedeemPoints
  );

  const loyalityPoints = useSelector(
    (state) => state?.isUnifiedSlice?.UnifiedCustomerInfo?.totalPoints
  );
  const selectedType = useSelector((state) => state?.deliveryModule);
  const restaurant = useSelector(
    (state) => state?.restaurentSlice?.restaurentData?.Menu?.Restaurent
  );
  const takeaway_fee = selectedType?.orderType == "takeaway" && restaurant?.takeaway_fees_amount ? restaurant?.takeaway_fees_amount : 0;

  let totalRedeemedPoints;
  let totalAmount;

  if (isRedeemPoints && isUnified?.is_aura_user) {
    if (total + takeaway_fee >= loyalityPoints) {
      totalRedeemedPoints = loyalityPoints;
      totalAmount = total + takeaway_fee - loyalityPoints;
    } else if (loyalityPoints > total + takeaway_fee) {
      totalRedeemedPoints = total + takeaway_fee;
      totalAmount = 0;
    }
  } else if (!isRedeemPoints && isUnified?.is_aura_user) {
    totalRedeemedPoints = loyalty_points;
    totalAmount = total + takeaway_fee;
  } else {
    totalRedeemedPoints = 0;
    totalAmount = 0;
  }

  return (
    <>
      {orderDetail &&
        orderDetail?.OrderItems?.map((el) => (
          <section className="feedback-receipt-detail">
            <div className="payment-listing" style={{background: customTheme?.background_color , border:`1px solid ${customTheme?.theme_color}`}} >
              <FeedbackSelectedItemStyle dir={direction}>
                <div className="selected-item">
                  <h6 style={{color: customTheme?.header_color}} >
                    {direction == "ltr" ? el?.item_name : el?.ar_item_name}
                  </h6>
                  <label style={{color: customTheme?.text_color}} >QAR {el?.price}</label>
                </div>
                <div className="quantity">
                  <h6 style={{color: customTheme?.header_color}} >
                    QTY : {el?.quantity}
                    {/* <span style={{ color: "red" }}>*</span> 25 kcal */}
                  </h6>
                </div>
                <div className="add-on">
                  {el?.OrderItemAddOns?.map((addons) => (
                    <h6 style={{color: customTheme?.header_color}} >Add On : {addons?.option_item_name}</h6>
                  ))}
                </div>
              </FeedbackSelectedItemStyle>
            </div>
          </section>
        ))}
      <BillDetailStyle dir={direction}>
        <div className="bill-details" style={{background: customTheme?.background_color , border:`1px solid ${customTheme?.theme_color}`}} >
          <h1 style={{color: customTheme?.theme_color}} >
            <IntlMessage id="cart.billdetails.title" />
          </h1>
          <div className="charges">
            <h2 style={{color: customTheme?.header_color}} >
              <IntlMessage id="cart.itemtotal" /> :{" "}
            </h2>
            <h4 style={{color: customTheme?.text_color}} >QAR {sub_total ? sub_total.toFixed(2) : 0}</h4>
          </div>
          {/* <div className="charges">
            <h2 style={{color: customTheme?.header_color}} >
              <IntlMessage id="cart.taxesandcharges" /> :{" "}
            </h2>
            <h4 style={{color: customTheme?.text_color}} >QAR {tax_charges ? tax_charges.toFixed(2) : 0}</h4>
          </div> */}
          {discount !== 0 && discount !== null && (
            <div className="charges">
              <h2 style={{color: customTheme?.header_color}} >
                <IntlMessage id="cart.discount" /> :{" "}
              </h2>
              <h4 style={{color: customTheme?.text_color}} >- QAR {discount ? discount : 0}</h4>
            </div>
          )}

          {sub_total && (
            <div className="charges">
              <h2 style={{color: customTheme?.header_color}} >
                <IntlMessage id="cart.fullPaymentMode" /> :
              </h2>
              <h4 style={{color: customTheme?.text_color}} >
                QAR{" "}
                {sub_total && order_status === "6"
                  ? sub_total.toFixed(2) -
                    (partial_payment_amount + specific_payment_amount)
                  : 0}
              </h4>
            </div>
          )}

          {partial_payment_amount >= 0 && (
            <div className="charges">
              <h2 style={{color: customTheme?.header_color}} >
                <IntlMessage id="cart.partialPayment" /> :
              </h2>
              <h4 style={{color: customTheme?.text_color}} >QAR {partial_payment_amount}</h4>
            </div>
          )}

          {specific_payment_amount >= 0 && (
            <div className="charges">
              <h2 style={{color: customTheme?.header_color}} >
                <IntlMessage id="cart.specificPayment" /> :
              </h2>
              <h4 style={{color: customTheme?.text_color}} >QAR {specific_payment_amount}</h4>
            </div>
          )}

          <div className="charges">
            <h2 style={{color: customTheme?.header_color}} >
              <IntlMessage id="payment.partialamount.tip" /> :{" "}
            </h2>
            <h4 style={{color: customTheme?.text_color}} >QAR {tip ? Number(tip) : 0}</h4>
          </div>

          {selectedType?.orderType == "takeaway" && restaurant?.takeaway_fees_amount > 0 && (
            <div className="charges">
              <h2 style={{ color: customTheme?.header_color,textTransform:"capitalize" }}>
                {restaurant?.takeaway_fees_name} :{" "}
              </h2>
              <h4 style={{ color: customTheme?.text_color }}>
                QAR {restaurant?.takeaway_fees_amount}
              </h4>
            </div>
          )}

          {isUnified?.is_aura_user && isRedeemPoints && (
            <div className="charges">
              <h2 style={{color: customTheme?.header_color}} >
                <IntlMessage id="cart.redeemedPoints" /> :
              </h2>
              <h4 style={{color: customTheme?.text_color}} >QAR {totalRedeemedPoints}</h4>
            </div>
          )}

          <div className="charges">
            <h2 style={{color: customTheme?.header_color}} >
              <IntlMessage id="cart.totalpayable" /> :{" "}
            </h2>

            {isUnified?.is_aura_user ? (
              <h4 style={{color: customTheme?.text_color}} >QAR {totalAmount}</h4>
            ) : (
              <>
                {order_status === "6" || payment_status === "2" ? (
                  <h4 style={{color: customTheme?.text_color}} >QAR 0</h4>
                ) : (
                  <>
                    {partial_payment_amount > 0 && (
                      <h4 style={{color: customTheme?.text_color}} >QAR {total - partial_payment_amount}</h4>
                    )}

                    {specific_payment_amount > 0 && (
                      <h4 style={{color: customTheme?.text_color}} >QAR {total - specific_payment_amount}</h4>
                    )}

                    {partial_payment_amount == 0 &&
                      specific_payment_amount == 0 && (
                        <h4 style={{ color: customTheme?.text_color }}>
                          QAR {total ? total + takeaway_fee : 0}
                        </h4>
                      )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </BillDetailStyle>
    </>
  );
}

export const BillDetailStyle = styled.div`
  .bill-details {
    background: #f8f8fb;
    border-radius: 16px;
    padding: 8px;

    h1 {
      font-family: "Jost";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.05em;
      color: #fc2b6e;
      margin-bottom: 10px;
    }

    .charges {
      display: flex;
      align-items: center;

      h2 {
        font-family: "Jost";
        font-style: normal;
        font-weight: 500;
        font-size: 13.5px;
        line-height: 20px;
        letter-spacing: 0.05em;
        color: #202020;
        width: 50%;
      }

      h4 {
        font-family: "Jost";
        font-style: normal;
        font-weight: 400;
        font-size: 13.5px;
        line-height: 20px;
        letter-spacing: 0.05em;
        color: #202020;
        width: 50%;
        text-align: ${({ dir }) => dir === "ltr" && "right"};
        text-align: ${({ dir }) => dir === "rtl" && "left"};
      }
    }
    .bold {
      font-weight: 600 !important;
      font-size: 15px !important;
      line-height: 22px !important;
    }

    h2 {
      font-family: "Jost";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      color: #000000;
      margin-top: 12px;
    }

    .paymentAmount {
      display: flex;
      align-items: center;
      margin-top: 8px;
      input {
        background: #fcfdfe;
        border: 1px solid #f0f1f7;
        border-radius: 8px;
        width: 70%;
        padding: 10px 12px;
      }
      h3 {
        font-family: "Jost";
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 21px;
        color: #000000;
        width: 30%;
        padding-left: 6px;
      }
    }
  }
`;

const FeedbackSelectedItemStyle = styled.div`
  .selected-item {
    display: flex;
    align-items: center;
    h6 {
      font-family: "Jost";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
      letter-spacing: 0.02em;
      width: 50%;
      color: #000000;
    }
    label {
      font-family: "Jost";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      letter-spacing: 0.02em;
      width: 50%;
      text-align: ${({ dir }) => dir === "ltr" && "right"};
      text-align: ${({ dir }) => dir === "rtl" && "left"};
      color: rgba(0, 0, 0, 0.6);
    }
  }
  .quantity {
    h6 {
      font-family: "Jost";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      letter-spacing: 0.02em;

      color: rgba(0, 0, 0, 0.6);
    }
  }

  .add-on {
    border-bottom: 0.25px solid #a2a2a2;
    padding-bottom: 12px;
    margin-bottom: 12px;
    h6 {
      font-family: "Jost";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 17px;
      letter-spacing: 0.02em;
      color: #2b2b2b;
    }
  }
`;
