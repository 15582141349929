import React, { useState } from "react";
import { MinusIcon, PlusIcon } from "../../Utils/Icons";
import IntlMessage from "../../Utils/IntlMessage";

const Items = ({ handleChecked, calculateItemPrice, val }) => {
  const [totalPeople, setTotalPeople] = useState(val?.customQuantity || 1);
  const [checked, setChecked] = useState(val?.ischecked || false);
  console.log(totalPeople, "val?.customQuantity", val);

  const handleItemQuntity = (type, quantity, val) => {
    if (type === "Add" && quantity !== totalPeople) {
      setTotalPeople(totalPeople + 1);
      handleChecked({
        ...val,
        customQuantity: totalPeople + 1,
        ischecked: true,
      });
      setChecked(true);
    }
    if (type === "Sub" && totalPeople !== 1) {
      setTotalPeople(totalPeople - 1);
      handleChecked({
        ...val,
        customQuantity: totalPeople - 1,
        ischecked: true,
      });
    }
  };

  const handleSetCheck = () => {
    setChecked(!checked);

    if (totalPeople !== 1 && !checked === false) {
      setTotalPeople(1);
    }
  };

  console.log(val, "valvalval");
  return (
    <div className="itemsWrapper">
      <div>
        {val?.payment_status === "1" ? (
          <input
            type="checkbox"
            values="itemName"
            name="itemName"
            checked={checked}
            onChange={() => {
              handleChecked({ ...val, customQuantity: totalPeople });
              handleSetCheck();
            }}
          />
        ) : (
          <input type="checkbox" values="itemName" name="itemName" disabled />
        )}
        <div className="itemDetails">
          <div style={{ fontSize: "14px" }}>{val?.item_name}</div>
          {val?.paid_quantity === 0 ? (
            <div style={{ fontSize: "12px" }}>{val?.quantity}X</div>
          ) : (
            <div style={{ fontSize: "12px" }}>
              {val?.payment_status === "1"
                ? val?.quantity - val?.paid_quantity
                : val?.quantity}    
              X
            </div>
          )}
        </div>
      </div>
      <div>
        {val?.payment_status === "1" && (
          <>
            {val?.quantity > 1 && (
              <div className="AddBtn">
                {val?.paid_quantity === 0 ? (
                  <i
                    className={`icon ${totalPeople === 1 && "defaultIcon"}`}
                    onClick={() => handleItemQuntity("Sub", val?.quantity, val)}
                  >
                    <MinusIcon />
                  </i>
                ) : (
                  <i
                    className={`icon ${totalPeople === 1 && "defaultIcon"}`}
                    onClick={() =>
                      handleItemQuntity(
                        "Sub",
                        val?.quantity - val?.paid_quantity,
                        val
                      )
                    }
                  >
                    <MinusIcon />
                  </i>
                )}
                <span className="count">{totalPeople}</span>
                {val?.paid_quantity === 0 ? (
                  <i
                    className="icon"
                    onClick={() => {
                      handleItemQuntity("Add", val?.quantity, val);
                    }}
                  >
                    <PlusIcon />
                  </i>
                ) : (
                  <i
                    className="icon"
                    onClick={() => {
                      handleItemQuntity(
                        "Add",
                        val?.quantity - val?.paid_quantity,
                        val
                      );
                    }}
                  >
                    <PlusIcon />
                  </i>
                )}
              </div>
            )}
          </>
        )}
        {val?.payment_status === "1" ? (
          <div className="amount">
            <IntlMessage id="Snapay.QAR" /> {calculateItemPrice(val)}.00
          </div>
        ) : (
          <div className="amount" style={{ color: "green" }}>
            <IntlMessage id="Snapay.PAID" />
          </div>
        )}
      </div>
    </div>
  );
};

export default Items;
