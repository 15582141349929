import styled from "styled-components";
import React from "react";
import { useSelector } from "react-redux";

function ChatResponse({ message, time }) {
  const direction = useSelector((state) => state.selectLang.direction);
  const customTheme = useSelector((state) => state?.theme?.branding);

  return (
    <ChatResponseStyle dir={direction}>
      <div className="col-12" style={{background: customTheme?.background_color , border:`1px solid ${customTheme?.theme_color}`}}>
        <label style={{color: customTheme?.text_color}} >{message}</label>
        <h6 style={{color: customTheme?.text_color}} >{time}</h6>
      </div>
    </ChatResponseStyle>
  );
}

export default ChatResponse;

const ChatResponseStyle = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px 20px;
  justify-content: end;

  .col-12 {
    background: ${({ theme }) => theme?.UserChatBg};
    border-radius: 16px;
    padding: 8px 11px;
    width: 70%;
    max-width: 500px;
  }

  label {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
    text-align: left;
  }
  h6 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    text-align: ${({ dir }) => (dir === "ltr" ? "right" : "left")};
    color: #a4a3a3;
  }
`;
