import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import BillDetail from "../../components/BillDetail";
import SelectedItem from "../../components/SelectedItem";
import Tip from "../../components/Tip";
import styled from "styled-components";
import { CircularProgress } from "@mui/material";
import {
  getOrderDetails,
  paymentOnSkipcash,
  verifyPosCheckStatus,
} from "../../services/Collection";
import ChatResponse from "../../components/ChatResponse";
import { LoadingWapper } from "../../style/GobalStyle";
import IntlMessage from "../../Utils/IntlMessage";

function PayPartialAmount() {
  const [isPartialButtonDisabled, setPartialButtonDisabled] = useState(false);
  const OrderDetail = useSelector(
    (state) => state.orderInformationSlice.orderDeails
  );
  // const RestaurentTheme = useSelector(
  //   (state) =>
  //     state?.restaurentSlice?.restaurentData?.Menu?.Restaurent?.MenuPageBranding
  // );

  // const RestaurentTheme = useSelector((state) => state?.theme?.branding);
  const customTheme = useSelector((state) => state?.theme?.branding);


  const [partialAmount, setPartialAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [redirectPaymentGatewayURL, setRedirectPaymentGatewayURL] =
    useState(null);
  const AuthDetails = useSelector((state) => state?.userAuth?.data);

  var today = new Date();
  var time = today.getHours() + ":" + today.getMinutes();
  var tipAmount = 0;
  const sendValues = (tips) => {
    if (tips.value !== "") {
      tipAmount = tips.value;
    } else {
      tipAmount = 0;
    }
  };

  const [fetchingDataLoader, setFetchingDataLoader] = useState(false);
  const [data, setData] = useState([]);

  const OrderDetails = async () => {
    setFetchingDataLoader(true);
    if (OrderDetail) var { id } = OrderDetail;

    let res = await getOrderDetails(id);
    if (res.status === 200) {
      let data = res.data;
      setData([data]);
      setFetchingDataLoader(false);
    }
  };

  const ParticalAmount = (value) => {
    // value.preventDefault();
    setPartialAmount(value.target.value);
  };

  const validate = async () => {
    let Ttotal =
      data?.[0]?.total -
      (data?.[0]?.specific_payment_amount +
        data?.[0]?.partial_payment_amount +
        data?.[0]?.discount);

    console.log(
      Number(Ttotal) - Number(partialAmount),
      "Number(Ttotal) - Number(partialAmount)"
    );

    if (Number(partialAmount) >= 1 && Number(partialAmount) <= Number(Ttotal)) {
      if (
        Number(Ttotal) - Number(partialAmount) >= 1 ||
        Number(Ttotal) - Number(partialAmount) == 0
      ) {
        delayForPayment();
      } else {
        toast.error(`Partial amount can't exceeds ${Number(Ttotal) - 1} `);
      }
    } else {
      toast.error("Please enter vaild partial payment amount");
    }
  };

  const openSkipCashPaymentGateway = () => {
    if (redirectPaymentGatewayURL) {
      window.open(redirectPaymentGatewayURL?.payUrl, "_self");
    }
  };

  const delayForPayment = () => {
    setLoading(true);
    setTimeout(() => {
      posCheckStatus();
    }, 6000);
  };

  const posCheckStatus = async () => {
    let params = new URLSearchParams();
    params.append("order_id", OrderDetail ? OrderDetail.id : null);
    let res = await verifyPosCheckStatus(params.toString());
    if (res.status === 200) {
      skipcashPayment();
    } else {
      toast.error(res?.response?.data?.message || res?.message || res?.error, {
        theme: "colored",
      });
      setLoading(false);
    }
  };

  const skipcashPayment = async () => {
    let req = {
      order_id: OrderDetail ? OrderDetail.id : null,
      customer_id: AuthDetails?.id,
      restaurent_id: AuthDetails?.restaurent_id,
      amount: Number(partialAmount),
      tip: tipAmount,
      //full- 1 , partia; - 2 , spec - 3
      type: "2",
    };
    let res = await paymentOnSkipcash(req);
    if (res.status === 200) {
      setRedirectPaymentGatewayURL(res?.data?.resultObj);
      setLoading(false);
    } else {
      setLoading(false);
      toast.error(res.error || res.response.data.message);
    }
  };

  useEffect(() => {
    const { specific_payment_amount } = OrderDetail;
    if (specific_payment_amount > 0) {
      setPartialButtonDisabled(true);
    }
  }, [OrderDetail]);

  useEffect(() => {
    openSkipCashPaymentGateway();
  }, [redirectPaymentGatewayURL]);

  useEffect(() => {
    OrderDetails();
  }, []);

  return (
    <div>
      <ChatResponse
        message={<IntlMessage id="payment.pay.method.partialamount" />}
        time={time}
      />

      {fetchingDataLoader && data?.length > 0 ? (
        <LoadingWapper>
          <CircularProgress sx={{ color: customTheme?.theme_color, my: 2 }} />
        </LoadingWapper>
      ) : (
        <div className="payment-listing" style={{background: customTheme?.background_color , border:`1px solid ${customTheme?.theme_color}`}}>
          <SelectedItem data={data} />
          <BillDetail
            type="Partial"
            data={data}
            ParticalAmount={ParticalAmount}
          />
          <Tip sendValues={sendValues} />
          <div className="paymentButton">
            {loading ? (
              <CircularProgress sx={{ color: customTheme?.theme_color, my: 2 }} />
            ) : data[0]?.order_status == "5" || data[0]?.order_status == "6" ? (
              ""
            ) : (
              <>
                <button
                  onClick={validate}
                  style={{
                    background: `linear-gradient(91.33deg,${customTheme?.button_primary_color} 0%,${customTheme?.button_secondary_color} 100%)`,
                  }}
                >
                  <IntlMessage id="payement.button.makepayment" />
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default PayPartialAmount;

const PayPartialButton = styled.button`
  display: flex;
  align-items: center;
  width: 70%;
  border: none;
  max-width: 500px;
  outline: none;
  background: #fff7f4;
  border-radius: 5.8481px;
  padding: 12px;
  margin: 10px 20px;

  h6 {
    margin-left: 12px;
    font-family: "Jost";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.05em;
    color: #363636;
  }
  &:hover {
    background: #ffbba2;
  }
`;
