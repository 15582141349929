import React from 'react'
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Header } from '../../components';

function PaymentOne() {

  const direction = useSelector((state)=>state.selectLang.direction);

  return (
    <>
      <Header />
      <div className='paymentWrapper' dir={direction}>
        <h6>Payment</h6>
        <div className='methods'>
          <img src='/assets/images/Mastercard.svg' alt=''/>
          <img src='/assets/images/ApplePay.svg' alt=''/>
          <img src='/assets/images/gpay.svg' alt=''/>
        </div>
        <NavLink to='/payment-card-info'><button>CONTINUE</button></NavLink>
      </div>
    </>
  )
}

export default PaymentOne