import React, { useEffect, useState } from "react";
import { Header } from "../../components";
import { useSelector, useDispatch } from "react-redux";
import { restartChat, showLink, updateChat } from "../../store/ChatHistory";
import PayPartialAmount from "./PayPartialAmount";
import PayFullAmount from "./PayFullAmount";
import PayForSpecificDish from "./PayForSpecificDish";
import OrderDetailCustomer from "./OrderDetail";
import ChatOption, {
  CallWaiter,
  FullPayment,
  PartitalPayment,
  SpecificPayment,
} from "../../components/ChatOption";
import ChatResponse from "../../components/ChatResponse";
import { NavLink, useNavigate } from "react-router-dom";
import BoatMessage from "../../components/BoatMessage";
import { useRef } from "react";
import styled from "styled-components";
import FeedBackReceiptDetail from "../ReceiptDetail/FeedbackReceiptDetail";
import {
  getHelpMesaage,
  getOrderDetails,
  getUpdateOrderDetails,
  syncOrderPayemnt,
  syncPOS,
} from "../../services/Collection";
import { readNotifaiction } from "../../store/Notifications";
import { toast } from "react-toastify";
import WaiterMessage from "./WaiterMessage";
import { addOrderDetails } from "../../store/orderPayment";
import {
  CallTheWaiterIcon,
  OrderDetailChatIcon,
  PayChatIcon,
  SendChat,
} from "../../Utils/Images";
import { OrderIcon, PayByCardIcon, WaiterIcon } from "../../Utils/Icons";
import IntlMessage from "../../Utils/IntlMessage";
import { FormattedMessage, useIntl } from "react-intl";

const pay = require("../../assets/pay.png");
const waiter = require("../../assets/waiter.png");
const orderdetail = require("../../assets/orderdetail.png");

const Payment = () => {
  var today = new Date();
  var time = today.getHours() + ":" + today.getMinutes();

  const Navigate = useNavigate();
  const scrollDiv = useRef(null);
  const [isWaiterCalled, setWaiterCalled] = useState(false);
  const [pusherCall, setPusherCall] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [userMessage, setCustomerMessage] = useState("");
  const [isSpecificDisabled, setSpecificDisabled] = useState(false);
  const [isPartialDisabled, setPartialDisabled] = useState(false);
  const [isPaid, setPaid] = useState(false);
  const isPaymentDisable = useSelector(
    (state) =>
      state?.restaurentSlice?.restaurentData?.Menu?.Restaurent?.payment_enable
  );

  const { message } = useSelector(
    ({ notificationsResult, restaurentSlice }) => ({
      ...notificationsResult,
      ...restaurentSlice,
    })
  );

  const intl = useIntl();

  // const getAlreadyScanedID = useSelector((state) => state.StoreForMultiUsers);
  // const currentID = getAlreadyScanedID?.workStation?.slice(-1)?.[0]?.id;

  const getAlreadyScanedID = useSelector((state) => state.StoreForMultiUsers);
  const currentActive =
    getAlreadyScanedID?.getScannedQrcodeAndRestuarent?.slice(-1);

  const currentID = currentActive?.[0]?.qrcodeid;
  const RestuarantDetails = useSelector(
    (state) => state?.restaurentSlice?.restaurentData
  );

  const dispatch = useDispatch();
  const { orderDeails } = useSelector(({ orderInformationSlice }) => ({
    ...orderInformationSlice,
  }));

  const OrderDetail = useSelector(
    (state) => state?.orderInformationSlice?.orderDeails
  );

  const { chat, showChatLinks, direction } = useSelector(
    ({ chatHistorySlice, selectLang }) => ({
      ...chatHistorySlice,
      ...selectLang,
    })
  );

  console.log(showChatLinks , "showChatLinks");

  const [handleOrderDetailsClick, setHandleOrderDetailsClick] = useState(false);
  const [fetchingDataLoader, setFetchingDataLoader] = useState(false);
  const [fetchingDetails, setFetchingDeatils] = useState([]);

  //Getting the customer_id
  const handlePayment = () => {
    var today = new Date();
    var time = today.getHours() + ":" + today.getMinutes();
    const obj = {
      type: "userMessage",
      message: intl.formatMessage({
        id: "payment.button.pay",
      }),
      time: time,
    };

    const obj1 = {
      type: "payFull",
      name: intl.formatMessage({
        id: "payment.pay.method.fullamount",
      }),
      onClick: handleFullPayment,
      img: pay,
    };

    const obj2 = {
      type: "payPartial",
      name: intl.formatMessage({
        id: "payment.pay.method.partialamount",
      }),
      onClick: handlePartialPayment,
      img: pay,
    };

    const obj3 = {
      type: "paySpecif",
      name: intl.formatMessage({
        id: "payment.pay.method.specificdishes",
      }),
      onClick: handleSpecificDishPayment,
      img: pay,
    };
    dispatch(updateChat([obj, obj1, obj2, obj3]));
    dispatch(showLink(true));
    setShowMenu(false);
  };
  const handlePaymentCustomMessage = () => {
    var today = new Date();
    var time = today.getHours() + ":" + today.getMinutes();
    const obj = {
      type: "userMessage",
      message: userMessage,
      time: time,
    };

    const obj1 = {
      type: "chatOption",
      name: intl.formatMessage({
        id: "payment.pay.method.fullamount",
      }),
      onClick: handleFullPayment,
      img: pay,
    };

    const obj2 = {
      type: "chatOption",
      name: intl.formatMessage({
        id: "payment.pay.method.partialamount",
      }),
      onClick: handlePartialPayment,
      img: pay,
    };

    const obj3 = {
      type: "chatOption",
      name: intl.formatMessage({
        id: "payment.pay.method.specificdishes",
      }),
      onClick: handleSpecificDishPayment,
      img: pay,
    };
    dispatch(updateChat([obj, obj1, obj2, obj3]));
    dispatch(showLink(true));
    setShowMenu(false);
  };
  // handle when user click on cal waiter button
  const handleWaiter = async () => {
    var today = new Date();

    var time = today.getHours() + ":" + today.getMinutes();
    const obj = {
      type: "boatMessage",
      message: intl.formatMessage({
        id: "payment.button.callthewaiter",
      }),
      time: time,
    };

    dispatch(updateChat([obj]));

    const apiRes = await getHelpMesaage(1, OrderDetail.id);
    if (apiRes.status === 200) {
      setWaiterCalled(true);
      setPusherCall(true);
    } else {
      toast.error(apiRes.message);
    }
  };
  useEffect(() => {}, [isWaiterCalled, pusherCall]);

  //const handle caht bot
  const handleChatBot = async () => {
    const apiRes = await getHelpMesaage(1, OrderDetail.id);
    if (apiRes.status === 200) {
      setWaiterCalled(true);
      setPusherCall(true);
    } else {
      toast.error(apiRes.message);
    }
  };

  const handleOrderDetail = () => {
    setFetchingDeatils([]);
    setFetchingDataLoader(true);
    dispatch(restartChat());
    const obj = {
      type: "orderDetail",
    };
    setHandleOrderDetailsClick(true);
    dispatch(updateChat([obj]));
    dispatch(showLink(true));
    setShowMenu(false);
  };

  const handleFullPayment = () => {
    const obj = {
      type: "FullPayment",
    };
    dispatch(updateChat([obj]));
    // setChatData((prev)=>[...prev,obj]);
  };

  const handlePartialPayment = () => {
    const obj = {
      type: "PartialPayment",
    };
    dispatch(updateChat([obj]));
    // setChatData((prev)=>[...prev,obj]);
  };

  const handleSpecificDishPayment = () => {
    const obj = {
      type: "SpecificDishPayment",
    };
    // setChatData((prev)=>[...prev,obj]);
    dispatch(updateChat([obj]));
  };

  const ViewReceiptHandler = () => {
    const obj1 = {
      type: "userMessage",
      message: "View Receipt",
      time: "1:30 pm",
    };

    const obj = {
      type: "ShowViewReceipt",
    };
    // setChatData((prev)=>[...prev,obj]);
    dispatch(updateChat([obj1, obj]));
  };

  const EmailHandler = () => {
    const obj1 = {
      type: "userMessage",
      message: "Email",
      time: "1:30 pm",
    };

    const obj2 = {
      type: "boatMessage",
      message: "Enter your email and we will email Receipt.",
      time: "1:30 pm",
    };

    const obj3 = {
      type: "userMessage",
      message: "abc@gmail.com",
      time: "1:30 pm",
    };
    const obj4 = {
      type: "boatMessage",
      message: "Sent",
      time: "1:30 pm",
    };

    dispatch(updateChat([obj1, obj2, obj3, obj4]));
  };

  const GivefeedbackHandler = () => {
    Navigate("/feedback");
  };
  //Handle custom message send
  const handleSendMessage = async () => {
    const { id } = orderDeails;
    if (id) {
      if (userMessage) {
        //Pusher call
        var today = new Date();
        var time = today.getHours() + ":" + today.getMinutes();
        const obj1 = {
          type: "userMessage",
          message: userMessage,
          time: time,
          order_id: OrderDetail.id,
        };
        const message_type = userMessage.toLowerCase();
        if (message_type === "call the waiter") {
          handleChatBot(message);
          dispatch(updateChat([obj1]));
          setCustomerMessage("");
          // setShowMenu(true);
        } else if (message_type === "help") {
          handleChatBot(message);
          dispatch(updateChat([obj1]));
          setCustomerMessage("");
        } else if (message_type === "call") {
          handleChatBot(message);
          dispatch(updateChat([obj1]));
          setCustomerMessage("");
        } else if (message_type === "pay") {
          handlePaymentCustomMessage();
          // dispatch(updateChat([obj1]))
          setCustomerMessage("");
          // setShowMenu(true);
        } else if (message_type === "payment") {
          handlePaymentCustomMessage();
          // dispatch(updateChat([obj1]))
          setCustomerMessage("");
          // setShowMenu(true);
        } else if (message_type === "bill") {
          handleOrderDetail();
          dispatch(updateChat([obj1]));
          setCustomerMessage("");
          // setShowMenu(true);
        } else if (message_type === "order") {
          handleOrderDetail();
          dispatch(updateChat([obj1]));
          setCustomerMessage("");
          // setShowMenu(true);
        } else if (message_type === "order details") {
          handleOrderDetail();
          dispatch(updateChat([obj1]));
          setCustomerMessage("");
          // setShowMenu(true);
        } else if (message_type === "order detail") {
          handleOrderDetail();
          dispatch(updateChat([obj1]));
          setCustomerMessage("");
        } else {
          setCustomerMessage("");
          dispatch(updateChat([obj1]));

          setShowMenu(true);
        }
      }
    } else {
      toast.warning("You didnt have any active orders");
    }
  };
  //Scroll the postion of div when option button are clicked
  useEffect(() => {
    if (scrollDiv?.current) {
      scrollDiv?.current.scrollIntoView({
        behavior: "smooth",
      });
    }
    //Every time visit on this screen will set message as read
    handleReadNotifaiction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chat, showMenu, message]);

  const handleReadNotifaiction = () => {
    dispatch(readNotifaiction(true));
  };

  // GET ORDER DETAILS & GO FOR PAYMENT
  const [orderdedItem, setOrderdedItem] = useState([]);
  const [isLoaded, setLoaded] = useState(false);

  const continueButton = () => {
    if (orderDeails) var { id } = orderDeails;
    if (id) {
      setShowMenu(true);
      getOrderDetails(id).then((res) => {
        if (res.status === 200) {
          //Setting orderd item
          let data = res.data;
          setOrderdedItem([data]);
        }
      });
      setLoaded(true);
    }
  };

  // const [fetchingDetails, setFetchingDeatils] = useState([]);
  const getUpdatedOrderdetails = () => {
    if (!isLoaded) {
      if (orderDeails) var { id } = orderDeails;
      let payload = {
        order_id: id || null,
      };
      if (id) {
        getUpdateOrderDetails(payload).then((res) => {
          if (res.status === 200) {
            syncposAndsendValuestoDB();
            syncOrderPaymentinDB();
            dispatch(addOrderDetails(res?.data));
          }
        });
        setLoaded(true);
      }
    }
  };

  useEffect(() => {
    if (OrderDetail) {
      var { partial_payment_amount, order_status, specific_payment_amount } =
        OrderDetail;
    }

    if (partial_payment_amount > 0) {
      setSpecificDisabled(true);
    }

    if (specific_payment_amount > 0) {
      setPartialDisabled(true);
    }
    if (order_status === "5" || order_status === "6") {
      setPaid(true);
    }
  }, [OrderDetail]);

  const orderTransferAlert = (data) => {
    if (data?.order_status == "5" && data?.transfer_pos_check_num != null) {
      toast.error("Your Order has been transfered to other Table");
    }
  };

  const OrderDetails = async () => {
    setFetchingDataLoader(true);
    if (OrderDetail) var { id } = OrderDetail;

    if (id) {
      let res = await getOrderDetails(id);
      if (res.status === 200) {
        let data = res.data;
        dispatch(addOrderDetails(res?.data));
        setFetchingDeatils([data]);
        setFetchingDataLoader(false);
        orderTransferAlert(res?.data);
      } else {
        toast.error(res.message, { theme: "colored" });
        setFetchingDataLoader(false);
      }
    }
  };

  const syncposAndsendValuestoDB = async (response) => {
    let req = {
      restaurent_id: RestuarantDetails?.restaurent_id,
      qr_code_id: RestuarantDetails?.id,
    };
    let res = await syncPOS(req);
    if (res.status === 200) {
      // dispatch(authSucess(response));
      OrderDetails();
      console.log("pos sync");
    } else {
      OrderDetails();

      console.log("pos sync error");
    }
  };

  const syncOrderPaymentinDB = async () => {
    let req = {
      restaurent_id: RestuarantDetails?.restaurent_id,
      qr_code_id: RestuarantDetails?.id,
    };

    let res = await syncOrderPayemnt(req);
    if (res.status === 200) {
      // dispatch(authSucess(response));
      OrderDetails();
      console.log("order payemnt sync");
    } else {
      OrderDetails();
      // dispatch(authSucess(response));
      console.log("order payemnt sync error");
    }
  };

  useEffect(() => {
    syncposAndsendValuestoDB();
    syncOrderPaymentinDB();
  }, [chat]);

  useEffect(() => {
    getUpdatedOrderdetails();
  }, []);

  return (
    <PaymentParent>
      <Header />
      <div dir={direction}>
        {/* custom payment selected by restaurent status begin is here   */}
        <BoatMessage
          type={"beginningMessage"}
          messagePart1={<IntlMessage id="payment.welcome.message.part1" />}
          messagePart2={<IntlMessage id="payment.welcome.message.part2" />}
          time={time}
        />
        <div style={{ marginBottom: "75px" }}>
          {!isPaid && !isPaymentDisable && (
            <ChatOption
              img={PayChatIcon}
              icon={<PayByCardIcon className="icon" />}
              handleChatOption={handlePayment}
              name={<IntlMessage id="payment.button.pay" />}
            />
          )}
          {/* <CallWaiter
            img={CallTheWaiterIcon}
            icon={<WaiterIcon className="icon" />}
            isWaiterCalled={isWaiterCalled}
            handleWaiterCall={handleWaiter}
            name={<IntlMessage id="payment.button.callthewaiter" />}
          /> */}
          {OrderDetail && (
            <ChatOption
              img={OrderDetailChatIcon}
              icon={<OrderIcon className="icon" />}
              handleChatOption={handleOrderDetail}
              name={<IntlMessage id="payment.button.orderdetails" />}
            />
          )}

          {chat.length > 0 &&
            chat?.map((item, key) => {
              return item?.type === "userMessage" ? (
                <ChatResponse
                  key={key}
                  message={item?.message}
                  time={item?.time}
                />
              ) : item?.type === "boatMessage" ? (
                <BoatMessage
                  key={key}
                  message={item?.message}
                  time={item?.time}
                  name={item?.name}
                />
              ) : item?.type === "payFull" ? (
                <FullPayment
                  key={key}
                  img={item?.img}
                  icon={<PayByCardIcon className="icon" />}
                  handleChatOption={item?.onClick}
                  name={item?.name}
                />
              ) : item?.type === "payPartial" ? (
                <PartitalPayment
                  key={key}
                  img={item?.img}
                  icon={<PayByCardIcon className="icon" />}
                  handleChatOption={item?.onClick}
                  name={item?.name}
                  isPartialDisabled={isPartialDisabled}
                />
              ) : item?.type === "paySpecif" ? (
                OrderDetail?.discount === 0 && (
                  <>
                    <SpecificPayment
                      key={key}
                      img={item?.img}
                      icon={<PayByCardIcon className="icon" />}
                      handleChatOption={item?.onClick}
                      name={item?.name}
                      isSpecificDisabled={isSpecificDisabled}
                    />
                  </>
                )
              ) : item?.type === "waiterMessage" ? (
                <>
                  <WaiterMessage
                    key={key}
                    message={item?.message}
                    time={item?.time}
                  />
                </>
              ) : item?.type === "FullPayment" ? (
                <PayFullAmount key={key} />
              ) : item?.type === "PartialPayment" ? (
                <PayPartialAmount />
              ) : item?.type === "SpecificDishPayment" ? (
                <PayForSpecificDish />
              ) : item?.type === "orderDetail" && handleOrderDetailsClick ? (
                <>
                  <OrderDetailCustomer
                    fetchingDetails={fetchingDetails}
                    fetchingDataLoader={fetchingDataLoader}
                  />
                </>
              ) : item?.type === "ViewReceipt" ? (
                <ChatOption
                  key={key}
                  img={item?.img}
                  handleChatOption={ViewReceiptHandler}
                  name={item?.name}
                />
              ) : item?.type === "Email" ? (
                <ChatOption
                  key={key}
                  img={item?.img}
                  handleChatOption={EmailHandler}
                  name={item?.name}
                />
              ) : item?.type === "Givefeedback" ? (
                <ChatOption
                  key={key}
                  img={item?.img}
                  handleChatOption={GivefeedbackHandler}
                  name={item?.name}
                />
              ) : item?.type === "ShowViewReceipt" ? (
                <FeedBackReceiptDetail />
              ) : (
                ""
              );
            })}

          {message && <ChatResponse message={message} />}

          {/* {showChatLinks && (
            <div className="bottom-links">
              <h6 className="link" onClick={continueButton}>
                <IntlMessage id="payment.button.continue" />
              </h6>
              <NavLink className="link" to={`/${currentID}/addfoodlist`}>
                <IntlMessage id="payment.button.gobacktothemenu" />
              </NavLink>
            </div>
          )} */}

          {showMenu && (
            <>
              {!isPaid && !isPaymentDisable && (
                <ChatOption
                  img={pay}
                  icon={<PayByCardIcon className="icon" />}
                  handleChatOption={handlePayment}
                  name={<IntlMessage id="payment.button.pay" />}
                />
              )}
              {/* <CallWaiter
                img={waiter}
                icon={<WaiterIcon className="icon" />}
                isWaiterCalled={isWaiterCalled}
                handleWaiterCall={handleWaiter}
                name={<IntlMessage id="payment.button.callthewaiter" />}
              /> */}
              {OrderDetail && (
                <ChatOption
                  img={orderdetail}
                  icon={<OrderIcon className="icon" />}
                  handleChatOption={handleOrderDetail}
                  name={<IntlMessage id="payment.button.orderdetails" />}
                />
              )}
            </>
          )}
          <div ref={scrollDiv} />
        </div>
        {/* <section className="chat-bottom-footer">
          <div
            className={
              "payment-input-inner-field " +
              (direction === "rtl" ? "arabic" : "")
            }
          >
            <img
              src={SendChat}
              alt="send message"
              dir={direction}
              onClick={handleSendMessage}
            />
            <input
              type="text"
              value={userMessage}
              placeholder={intl.formatMessage({
                id: "payment.chat.placeholder",
              })}
              onChange={(e) => setCustomerMessage(e.target.value)}
            />
          </div>
        </section> */}
      </div>
    </PaymentParent>
  );
};

export default Payment;
const PaymentParent = styled.div`
  .bottom-links {
    display: flex;
    width: 70%;
    max-width: 500px;
    padding: 12px;
    margin: 10px 20px;
    justify-content: space-between;
    .link {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      text-decoration-line: underline;
      color: #fc2b6e;
      padding: 0 10px 0 0;
    }
  }

  .chat-bottom-footer {
    width: 100%;
    padding: 20px;
    position: fixed;
    bottom: 0;
  }

  .payment-input-inner-field {
    position: relative;
    input {
      background: #ffffff;
      box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
      border-radius: 60px;
      width: 100%;
      padding: 15px 10% 15px 15px;
      border: none;
    }
    img {
      position: absolute;
      right: 3%;
      top: 30%;
    }
    img:hover {
      cursor: pointer;
    }
  }

  .arabic {
    input {
      padding: 15px 15px 15px 10%;
    }
    img {
      left: 3%;
      right: auto;
    }
  }
`;
