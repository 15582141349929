import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { callbackURl } from "../services/Collection";

export default function TestCallbackURL() {

  const originalString = "This is a string with some empty spaces.";
  const newString = originalString.replace(/ /g, "&nbsp;");
  console.log(newString);

  return (
    <div>
      <div>Testing Callback Url</div>
    </div>
  );
}
