import React from "react";

function Discount({ direction, colorStyle, discountItem }) {

  return (
    <>
      {discountItem?.length > 0 &&
        discountItem.map(
          (udx) =>
            udx?.discount && (
              <div className="PriceSectionBox" dir={direction}>
                <div className="QuantitiyWrapper">
                  <div className="QunatityContainer">
                    <img
                      src={require("../../assets/Discount.png")}
                      alt="discount"
                    />
                    <div className="DiscoundContainer">
                      <p
                        style={{ color: colorStyle }}
                      >{`Crazy QAR ${udx?.discount} OFF deals`}</p>
                      <h5>On Item Prices</h5>
                    </div>
                  </div>
                </div>
              </div>
            )
        )}
    </>
  );
}

export default Discount;
