import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import IntlMessage from "../../Utils/IntlMessage";
import {
  BackgroundCover,
  Coverorder,
  Coverpayment,
  Coverscan,
} from "../../Utils/Images";
import { useNavigate } from "react-router-dom";

export default function LoginOptions() {
  const aqlutstorage = process.env.REACT_APP_IMAGE_BASEURL;
  const containerProfile = process.env.REACT_APP_CONTAINER_PROFILE;

  const navigate = useNavigate();
  const direction = useSelector((state) => state.selectLang.direction);
  const restaurenDetail = useSelector(
    (state) => state?.restaurentSlice?.restuarentProfileInfo
  );
  const getAlreadyScanedID = useSelector((state) => state.StoreForMultiUsers);
  const currentActive =
    getAlreadyScanedID?.getScannedQrcodeAndRestuarent?.slice(-1);

  let logo_restaurent = aqlutstorage + containerProfile + restaurenDetail?.logo;
  const QrCodeGroupType = useSelector(
    (state) => state?.restaurentSlice?.restaurentData?.QrCodeGroup?.group_type
  );

  const handleButton = (type) => {
    if (type === "customerverification") {
      navigate(
        `/${currentActive?.[0]?.qrcodeid}-${currentActive?.[0]?.restaurentid}/orderveri`
      );
    } else if (type === "continueguest") {
      navigate(
        `/${currentActive?.[0]?.qrcodeid}-${currentActive?.[0]?.restaurentid}/guest-menu`
      );
    }
  };

  return (
    <AppIntroWrapper>
      <section className="upper-wrapper">
        <TabDiv>
          <h1>1</h1>
          <div>
            <p>scan</p>
            <p>إمسح</p>
          </div>

          <img src={Coverscan} alt="scan" />
        </TabDiv>

        <TabDiv>
          <h1>2</h1>
          <div>
            <p>order</p>
            <p>أطلب</p>
          </div>

          <img src={Coverorder} alt="scan" />
        </TabDiv>

        <TabDiv>
          <h1>3</h1>
          <div>
            {QrCodeGroupType === "Dine In" ? (
              <>
                <p style={{ fontSize: "14px", lineHeight: "14px" }}>
                  pay full or <br /> split payments
                </p>
                <p style={{ fontSize: "14px" }}>
                  إدفع بالكامل أو تقاسم الفاتوره
                </p>
              </>
            ) : (
              <>
                <p>pay</p>
                <p>ادفع</p>
              </>
            )}
          </div>

          <img src={Coverpayment} alt="scan" />
        </TabDiv>
      </section>

      <section className="lower-wrapper">
        <SelectLangWrapper>
          <RestaurantLogoBox dir={direction}>
            <img src={logo_restaurent} alt="logo" />
          </RestaurantLogoBox>

          <div className="upper" dir={direction}>
            <header>
              <IntlMessage id="welcomescreen.title" />{" "}
              {direction === "rtl"
                ? restaurenDetail?.ar_name
                : restaurenDetail?.name}
            </header>
          </div>

          <UserAuthentication>
            <div onClick={() => handleButton("customerverification")}>
              <p>
                <IntlMessage id="authorization.quickorder" />
              </p>
            </div>
            <div onClick={() => handleButton("continueguest")}>
              <p>
                <IntlMessage id="authorization.continueguest" />
              </p>
            </div>
          </UserAuthentication>

          <h1 className="NoteForGuestUser">
            <IntlMessage id="authorization.NoteForGuestUser" />
          </h1>
        </SelectLangWrapper>
      </section>
    </AppIntroWrapper>
  );
}

const TabDiv = styled.div`
  width: 100%;
  height: 89px;
  background: rgba(255, 216, 229, 0.5);
  border-radius: 200px;
  padding: 9px 30px;
  display: flex;
  align-items: center;
  position: relative;

  @media (max-height: 660px) {
    height: 78px;
  }

  img {
    width: 107px;
    height: 107px;
    position: absolute;
    right: -30px;
  }

  h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 70px;
    line-height: 105px;
    color: #bd01a8;
  }

  div {
    margin: 0 9px;

    p {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 23px;
      line-height: 34px;
      text-transform: uppercase;
      color: #bd01a8;
    }
  }
`;

const AppIntroWrapper = styled.div`
  width: 100%;
  // height: 100vh;

  .upper-wrapper {
    width: 100%;
    height: 70vh;
    display: flex;
    flex-direction: column;
    gap: 50px;
    justify-content: center;
    padding: 0 40px 0 20px;

    @media (max-height: 785px) {
      height: auto;
      margin-top: 40px;
    }
    @media (max-height: 710px) {
      height: auto;
      margin-top: 20px;
    }
  }
`;

const UserAuthentication = styled.div`
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  div {
    width: 100%;
    margin: 0 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: linear-gradient(91.33deg, #fc2b6e 0%, #bc00a9 100%);
    padding: 7px 0;
    border: 1.5px solid #ffffff;

    p {
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 00;
      font-size: 15px;
      line-height: 18px;
      -webkit-letter-spacing: 0.36px;
      -moz-letter-spacing: 0.36px;
      -ms-letter-spacing: 0.36px;
      letter-spacing: 0.36px;
      color: #fff;
      text-align: center;
      padding: 0 10px;
    }
  }
`;

const RestaurantLogoBox = styled.div`
  position: absolute;
  width: 100px;
  height: 100px;
  left: ${({ dir }) => dir === "ltr" && "30px"};
  right: ${({ dir }) => dir === "rtl" && "30px"};
  top: -50px;
  background: #fc2b6e;
  border: 1px solid #fc2b6e;
  border-radius: 100px;
  padding: 1px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 100px;
  }
`;

const SelectLangWrapper = styled.div`
  width: 100%;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  background: #ffffff;
  border-top: 1px solid #ffffff;
  border-radius: 38px 38px 0px 0px;
  border-radius: 30px 30px 0px 0px;
  padding: 20px;
  box-shadow: 4px -1px 16px 1px #afabab99;

  .upper {
    text-align: center;
    header {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 36px;
      letter-spacing: 0.02em;
      color: #000000;
      margin-top: 40px;
    }

    p {
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #7b7f91;
      padding: 10px 0;
    }
  }

  .lower {
    display: flex;
    margin: 20px 0;
    select {
      width: 100%;
      height: 48px;
      background: linear-gradient(90deg, #ff3f7d 0%, #db1ac7 100%);
      background: #ff3f7d;
      border: 1px solid #d2d2d3;
      border-radius: 28px;
      padding: 0 20px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #ffffff;
      -webkit-appearance: none;
      -moz-appearance: none;
    }
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 45px;
      background: linear-gradient(90deg, #ff3f7d 0%, #db1ac7 100%);
      margin: 0 10px;
      border: 1px solid #ffffff;
      border-radius: 100%;

      @media (max-width: 500px) {
        width: 55px;
        height: 45px;
        margin-right: 0px;
      }
    }
  }

  h1 {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
    letter-spacing: 0.36px;
    color: #ffffff;
    padding: 30px 0;
    text-align: center;
  }

  .NoteForGuestUser {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    -webkit-letter-spacing: 0.36px;
    -moz-letter-spacing: 0.36px;
    -ms-letter-spacing: 0.36px;
    text-align: center;
    padding: 10px 0 0 0;
    color: #fc2b6e;

    @media (max-height: 660px) {
      display:none;
    }

  }
`;

const SelectWrapper = styled.div`
  position: relative;
  background: ${({ background_image, background_img }) =>
    `${
      background_image !== undefined
        ? `url(${background_img})`
        : `url(${BackgroundCover.toString()})`
    }`};
  background-repeat: no-repeat;
  background-size: 100% 75%;
  width: 100%;
  height: 100vh;
`;
