import { createSlice } from "@reduxjs/toolkit";

export const themeSlice = createSlice({
  name: "theme",
  initialState: {
    branding:{
      display_image: "sesame-garlic-ramen-noodles-15.jpg",
      webpage_background_color: "#000000",
      welcome_button_text_color: "#000000",
      welcome_button_bg_color: "#ffffff",
      welcome_text: "Welcome to Saurabh's World",
      home_webpage_bg_color: "#ffffff",
      home_text_color: "#FFC0CB",
      home_price_text_color: "#000000",
      home_button_text_color: "#ffffff",
      home_button_bg_color: "#650606",
      home_heading_text_color: "#ffffff",
      display_image_for_web_background: true,
      ar_welcome_text: "مرحبا بكم في مطعمنا",
      place_order_primary_button_colour: "#f568f8",
      place_order_secondary_button_colour: "#dd13b8",
      review_primary_button_colour: "#ed0c66",
      review_secondary_button_colour: "#c238b4",
      menu_primary_button_colour: "#e09d29",
      menu_secondary_button_colour: "#ea0b0b",
      payment_primary_button_colour: "#fc2b6e",
      payment_secondary_button_colour: "#BC00A9",
      theme_color: "#fc2b6e",
      background_color: "#FFFFFF",
      header_color: "#202020",
      text_color: "#7b7f91",
      placeholder_box_color:"#ffffff"
    }
    
  },

  reducers: {
    customTheme: (state, action) => {
      console.log(action?.payload , "actionnnnnnnnnnn")
      if (action.payload) {
        state.branding = action.payload;
      }
    },
  },
});

export const { customTheme } = themeSlice.actions;
export default themeSlice.reducer;
