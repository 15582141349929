import React from 'react'
import styled from 'styled-components'

function CheckoutPrice() {
  return (
    <Checkout>
        <h5>Checkout</h5>
        <h6>QAR 150</h6>
    </Checkout>
  )
}

export default CheckoutPrice

const Checkout = styled.div`
  background: linear-gradient(0deg, #FFFFFF, #FFFFFF), #F55A2C;
  box-shadow: 0px 2px 27px #FFB49E;
  border-radius: 0px 0px 30px 30px;
  display: flex;
  justify-content: space-between;
  padding: 22px;
  h5{
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 32px;

    letter-spacing: 0.03em;

    color: #363636;
  }

  h6{
    font-family: 'Jost';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 29px;
    text-align: right;
    letter-spacing: 0.03em;

    color: ${(({theme})=>theme?.checkoutPriceColor)};
  }
`;