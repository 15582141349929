import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  // AddIcon,
  MarkAsSignatureIcon,
  SignatureIcon,
} from "../../Utils/Images";
import IntlMessage from "../../Utils/IntlMessage";
import { useLocation } from "react-router-dom";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";

function Item({ toggleDrawer, item, identifyLastItemOfSection }) {
  const aqlutstorage = process.env.REACT_APP_IMAGE_BASEURL;
  const containerItem = process.env.REACT_APP_CONTAINER_ITEM;

  const dir = useSelector((state) => state.selectLang.direction);
  const POSisEnable = useSelector((state) => state?.posSlice?.isEnable);
  const isItemSynced = useSelector(
    (state) =>
      state?.restaurentSlice?.restaurentData?.Menu?.Restaurent
        ?.disable_order_for_unsyncing
  );
  console.log("isItemSynced", isItemSynced);
  const customTheme = useSelector((state) => state?.theme?.branding);
  const RestuarantDetails = useSelector(
    (state) => state?.restaurentSlice?.restaurentData
  );

  const location = useLocation()?.pathname;
  const noOfWords = window.innerWidth <= 380 ? 125 : 140;

  let item_desc;
  if (dir === "ltr") {
    item_desc = item?.desc;
  } else {
    // eslint-disable-next-line no-unused-vars
    item_desc = item?.ar_desc;
  }

  const handleAddIcon = () => {
    if (RestuarantDetails?.QrCodeGroup?.group_type === "Menu") {
      return false;
    }
    if (RestuarantDetails?.Menu?.Restaurent?.disable_order) {
      return false;
    }
    // if (
    //   RestuarantDetails?.QrCodeGroup?.group_type === "Dine In" &&
    //   location?.includes("menu")
    // ) {
    //   return false;
    // }

    if (RestuarantDetails?.Menu?.Restaurent?.pos_integration_status) {
      if (RestuarantDetails?.Menu?.Restaurent?.disable_order_for_unsyncing) {
        return false;
      } else {
        return true;
      }
    }
    return true;
  };

  const checkingShowingPrice = (price) => {
    if (POSisEnable) {
      if (price === null) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const CheckItemQuantity = (item) => {
    if (
      item?.item_availability_quantity > 0 ||
      item?.item_availability_quantity == null
    ) {
      return true;
    }
    return false;
  };

  console.log(item, "itemitemitem");

  return (
    <ItemParent
      identifyLastItemOfSection={identifyLastItemOfSection}
      dir={dir}
      themeColor={customTheme?.theme_color}
      onClick={() => CheckItemQuantity(item) && toggleDrawer(item)}
    >
      {item?.item_availability_quantity !== null && (
        <>
          {item?.item_availability_quantity <= 5 && (
            <span className="quantitylabel" style={{ background: "green" }}>
              Only {item?.item_availability_quantity} left
            </span>
          )}

          {item?.item_availability_quantity == 0 && (
            <span className="quantitylabel" style={{ background: "red" }}>
              Out of Stock
            </span>
          )}
        </>
      )}
      <div className="ItemImage">
        {item?.image && (
          <img
            src={
              item?.image
                ? aqlutstorage + containerItem + item.image
                : require("../../assets/food.png")
            }
            alt="Food Item"
            onClick={() => CheckItemQuantity(item) && toggleDrawer(item)}
          />
        )}

        {handleAddIcon() && (
          <label
            className="addtocart"
            onClick={() => CheckItemQuantity(item) && toggleDrawer(item)}
            style={{ background: customTheme?.theme_color }}
          >
            <p>
              {/* Add to cart */}
              <IntlMessage id="addfoodlist.button.addtocart" />
            </p>

            <ShoppingCartOutlinedIcon fontSize="small" sx={{ color: "#fff" }} />

            {/* <i><AddIcon color={customTheme?.theme_color} /></i> */}
          </label>
        )}
      </div>

      <div className="ItemDetail">
        <div>
          {" "}
          <div className="ItemNameAndBatches">
            <h5 style={{ color: customTheme?.header_color }}>
              {dir === "ltr" ? item?.name : item?.ar_name}
            </h5>
            <div>
              {item.mark_section_as_new && (
                <img src={SignatureIcon} alt="SignatureIcon" />
              )}
              {item.mark_section_as_signature && (
                <img src={MarkAsSignatureIcon} alt="MarkAsSignatureIcon" />
              )}
            </div>
          </div>
          <p style={{ color: customTheme?.text_color }}>
            {item_desc.slice(0, noOfWords)}
            {item_desc?.length > noOfWords && (
              <span
                style={{ color: customTheme?.theme_color }}
                onClick={() => CheckItemQuantity(item) && toggleDrawer(item)}
              >
                ...
                <IntlMessage id="addfoodlist.button.seemore" />
              </span>
            )}
          </p>
        </div>

        {/* <div className="CaloriesAndCustomizable">
          {item?.ItemPrices[0]?.calories && (
            <h1
              style={{
                border: `1px solid ${customTheme?.theme_color}`,
                color: customTheme?.theme_color,
              }}
            >
              {item?.ItemPrices[0]?.calories + "  " + "kcal"}
            </h1>
          )}
          {item?.MenuItemOptions?.length > 0 && (
            <h2
              style={{
                border: `1px solid ${customTheme?.theme_color}`,
                color: customTheme?.theme_color,
              }}
            >
              <IntlMessage id="addfoodlist.button.customizable" />
            </h2>
          )}
        </div> */}
        <div>
          <div className="availablePrice">
            {item?.ItemPrices?.length > 1 ? (
              <h6 style={{ color: customTheme?.header_color }}>
                <IntlMessage id="addfoodlist.priceOnSelection" />
              </h6>
            ) : (
              <h6 style={{ color: customTheme?.theme_color }}>
                {checkingShowingPrice(item?.ItemPrices[0]?.pos_item_price)
                  ? "QAR " + item?.ItemPrices[0]?.pos_item_price
                  : "QAR " + item?.ItemPrices[0]?.price}
              </h6>
            )}
          </div>

          <AllergiesWrapper>
            <div className="FreeItemName">
              <div
                style={{
                  display: "flex",
                  gap: "8px",
                  // paddingTop: "9px",
                  color: customTheme?.text_color,
                }}
              >
                {item?.allergies?.length > 0 &&
                  item?.allergies?.map((item, key) => (
                    // <Popover
                    //   prefixCls="alleriges-popup"
                    //   content={
                    //     <AllergiesPopoverName
                    //       style={{ color: customTheme?.theme_color }}
                    //     >
                    //       {item?.name}
                    //     </AllergiesPopoverName>
                    //   }
                    //   trigger="hover"
                    // >
                    <div className="allergies-icon-wrapper">
                      <img
                        src={`${aqlutstorage + containerItem + item?.image}`}
                        alt=""
                        style={{ width: "20px" }}
                      />
                    </div>
                    // </Popover>
                  ))}
              </div>
            </div>
          </AllergiesWrapper>
        </div>
      </div>
    </ItemParent>
  );
}

export default Item;

const AllergiesPopoverName = styled.div`
  font-family: "Nunito Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 157%;
  margin: 0;
  color: #858992;
`;

const AllergiesWrapper = styled.div`
  .FreeItemName {
    position: relative;

    span {
      display: flex;
      font-family: Poppins;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;

      span {
        margin-top: 1.6px;
      }
    }
    p {
      margin: 0;
      color: #7b7f91;
      margin-right: 5px;
      padding-top: 6px;
    }

    .allergies-icon-wrapper {
      width: fit-content;
      height: fit-content;
      border: 1px solid #232323;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 3px;
      border-radius: 50%;
      cursor: pointer;
      img {
        filter: contrast(1);
      }
    }
  }
`;

const ItemParent = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  height: 176px;
  gap: 5px;
  position: relative;

  .quantitylabel {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 4;
    padding: 2px 8px;
    border-radius: 4px;
    color: white;
    font-size: 13px;
    font-weight: 600;
  }

  .ItemImage {
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: space-between;
    width: 100%;
    position: relative;

    img {
      border-radius: 8px;
      width: 100%;
      height: 160px;
      object-fit: cover;
      background: #fff;
      border: 1px solid #0000000d;
    }

    .addtocart {
      position: absolute;
      bottom: 0;
      right: ${({ dir }) => (dir === "ltr" ? "23px" : "23px")};
      left: ${({ dir }) => (dir === "rtl" ? "23px" : "23px")};
      // width: 100%;
      padding: 6px 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 8px;
      p {
        font-family: "Nunito Sans", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 14px;
        color: #fff;
      }
    }
  }

  .addItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: ${({ dir }) => dir === "ltr" && "20px"};
    margin-left: ${({ dir }) => dir === "rtl" && "20px"};
    img {
      border-radius: 8px;
      width: 76px;
      height: 76px;
    }
    button {
      width: 54.41px;
      height: 26.27px;

      border-radius: 6.25377px;
      border-style: none;
      font-family: "Jost";
      font-style: normal;
      font-weight: 500;
      font-size: 12.3512px;
      line-height: 18px;
      letter-spacing: 0.05em;

      margin-top: -15px;
      :hover {
        cursor: pointer;
      }
    }
    p {
      font-family: "Jost";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #a1a1a1;
    }
  }

  .ItemDetail {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;

    label {
      width: 26px;
      height: 26px;
      border: 0.5px solid rgba(36, 36, 36, 0.3);
      border-radius: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .ItemNameAndBatches {
      display: flex;
      justify-content: space-between;
      h5 {
        font-family: "Nunito Sans", sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        color: #575962;
      }
      div {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      img {
        width: 15px;
        height: 15px;
        margin: 0 2px;
      }
    }

    .availablePrice {
      display: flex;
      justify-content: space-between;
      p {
        font-size: 12px;
        position: absolute;
        left: ${({ dir }) => dir === "rtl" && "0"};
        right: ${({ dir }) => dir === "ltr" && "0"};
      }
    }

    h6 {
      font-family: "Nunito Sans", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }
    p {
      font-family: "Nunito Sans", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #575962;
      /* padding-top: 8px; */
      span {
        color: #fc2b6e;
        white-space: nowrap;
      }
    }

    .CaloriesAndCustomizable {
      display: flex;
      margin: 5px 0;

      h1 {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0.5px solid rgba(36, 36, 36, 0.3);
        border-radius: 30px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 15px;
        color: #fc2b6e;
        padding: 5px 10px;
      }

      h2 {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0.5px solid rgba(36, 36, 36, 0.3);
        border-radius: 30px;

        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 15px;
        color: #7b7f91;
        padding: 5px 10px;
        margin: 0 10px;
      }
    }
  }
`;
