import React from "react";
import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

function CardDatePicker({ onDateTouch, value }) {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        views={["year", "month"]}
        inputFormat="MM/yyyy"
        label="Custom input"
        value={value}
        onChange={(newValue) => {
          onDateTouch(newValue);
        }}
        renderInput={({ inputRef, inputProps, InputProps }) => (
          <Box
            sx={{
              display: "flex",
              position: "relative",
              alignItems: "center",
              paddingBottom: "20px !important",
            }}
          >
            <Input
              ref={inputRef}
              {...inputProps}
              className="form-control"
              placeholder="MM/YY"
            />
            <span style={{ paddingBottom: "20px " }}>
              {InputProps?.endAdornment}
            </span>
          </Box>
        )}
      />
    </LocalizationProvider>
  );
}

export default CardDatePicker;
