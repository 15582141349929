import { Drawer } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import {
  BackIcon,
  CrossIcon,
  FacebookIcon,
  InstagramIcon,
  SidenavCross,
  SnapchatIcon,
  TickTokIcon,
  WebsiteIcon,
} from "../../Utils/Icons";
import { useDispatch, useSelector } from "react-redux";
import { langSuccess } from "../../store/selectlang";
import { useNavigate } from "react-router-dom";
import IntlMessage from "../../Utils/IntlMessage";

export default function Sidenav({ open, onClose, applogo, phoneNumber }) {
  const [switchScreen, setSwitchScreen] = useState(true);
  const theme = useSelector((state) => state?.theme?.branding);
  const selectedLang = useSelector((state) => state?.selectLang);
  const direction = useSelector((state) => state?.selectLang?.direction);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const aqlutstorage = process.env.REACT_APP_IMAGE_BASEURL;
  const containerProfile = process.env.REACT_APP_CONTAINER_PROFILE;
  const restaurentInfo = useSelector(
    (state) => state?.restaurentSlice?.restuarentProfileInfo
  );

  let logo_restaurent = aqlutstorage + containerProfile + applogo;

  const handleLang = (lang) => {
    if (lang === "en" && lang !== selectedLang?.lang) {
      let obj = {
        lang: "en",
        direction: "ltr",
      };
      dispatch(langSuccess(obj));
    } else if (lang === "ar" && lang !== selectedLang?.lang) {
      let obj = {
        lang: "ar",
        direction: "rtl",
      };
      dispatch(langSuccess(obj));
    }
  };

  console.log(phoneNumber, "phoneNumberphoneNumber");

  return (
    <div>
      <Drawer
        placement={selectedLang?.lang === "en" ? "left" : "right"}
        closable={false}
        onClose={onClose}
        open={open}
        key={"left"}
        width={300}
        style={{ padding: 0 }}
      >
        <SidenavWrapper themeColor={theme?.theme_color} dir={direction}>
          <div className="sidenav-top-icons">
            {switchScreen ? (
              <i onClick={onClose}>
                <SidenavCross color={theme?.theme_color} />
              </i>
            ) : (
              <i onClick={() => setSwitchScreen(true)}>
                <BackIcon color={theme?.theme_color} />
              </i>
            )}
          </div>

          {switchScreen && (
            <div className="app-logo-wrapper">
              <img src={logo_restaurent} alt="Logo" />
            </div>
          )}

          {switchScreen ? (
            <div className="sidenav-option-wrapper">
              <p onClick={() => setSwitchScreen(false)}>
                <IntlMessage id="sidenav.Language" />
                {selectedLang?.lang === "en" ? `(EN)` : `(AR)`}{" "}
              </p>
              <p onClick={() => navigate(`/SmartFeedback`)}>
                <IntlMessage id="sidenav.GiveFeedback" />
              </p>
              {phoneNumber && (
                <a href={`tel:${phoneNumber}`} style={{ color: "#575962" }}>
                  <p>
                    <IntlMessage id="sidenav.CallRestaurent" />
                  </p>
                </a>
              )}
            </div>
          ) : (
            <div className="sidenav-option-wrapper">
              <p
                className={selectedLang?.lang === "en" && "active"}
                onClick={() => handleLang("en")}
              >
                English
              </p>
              <p
                className={selectedLang?.lang === "ar" && "active"}
                onClick={() => handleLang("ar")}
              >
                Qatar
              </p>
            </div>
          )}
        </SidenavWrapper>
        <SocialSidenavWrapper themeColor={theme?.theme_color}>
          {restaurentInfo?.facebook_link && (
            <i>
              <a
                href={restaurentInfo?.facebook_link}
                target={"_blank"}
                style={{ display: "flex" }}
              >
                <FacebookIcon color={theme?.theme_color} />
              </a>
            </i>
          )}

          {restaurentInfo?.instagram_link && (
            <i>
              <a
                href={restaurentInfo?.instagram_link}
                target={"_blank"}
                style={{ display: "flex" }}
              >
                <InstagramIcon color={theme?.theme_color} />
              </a>
            </i>
          )}

          {restaurentInfo?.website_link && (
            <i>
              <a
                href={restaurentInfo?.website_link}
                target={"_blank"}
                style={{ display: "flex" }}
              >
                <WebsiteIcon color={theme?.theme_color} />
              </a>
            </i>
          )}
          {restaurentInfo?.snapchat_link && (
            <i>
              <a
                href={restaurentInfo?.snapchat_link}
                target={"_blank"}
                style={{ display: "flex" }}
              >
                <SnapchatIcon color={theme?.theme_color} />
              </a>
            </i>
          )}

          {restaurentInfo?.tiktok_link && (
            <i>
              <a
                href={restaurentInfo?.tiktok_link}
                target={"_blank"}
                style={{ display: "flex" }}
              >
                <TickTokIcon color={theme?.theme_color} />
              </a>
            </i>
          )}
        </SocialSidenavWrapper>
      </Drawer>
    </div>
  );
}

const SocialSidenavWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;

  i {
    border: ${({ themeColor }) => `1px solid ${themeColor}`};
    padding: 6px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-item: center;
    cursor: pointer;
  }
  svg {
    width: 20px;
    height: 20px;
  }
`;
const SidenavWrapper = styled.div`
  width: 100%;
  height: calc(100dvh - 48px);
  //   background: #a7a5a5ee;
  background: white;

  display: flex;
  flex-direction: column;

  .sidenav-top-icons {
    padding: 14px 16px;
    > i {
      width: fit-content;
      cursor: pointer;
      svg {
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }
  }

  .app-logo-wrapper {
    width: 100%;
    min-height: 118px;
    max-height: 100%;
    margin: 10px 0 20px 0;
    // margin-top: 48px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 133px;
      height: 133px;
      object-fit: contain;
    }
  }

  .sidenav-option-wrapper {
    width: 100%;
    height: 400px;
    background: white;
    padding: 20px;

    color: #575962;
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
    cursor: pointer;

    display: flex;
    flex-direction: column;
    gap: 24px 0;
  }

  .active {
    color: ${({ themeColor }) => themeColor};
  }
`;
