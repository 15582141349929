/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate,useLocation } from "react-router-dom";
import { AddressBackBtn, YourLocationIcon } from "../../Utils/Images";
import MapComponent from "./MapComponent";
import { SelectMapLocationStyleBox } from "./SelectMapLocationStyle";
import { createAddressInfo } from "../../services/Collection";
import { toast } from "react-toastify";
import { LoadingWapper } from "../../style/GobalStyle";
import { CircularProgress } from "@mui/material";
import { MapIcon } from "../../Utils/Icons";

export const SelectMapLocation = () => {
  const navigate = useNavigate();

  const getAlreadyScanedID = useSelector((state) => state.StoreForMultiUsers);
  const currentActive =
    getAlreadyScanedID?.getScannedQrcodeAndRestuarent?.slice(-1);

  const currentID = currentActive?.[0]?.qrcodeid;
  const customTheme = useSelector((state) => state?.theme?.branding);

  const [addressLocation, setAddressLocation] = useState({
    city: "",
    state: "",
    country: "",
    fullAddress: "",
  });
  const [locationLatLong, setLocationLatLong] = useState({
    lat: "",
    lng: "",
  });
  const [loading, setLoading] = useState(false);
  const [haveOfflineInfo,setHaveOfflineInfo]=useState(false)

  const addressDetail = useLocation()?.state;

  useEffect(()=>{
    if(addressDetail?.lat){
      let {lat,lng,makeDefault,...restInfo}=addressDetail
      setLocationLatLong({lat,lng})
      setAddressLocation({...restInfo})
      setHaveOfflineInfo(true)
    }
  },[addressDetail])

  const handleSetLocation=async()=>{
    if(!haveOfflineInfo){
      navigate(`/${currentID}/add-location`, {
        state: {
          ...addressLocation,                         
          ...locationLatLong,
          prevScreen: "map-location",
        },                     
      })
    } 
    else if(haveOfflineInfo){
      setLoading(true)
      let req = {
        full_address: addressDetail?.fullAddress,
        house_number: addressDetail?.houseNo,
        street: addressDetail?.streetName,
        city: addressDetail.city,
        // state: value.state,
        country: addressDetail.country,
        // pincode: value.pincode,
        extra_detail: addressDetail?.extraDetails,
        isPrimary: addressDetail?.makeDefault,
        latitude: addressDetail?.lat,
        longitude: addressDetail?.lng,
      };
      let res = await createAddressInfo(req);
      if (res.status === 200) {
        setLoading(false);
        navigate(`/${currentActive?.[0]?.qrcodeid}/select-location`);
        toast.success(res.message, { theme: "colored" });
      } else {
        setLoading(false);
        toast.error(res.message, { theme: "colored" });
      }
    }                                                                                                    
  }

  return (
    <SelectMapLocationStyleBox>
      {loading && (
        <LoadingWapper
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress sx={{ color: customTheme?.theme_color }} />
        </LoadingWapper>
      )
      }
      <div className="map-wrapper"  >
        <MapComponent
          setAddressLocation={setAddressLocation}
          setLocationLatLong={setLocationLatLong}
          locationLatLong={locationLatLong}
        />
      </div>
      <div className="map-btnsWrapper" style={{background: customTheme?.background_color}}>
        <div className="btns-title">
          <div className="back-btn" onClick={() => navigate(-1)}
           style={{
            background: `linear-gradient(91.33deg,${customTheme?.button_primary_color} 0%,${customTheme?.button_secondary_color} 100%)`,
          }}
          >
            <img alt="" src={AddressBackBtn} />
          </div>
          <h3 style={{color: customTheme?.header_color}} >Your Location</h3>
        </div>
        <div className="currentLocation-Box">
        <MapIcon PrimaryColor={customTheme?.button_primary_color} SecondaryColor={customTheme?.button_secondary_color} />
          <h4 style={{color: customTheme?.text_color}} >{addressLocation?.fullAddress}</h4>
        </div>

        <div className="maps-btns">
          <button
            className="set-location"
            onClick={handleSetLocation}
            style={{
              color: customTheme?.button_text_color,
              background: `linear-gradient(91.33deg,${customTheme?.button_primary_color} 0%,${customTheme?.button_secondary_color} 100%)`,
            }}
          >
            Set Location
          </button>
          {/* <button
            onClick={() =>
              navigate(`/${currentID}/add-location`, { state: addressLocation })
            }
            className="add-location"
          >
            Add Address
          </button> */}
        </div>
      </div>
    </SelectMapLocationStyleBox>
  );
};
