import axios from "axios";
import { store } from "../store/store";
import { Logout } from "../store/userLoginReducer";
const {dispatch} = store;

const EndPoint =
  process.env.REACT_APP_BASEURL + process.env.REACT_APP_API_VERSION;

const Api = axios.create({
  timeout: 1000000,
  baseURL: EndPoint,
});

Api.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
Api.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

Api.interceptors.request.use(
  (config) => {
    const lang = store.getState().selectLang.lang;
    const token = store.getState().userAuth.data?.token;
    const guestToken = store.getState().userAuth?.token;

    const getAlreadyScanedID = store.getState()?.StoreForMultiUsers?.getScannedQrcodeAndRestuarent?.slice(-1)?.[0]?.qrcodeid;
    const aqlutAuthType = token ? false : true;

    config.headers = {
       Authorization: token ? `Bearer ${token}` : guestToken,
      "Aqlut-Auth-Type": aqlutAuthType,
      "Accept-Language": lang,
      "Qrcodeid": getAlreadyScanedID
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

Api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log(error, "errorerror")
    if(error?.response?.status === 401){
      const getAlreadyScanedID = store.getState()?.StoreForMultiUsers?.getScannedQrcodeAndRestuarent?.slice(-1)?.[0]?.qrcodeid;
      dispatch(Logout());
      window.location.href = `/?qrcodeid=${getAlreadyScanedID}`;
    }
    return Promise.reject(error);
  }
);

export default Api;
