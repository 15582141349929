import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { SnapayGrayBackground } from "../../Utils/Images";
import { ArrowLeftIcon, Refresh } from "../../Utils/Icons";
import { useLocation, useNavigate } from "react-router-dom";
import { addCommaToNumbers } from "../../Utils/CommanFunctions";
import IntlMessage from "../../Utils/IntlMessage";

const OrderedItemsBill = ({ updateOrder, transactionData }) => {
  const customTheme = useSelector((state) => state?.theme?.branding);
  const order = useSelector(
    (state) => state?.orderInformationSlice?.PosSyncedOrder
  );
  const restaurantDetails = useSelector((state)=>state?.restaurentSlice?.restaurentData?.Menu?.Restaurent)
  const location = useLocation().pathname;
  const navigate = useNavigate();
  const getAlreadyScanedID = useSelector((state) => state.StoreForMultiUsers);
  const currentActive =
    getAlreadyScanedID?.getScannedQrcodeAndRestuarent?.slice(-1);
  const langDir = useSelector((state) => state?.selectLang);

  const calculateItemPrice = (payload) => {
    let tempPrice = 0;
    if (payload?.OrderItemAddOns?.length > 0) {
      for (let index = 0; index < payload?.OrderItemAddOns?.length; index++) {
        tempPrice = tempPrice + Number(payload?.OrderItemAddOns[index]?.price);
      }
      tempPrice = tempPrice + payload?.amount;
    } else {
      tempPrice = payload?.amount;
    }
    return tempPrice;
  };

  return (
    <OrderedItemsBillStyle
      textColor={customTheme?.text_color}
      background={SnapayGrayBackground}
      dir={langDir?.direction}
    >
      {!location?.includes("aqlutpaymentsuccessful") && (
        <PayBillHeader>
          <div className="divWrapper">
            <i
              onClick={() =>
                navigate(`/?qrcodeid=${currentActive?.[0]?.qrcodeid}`)
              }
            >
              <ArrowLeftIcon
                button_primary_color={customTheme?.button_primary_color}
              />
            </i>
            <p>
              {langDir?.direction === "ltr"
                ? order?.QrCode?.name
                : order?.QrCode?.ar_name}
            </p>
          </div>
          <i
          onClick={() => updateOrder("refresh")}
          >
            <Refresh
              button_text_color={customTheme?.button_text_color}
              button_primary_color={customTheme?.button_primary_color}
            />
          </i>
        </PayBillHeader>
      )}
      {order &&
        order?.OrderItems.map((ele) => {
          return (
            <>
              <div className="itemsList">
                <div className="itemsListInner">
                  <div className="quantity">{ele?.quantity}X</div>
                  <div className="itemName">
                    <span>{ele?.item_name}</span>
                    {ele?.OrderItemAddOns?.map((addons) => (
                      <span className="addons">{addons?.option_item_name}</span>
                    ))}
                  </div>
                </div>
                <div className="amount">
                  <IntlMessage id="Snapay.QAR" /> {calculateItemPrice(ele)}.00
                </div>
              </div>
            </>
          );
        })}
      {order?.discount > 0 && (
        <div className="totalOfItems">
          <span>
            <IntlMessage id="Snapay.OrderItemsDiscount" />
          </span>
          <span>
            <IntlMessage id="Snapay.QAR" /> {addCommaToNumbers(order?.discount)}
          </span>
        </div>
      )}

      <div className="totalOfItems" style={{ marginTop: "10px" }}>
        <span>
          <IntlMessage id="Snapay.OrderItemsSubTotal" />
        </span>
        <span>
          <IntlMessage id="Snapay.QAR" /> {addCommaToNumbers(order?.sub_total)}
        </span>
      </div>

      {!location?.includes("aqlutpaymentsuccessful") && (
        <div className="totalOfItems" style={{ marginTop: "10px" }}>
          <span>
            <IntlMessage id="Snapay.OrderItemsDueAmount" />
          </span>
          <span>
            <IntlMessage id="Snapay.QAR" /> {addCommaToNumbers(order?.total)}
          </span>
        </div>
      )}

      {location?.includes("aqlutpaymentsuccessful") && (
        <>
          <div className="totalOfItems" style={{ marginTop: "10px" }}>
            <span>
              <IntlMessage id="Snapay.SuccessFulYouPaid" />
            </span>
            <span>
              <IntlMessage id="Snapay.QAR" />{" "}
              {addCommaToNumbers(transactionData?.amount)}
            </span>
          </div>
          {transactionData?.getPreviousTransactionDetails?.length > 0 && (
            <div className="totalOfItems" style={{ marginTop: "10px" }}>
              <span>
                <IntlMessage id="Snapay.SuccessFulPreviousPayment" />
              </span>
              <span>
                <IntlMessage id="Snapay.QAR" />{" "}
                {addCommaToNumbers(
                  transactionData?.getPreviousTransactionDetails[0]?.amount
                )}
              </span>
            </div>
          )}
          <div className="totalOfItems" style={{ marginTop: "10px" }}>
            <span>
              <IntlMessage id="Snapay.SuccessFulTotalDue" />
            </span>
            <span>
              <IntlMessage id="Snapay.QAR" /> {addCommaToNumbers(order?.total)}
            </span>
          </div>
          {transactionData?.tip && Number(transactionData?.tip) !== 0 && (
            <div className="totalOfItems" style={{ marginTop: "10px" }}>
              <span>
                <IntlMessage id="Snapay.SuccessFulTipPaid" />
              </span>
              <span>
                <IntlMessage id="Snapay.QAR" />{" "}
                {addCommaToNumbers(transactionData?.tip)}
              </span>
            </div>
          )}
          {console.log("restaurantDetails",restaurantDetails)}
          {restaurantDetails?.takeaway_fees_amount > 0 && (
            <div className="totalOfItems" style={{ marginTop: "10px", textTransform:"capitalize" }}>
              <span>
                {restaurantDetails?.takeaway_fees_name}
              </span>
              <span>
                <IntlMessage id="Snapay.QAR" />{" "}
                {addCommaToNumbers(restaurantDetails?.takeaway_fees_amount)}
              </span>
            </div>
          )}
        </>
      )}
    </OrderedItemsBillStyle>
  );
};

export default OrderedItemsBill;

const OrderedItemsBillStyle = styled.div`
  padding: ${({ dir }) =>
    dir === "ltr" ? `18px 18px 18px 0px` : `18px 0 18px 18px`};
  border-radius: 8px;
  background: ${({ background }) => `url(${background})`};
  background-size: contain;

  .itemsList {
    width: 100%;
    display: flex;
    aligni-items: center;
    justify-content: space-between;
    gap: 10px;

    .itemsListInner {
      width: 100%;
      display: flex;
      color: ${({ textColor }) => textColor};
      font-family: "DM Sans", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .quantity {
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .itemName {
    width: 100%;
    padding: 8px;
    display: flex;
    flex-direction: column;
    border-left: ${({ dir }) =>
      dir === "ltr" && `2px solid rgba(43, 43, 43, 0.24)`};
    border-right: ${({ dir }) =>
      dir === "rtl" && `2px solid rgba(43, 43, 43, 0.24)`};
    .addons {
      font-size: 12px;
    }
  }

  .amount {
    width: 114px;
    padding: 8px 0;
    display: flex;
    -webkit-box-align: center;
    justify-content: end;
    white-space: nowrap;
    color: ${({ textColor }) => textColor};
    font-family: "DM Sans", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.36px;
  }

  .totalOfItems {
    display: flex;
    justify-content: space-between;
    padding: ${({ dir }) =>
      dir === "ltr" ? `2px 0px 2px 18px` : `2px 18px 2px 0`};
    margin-top: 22px;
    color: ${({ textColor }) => textColor};
    font-family: "DM Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;

const PayBillHeader = styled.div`
  display: flex;
  padding: 0 0 10px 7px;
  justify-content: space-between;
  align-items: center;

  .divWrapper {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  p {
    color: #171717;
    text-align: right;
    font-family: DM Sans,sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  svg {
    cursor: pointer;
  }
`;
