import { CircularProgress } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { toast } from "react-toastify";
import styled from "styled-components";
import { Header } from "../../components";
import {
  orderPaymentStatus,
  paymentOnSkipcash,
} from "../../services/Collection";
import {
  CODIcon,
  MenuIcon,
  OnlinePaymentIcon,
  PARIcon,
} from "../../Utils/Images";

export default function UnifiedPayment() {
  const [paymentMode, setPaymentMode] = useState(1);
  const id = useLocation();
  const [loading, setLoading] = useState(false);
  const orderDetails = useSelector(
    (state) => state?.orderInformationSlice?.orderDeails
  );
  const AuthDetails = useSelector((state) => state?.userAuth?.data);
  // const getAlreadyScanedID = useSelector((state) => state.StoreForMultiUsers);
  // const currentID = getAlreadyScanedID?.workStation?.slice(-1)?.[0]?.id;

  const getAlreadyScanedID = useSelector((state) => state.StoreForMultiUsers);
  const currentActive =
    getAlreadyScanedID?.getScannedQrcodeAndRestuarent?.slice(-1);

  const currentID = currentActive?.[0]?.qrcodeid;

  const [redirectPaymentGatewayURL, setRedirectPaymentGatewayURL] =
    useState(null);

  const navigate = useNavigate();
  const placeOrderDetails = useLocation()?.state;

  const isCashOnDelivery = useSelector(
    (state) =>
      state?.restaurentSlice?.restaurentData?.Menu?.Restaurent?.cash_on_delivery
  );
  const isTakeawayOrders = useSelector(
    (state) => state?.isUnifiedSlice?.orderType
  );

  const isPayAtRestaurant = useSelector(
    (state) =>
      state?.restaurentSlice?.restaurentData?.Menu?.Restaurent
        ?.pay_at_restaurant
  );

  const isDeliveryFee = useSelector(
    (state) =>
      state?.restaurentSlice?.delivery_fee
  );

  const skipcashPayment = async () => {
    let req = {
      order_id: orderDetails ? orderDetails.id : null,
      customer_id: AuthDetails?.id,
      restaurent_id: AuthDetails?.restaurent_id,
      amount:
        Number(placeOrderDetails?.total) +
        Number(placeOrderDetails?.service_tax) +
        Number(placeOrderDetails?.vat) +
        Number(orderDetails?.delivery_fee) -
        Number(placeOrderDetails?.loyalty_points),
      // uid: orderidRef,
      tip: 0,
      //full- 1 , partia; - 2 , spec - 3
      type: "1",
    };
    let res = await paymentOnSkipcash(req);
    if (res.status === 200) {
      setRedirectPaymentGatewayURL(res?.data?.resultObj);
      setLoading(false);
    } else {
      setLoading(false);
      toast.error(res.error || res.response.data.message);
    }
  };

  const cashOnDelivery = async () => {
    let obj = {
      payment_method: paymentMode,
      order_id: orderDetails?.id,
      delivery_fee: isDeliveryFee
    };
    let res = await orderPaymentStatus(obj);
    if (res.status === 200) {
      toast.info(res.message, { theme: "colored" });
      navigate(`/${currentID}/pre-payment-order-details`);
      setLoading(false);
    } else {
      toast.error(res.error || res.response.data.message);
      setLoading(false);
    }
  };

  const PaymentStatus = async () => {
    setLoading(true);
    if (paymentMode == "1") {
      skipcashPayment();
    } else {
      cashOnDelivery();
    }
  };

  const openSkipCashPaymentGateway = () => {
    if (redirectPaymentGatewayURL) {
      window.open(redirectPaymentGatewayURL?.payUrl, "_self");
    }
  };

  useEffect(() => {
    openSkipCashPaymentGateway();
  }, [redirectPaymentGatewayURL]);

  return (
    <UnifiedPaymentWrapper>
      <div className="header-Wrapper">
        <Header />
      </div>

      <div className="PaymentWrapper">
        <header>Payment</header>
        <PaymentCard>
          <div>
            <input
              type="radio"
              name="paymentMode"
              value="1"
              checked={paymentMode == "1" ? true : false}
              onChange={(e) => setPaymentMode(e.target.value)}
            />
            <label>Online Payment</label>
          </div>

          <img src={OnlinePaymentIcon} alt="Online Payment" />
        </PaymentCard>

        {isCashOnDelivery && isTakeawayOrders != "Takeaway" && (
          <PaymentCard>
            <div>
              <input
                type="radio"
                name="paymentMode"
                value="2"
                checked={paymentMode == "2" ? true : false}
                onChange={(e) => setPaymentMode(e.target.value)}
              />
              <label>COD (Cash on Delivery)</label>
            </div>

            <img src={CODIcon} alt="Online Payment" />
          </PaymentCard>
        )}

        {isPayAtRestaurant && isTakeawayOrders == "Takeaway" && (
          <PaymentCard>
            <div>
              <input
                type="radio"
                name="paymentMode"
                value="3"
                checked={paymentMode == "3" ? true : false}
                onChange={(e) => setPaymentMode(e.target.value)}
              />
              <label>PAR (Pay at Restaurant)</label>
            </div>
            <img src={PARIcon} alt="Online Payment" />
          </PaymentCard>
        )}

        <CartAddressBtn>
          {loading ? (
            <div className="ReviewOrderButton">
              <p>Loading ...</p>
            </div>
          ) : (
            <div onClick={PaymentStatus} className="ReviewOrderButton">
              <p>Continue</p>
            </div>
          )}
        </CartAddressBtn>
      </div>

      {/* <CartAddressBtn>
        <div
          // onClick={addressnotFound ? handleAddAddress : handlePlaceIOrder}
          className="ReviewOrderButton"
        >
          <p>Continue</p>
        </div>
      </CartAddressBtn> */}
    </UnifiedPaymentWrapper>
  );
}

const UnifiedPaymentWrapper = styled.div`
  width: 100%;
  height: 100vh;
  background: #fff;

  .PaymentWrapper {
    width: 100%;
    padding: 10px 20px;

    header {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      color: #fc2b6e;
      padding: 8px 0;
    }
  }
`;

const PaymentCard = styled.div`
  width: 100%;
  background: #f8f8fb;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 12px;
  margin: 20px 0;
  div {
    display: flex;
    align-items: center;
    justtify-content: center;
    label {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      letter-spacing: 0.2px;
      color: #000000;
      padding: 0 10px;
    }
  }

  input {
    accent-color: #f82873;
  }

  img {
    width: 48px;
    height: 22.85px;
  }
`;

const CartAddressBtn = styled.div`
  width: 100%;
  ${"" /* height: 100%; */}
  padding: 20px;

  .ReviewOrderButton {
    width: 85%;
    height: 48px;
    border-radius: 30px;
    margin: auto;
    display: flex;
    align-items: center;
    background: linear-gradient(91.33deg, #fc2b6e 0%, #bc00a9 100%);
    justify-content: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin-bottom: 20px;

    button {
      width: 100%;
      height: 100%;
      border: none;
      cursor: pointer;
      background: transparent;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.06px;
      color: #ffffff;
    }

    p {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.06px;
      color: #ffffff;
    }
  }
`;
