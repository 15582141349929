import React, { useState } from 'react'
import {
  Header,
  FoodModal,
  RecommendedItem,
  AllergiesItem,
} from '../../components'
import './DescriptionWithFreeItem.scss'
// import * as React from 'react';
import Box from '@mui/material/Box'
import Popper from '@mui/material/Popper'
import { ClickAwayListener } from '@mui/material'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import { display } from '@mui/system'
import { useSelector } from 'react-redux'

export default function DescriptionWithFreeItem() {
  const [model, setModel] = useState(false)

  const toggleDrawer = (event) => {
    setModel(true)
  }

  const [itemsmodel, setItemModel] = useState(false)
  const [allegiesModel, setallegiesModel] = useState(false)
  const [inputNone, updateInputNode] = useState('none')

  const toggleItem = (event) => {
    setItemModel(true)
  }
  //allergies event hook is here...
  const toggleAllegiesItem = (event) => {
    setallegiesModel(true)
  }
  const special_notes = (event) => {
    updateInputNode('block')
  }

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const open = Boolean(anchorEl)

  const id = open ? 'simple-popper' : undefined
  const userlang = useSelector((state) => state.selectLang.direction)
  return (
    <>
      <Header />
      <div className="AddFood">
        <div className="Foodstyle">
          <h4>Salads (3)</h4>
        </div>

        <div className="ListingWrapper">
          <div className="MainContent">
            <div className="FoodList">
              <img src={require('../../assets/food.png')} />
              <button className="FoodGet" onClick={toggleDrawer}>
                ADD
              </button>
            </div>
            <div className="FoodDetails">
              <h5>Jamie's Super Corn</h5>
              <img
                className="batch"
                src={require('../../assets/batch1.png')}
              ></img>
              <img
                className="specialBatch"
                src={require('../../assets/batch.png')}
              ></img>
              <p>Lorem Ipsum is simply dummy text of the printing and type </p>
              <h6>Price on selection</h6>
            </div>
          </div>

          <div className="MainContent">
            <div className="FoodList">
              <img src={require('../../assets/food.png')} />
              <button className="FoodGet">ADD</button>
            </div>
            <div className="FoodDetails">
              <h5>Jamie's Super Corn</h5>
              <img
                className="batch"
                src={require('../../assets/batch1.png')}
              ></img>
              <img
                className="specialBatch"
                src={require('../../assets/batch.png')}
              ></img>
              <p>Lorem Ipsum is simply dummy text of the printing and type </p>
              <h6>Price on selection</h6>
            </div>
          </div>

          <div className="MainContent">
            <div className="FoodList">
              <img src={require('../../assets/food.png')} />
              <button className="FoodGet">ADD</button>
            </div>
            <div className="FoodDetails">
              <h5>Jamie's Super Corn</h5>
              <img
                className="batch"
                src={require('../../assets/batch1.png')}
              ></img>
              <img
                className="specialBatch"
                src={require('../../assets/batch.png')}
              ></img>
              <p>Lorem Ipsum is simply dummy text of the printing and type </p>
              <h6>Price on selection</h6>
            </div>
          </div>

          <div className="MainContent">
            <div className="FoodList">
              <img src={require('../../assets/food.png')} />
              <button className="FoodGet">ADD</button>
            </div>
            <div className="FoodDetails">
              <h5>Jamie's Super Corn</h5>
              <p>Lorem Ipsum is simply dummy text of the printing and type </p>
              <h6>Price on selection</h6>
            </div>
          </div>

          <div className="MainContent">
            <div className="FoodList">
              <img src={require('../../assets/food.png')} />
              <button className="FoodGet">ADD</button>
            </div>
            <div className="FoodDetails">
              <h5>Jamie's Super Corn</h5>
              <p>Lorem Ipsum is simply dummy text of the printing and type </p>
              <h6>Price on selection</h6>
            </div>
          </div>

          <div className="MainContent">
            <div className="FoodList">
              <img src={require('../../assets/food.png')} />
              <button className="FoodGet">ADD</button>
            </div>
            <div className="FoodDetails">
              <h5>Jamie's Super Corn</h5>
              <p>Lorem Ipsum is simply dummy text of the printing and type </p>
              <h6>Price on selection</h6>
            </div>
          </div>
        </div>

        {/* here started second  component */}

        <div className="AddStater">
          <h4>Salads (3)</h4>
        </div>

        <div className="ListingWrapper">
          <div className="MainContent">
            <div className="FoodList">
              <img src={require('../../assets/food.png')} />
              <button className="FoodGet">ADD</button>
            </div>
            <div className="FoodDetails">
              <h5>Jamie's Super Corn</h5>
              <img
                className="batch"
                src={require('../../assets/batch1.png')}
              ></img>
              <img
                className="specialBatch"
                src={require('../../assets/batch.png')}
              ></img>
              <p>Lorem Ipsum is simply dummy text of the printing and type </p>
              <h6>Price on selection</h6>
            </div>
          </div>

          <div className="MainContent">
            <div className="FoodList">
              <img src={require('../../assets/food.png')} />
              <button className="FoodGet">ADD</button>
            </div>
            <div className="FoodDetails">
              <h5>Jamie's Super Corn</h5>
              <img
                className="batch"
                src={require('../../assets/batch1.png')}
              ></img>
              <img
                className="specialBatch"
                src={require('../../assets/batch.png')}
              ></img>
              <p>Lorem Ipsum is simply dummy text of the printing and type </p>
              <h6>Price on selection</h6>
            </div>
          </div>

          <div className="MainContent">
            <div className="FoodList">
              <img src={require('../../assets/food.png')} />
              <button className="FoodGet">ADD</button>
            </div>
            <div className="FoodDetails">
              <h5>Jamie's Super Corn</h5>
              <img
                className="batch"
                src={require('../../assets/batch1.png')}
              ></img>
              <img
                className="specialBatch"
                src={require('../../assets/batch.png')}
              ></img>
              <p>Lorem Ipsum is simply dummy text of the printing and type </p>
              <h6>Price on selection</h6>
            </div>
          </div>

          <div className="MainContent">
            <div className="FoodList">
              <img src={require('../../assets/food.png')} />
              <button className="FoodGet">ADD</button>
            </div>
            <div className="FoodDetails">
              <h5>Jamie's Super Corn</h5>
              <img
                className="batch"
                src={require('../../assets/batch1.png')}
              ></img>
              <img
                className="specialBatch"
                src={require('../../assets/batch.png')}
              ></img>
              <p>Lorem Ipsum is simply dummy text of the printing and type </p>
              <h6>Price on selection</h6>
            </div>
          </div>

          <div className="MainContent">
            <div className="FoodList">
              <img src={require('../../assets/food.png')} />
              <button className="FoodGet">ADD</button>
            </div>
            {/* here put logic in last data content */}
            <div className="FoodDetails" style={{ marginBottom: '90px' }}>
              <h5>Jamie's Super Corn</h5>
              <img
                className="batch"
                src={require('../../assets/batch1.png')}
              ></img>
              <img
                className="specialBatch"
                src={require('../../assets/batch.png')}
              ></img>
              <p>Lorem Ipsum is simply dummy text of the printing and type </p>
              <h6>Price on selection</h6>
            </div>
          </div>
        </div>

        <div className="MenuContainer">
          <button aria-describedby={id} type="button" onClick={handleClick}>
            MENU
          </button>
          {/* <ClickAwayListener onClickAway={}> */}

          <Popper
            className="addfood-menu"
            id={id}
            open={open}
            anchorEl={anchorEl}
          >
            <Box style={styleMenuBox}>
              <div className="InnderMenuBox">
                <label>Salads</label>
                <span>3</span>
              </div>

              <div className="InnderMenuBox">
                <label>Burgers</label>
                <span>8</span>
              </div>

              <div className="InnderMenuBox">
                <label>Sides</label>
                <span>11</span>
              </div>

              <div className="InnderMenuBox">
                <label>Slice</label>
                <span>11</span>
              </div>
            </Box>
          </Popper>
          {/* </ClickAwayListener> */}
        </div>

        {/* footer component start*/}

        <div className="ItemWrapper">
          <div className="ItemDetails">
            <img src={require('../../assets/Bag.png')} />
            <div className="Itemturn">
              <p>Items</p>
              <h5>QAR 30</h5>
            </div>
          </div>
          <div className="ViewItem">
            <button
              type="button"
              onClick={(e) => {
                e.preventDefault()
                window.location.href = '/cartwithdiscount'
              }}
            >
              VIEW BASKET
            </button>
          </div>
        </div>

        {/* footer component end*/}

        <SwipeableDrawer
          anchor={'bottom'}
          open={model}
          className="mui-drawer-wrapper"
          // onClose={toggleDrawer()}
          // onOpen={toggleDrawer()}
        >
          {/* food component start here */}

          <FoodModal onClose={() => setModel(false)} />

          {/* food component end here  */}

          {/* Price Options section start */}

          <div className="PriceSectionBox">
            <div className="Innersection">


              
              <div className="PriceHeader">
                <img
                  className="dolloarSym"
                  src={require('../../assets/dollarSymbol.png')}
                />
                <h1 className="PriceHeading">Price Options</h1>
              </div>
              <div className="Innermenu">
                <div className="ChekboxContainer">
                  <input
                    className="PriceListCheckbox"
                    type="checkbox"
                    name="topping"
                  />
                  <div className="Pricedetails">
                    <p>Medium</p>
                    <span>25 kcal</span>
                  </div>
                </div>

                <div className="NumberContainer ">
                  <span>QAR 150</span>
                </div>
              </div>

              <div className="Innermenu">
                <div className="ChekboxContainer">
                  <input
                    className="PriceListCheckbox"
                    type="checkbox"
                    name="topping"
                  />
                  <div className="Pricedetails">
                    <p>Large</p>
                    <span>QAR 150</span>
                  </div>
                </div>

                <div className="NumberContainer ">
                  <span>QAR 150</span>
                </div>
              </div>

              <div className="Innermenu">
                <div className="ChekboxContainer">
                  <input
                    className="PriceListCheckbox"
                    type="checkbox"
                    name="topping"
                  />
                  <div className="Pricedetails">
                    <p>Extra Large</p>
                    <span>25 kcal</span>
                  </div>
                </div>

                <div className="NumberContainer ">
                  <span>QAR 150</span>
                </div>
              </div>
            </div>
          </div>

          {/* Price Options section  end*/}

          {/* Add on section start*/}

          <div className="PriceSectionBox">
            <div className="Innersection">
              <div className="PriceHeader">
                <img
                  className="dolloarSym"
                  src={require('../../assets/AddOn.png')}
                />
                <h1 className="PriceHeading">Add On</h1>
              </div>
              <div className="Innermenu">
                <div className="ChekboxContainer">
                  <input
                    className="PriceListCheckbox"
                    type="checkbox"
                    name="topping"
                  />
                  <div className="Pricedetails">
                    <p>Medium</p>
                    <span>25 kcal</span>
                  </div>
                </div>

                <div className="NumberContainer ">
                  <span>QAR 150</span>
                </div>
              </div>

              <div className="Innermenu">
                <div className="ChekboxContainer">
                  <input
                    className="PriceListCheckbox"
                    type="checkbox"
                    name="topping"
                  />
                  <div className="Pricedetails">
                    <p>Large</p>
                    <span>QAR 150</span>
                  </div>
                </div>

                <div className="NumberContainer ">
                  <span>QAR 150</span>
                </div>
              </div>

              <div className="Innermenu">
                <div className="ChekboxContainer">
                  <input
                    className="PriceListCheckbox"
                    type="checkbox"
                    name="topping"
                  />
                  <div className="Pricedetails">
                    <p>Extra Large</p>
                    <span>25 kcal</span>
                  </div>
                </div>

                <div className="NumberContainer ">
                  <span>QAR 150</span>
                </div>
              </div>
            </div>
          </div>

          {/* Add on section end*/}

          {/* Quantity section  Start*/}

          <div className="PriceSectionBox">
            <div className="QuantitiyWrapper">
              <div className="QunatityContainer">
                <img src={require('../../assets/quantity.png')} />

                <p>Quantity</p>
              </div>

              <div className="QuantityMainWrapper">
                <button>-</button>
                <p>10</p>
                <button>+</button>
              </div>
            </div>
          </div>

          {/* Quantity section  end*/}

          {/*  discount Items start*/}

          <div className="PriceSectionBox">
            <div className="QuantitiyWrapper">
              <div className="QunatityContainer">
                <img src={require('../../assets/Discount.png')} />
                <div className="DiscoundContainer">
                  <p>Free Item</p>
                  <h5>classic Lime</h5>
                  <h5>Qty : 1</h5>
                  <span>Free(45 kcal)</span>
                </div>
              </div>
            </div>
          </div>

          {/*  discount Items start*/}

          {/*  Recommended Items start*/}

          <div className="PriceSectionBox" onClick={toggleItem}>
            <div className="QuantitiyWrapper">
              <div className="QunatityContainer">
                <img src={require('../../assets/Bookmark.png')} />
                <div className="DiscoundContainer">
                  <p>Recommended Items</p>
                  <h5>Fries, Milkshake, Pepsi</h5>
                </div>
              </div>
              <div className="VectorContainer">
                <img src={require('../../assets/Vector.png')} />
              </div>
            </div>
          </div>

          {/*  Recommended Items end*/}

          {/* alergy section start  */}
          <div className="PriceSectionBox" onClick={toggleAllegiesItem}>
            <div className="QuantitiyWrapper">
              <div className="QunatityContainer">
                <img src={require('../../assets/allergy.png')} />
                <div className="DiscoundContainer">
                  <p>Allergies</p>
                  <h5>Diary Products</h5>
                </div>
              </div>
              <div className="VectorContainer">
                <img src={require('../../assets/Vector.png')} />
              </div>
            </div>
          </div>

          {/* special notes secitons start here */}
          <div className="PriceSectionBox" onClick={special_notes}>
            <div className="QuantitiyWrapper">
              <div className="QunatityContainer">
                <img src={require('../../assets/special_notes.png')} />
                <div className="DiscoundContainer">
                  <p>Special Notes</p>
                  <div className="special_notes_deco">
                    <input
                      type="text"
                      id="special-form-control"
                      placeholder="Lorem Ipsum is simply dummy text of the printing and typesetting Ipsum is simply dummy text of the printing and typesetting Ipsum is simply dummy text of the printing and typesetting"
                      className="special-form-control"
                      style={{ display: inputNone }}
                    />
                  </div>
                </div>
              </div>
              <div className="VectorContainer">
                <img src={require('../../assets/Vector.png')} />
              </div>
            </div>
          </div>
          {/* end of special notes is here */}

          {/* alergy section end here */}

          {/* Totatl price*/}

          <div className="PriceSectionBox">
            <div className="QuantitiyWrapper">
              <div className="QunatityContainer">
                <img src={require('../../assets/totalprice.png')} />
                <div className="DiscoundContainer">
                  <p>Toal Price</p>
                  {/* <h5>Diary Products</h5> */}
                </div>
              </div>
              <div className="TotalPrice">
                {/* <img src={require('../../assets/Vector.png')} />
                 */}
                <p>QAR 306</p>
              </div>
            </div>
          </div>

          {/* Total price end */}

          {/* add a button start*/}

          <div className="BasketContainer">
            <div className="BasketWrapper">
              <button
                onClick={(e) => {
                  e.preventDefault()
                  window.location.href = '/addfoodlist'
                }}
              >
                ADD TO BASKET
              </button>
            </div>
          </div>

          {/* add a button end */}
        </SwipeableDrawer>

        <SwipeableDrawer
          anchor={'bottom'}
          open={itemsmodel}
          className="mui-item-wrapper"
          // onClose={setItemModel(false)}
          // onOpen={toggleDrawer()}
        >
          {/* <FoodModal onClose={() => setItemModel(false)} /> */}

          <RecommendedItem onClose={() => setItemModel(false)} />
        </SwipeableDrawer>

        <SwipeableDrawer
          anchor={'bottom'}
          open={allegiesModel}
          className="mui-item-wrapper"
          // onClose={setItemModel(false)}
          // onOpen={toggleDrawer()}
        >
          {/* <FoodModal onClose={() => setItemModel(false)} /> */}

          <AllergiesItem Close={() => setallegiesModel(false)} />
        </SwipeableDrawer>
      </div>
    </>
  )
}

const styleMenuBox = {
  background: 'red',
  background: '#000000',
  opacity: '0.9',
  boxShadow: '0px 3px 8px #222222',
  borderRadius: '9px',
  width: '200px',
  margin: '0px 0PX 10PX 0PX ',
}
