import React, { useState, useEffect } from "react";
import "../OrderVerification/Orderveri.scss";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { checkCustomerExists } from "../../services/Authapi";
import { BackgroundCover } from "../../Utils/Images";
import { CircularProgress } from "@mui/material";
import { LoadingWapper } from "../../style/GobalStyle";
import { toast } from "react-toastify";
import IntlMessage from "../../Utils/IntlMessage";
import { useIntl } from "react-intl";

export default function OrderVeri() {
  const aqlutstorage = process.env.REACT_APP_IMAGE_BASEURL;
  const containerProfile = process.env.REACT_APP_CONTAINER_PROFILE;
  const containerMenuBranding = process.env.REACT_APP_CONTAINER_MENUBRANDING;

  const [phoneNumber, setPhoneNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const userlang = useSelector((state) => state?.selectLang?.direction);
  const getAlreadyScanedID = useSelector((state) => state.StoreForMultiUsers);
  const currentActive =
    getAlreadyScanedID?.getScannedQrcodeAndRestuarent?.slice(-1);
  const [loading, setLoading] = useState(false);
  const intl = useIntl();

  //use states
  const [dialCode, setDialCode] = useState("");
  const [phoneNumberWithoutDialcCode, setPhoneNumberWithoutDialcode] =
    useState("");
  const restaurentInfo = useSelector(
    (state) => state?.restaurentSlice?.restaurentData?.Menu?.Restaurent
  );
  if (restaurentInfo) {
    var { id } = restaurentInfo;
    var styling = restaurentInfo?.MenuPageBranding;
    var restaurent_data = restaurentInfo;
  }

  const background_image = styling?.display_image;
  const restaurent_logo = restaurent_data?.logo;

  let background_img = aqlutstorage + containerMenuBranding + background_image;

  let logo_restaurent = aqlutstorage + containerProfile + restaurent_logo;

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm();

  const navigate = useNavigate();

  async function onSubmit(value) {
    setLoading(true);
    let phoneNum = phoneNumber.replace(/[^\d]/g, "");
    if (phoneNum?.length > 8 && phoneNum?.length <= 12) {
      let obj = {
        restaurent_id: currentActive?.[0]?.restaurentid,
        name: value.fullName,
        country_code: "+" + dialCode,
        mobile_no: phoneNumberWithoutDialcCode,
      };
      //Check if customer is already exists or not?
      const res = await checkCustomerExists(obj);
      // if customer not exists then redirect user to verify OTP page
      if (res.status === 200) {
        navigate(
          `/${currentActive?.[0]?.qrcodeid}-${currentActive?.[0]?.restaurentid}/verifyotp`,
          { state: { data: obj } }
        );
        setLoading(false);
        return;
      }
      //if customer  exists then save their info
      else if (res.status === 201) {
        // dispatch(authSucess(res.data));
        navigate(
          `/${currentActive?.[0]?.qrcodeid}-${currentActive?.[0]?.restaurentid}/verifyotp`,
          { state: { data: obj } }
        );
        setLoading(false);
        return;
      } else {
        toast.error(res.response.data.message, { theme: "colored" });
        setLoading(false);
      }
    } else {
      setTimeout(() => {
        setErrorMessage("Please enter a valid phone number");
      }, 1000);
      setErrorMessage("");
      setLoading(false);
    }
  }
  useEffect(() => {}, [errorMessage]);
  // here we will get the phone no. input value
  const handlePhoneNumberInput = (value, data, event, formattedValue) => {
    setErrorMessage("");
    setPhoneNumber(value);
  };
  const handlePhoneNumber = (inputNumber, country, countries) => {
    const dial_code = country.dialCode;
    const phoneNumber = inputNumber.slice(
      dial_code?.length,
      inputNumber?.length
    );
    setPhoneNumberWithoutDialcode(phoneNumber);
    setDialCode(dial_code);
  };
  //don't mess with this
  useEffect(() => {}, [dialCode, phoneNumberWithoutDialcCode]);

  // This will watch the cheages in these two input components
  useEffect(() => {}, [errorMessage, phoneNumber]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="user-info-form">
      <SelectWrapper
        className="OrderVeri"
        background_image={background_image}
        background_img={background_img}
        dir={userlang}
      >
        <OrderVerificationWrapper>
          <RestaurantLogoBox dir={userlang}>
            <img src={logo_restaurent} alt="logo" />
          </RestaurantLogoBox>
          <div>
            <header>
              <IntlMessage id="orderverification.tilte" />
            </header>

            <input
              placeholder={intl.formatMessage({
                id: "orderverification.name.placeholder",
              })}
              {...register("fullName", { required: true })}
            />
            {errors.fullName && <ErrorMessage>Name is required.</ErrorMessage>}

            <PhoneInputWrapper dir="ltr">
              <PhoneInput
                placeholder="Enter phone number"
                country={"qa"}
                inputRef={register}
                onChange={(e) => handlePhoneNumberInput(e)}
                inputStyle={{ width: "100% !important", padding: "0 50px" }}
                isValid={(inputNumber, country, countries) => {
                  handlePhoneNumber(inputNumber, country, countries);
                }}
              />
            </PhoneInputWrapper>

            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

            {loading ? (
              <LoadingWapper style={{ height: "35px", margin: "20px 0" }}>
                <CircularProgress sx={{ color: "#fc2b6e !important" }} />
              </LoadingWapper>
            ) : (
              <button type="submit">
                <IntlMessage id="orderverification.button.Verify" />
              </button>
            )}
          </div>
        </OrderVerificationWrapper>
      </SelectWrapper>
    </form>
  );
}

const ErrorMessage = styled.span`
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #fc2b6e;
`;

const OrderVerificationWrapper = styled.div`
  width: 100%;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  background: #ffffff;
  border-radius: 30px 30px 0px 0px;
  padding: 20px;

  div {
    header {
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 34px;
      letter-spacing: 0.36px;
      color: #202020;
      padding: 10px 0;
    }
    input {
      width: 100%;
      height: 48px;
      background: #ffffff;
      border: 1px solid #d2d2d3;
      border-radius: 28px;
      padding: 0 20px;
      margin: 10px 0;

      :focus {
        outline: none;
      }
    }
    button {
      width: 100%;
      height: 48px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.06px;
      color: #ffffff;
      background: linear-gradient(91.33deg, #fc2b6e 0%, #bc00a9 100%);
      border-radius: 30px;
      border-style: none;
      margin: 10px 0;
    }
  }
`;

const RestaurantLogoBox = styled.div`
  position: absolute;
  width: 100px;
  height: 100px;
  left: ${({ dir }) => dir === "ltr" && "30px"};
  right: ${({ dir }) => dir === "rtl" && "30px"};
  top: -75px;
  background: #ffffff;
  border: 1px solid #c6c6c6;
  border-radius: 20px;
  padding: 20px;

  img {
    width: 100%;
    height: 100%;
  }
`;

const SelectWrapper = styled.div`
  position: relative;
  background: ${({ background_image, background_img }) =>
    `${
      background_image !== undefined
        ? `url('${background_img}')`
        : `url(${BackgroundCover.toString()})`
    }`};
  background-repeat: no-repeat;
  background-size: 100% 70%;
`;

const PhoneInputWrapper = styled.div`
  margin: 10px 0;
  .react-tel-input .form-control {
    width: 100% !important;
    height: 48px !important ;
    background: #ffffff !important;
    border: 1px solid #d2d2d3 !important;
    border-radius: 28px !important;
    padding: 0 60px !important;
  }
  .react-tel-input .selected-flag .arrow {
    right: ${({ dir }) => dir === "rtl" && "20px"};
    left: ${({ dir }) => dir === "ltr" && "20px"};
  }

  .flag-dropdown {
    background: #ffffff !important;
    border: 1px solid #d2d2d3 !important;
    border-radius: 28px !important;
    padding: 0 3px !important;
    border-radius: ${({ dir }) => dir === "rtl" && "0 6px 6px 0 !important"};
    border-radius: ${({ dir }) => dir === "ltr" && "6px 0 0 6px !important"};
  }
`;
