import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import IntlMessage from "../../Utils/IntlMessage";
import { AllergiesIcon } from "../../Utils/Icons";

function Allergies({ toggleAllegiesItem, colorStyle, item }) {
  const aqlutstorage = process.env.REACT_APP_IMAGE_BASEURL;
  const containerItem = process.env.REACT_APP_CONTAINER_ITEM;
  const { direction } = useSelector(({ selectLang }) => ({ ...selectLang }));
  const customTheme = useSelector((state) => state?.theme?.branding);

  return (
    <AllergiesWrapper
      dir={direction}
      style={{ background: customTheme?.background_color }}
    >
      <div className="QuantitiyWrapper">
        <div className="FreeItemContainer">
          <div className="freeItemHeader">
            {" "}
            <i>
              <AllergiesIcon color={customTheme?.theme_color} />
            </i>
            <h1
              style={{ color: customTheme?.theme_color }}
              className="QuantityHeading"
            >
              <IntlMessage id="AddItemDrawer.allegries" />
            </h1>
          </div>
          <div className="FreeItemName">
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "8px",
                marginTop: "9px",
                color: customTheme?.text_color,
              }}
            >
              {item?.allergies?.length > 0 &&
                item?.allergies?.map((item, key) => (
                  <>
                    <div style={{ display: "flex", gap: "8px" }}>
                      <div className="allergies-icon-wrapper">
                        <img
                          src={`${aqlutstorage + containerItem + item?.image}`}
                          alt=""
                          style={{ width: "20px" }}
                        />
                      </div>
                      <p style={{ color: customTheme?.text_color }}>
                        {item?.name}
                      </p>
                    </div>
                  </>
                ))}
            </div>
          </div>
        </div>
        {/* <div className="ArrowIcon">
          <div>
             <RightArrow color={customTheme?.theme_color}/>
          </div>
        </div> */}
      </div>
    </AllergiesWrapper>
  );
}

export default Allergies;

const AllergiesPopoverName = styled.div`
  font-family: "Nunito Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 157%;
  margin: 0;
  color: #858992;
`;

const AllergiesWrapper = styled.div`
  background: white;
  padding-bottom: 10px;

  .QuantitiyWrapper {
    width: 95%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    margin-top: 15px;

    ${
      "" /* p {
      margin: 1px 10px;
      font-family: "Jost";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 23px;
      color: #f55a2c;
      margin-left: 10px;
    } */
    }
  }
  .FreeItemName {
    padding: 6px 29px;
    span {
      display: flex;
      font-family: Poppins;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;

      span {
        margin-top: 1.6px;
      }
    }
    p {
      margin: 0;
      color: #7b7f91;
      margin-right: 5px;
      padding-top: 6px;
    }

    .allergies-icon-wrapper {
      width: fit-content;
      height: fit-content;
      border: 1px solid #232323;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 3px;
      border-radius: 50%;
      cursor: pointer;
      img {
        filter: contrast(1);
      }
    }
  }
  .QunatityContainer {
    display: flex;
    p {
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #fc2b6e;
    }
    .QuantityHeading {
      font-family: Poppins;
      white-space: pre !important;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;

      color: #fc2b6e;

      ${"" /* margin-top: 0; */}
    }
  }

  .QuantityMainWrapper button:hover {
    cursor: pointer;
  }
  .QuantityMainWrapper {
    display: flex;
    width: 78px;
    height: 33px;
    justify-content: space-between;
    // background-color: white;
    background: #ffffff;
    border: 0.5px solid rgba(36, 36, 36, 0.3);
    border-radius: 30px;
    margin-top: 5px;

    button {
      border: none;
      background: none;
      color: #fc2b6e;
      font-size: 22px;
      ${"" /* width: 20px */}
    }
    p {
      color: #202020;
      margin: 5px;
      margin-top: 6.7px;
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .FreeItemContainer {
    display: inline-grid;
    p {
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #fc2b6e;
    }
    .QuantityHeading {
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;

      color: #fc2b6e;

      ${"" /* margin-top: 0; */}
    }
  }
  .freeItemHeader {
    display: flex;
    margin-bottom: -11px;
    gap: 7px;
  }
  .ArrowIcon {
    div {
      margin-top: 10px;
      width: 30px;
    }
    img {
      width: 12px;
    }
  }
  .Special-Note {
    background: #f5f5f5;
    border: 1px solid #f5f5f5;
    border-radius: 10px;
    width: 90%;
    margin: auto;

    textarea {
      width: 82vw;
      background: #f5f5f5;
      border: none;
      height: 80px;
      border-radius: 10px;
      box-shadow: 20px;
      padding-top: 10px;
      padding-left: 25px;
      opacity: 1 !important;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #202020;
      :focus {
        outline: none;
      }
    }
  }
`;
