import { SwipeableDrawer } from "@mui/material";
import React from "react";
import OtpInput from "react-otp-input";
import styled from "styled-components";
import IntlMessage from "../../Utils/IntlMessage";

export const VerifyOrder = ({
  numInputs,
  inputValues,
  otp,
  open,
  handleClose,
  handleAuthCodeSubmit,
  loading,
}) => {
  const getValues = (otp) => {
    inputValues(otp);
  };

  return (
    <SwipeableDrawer
      transitionDuration={{ appear: 1000, enter: 500, exit: 1000 }}
      anchor={"bottom"}
      open={open}
      onClose={handleClose}
      style={{
        borderRadius: "20px",
      }}
    >
      <AuthCodeWrapper style={styleMenuBoxForEnglish}>
        <TitleWrapper>
          <h3>
            <IntlMessage id="authorization.header" />
          </h3>
        </TitleWrapper>
        <OtpWrapper>
          <OtpInput
            value={otp}
            onChange={getValues}
            autoFocus
            numInputs={5}
            isInputNum={true}
            disabled={false}
            secure
          />
        </OtpWrapper>
        <BtnWrapper>
          {loading ? (
            <button>
              <IntlMessage id="authorization.loading" />
            </button>
          ) : (
            <button type="submit" onClick={handleAuthCodeSubmit}>
              <IntlMessage id="verifyotp.button.submit" />
            </button>
          )}
        </BtnWrapper>
      </AuthCodeWrapper>
    </SwipeableDrawer>
  );
};

const AuthCodeWrapper = styled.div`
  width: 100%;
  height: 250px;
  padding: 10px 0;
  background: #ffff;
  color: #000;
  borderradius: 20px;
  animation-name: slideInAnimation;
  animation-duration: 0.3s;
  animation-timing-function: ease-in;
  animation-iteration-count: 1;
  animation-delay: 0s;
  @keyframes slideInAnimation {
    0% {
      width: 100%;
      height: 22px;
      transform: translateY(0);
      opacity: 0.1;
    }

    100% {
      width: 100%;
      height: 250px;
      transform: translateY(100);
      opacity: 1;
    }
  }
`;

const inputStyle = {
  width: "100%",
  height: "42px",
  border: "2px solid red",
  borderRadius: "8px",
  margin: "20px 0",
};

const styleMenuBoxForEnglish = {
  "&::-webkit-scrollbar": {
    width: "0.4em",
  },
};

const TitleWrapper = styled.div`
  width: 100%;
  padding: 10px;
  text-align: center;
  h3 {
    font-family: "Poppins";
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
  }
`;

const OtpWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px 0;

  input {
    width: 48px !important;
    height: 48px;
    background: #ffffff;
    border: 1px solid #fc2b6e;
    border-radius: 8px;
    margin: 5px;
  }
`;

const BtnWrapper = styled.div`
  padding: 0 50px;
  button {
    width: 100%;
    height: 48px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.06px;
    color: #ffffff;
    background: linear-gradient(91.33deg, #fc2b6e 0%, #bc00a9 100%);
    border-radius: 30px;
    border-style: none;
    margin: 10px 0;
  }
`;
