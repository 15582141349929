import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

function SelectSpecificDish({ paid, data, getSelectedItem }) {
  const sendSelected = (check) => {
    getSelectedItem(check, data);
  };

  const dir = useSelector((state) => state?.selectLang?.direction);
  const customTheme = useSelector((state) => state?.theme?.branding);

  return (
    <SelectSpecificDishStyle>
      <div style={{ width: "50%" }}>
        <h6 style={{color:customTheme?.header_color}} >
          {dir === "ltr" ? data?.item_name || "" : data?.ar_item_name || ""}
        </h6>
        <p style={{color:customTheme?.text_color}} >QAR {data?.sub_total}</p>
      </div>
      <div style={{ width: "50%", textAlign: "end" }}>
        {paid ? (
          <h5 style={{color:customTheme?.theme_color}} >Paid</h5>
        ) : (
          data?.price > 0 && (
            <input
            style={{accentColor:customTheme?.theme_color}}
              type="checkbox"
              onClick={(e) => sendSelected(e.target.checked)}
            />
          )
        )}
      </div>
    </SelectSpecificDishStyle>
  );
}

export default SelectSpecificDish;

const SelectSpecificDishStyle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  h6 {
    font-family: "Jost";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.02em;
    color: #000000;
  }
  p {
    font-family: "Jost";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.02em;
    color: rgba(0, 0, 0, 0.6);
  }

  input[type="checkbox"] {
    accent-color: #f55a2c;
    border-radius: 3px;
    width: 16px;
    height: 16px;
  }

  h5 {
    font-family: "Jost";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.02em;

    color: ${({ theme }) => theme?.paymentSuccessColor};
  }
`;
