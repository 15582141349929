import React, { useState, useEffect } from "react";
import "../style/SelectLanguage.scss";
import { useDispatch } from "react-redux";
import { langSuccess } from "../store/selectlang.js";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { showMenuByqrID } from "../store/Menu";
import { addRestaurentData } from "../store/restaurenDetail";
import axios from "axios";
import { Backdrop, CircularProgress } from "@mui/material";
import { addQrData } from "../store/Cart";
import {
  BackgroundCover,
  Coverorder,
  Coverpayment,
  Coverscan,
} from "../Utils/Images";
import styled from "styled-components";
import IntlMessage from "../Utils/IntlMessage";
import { checkQrcodeIsMaster } from "../store/userLoginReducer";
import { ArrowIcon } from "../Utils/Icons";

export default function SelectLanguage() {
  const aqlutstorage = process.env.REACT_APP_IMAGE_BASEURL;
  const containerProfile = process.env.REACT_APP_CONTAINER_PROFILE;
  const containerMenuBranding = process.env.REACT_APP_CONTAINER_MENUBRANDING;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const userlang = useSelector((state) => state.selectLang.direction);
  const [message, setmessage] = useState("");
  const [lang, SelectLang] = useState(null);
  const id = window?.location?.search?.substring(1)?.split("=")?.[1];
  const name = window?.location?.search?.substring(1)?.split("=")?.[0];
  const { restaurentData } = useSelector(({ restaurentSlice }) => ({
    ...restaurentSlice,
  }));
  // const QrCodeID = useSelector(
  //   (state) => state?.StoreForMultiUsers?.workStation?.slice(-1)?.[0]?.id
  // );
  const getAlreadyScanedID = useSelector((state) => state.StoreForMultiUsers);
  const currentActive =
    getAlreadyScanedID?.getScannedQrcodeAndRestuarent?.slice(-1);

  const styling = restaurentData?.Menu?.Restaurent?.MenuPageBranding;
  const restaurent_data = restaurentData?.Menu?.Restaurent;

  const QrCodeGroupType = restaurentData?.QrCodeGroup?.group_type;

  //style
  const background_image = styling?.display_image;
  const restaurent_logo = restaurent_data?.logo;
  let background_img = aqlutstorage + containerMenuBranding + background_image;
  let logo_restaurent = aqlutstorage + containerProfile + restaurent_logo;

  //call restaurent api
  async function getMenuItem(payload) {
    setmessage("");
    if (window.navigator.onLine) {
      const EndPoint =
        process.env.REACT_APP_BASEURL + process.env.REACT_APP_API_VERSION;

      const baseURL = `${EndPoint}restaurents/getQrCodeDetails/`;
      var url = `${baseURL}?qr_code_id=${payload}`;

      try {
        const res = await axios({
          method: "get",
          url: url,
          headers: { "Content-Type": "multipart/form-data" },
        });
        dispatch(addRestaurentData(res?.data?.data));
        dispatch(addQrData(res?.data?.data?.QrCodeGroup));

        let data = {
          pos_integration_status:
            res?.data?.data?.QrCodeGroup?.Restaurent?.pos_integration_status,
        };
        dispatch(checkQrcodeIsMaster(res?.data?.data?.is_master));
        // dispatch(posStatus(data));
        setLoading(false);
      } catch (response_1) {
        let msg =
          "Menu or  sections not found for Restaurents please try with another QR code.";
        setmessage(msg);
        setLoading(false);
      }
    } else {
      let msg = "Please check your internet connection.";
      setmessage(msg);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (lang === "eng") {
      let obj = {
        lang: "en",
        direction: "ltr",
      };

      dispatch(langSuccess(obj));
    } else if (lang === "arb") {
      let obj = {
        lang: "ar",
        direction: "rtl",
      };
      dispatch(langSuccess(obj));
    }
  }, [lang]);

  useEffect(() => {
    setLoading(true);
    if (name === "qrcodeid") {
      // let res = await showMenu(qrCodeID)
      dispatch(showMenuByqrID(id));
      if (currentActive?.[0]?.qrcodeid) {
        getMenuItem(currentActive?.[0]?.qrcodeid);
      }
    } else if (name !== "qrcodeid") {
      let msg = "Please scan Qr CODE again"; 
      // setLoading(false);
      setmessage(msg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentActive?.[0]?.qrcodeid]);

  // function submit() {
  //   navigate(
  //     `/${currentActive?.[0]?.qrcodeid}-${currentActive?.[0]?.restaurentid}/login-options`
  //   );
  // }

  const redirectFunctionality = () => {
    if(QrCodeGroupType === "Dine In"){
      navigate(
        `/${currentActive?.[0]?.qrcodeid}-${currentActive?.[0]?.restaurentid}/login-options`
      );
    }else{
      navigate(
        `/${currentActive?.[0]?.qrcodeid}-${currentActive?.[0]?.restaurentid}/guest-menu`
      );
    }
  }


  if (loading) {
    return (
      <>
        <Backdrop
          sx={{
            color: "#FC2B6E",
            background: "#FFFFFF",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={loading}
        >
          <>
            {message !== "" ? (
              <h1
                style={{
                  fontSize: "20px",
                  margin: "50px",
                }}
              >
                {message}
              </h1>
            ) : (
              <Backdrop
                sx={{
                  color: "#FC2B6E",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={loading}
              >
                <div style={{ display: "block", textAlign: "center" }}>
                  <CircularProgress color="inherit" />
                  <p style={{ marginTop: "5px" }}>Processing...</p>
                </div>
              </Backdrop>
            )}
          </>
        </Backdrop>
      </>
    );
  }

  return (
    <>
      <AppIntroWrapper>
        <section className="upper-wrapper">
          <TabDiv onClick={()=>redirectFunctionality()} >
            <h1>1</h1>
            <div>
              <p>scan</p>
              <p>إمسح</p>
            </div>

            <img src={Coverscan} alt="scan" />
          </TabDiv>

          <TabDiv onClick={()=>redirectFunctionality()} >
            <h1>2</h1>
            <div>
              <p>order</p>
              <p>أطلب</p>
            </div>

            <img src={Coverorder} alt="scan" />
          </TabDiv>

          <TabDiv onClick={()=>redirectFunctionality()} >
            <h1>3</h1>
            <div>
              {QrCodeGroupType === "Dine In" ? (
                <>
                  <p style={{ fontSize: "14px", lineHeight: "14px" }}>
                    pay full or <br /> split payments
                  </p>
                  <p style={{ fontSize: "14px" }}>
                    إدفع بالكامل أو تقاسم الفاتوره
                  </p>
                </>
              ) : (
                <>
                  <p>pay</p>
                  <p>ادفع</p>
                </>
              )}
            </div>

            <img src={Coverpayment} alt="scan" />
          </TabDiv>
        </section>

        <section className="lower-wrapper">
          {message !== "" ? (
            <SelectLangWrapper>
              <h1>{message}</h1>
            </SelectLangWrapper>
          ) : (
            <SelectLangWrapper>
              <RestaurantLogoBox dir={userlang}>
                <img src={logo_restaurent} alt="logo" />
              </RestaurantLogoBox>

              <div className="upper" dir={userlang}>
                <header>
                  <IntlMessage id="welcomescreen.title" />{" "}
                  {userlang === "rtl"
                    ? restaurent_data?.ar_name
                    : restaurent_data?.name}
                </header>
              </div>

              <div className="lower">
                <select
                  onChange={(e) => SelectLang(e.target.value || null)}
                  value={lang || ""}
                >
                  <option value="eng">English</option>
                  <option value="arb">Arabic</option>
                </select>

                <button onClick={redirectFunctionality}>
                  <ArrowIcon width={9} height={11} color={'transparent'} />
                </button>
              </div>
            </SelectLangWrapper>
          )}
        </section>
      </AppIntroWrapper>
    </>
  );
}

const TabDiv = styled.div`
  width: 100%;
  height: 89px;
  background: rgba(255, 216, 229, 0.5);
  border-radius: 200px;
  padding: 9px 30px;
  display: flex;
  align-items: center;
  position: relative;

  @media (max-height: 660px) {
    height: 78px;
  }

  img {
    width: 107px;
    height: 107px;
    position: absolute;
    right: -30px;
  }

  h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 70px;
    line-height: 105px;
    color: #bd01a8;
  }

  div {
    margin: 0 9px;

    p {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 23px;
      line-height: 34px;
      text-transform: uppercase;
      color: #bd01a8;
    }
  }
`;

const AppIntroWrapper = styled.div`
  width: 100%;
  // height: 100vh;

  .upper-wrapper {
    width: 100%;
    height: 70vh;
    display: flex;
    flex-direction: column;
    gap: 50px;
    justify-content: center;
    padding: 0 40px 0 20px;

    @media (max-height: 785px) {
      height: auto;
      margin-top: 40px;
    }
    @media (max-height: 710px) {
      height: auto;
      margin-top: 20px;
    }
  }
`;

const RestaurantLogoBox = styled.div`
  position: absolute;
  width: 100px;
  height: 100px;
  left: ${({ dir }) => dir === "ltr" && "30px"};
  right: ${({ dir }) => dir === "rtl" && "30px"};
  top: -50px;
  background: #fc2b6e;
  border: 1px solid #fc2b6e;
  border-radius: 100px;
  padding: 1px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 100px;
  }
`;

const SelectLangWrapper = styled.div`
  width: 100%;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  background: linear-gradient(90deg, #fc2b6e 0%, #bc00a9 100%);
  border-top: 1px solid #ffffff;
  border-radius: 38px 38px 0px 0px;
  border-radius: 30px 30px 0px 0px;
  padding: 33px 20px;
  box-shadow: 4px -1px 16px 1px #afabab99;

  @media (max-height: 660px) {
    margin: 0;
    padding: 15px 20px;
  }

  .upper {
    text-align: center;
    header {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      letter-spacing: 0.02em;
      color: #ffffff;
      margin-top: 40px;
      @media (max-height: 785px) {
        font-size: 20px;
        margin-top: 30px;
      }
    }

    p {
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #7b7f91;
      padding: 10px 0;
    }
  }

  .lower {
    display: flex;
    margin: 20px 0;

    select {
      width: 100%;
      height: 48px;
      background: linear-gradient(90deg, #ff3f7d 0%, #db1ac7 100%);
      border: 1px solid #d2d2d3;
      border-radius: 28px;
      padding: 0 20px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #ffffff;
      -webkit-appearance: none;
      -moz-appearance: none;

      option {
        color: #000000;
      }
    }
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 45px;
      background: #c7079e;
      border: 1px solid #ffffff;
      margin: 0 10px;
      border-radius: 100%;

      @media (max-width: 500px) {
        width: 55px;
        height: 45px;
        margin-right: 0px;
      }
    }
  }

  h1 {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
    letter-spacing: 0.36px;
    color: #ffffff;
    padding: 30px 0;
    text-align: center;
  }
`;

const SelectWrapper = styled.div`
  position: relative;
  background: ${({ background_image, background_img }) =>
    `${background_image !== undefined
      ? `url(${background_img})`
      : `url(${BackgroundCover.toString()})`
    }`};
  background-repeat: no-repeat;
  background-size: 100% 75%;
  width: 100%;
  height: 100vh;
`;
