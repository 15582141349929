import React, { useState } from "react";
import { Header } from "../../components";
import "../Feedback/Feedback.scss";
import smile1 from "../../assets/smile1.png";
import smile2 from "../../assets/smile2.png";
import smile3 from "../../assets/smile3.png";
import smile4 from "../../assets/smile4.png";
import smile5 from "../../assets/smile5.png";
import { useSelector } from "react-redux";
import { feedbackSendByCustomer } from "../../services/Collection";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import IntlMessage from "../../Utils/IntlMessage";
import { useIntl } from "react-intl";

export default function Feedback() {
  const [updateSmile, setUpdateSmile] = useState({});
  const [des, setDec] = useState("");
  const intl = useIntl();
  const userlang = useSelector((state) => state.selectLang.direction);
  const restaurentDetails = useSelector(
    (state) => state?.restaurentSlice?.restaurentData?.restaurent_id
  );
  const qrCodeId = useSelector(
    (state) => state?.restaurentSlice?.restaurentData
  );
  const OrderID = useSelector(
    (state) => state?.orderInformationSlice?.orderDeails?.id
  );
  const customTheme = useSelector((state) => state?.theme?.branding);


  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const sendFeddback = async () => {
    if (des && updateSmile.value) {
      setLoading(true);
      let req = {
        order_id: OrderID,
        restaurent_id: restaurentDetails,
        thoughts: des,
        rate_of_exeperience: updateSmile.emojiName,
        type: 4,
        qr_code_id: qrCodeId?.id,
      };

      let res = await feedbackSendByCustomer(req);
      if (res.status === 200) {
        toast.info(res.message, { theme: "colored" });
        navigate("/thanks");
        setLoading(false);
      } else {
        toast.error(res.response.data.message, { theme: "colored" });
        setLoading(false);
      }
    } else {
      toast.error("Please fill feedback form");
    }
  };

  return (
    <>
      <Header />

      <section className="feedback" dir={userlang}>
        <h1 style={{ color: customTheme?.theme_color }}>
          <IntlMessage id="givefeedback.title" />
        </h1>
        <div className="inner-feedback">
          <p style={{ color: customTheme?.theme_color }}>
            <IntlMessage id="givefeedback.question" />
          </p>
          <input
            type="text"
            placeholder={intl.formatMessage({
              id: "givefeedback.placeholder",
            })}
            className="feedback-box"
            onChange={(evt) => {
              setDec(evt.target.value);
            }}
          />
          <h6 style={{ color: customTheme?.theme_color }}>
            <IntlMessage id="givefeedback.rateexperience" />
          </h6>
          <div className="feedback-smile">
            <span>
              <img
                src={smile1}
                onClick={(e) =>
                  setUpdateSmile({ emojiName: "smile1", value: e.target.src })
                }
                className={
                  updateSmile.emojiName === "smile1" ? "activeSmile" : ""
                }
                alt="smile"
              />
            </span>
            <span>
              <img
                src={smile2}
                onClick={(e) =>
                  setUpdateSmile({ emojiName: "smile2", value: e.target.src })
                }
                className={
                  updateSmile.emojiName === "smile2" ? "activeSmile" : ""
                }
                alt="smile"
              />
            </span>
            <span>
              <img
                src={smile3}
                onClick={(e) =>
                  setUpdateSmile({ emojiName: "smile3", value: e.target.src })
                }
                className={
                  updateSmile.emojiName === "smile3" ? "activeSmile" : ""
                }
                alt="smile"
              />
            </span>
            <span>
              <img
                src={smile4}
                onClick={(e) =>
                  setUpdateSmile({ emojiName: "smile4", value: e.target.src })
                }
                className={
                  updateSmile.emojiName === "smile4" ? "activeSmile" : ""
                }
                alt="smile"
              />
            </span>
            <span>
              <img
                src={smile5}
                onClick={(e) =>
                  setUpdateSmile({ emojiName: "smile5", value: e.target.src })
                }
                className={
                  updateSmile.emojiName === "smile5" ? "activeSmile" : ""
                }
                alt="smile"
              />
            </span>
          </div>
        </div>
        <div className="feedback-send">
          <button
            type="button"
            onClick={sendFeddback}
            style={{
              color: customTheme?.button_text_color,
              background: `linear-gradient(91.33deg,${customTheme?.button_primary_color} 0%,${customTheme?.button_secondary_color} 100%)`,
            }}
          >
            <IntlMessage id="givefeedback.button.send" />
          </button>
        </div>
      </section>
    </>
  );
}
