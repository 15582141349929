import styled from "styled-components";

export const CartAdressWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const AddressWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  background: #fff;
  margin-bottom: 20px;

  .address-title {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .title {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #242424;
    }
    .Change-label {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 18px;
      color: #fc2b6e;
    }
  }
`;

export const SelectedAddressBox = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 10px;
  background: #f8f8fb;
  border-radius: 10px;
  padding: 15px;

  .SelectedAddress-title {
    width: 100%;

    h3 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      color: #fc2b6e;
    }
  }

  .SelectedAddress-content {
    width: 100%;

    label {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #202020;
    }
  }
`;

export const DeliveryWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 10px;
`;

export const DeliveryTimeBox = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 10px;
  background: #f8f8fb;
  border-radius: 10px;
  padding: 15px;
  padding-top: 5px;

  .delivery-time {
    input {
      accent-color: #f62773;
      margin-top: 15px;
    }
    label {
      padding: 0 10px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 5px;
    }
  }
`;

export const ScheduledTimeWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 20px;

  .chooseDay {
    display: flex;
    gap: 3%;
    margin-top: 10px;
    text-align: center;

    .day-cards {
      width: 20%;
      border: 1px solid #f92972;
      border-radius: 12px;
      padding: 10px;
      padding-top: 0;

      span {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        color: #f72874;
      }
      h4 {
        font-family: "Avenir LT Std";
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 18px;
        color: #f72874;
        padding-top: 15px;
      }
    }
    .active {
      width: 20%;
      border: 1px solid #f92972;
      border-radius: 12px;
      padding: 10px;
      padding-top: 0;
      background: linear-gradient(91.33deg, #fc2b6e 0%, #bc00a9 100%);

      span {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        color: #fff;
      }
      h4 {
        font-family: "Avenir LT Std";
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 18px;
        color: #fff;
        padding-top: 15px;
      }
    }
  }
`;

export const AvailableTimeWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 20px;

  .available-time-cards {
    width: 100%;
    height: 100%;
    margin-top: 10px;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    ${"" /* grid-template-columns: repeat(4, 500px); */}
    gap: 5px;

    .time-card {
      width: 100px;
      ${"" /* height: 40px; */}
      background: #ffffff;
      border: 1px solid rgba(107, 119, 154, 0.1);
      border-radius: 100px;
      margin-top: 5px;
      padding: 10px 0px;
      text-align: center;
      color: #6b779a;

      :hover {
        background: linear-gradient(91.33deg, #fc2b6e 0%, #bc00a9 100%);
        color: #ffffff;
      }
      span {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        letter-spacing: 0.233605px;
      }
    }
  }

  @media (max-width: 1130px) {
    .available-time-cards {
      grid-template-columns: repeat(9, 1fr);
    }
  }

  @media (max-width: 930px) {
    .available-time-cards {
      grid-template-columns: repeat(7, 1fr);
    }
  }
  @media (max-width: 830px) {
    .available-time-cards {
      grid-template-columns: repeat(5, 1fr);
    }
  }
  @media (min-width: 470px) and (max-width: 580px) {
    .available-time-cards {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  @media (max-width: 470px) {
    .available-time-cards {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  @media (max-width: 330px) {
    .available-time-cards {
      grid-template-columns: repeat(2, 1fr);
    }
  }
`;

export const CartAddressBtn = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;

  .ReviewOrderButton {
    width: 26%;
    height: 48px;
    border-radius: 30px;
    // margin: auto;
    display: flex;
    align-items: center;
    background: linear-gradient(91.33deg, #fc2b6e 0%, #bc00a9 100%);
    justify-content: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin-bottom: 10px;

    @media(max-width:1024px){
      width:85%;
      margin: auto;
      margin-bottom: 10px;
    }

    button {
      width: 100%;
      height: 100%;
      border: none;
      cursor: pointer;
      background: transparent;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.06px;
      color: #ffffff;
    }

    p {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.06px;
      color: #ffffff;
    }
  }
`;
