import React,{useState} from 'react';
import "../CartWithOutDiscount/CartWithOutDiscount.scss"
import { Header, RecommendedItem } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { restartChat } from '../../store/ChatHistory';

export default function CartWithOutDiscount(){

    let [num, setNum]= useState(1);
    const navigate=useNavigate();
    const userlang=useSelector((state) => state.selectLang.direction);

  let incNum =()=>{
    if(num<10)
    {
    setNum(Number(num)+1);
    }
  };
  let decNum = () => {
     if(num>1){
      setNum(num - 1);
     }
  }

    const handleChange = (event) => {
        setNum(event.target.value);
    }

    const dispatch =  useDispatch()
    const handleMakePayment=() => {
        dispatch(restartChat([]))
        navigate("/payment")
    }
    
    return (
        <>
        <Header/>
        <section className="cartwithdiscount" dir={userlang}>
            <div className="order-Foodstyle"><h4>Your Order Details</h4></div>
            <div className='order-detail'>
                <div className="order-MainContent">
                <div className="order-FoodList">
                <img src="/static/media/food.740a12a09af04cff8ff3.png" />
                <div className='order-counter'>
                <button className="minus" type='button' onClick={decNum}>-</button>
                <input type="text" className="custom-form-control" value={num} readOnly='readonly' onChange={handleChange}/>
                <button className="plus" type='button' onClick={incNum}>+</button>
                </div>
                </div>
                <div className="order-FoodDetails">
                <h5>Jamie's Super Corn</h5>
                <p>QAR 541</p>
                <h6>Add On : Chicken</h6>
                </div>
                <div className='delete-icon'>
                    <img src={require('../../assets/Delete.png')} />
                </div>
                </div>
                

                <div className="order-MainContent">
                <div className="order-FoodList">
                <img src="/static/media/food.740a12a09af04cff8ff3.png" />
                <div className='order-counter'>
                <button className="minus" type='button' onClick={decNum}>-</button>
                <input type="text" className="custom-form-control" readOnly='readonly' value={num} onChange={handleChange}/>
                <button className="plus" type='button' onClick={incNum}>+</button>
                </div>
                </div>
                <div className="order-FoodDetails">
                <h5>Jamie's Super Corn</h5>
                <p>QAR 541</p>
                <h6>Add On : Chicken</h6>
                </div>
                <div className='delete-icon'>
                    <img src={require('../../assets/Delete.png')} />
                </div>
                </div>


                <div className="order-MainContent">
                <div className="order-FoodList">
                <img src="/static/media/food.740a12a09af04cff8ff3.png" />
                <div className='order-counter'>
                <button className="minus" type='button' onClick={decNum}>-</button>
                <input type="text" className="custom-form-control" value={num} readOnly='readonly' onChange={handleChange}/>
                <button className="plus" type='button' onClick={incNum}>+</button>
                </div>
                </div>
                <div className="order-FoodDetails">
                <h5>Jamie's Super Corn</h5>
                <p>QAR 541</p>
                <h6>Add On : Chicken</h6>
                </div>
                <div className='delete-icon'>
                    <img src={require('../../assets/Delete.png')} />
                </div>
                </div>


            </div>
            
        </section>
        <section className='bill-cart-detail' dir={userlang}>
        <p>Bill Details</p>
                <div className='inner-bill-detail'>
                    <div className='left-bill-detail'>Item Total :</div>
                    <div className='right-bill-detail'>QAR 500</div>
                    <div className='left-bill-detail'>Taxes & Charges :</div>
                    <div className='right-bill-detail'>QAR 10</div>
                </div>
                <div className='total-inner-bill-detail'>
                <div className='left-bill-detail'>Total Payable :</div>
                    <div className='right-bill-detail'>QAR 510</div>
                    </div>
        </section>

            <RecommendedItem/>


            
            {/* footer component start here */}
            <div className='ItemWrapper'>
                    <div className='ItemDetails'>
                        <img src={require('../../assets/Bag.png')} />
                        <div className='Itemturn'>
                            <p>Items</p>
                            <h5>QAR 30</h5>
                        </div>
                    </div>
                    <div className='ViewItem'>
                        <button onClick={handleMakePayment}>MAKE PAYMENT</button>
                    </div>
                </div>
            {/* end of footer component */}
        </>
    )
}