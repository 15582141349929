import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Header } from '../../components';
import { SendChat } from '../../Utils/Images';
import "../ChatOption/ChatOption.scss";
const pay=require('../../assets/pay.png');
const waiter=require('../../assets/waiter.png');
const send=require('../../assets/Send.png');
const orderdetail=require('../../assets/orderdetail.png')

export default function ChatOption(){
    const userlang=useSelector((state) => state.selectLang.direction);
    const [active,setActive]=useState('');
    const handlePay = (e) => {
   
        setActive(e);
    }
    return (
        <>
        <Header/>

        <div className='crosssignheader'>
                <img src={require('../../assets/cross-sign.png')} onClick={((e) => {
                    e.preventDefault();
                    window.location.href="/addfoodlist"
                })} />
        </div>
        <section className='user-chat-option' >
            <div className='inner-chat-option'>
                <input type="text" disabled placeholder='Welcome to Restaurant! We are eagerly awaiting to serve you tasty food, What would you like to do?' className='custom-chat-option' />
                <span>1:30 pm</span>                
                <div className='payment-option'>
                    <div className='pay-option'>
                        <img src={pay} alt='pay'/>
                        <button type='button' onClick={(e)=>handlePay('pay')} className={"custom-chat-button "+(active === 'pay'?'active':'')} >Pay </button>
                    </div>
                    <div className='waiter-option'>
                        <img src={waiter} alt='waiter'/>
                        <button type='button' onClick={(e => handlePay('call_the_waiter'))} className={"custom-chat-button "+ (active ==='call_the_waiter' ?'active' : '')}  >Call the waiter</button>
                    </div>

                    <div className='order-option'>
                        <img src={orderdetail} alt='waiter'/>
                        <button type='button' onClick={(e) => handlePay('order_detail')} className={"custom-chat-button "+ (active === 'order_detail' ? 'active':'')}>Order Detail</button>
                    </div>

                </div>
            </div>
        </section>

        {/* <section className='chat-bottom-footer'>
            <div className='input-inner-field'>
                <img src={SendChat} alt="send message" dir={userlang}/>
                <input type="text" placeholder="Type here.." />
            </div>
        </section> */}
        </>
    )
}