import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { FoodModal, RecommendedItem } from "../../components";
import styled from "styled-components";
import AddOn from "./AddOn";
import AddQuantity from "./AddQuantity";
import Discount from "./Discount";
import FreeItem from "./FreeItem";
import Allergies from "./Allergies";
import SpecialNotes from "./SpecialNotes";
import RecommendedItemHeader from "./RecommendedItem";
import {
  ClearAddOn,
  removeItem,
  SelectPriceOption,
} from "../../store/selectItem";
import moment from "moment";

import {
  addToCART,
  checkItemAvailabilityOnPos,
  getItemOfffer,
  getRestaurentStatus,
  viewCart,
} from "../../services/Collection";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { CircularProgress, ThemeProvider, createTheme } from "@mui/material";
import { Backdrop } from "@mui/material";
import { addData } from "../../store/Cart";
// import { DollarIcon } from "../../Utils/Images";
import ViewBasket from "../../components/ViewBasket";
import IntlMessage from "../../Utils/IntlMessage";
import { DollarIcon } from "../../Utils/Icons";
import {
  handleConditionOfAddBasket,
  handleDineInCondition,
} from "../../Utils/CommanFunctions";

function AddItemDrawer({
  model,
  setModel,
  toggleAllegiesItem,
  special_notes,
  inputNone,
  setAlertMessage,
}) {
  const navigate = useNavigate();
  const [isLoadingButton, setLoadingButton] = useState(false);
  const [isChecked, setisChecked] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [freeItems, setFreeItems] = useState([]);
  const [discountItem, setdiscountItem] = useState([]);
  const location = useLocation();
  const RestuarantDetails = useSelector(
    (state) => state?.restaurentSlice?.restaurentData
  );
  const userAuth = useSelector((state) => state?.userAuth);

  const {
    direction,
    item,
    recommendedItems,
    priceOption,
    quantity,
    QrData,
    specialNotes,
    addOn,
    totalPrice,
    addOnId,
    restaurentData,
  } = useSelector(
    ({ selectLang, selectItemSlice, restaurentSlice, cartSlice }) => ({
      ...selectLang,
      ...cartSlice,
      ...selectItemSlice,
      ...restaurentSlice,
    })
  );
  //Check restuarent is closed or not
  const styling = restaurentData?.Menu?.Restaurent?.MenuPageBranding;
  //style

  const home_button_bg_color = styling?.home_button_bg_color;
  //customer id
  const POSisEnable = useSelector((state) => state?.posSlice?.isEnable);
  // const getAlreadyScanedID = useSelector((state) => state.StoreForMultiUsers);
  // const currentID = getAlreadyScanedID?.workStation?.slice(-1)?.[0]?.id;

  const getAlreadyScanedID = useSelector((state) => state.StoreForMultiUsers);
  const currentActive =
    getAlreadyScanedID?.getScannedQrcodeAndRestuarent?.slice(-1);

  const currentID = currentActive?.[0]?.qrcodeid;
  const isDeliveryEnable = useSelector((state) => state?.deliveryModule);
  const customTheme = useSelector((state) => state?.theme?.branding);
  const customer_id = useSelector((state) => state?.userAuth?.data?.id);
  const dispatch = useDispatch();
  const closeAddModal = () => {
    setLoadingButton(false);
    setLoading(false);
    dispatch(removeItem());
    setModel(false);
  };

  //Getting offer list
  const getOffer = async () => {
    try {
      const res = await getItemOfffer(item.id);
      setFreeItems([]);
      setdiscountItem([]);
      if (res.status === 200) {
        const data = res.data;
        setLoading(false);
        // eslint-disable-next-line array-callback-return
        data.map((udx) => {
          if (udx?.DiscountFreeItems?.length > 0) {
            setFreeItems(udx?.DiscountFreeItems);
          } else if (udx?.DiscountItemPrices) {
            setdiscountItem([udx?.DiscountItemPrices]);
          }
        });
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const selectPrice = (id, price, calories) => {
    const obj = {
      id,
      price,
      calories,
    };
    setisChecked(true);
    dispatch(SelectPriceOption(obj));
  };

  function isOpen(openingHour, closingHour, currentHour) {
    if (closingHour <= openingHour) {
      // The restaurant closes the next day.
      if (currentHour >= openingHour || currentHour < closingHour) {
        return true;
      }
    } else {
      // The restaurant closes on the same day.
      if (currentHour >= openingHour && currentHour < closingHour) {
        return true;
      }
    }
    return false;
  }

  const openModalForGuestUserv = () => {
    setAlertMessage(true);
  };

  const addToCart = async () => {
    setLoadingButton(true);
    setLoading(true);
    var count = 0;
    const currentHour = moment().format("HH:mm:ss");
    console.log(" QrData?.restaurent_id,", QrData?.restaurent_id);
    const payload = {
      restaurent_id: QrData?.restaurent_id,
    };
    const response = await getRestaurentStatus(payload);
    const { close_time, open_time, status } = response.data;
    const { disable_order } = response?.data?.Restaurent;

    //Checking if resturent is accepting orders
    if (disable_order) {
      let alert = {
        message: "Currently restaurent is not accepting orders!",
      };
      navigate("/closed", {
        state: { message: alert },
      });
      return;
    }

    if (isOpen(open_time, close_time, currentHour)) {
      console.log("The restaurant is open");
    } else {
      console.log("The restaurant is closed");
      let alert = {
        message: "Currently restaurent is not accepting orders!",
      };
      navigate("/closed", {
        state: { message: alert },
      });
      return;
    }

    if (status === "1") {
      let alert = {
        message: `Restaurent is closed`,
      };
      navigate("/closed", {
        state: { message: alert },
      });

      return;
    }

    if (!isChecked && item.ItemPrices[0].name !== null) {
      toast.error("Please select price options");
      setLoading(false);
      setLoadingButton(false);
      return;
    }

    for (var i = 0; i < item?.MenuItemOptions?.length; i++) {
      count = 0;
      // if (item?.MenuItemOptions[i].required === "1") {
      var options =
        item?.MenuItemOptions[i]?.OptionGroupModule.OptionItemModules;

      for (var j = 0; j < options?.length; j++) {
        if (addOnId.includes(options[j].id)) {
          count++;
        }
      }

      if (count === 0 && item?.MenuItemOptions[i].required === "0") {
      } else {
        if (count < parseInt(item?.MenuItemOptions[i].min)) {
          dispatch(ClearAddOn());

          toast.error(
            "Please Select minimum " +
              item?.MenuItemOptions[i].min +
              " Add On from " +
              item?.MenuItemOptions[i].OptionGroupModule.name
          );
          setLoadingButton(false);
          setLoading(false);
          return;
        } else if (count > parseInt(item?.MenuItemOptions[i].max)) {
          dispatch(ClearAddOn());

          toast.error(
            "You can atmost Select " +
              item?.MenuItemOptions[i].max +
              " Add On from " +
              item?.MenuItemOptions[i].OptionGroupModule.name
          );
          setLoadingButton(false);
          setLoading(false);
          return;
        }
      }

      // }
    }

    let recommendedArr = [];
    recommendedItems.map((el) =>
      recommendedArr.push({
        recommended_item_id: el.id,
        count: el.count,
        recommended_price: el.price,
        total: Number(el.count) * Number(el.price),
      })
    );

    var addOnArr = [];

    addOn.map((el) => {
      addOnArr.push({
        option_group_modules_id: el.groupId,
        option_item_id: el.id,
        addon_calories: el.calories,
        addon_price: POSisEnable ? el.posModifierPrice : el.price,
      });
    });
    let qrcode = "";

    if (restaurentData?.QrCodeGroup?.group_type === "Dine In") {
      qrcode = "Dine In";
    } else {
      if (isDeliveryEnable?.orderType === "takeaway") {
        qrcode = "Takeaway";
      } else {
        qrcode = "Delivery";
      }
    }

    const obj1 = {
      customer_id: customer_id,
      item: item.id,
      section_id: item.sectionId,
      currency: "qar",
      price: parseFloat(priceOption.price * quantity).toFixed(2),
      qty: quantity,
      price_option_id: priceOption.id,
      option_price: priceOption.price,
      price_option_calories: priceOption.calories,
      recommended_item: recommendedArr,
      addon: addOnArr,
      special_notes: specialNotes,
      ar_special_notes: specialNotes,
      qr_code_id: currentID,
      qr_code_type: qrcode,
    };

    let res = await addToCART(obj1);
    if (res.status === 200) {
      updateFooter();
    } else {
      setLoadingButton(false);
      setLoading(false);
      toast.error(res.message);
    }
  };

  const updateFooter = async () => {
    let qrcode = "";

    if (restaurentData?.QrCodeGroup?.group_type === "Dine In") {
      qrcode = "Dine In";
    } else {
      if (isDeliveryEnable?.orderType === "takeaway") {
        qrcode = "Takeaway";
      } else {
        qrcode = "Delivery";
      }
    }

    let res = await viewCart(currentID, qrcode);
    if (res.status === 200) {
      dispatch(addData(res?.data));
      setModel(false);
      setLoadingButton(false);
      setLoading(false);
    } else {
      dispatch(addData([]));
      setModel(false);
      setLoadingButton(false);
      setLoading(false);
    }
  };

  const checkingShowingPrice = (price) => {
    if (POSisEnable) {
      if (price === null) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const checkItemAvailabilityBeforeaddToCart = () => {
    if (restaurentData?.Menu?.Restaurent?.disable_order) {
      setAlertMessage(true);
    } else {
      let section = restaurentData?.Menu?.Sections?.find(
        (val) => val?.id == item?.sectionId
      );
      if (item?.available_for_ordering) {
        if (
          section?.is_scheduled &&
          moment().isBetween(
            moment(section?.start_time, "h:mm A"),
            moment(section?.end_time, "h:mm A")
          )
        ) {
          addToCart();
        } else if (!section?.is_scheduled) {
          addToCart();
        } else {
          toast.error(
            `Sorry, ${section?.section_name} items currently unavailable.`,
            { theme: "colored" }
          );
        }
      } else {
        setAlertMessage(true);
      }
    }
  };

  useEffect(() => {
    const handleBackButton = (event) => {
      if (model) {
        setModel(false); // Close the drawer
        event.preventDefault(); // Prevent the default back action
        return;
      }
    };

    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [model]);

  useEffect(() => {
    if (model) {
      window.history.pushState(null, document.title, window.location.href);
    }
  }, [model]);

  return (
    <AddItemDrawerParent dir={direction}>
      {!isLoading ? (
        <SwipeableDrawer
          anchor={"bottom"}
          open={model}
          onClose={closeAddModal}
          PaperProps={{
            sx: {
              width: "30%",
              "@media (max-width: 1024px)": {
                width: "100%",
              },
              height: "100dvh",
              background: customTheme?.background_color,
              transform: "none !important",
            },
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              marginBottom: "108px",
            }}
          >
            <div>
              <FoodModal
                colorStyle={home_button_bg_color}
                selectedItem={item}
                onClose={closeAddModal}
              />
              <FoodModalBtnsWrapper
                style={{ background: customTheme?.background_color }}
              >
                {handleDineInCondition(RestuarantDetails, location) && (
                  <>
                    {totalPrice > 0 && (
                      <>
                        <AddQuantity
                          colorStyle={home_button_bg_color}
                          direction={direction}
                        />
                        <div
                          className="sectionSpacing"
                          style={{
                            border: `1px solid ${customTheme?.theme_color}`,
                            boxShadow: "none",
                          }}
                        ></div>
                      </>
                    )}
                  </>
                )}

                {item && item?.ItemPrices?.length > 1 && (
                  <>
                    <PriceSection
                      dir={direction}
                      style={{ background: customTheme?.background_color }}
                    >
                      <div className="Innersection">
                        {item?.ItemPrices?.length === 1 ? (
                          <>
                            <div className="InnerHeader">
                              <div className="PriceHeader">
                                <i className="dolloarSym">
                                  <DollarIcon
                                    color={customTheme?.theme_color}
                                  />
                                </i>
                                <h1
                                  style={{ color: customTheme?.theme_color }}
                                  className="PriceHeading"
                                >
                                  <IntlMessage id="AddItemDrawer.priceoptions" />
                                </h1>
                              </div>
                              <PriceNumberContainer>
                                <span>
                                  <b style={{ color: customTheme?.text_color }}>
                                    {checkingShowingPrice(
                                      item?.ItemPrices[0]?.pos_item_price
                                    )
                                      ? "QAR " +
                                        item?.ItemPrices[0]?.pos_item_price
                                      : "QAR " + item?.ItemPrices[0]?.price}
                                  </b>
                                </span>
                              </PriceNumberContainer>
                            </div>
                          </>
                        ) : (
                          <PriceSection>
                            <div className="Innersection">
                              <div className="InnerHeader">
                                <div className="PriceHeader">
                                  <i className="dolloarSym">
                                    <DollarIcon
                                      color={customTheme?.theme_color}
                                    />
                                  </i>
                                  <h1
                                    style={{ color: customTheme?.theme_color }}
                                    className="PriceHeading"
                                  >
                                    <IntlMessage id="AddItemDrawer.priceoptions" />
                                  </h1>
                                </div>
                              </div>

                              {item?.ItemPrices?.map((sitem, key) => {
                                return (
                                  <div key={key} className="Innermenu">
                                    {POSisEnable ? (
                                      <>
                                        <ChekboxContainer>
                                          <input
                                            className="PriceListCheckbox"
                                            type="radio"
                                            name="topping"
                                            onChange={() =>
                                              selectPrice(
                                                sitem.id,
                                                sitem?.pos_item_price,
                                                sitem.calories
                                              )
                                            }
                                          />
                                          <PriceNumberContainer>
                                            <span
                                              style={{
                                                color: customTheme?.text_color,
                                              }}
                                            >
                                              QAR {sitem?.pos_item_price}
                                            </span>
                                          </PriceNumberContainer>
                                        </ChekboxContainer>

                                        <ChekboxContainer>
                                          <PricesWrapper dir={direction}>
                                            <p>{sitem.name}</p>
                                          </PricesWrapper>
                                        </ChekboxContainer>
                                      </>
                                    ) : (
                                      <>
                                        <ChekboxContainer>
                                          <input
                                            className="PriceListCheckbox"
                                            type="radio"
                                            name="topping"
                                            onChange={() =>
                                              selectPrice(
                                                sitem.id,
                                                sitem?.price,
                                                sitem.calories
                                              )
                                            }
                                          />
                                          <PriceNumberContainer>
                                            <span
                                              style={{
                                                color: customTheme?.text_color,
                                              }}
                                            >
                                              QAR {sitem?.price}
                                            </span>
                                          </PriceNumberContainer>
                                        </ChekboxContainer>

                                        <ChekboxContainer>
                                          <PricesWrapper dir={direction}>
                                            <p>{sitem.name}</p>
                                          </PricesWrapper>
                                        </ChekboxContainer>
                                      </>
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                          </PriceSection>
                        )}
                      </div>
                    </PriceSection>
                    <div
                      className="sectionSpacing"
                      style={{
                        border: `1px solid ${customTheme?.theme_color}`,
                        boxShadow: "none",
                      }}
                    ></div>
                  </>
                )}

                {item?.allergies?.length > 0 && (
                  <>
                    <Allergies
                      item={item}
                      colorStyle={home_button_bg_color}
                      toggleAllegiesItem={toggleAllegiesItem}
                    />
                    <div
                      className="sectionSpacing"
                      style={{
                        border: `1px solid ${customTheme?.theme_color}`,
                        boxShadow: "none",
                      }}
                    ></div>
                  </>
                )}

                {item?.MenuItemOptions?.length > 0 && (
                  <>
                    <AddOn
                      addOn={addOn}
                      colorStyle={home_button_bg_color}
                      options={item?.MenuItemOptions}
                    />
                    <div
                      className="sectionSpacing"
                      style={{
                        border: `1px solid ${customTheme?.theme_color}`,
                        boxShadow: "none",
                      }}
                    ></div>
                  </>
                )}

                {/* {discountItem?.length > 0 && (
              <>
                <Discount
                  discountItem={discountItem}
                  colorStyle={home_button_bg_color}
                  direction={direction}
                />
                 <div className="sectionSpacing" style={{border:`1px solid ${customTheme?.theme_color}`, boxShadow:"none"}} ></div>
              </>
            )} */}

                {freeItems?.length > 0 && (
                  <>
                    <FreeItem
                      freeItems={freeItems}
                      colorStyle={home_button_bg_color}
                      direction={direction}
                    />
                    <div
                      className="sectionSpacing"
                      style={{
                        border: `1px solid ${customTheme?.theme_color}`,
                        boxShadow: "none",
                      }}
                    ></div>
                  </>
                )}
                {/* coditional rendring */}
                {item?.RecommendedItems?.length > 0 && (
                  <>
                    {/* <RecommendedItemHeader
                      colorStyle={home_button_bg_color}
                      direction={direction}
                      RecommendedItems={item?.RecommendedItems}
                    /> */}

                    <RecommendedItem />
                    <div
                      className="sectionSpacing"
                      style={{
                        border: `1px solid ${customTheme?.theme_color}`,
                        boxShadow: "none",
                      }}
                    ></div>
                  </>
                )}

                {
                  // restaurentData?.QrCodeGroup?.group_type != "Menu"
                  handleDineInCondition(RestuarantDetails, location) && (
                    <SpecialNotes
                      colorStyle={home_button_bg_color}
                      special_notes={special_notes}
                      direction={direction}
                      inputNone={inputNone}
                    />
                  )
                }
              </FoodModalBtnsWrapper>
            </div>
            <div>
              {/* {restaurentData?.QrCodeGroup?.group_type != "Menu" && ( */}
              {handleDineInCondition(RestuarantDetails, location) && (
                <ViewBasket
                  ViewText={
                    <IntlMessage id="AddItemDrawer.button.addtobasket" />
                  }
                  handleViewAction={
                    checkItemAvailabilityBeforeaddToCart
                    // handleConditionOfAddBasket(
                    //   userAuth,
                    //   RestuarantDetails,
                    //   currentActive?.[0]?.restaurentid
                    // )
                    //   ? openModalForGuestUserv
                    //   : checkItemAvailabilityBeforeaddToCart
                  }
                  identifyFoodModal={true}
                  totalPriceFromItemDrawer={totalPrice}
                />
              )}
            </div>
          </div>
        </SwipeableDrawer>
      ) : (
        <Backdrop
          sx={{
            color: home_button_bg_color,
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </AddItemDrawerParent>
  );
}

export default AddItemDrawer;

const Pricedetails = styled.div`
  margin-left: ${({ dir }) => dir === "ltr" && "20px"};
  margin-right: ${({ dir }) => dir === "rtl" && "20px"};

  p {
    font-family: "Jost";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.02em;

    color: #ffffff;
  }

  span {
    font-family: "Jost";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.02em;

    color: rgba(255, 255, 255, 0.6);
  }
`;

const AddItemDrawerParent = styled.div`
  .PriceHeader {
    display: flex;
    margin-top: 15px;
  }

  .PriceHeading {
    font-family: "Jost";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: #f55a2c;
  }
`;

const PricesWrapper = styled.div`
  margin-left: 20px;

  p {
    font-family: "DM Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: auto;
    letter-spacing: 0.02em;
    margin: 0;
    color: #000;
    text-transform: capitalize;
  }

  span {
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.02em;

    color: #000;
  }
`;

const PriceSection = styled.div`
  background-color: white;
  padding: 6px 0px;

  .Innersection {
    width: 95%;
    margin: 0 auto;
  }

  .PriceHeader {
    display: flex;
  }

  .PriceHeading {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    ${"" /* margin-left: 11px; */}
    color: #fc2b6e;
  }
  .Innermenu {
    padding: 6px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
  }
  .InnerHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const PriceNumberContainer = styled.div`
  display: inline-grid;
  span {
    font-family: "DM Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.02em;

    color: #000;
  }
`;

const ChekboxContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row-reverse;

  .PriceListCheckbox {
    width: 24px;
    height: 24px;
    margin: 0 8px;
    accent-color: #fc2b6e;
  }
`;

const FoodModalBtnsWrapper = styled.div`
  width: 100%;
  height: auto;
  // height: 100%;
  // margin-bottom: 190px;
`;
