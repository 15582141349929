import React from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router";
import styled from "styled-components";
import { aqlutstorage, containerProfile } from "../Utils/ImageStorage";

export default function PublicLayout() {
  const aqlutstorage = process.env.REACT_APP_IMAGE_BASEURL;
  const containerProfile = process.env.REACT_APP_CONTAINER_PROFILE;
  const restaurantDetails = useSelector(
    (state) => state?.restaurentSlice?.restaurentData?.Menu?.Restaurent
  );
  const logo_restaurent =
    aqlutstorage + containerProfile + restaurantDetails?.logo;
  const userlang = useSelector((state) => state.selectLang.direction);
  const customTheme = useSelector((state) => state?.theme?.branding);

  return (
    <PublicLayoutWrapper>
      <div className="left-section">
        <Outlet />
      </div>
      <div className="right-section">
        <section>
          <img src={logo_restaurent} alt="aqlut" />
        </section>
        <span
          style={{
            width: "10%",
            height: "1.5px",
            background: customTheme?.theme_color,
            margin: "0 0 20px 0",
          }}
        ></span>
        <header style={{ fontSize: "22px", color: customTheme?.theme_color }}>
          {userlang === "rtl"
            ? restaurantDetails?.ar_name
            : restaurantDetails?.name}
        </header>
      </div>
    </PublicLayoutWrapper>
  );
}

const PublicLayoutWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;

  .left-section {
    position:relative;
    width: 30%;
    min-width: 320px;
  }
  .right-section {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    section {
      width: 138px;
      height: 138px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    header {
      font-family: "Nunito Sans", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }
  }

  @media (max-width: 1024px) {
    .left-section {
      width: 100%;
    }
    .right-section {
      display: none;
    }
  }
`;
