import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { SnapayGrayBackground } from "../../Utils/Images";
import {
  ActionButton,
  LoaderWapper,
  ThemeButton,
  WhiteButton,
} from "../../style/SnapayGobalStyle";
import { useDispatch, useSelector } from "react-redux";
import { CreditCardIcon } from "../../Utils/Icons";
import Bowser from "bowser";
import ApplePay from "./ApplePay";
import {
  addCommaToNumbers,
  calculateCeiling,
} from "../../Utils/CommanFunctions";
import GooglePay from "./GooglePay";
import IntlMessage from "../../Utils/IntlMessage";
import { CircularProgress } from "@mui/material";
import { updateTip } from "../../store/orderPayment";
// import { initiateSession, registerdomainforApplepay } from "../../services/Collection";

export default function SnapayFooter({ CreditCard, setApplePayLoading }) {
  const customTheme = useSelector((state) => state?.theme?.branding);
  const order = useSelector(
    (state) => state?.orderInformationSlice?.PosSyncedOrder
  );
  let platform = Bowser?.parse(window?.navigator?.userAgent);
  // let platform = {
  //   os: {
  //     name: "iOS",
  //   },
  // };
  const orderTip = useSelector((state) => state?.orderInformationSlice?.tip);
  const orderSplit = useSelector(
    (state) => state?.orderInformationSlice?.splitBill
  );
  const paymentMode = useSelector(
    (state) => state?.orderInformationSlice?.payBillType
  );
  const [dummyLoading, setDummyLoading] = useState(true);
  const getAlreadyScanedID = useSelector((state) => state.StoreForMultiUsers);
  const currentActive =
    getAlreadyScanedID?.getScannedQrcodeAndRestuarent?.slice(-1);

  const userAmount = orderSplit
    ? Number(orderSplit?.total) + Number(orderTip?.tip ?? 0)
    : Number(order?.total) + Number(orderTip?.tip ?? 0);
  const actionButtonSyle = {
    flexDirection: "column",
    padding: 0,
    margin: "24px 0",
  };
  const dispatch = useDispatch();
  const restaurantInfo = useSelector(
    (state) => state?.restaurentSlice?.restaurentData?.Menu?.Restaurent
  );

  const handleRoundUp = (type, amount) => {
    setTimeout(() => {
      if (type) {
        let tipValue = amount - userAmount;
        let obj = {
          isCustomOpen: false,
          percentage: 0,
          tip: tipValue,
          type: 5,
        };
        dispatch(updateTip(obj));
      } else {
        let obj = {
          isCustomOpen: false,
          percentage: 0,
          tip: 0,
          type: 6,
        };
        dispatch(updateTip(obj));
      }
    }, 100);
  };

  const handleRollbackForRoundUpAmount = () => {
    let obj = {
      isCustomOpen: false,
      percentage: 0,
      tip: 0,
      type: 1,
    };

    if (restaurantInfo?.tip_selector) {
      dispatch(updateTip(null));
    } else {
      dispatch(updateTip(obj));
    }
  };

  const HandleApplePay = useCallback(
    ({ setApplePayLoading }) => {
      if (platform?.os?.name === "iOS") {
        return <ApplePay setApplePayLoading={setApplePayLoading} />;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [orderTip, orderSplit, paymentMode]
  );

  const HandleGooglePay = useCallback(() => {
    if (platform?.os?.name === "Android") {
      return <GooglePay />;
    }
  }, [orderTip, orderSplit, paymentMode]);

  useEffect(() => {
    setTimeout(() => {
      setDummyLoading(false);
    }, 2000);
  }, []);

  return (
    <SnapayFooterWrapper
      background={SnapayGrayBackground}
      border={customTheme?.button_primary_color}
      color={customTheme?.button_text_color}
    >
      {dummyLoading && (
        <div className="absoluteLoader">
          <LoaderWapper
            themeColor={customTheme?.theme_color}
            style={{ height: "100%" }}
          >
            <CircularProgress color="inherit" />
          </LoaderWapper>
        </div>
      )}
      <section className="footerHeading">
        <span>
          <IntlMessage id="Snapay.FooterHeading" />
        </span>
        <label>
          <IntlMessage id="Snapay.QAR" /> {addCommaToNumbers(userAmount)}
        </label>
      </section>

      {(orderTip === null || (orderTip?.tip === 0 && orderTip?.type != 6)) && (
        <section className="roundUp-section">
          <span>
            <IntlMessage id="Snapay.RoundUpRequest" />{" "}
            <b>
              <IntlMessage id="Snapay.QAR" /> {calculateCeiling(userAmount)}
            </b>
            , <IntlMessage id="Snapay.RoundUpDifference" />
          </span>
          <div className="confirm-buttons">
            <button className="btn-no" onClick={() => handleRoundUp(false)}>
              <b>
                {" "}
                <IntlMessage id="Snapay.RoundUpButtonNo" />
              </b>
            </button>
            <button
              className="btn-yes"
              onClick={() => handleRoundUp(true, calculateCeiling(userAmount))}
            >
              <b>
                <IntlMessage id="Snapay.RoundUpButtonYes" />
              </b>
            </button>
          </div>
        </section>
      )}

      {(orderTip?.type == 5 || orderTip?.tip > 0) && (
        <section className="review-text-box">
          <span className="review-text">
            <IntlMessage id="Snapay.RoundUpSuccess" />
          </span>
        </section>
      )}

      {orderTip?.type == 6 && (
        <section className="review-text-box">
          <span className="review-text">
            <IntlMessage id="Snapay.RoundUpFail" />
          </span>
        </section>
      )}

      <ActionButton background={SnapayGrayBackground} style={actionButtonSyle}>
        <HandleApplePay setApplePayLoading={setApplePayLoading} />
        <HandleGooglePay />
        <WhiteButton
          button_text_color={customTheme?.button_text_color}
          button_primary_color={customTheme?.button_primary_color}
          button_secondary_color={customTheme?.button_secondary_color}
          onClick={() => CreditCard(true)}
        >
          <CreditCardIcon color={customTheme?.button_primary_color} />
          <IntlMessage id="Snapay.CardBtn" />
        </WhiteButton>

        {(Number(orderTip?.type) === 6 || Number(orderTip?.type) === 5) && (
          <p className="back-button" onClick={handleRollbackForRoundUpAmount}>
            <IntlMessage id="Snapay.RoundUpButtonBack" />
          </p>
        )}
      </ActionButton>
    </SnapayFooterWrapper>
  );
}

const SnapayFooterWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: 22px 18px;
  gap: 10px;
  border-radius: 8px;
  background: ${({ background }) => `url(${background})`};
  background-size: contain;
  position: relative;

  .absoluteLoader {
    position: absolute;
    inset: 0;
    background: #fcfdfd;
  }
  .footerHeading {
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: #171717;
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.42px;
    span {
      font-weight: 900;
    }
    label {
      font-weight: 900;
    }
  }

  .roundUp-section {
    width: 100%;
    color: #171717;
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    margin: 16px 0px 0px 0px;
    letter-spacing: -0.42px;
    span {
      font-weight: 500;
    }
    label {
      font-weight: 900;
    }
    .confirm-buttons {
      width: 100%;
      display: flex;
      justify-content: center;
      gap: 10px;
      margin-top: 16px;

      .btn-yes {
        padding: 10px 20px;
        border: ${({ border }) => `1px solid ${border}`};
        color: ${({ border }) => border};
        background: rgb(255, 255, 255);
        border-radius: 5px;
        width: 100%;

        &:hover {
          color: ${({ color }) => color};
          background: ${({ border }) => border};
        }
      }
      .btn-no {
        padding: 10px 20px;
        border: ${({ border }) => `1px solid ${border}`};
        color: ${({ border }) => border};
        background: rgb(255, 255, 255);
        border-radius: 5px;
        width: 100%;
        &:hover {
          color: ${({ color }) => color};
          background: ${({ border }) => border};
        }
      }
    }
  }

  .review-text-box {
    margin-top: 16px;

    .review-text {
      color: ${({ border }) => border};
      font-family: DM Sans;
      font-size: 14px;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.42px;
    }
  }
`;
