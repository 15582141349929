import React, { useState } from "react";
import "./Tip.scss";
import {
  SadTip0,
  SmileTip1,
  SmileTip10,
  SmileTip100,
  SmileTip25,
  SmileTip50,
  customTip,
} from "../../Utils/Images";
import IntlMessage from "../../Utils/IntlMessage";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { updateTip } from "../../store/orderPayment";

function Tip({ sendValues }) {
  const [buttonValue, setbuttonValue] = useState({
    value: 0,
    active: "button1",
  });
  const customTheme = useSelector((state) => state?.theme?.branding);
  const dispatch = useDispatch();

  const getTipValues = () => {
    sendValues(buttonValue);
  };

  if (buttonValue.value >= 0) getTipValues();

  return (
    <TipWrapper
      style={{ background: customTheme?.background_color }}
      themeColor={customTheme?.theme_color}
    >
      <h1 style={{ color: customTheme?.theme_color }}>
        <IntlMessage id="payment.partialamount.tip" />
      </h1>

      <div className="TipsContainer">
        <button
          onClick={(e) => {
            setbuttonValue({ value: 0, active: "button1" });
            dispatch(updateTip({ tip: Number(0) }));
          }}
          className={
            "selectTip " + (buttonValue.active === "button1" ? "active" : "")
          }
        >
          <img src={SadTip0} alt="" />
          <span>0</span>
        </button>

        <button
          onClick={(e) => {
            setbuttonValue({ value: 10, active: "button2" });
            dispatch(updateTip({ tip: Number(10) }));
          }}
          className={
            "selectTip " + (buttonValue.active === "button2" ? "active" : "")
          }
        >
          <img src={SmileTip10} alt="" />
          <span>10</span>
        </button>

        <button
          onClick={(e) => {
            setbuttonValue({ value: 25, active: "button3" });
            dispatch(updateTip({ tip: Number(25) }));
          }}
          className={
            "selectTip " + (buttonValue.active === "button3" ? "active" : "")
          }
        >
          <img src={SmileTip25} alt="" />
          <span>25</span>
        </button>

        <button
          onClick={(e) => {
            setbuttonValue({ value: 50, active: "button4" });
            dispatch(updateTip({ tip: Number(50) }));
          }}
          className={
            "selectTip " + (buttonValue.active === "button4" ? "active" : "")
          }
        >
          <img src={SmileTip50} alt="" />
          <span>50</span>
        </button>

        <button
          onClick={(e) => {
            setbuttonValue({ value: 100, active: "button5" });
            dispatch(updateTip({ tip: Number(100) }));
          }}
          className={
            "selectTip " + (buttonValue.active === "button5" ? "active" : "")
          }
        >
          <img src={SmileTip100} alt="" />
          <span>100</span>
        </button>

        <button
          onClick={(e) => {
            setbuttonValue({ value: "", active: "button6" });
          }}
          className={
            "selectTip " + (buttonValue.active === "button6" ? "active" : "")
          }
        >
          <img src={customTip} alt="" />
          <span>
            <IntlMessage id="button.custom.tip" />
          </span>
        </button>
      </div>

      {buttonValue.active === "button6" && (
        <div className="totalAmount">
          <input
            type="number"
            value={setbuttonValue.value}
            placeholder={"Tip"}
            onChange={(e) => {
              setbuttonValue({ value: e.target.value, active: "button6" });
              dispatch(updateTip({ tip: Number(e.target.value) }));
            }}
          />
        </div>
      )}
    </TipWrapper>
  );
}

export default Tip;

const TipWrapper = styled.div`
  background: #f8f8fb;
  border-radius: 16px;
  padding: 8px;
  margin-top: 7px;
  h1 {
    font-family: "Jost";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.05em;
    color: #fc2b6e;
    margin-bottom: 10px;
  }

  .selectTip {
    display: flex;
    align-items: center;
    background: #fcfdfe;
    border: 0.960575px solid #f0f1f7;
    border-radius: 5.14793px;
    padding: 10px;
    gap: 13px;
    justify-content: center;
    span {
      font-family: "Jost";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 16px;
      // padding-left: 7px;
      color: #000000;
    }
  }
  .active {
    background: ${({ themeColor }) => `${themeColor}60`} !important;
    border: ${({ themeColor }) => `0.960575px solid ${themeColor}`} !important;
  }

  .totalAmount {
    position: relative;
    margin-top: 5px;
    input {
      width: 100%;
      background: #fcfdfe;
      border: 1px solid #f0f1f7;
      border-radius: 8px;
      padding: 11px;
      outline: none;
    }
    img {
      position: absolute;
      right: 3%;
      top: 35%;
    }
  }

  .TipsContainer {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;

    img {
      width: 28px;
      height: 28px;
    }

    @media only screen and (min-width: 1024px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media only screen and (max-width: 580px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media only screen and (max-width: 400px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
`;
