// eslint-disable-next-line no-unused-vars
import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import IntlMessage from "../../Utils/IntlMessage";

function SelectedItem({ data }) {
  const direction = useSelector((state) => state.selectLang.direction);
  const customTheme = useSelector((state) => state?.theme?.branding);

  return (
    <SelectedItemStyle dir={direction}>
      {data &&
        data.map(
          (item, i) =>
            item &&
            item.OrderItems.map((order, i) => (
              <>
                <div key={i} className="selected-item">
                  <h6 key={i + 1} style={{color: customTheme?.header_color}} >
                    {direction == "ltr"
                      ? order?.item_name
                      : order?.ar_item_name}
                  </h6>
                  <label key={i + 2} style={{color: customTheme?.text_color}} >
                    {order?.price && "QAR" + " " + order?.price} <br />
                    {order?.payment_status == "2" && (
                      <label
                        style={{
                          color: "green",
                          fontFamily: "Poppins",
                          fontWeight: 600,
                        }}
                      >
                        Paid
                      </label>
                    )}
                  </label>
                </div>
                <div key={i + 3} className="quantity">
                  <h6 key={i + 4} style={{color: customTheme?.text_color}} >
                    {order?.quantity && "QTY" + ":" + order?.quantity}
                  </h6>
                </div>


                <SpecialNotesWrapper>
                  <h6 style={{color: `${customTheme?.text_color}60`}} >
                    <IntlMessage id="AddItemDrawer.specialnotes" /> :
                  </h6>
                  <label style={{color: customTheme?.text_color}} >{order?.special_notes}</label>
                </SpecialNotesWrapper>

                {order?.OrderItemAddOns?.length > 0 && (
                  <div key={i + 5} className="add-on">
                    <h6 key={i + 6} style={{color: customTheme?.text_color}}>
                      <IntlMessage id="AddItemDrawer.addon" />
                      {order?.OrderItemAddOns &&
                        order?.OrderItemAddOns.map((addOn, i) => (
                          <div className="addOnDec">
                            <div>
                              <span key={i}>
                                {direction == "ltr"
                                  ? addOn?.option_group_name
                                  : addOn?.ar_option_group_name}
                              </span>
                              <span key={i} style={{ margin: "0px 5px" }}>
                                (
                                {direction == "ltr"
                                  ? addOn?.option_item_name
                                  : addOn?.ar_option_item_name}
                                )
                              </span>
                            </div>
                            <label key={i + 2}>
                              {addOn?.price && "QAR" + " " + addOn?.price}
                            </label>
                          </div>
                        ))}
                    </h6>
                  </div>
                )}
              </>
            ))
        )}

      {data[0]?.discount > 0 && (
        <div
          className="add-on"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h6>Discont</h6>
          <label style={{ color: "rgba(0,0,0,0.6)", fontSize: "14px" }}>
            {data[0]?.discount}
          </label>
        </div>
      )}
    </SelectedItemStyle>
  );
}

export default SelectedItem;

const SpecialNotesWrapper = styled.div`
  font-family: "Poppins";
  h6 {
    color: #2b2b2b;
  }
  label {
    color: red;
    fontsize: 14px;
  }
`;

const SelectedItemStyle = styled.div`
  .selected-item {
    display: flex;
    align-items: center;

    h6 {
      font-family: "Jost";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
      letter-spacing: 0.02em;
      width: 50%;
      color: #000000;
      padding: 5px 0px;
    }
    label {
      font-family: "Jost";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      letter-spacing: 0.02em;
      width: 50%;
      text-align: ${({ dir }) => dir === "ltr" && "right"};
      text-align: ${({ dir }) => dir === "rtl" && "left"};
      color: rgba(0, 0, 0, 0.6);
    }
  }
  .quantity {
    h6 {
      font-family: "Jost";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      letter-spacing: 0.02em;

      color: rgba(0, 0, 0, 0.6);
    }
  }

  .add-on {
    border-bottom: 0.25px solid #a2a2a2;
    padding-bottom: 12px;
    margin-bottom: 12px;
    h6 {
      font-family: "Jost";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 17px;
      letter-spacing: 0.02em;
      color: #2b2b2b;
      padding: 5px 0px;
    }
    .addOnDec {
      justify-content: space-between;
      display: flex;
      label {
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }

  /*.add-on:last-child{
    border: none;
}*/
`;
