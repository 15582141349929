import { createSlice } from "@reduxjs/toolkit";

export const MultiUserSlice = createSlice({
  name: "StoreForMultiUsers",
  initialState: {
    workStation: [],
    getScannedQrcodeAndRestuarent: [],
    waitingForResturantId: true,
    welcomeSplashCount:0
  },

  reducers: {
    setQrCodeID: (state, action) => {
      state.workStation = action.payload;
    },
    setQrCodeIDAndRestuarentID: (state, action) => {
      if (action.payload) {
        state.getScannedQrcodeAndRestuarent = action.payload;
      }
    },

    LoaderForRestuarentID: (state, action) => {
      if (action) {
        state.waitingForResturantId = action.payload;
      }
    },



  },
});

export const { setQrCodeID, setQrCodeIDAndRestuarentID, LoaderForRestuarentID} = MultiUserSlice.actions;
export default MultiUserSlice.reducer;
