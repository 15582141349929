import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { BillDetailStyle } from "./BillDetail";
import { getOrderDetails } from "../../services/Collection";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import IntlMessage from "../../Utils/IntlMessage";
import { useIntl } from "react-intl";

function BillDetail({ type, ParticalAmount }) {
  const Direction = useSelector((state) => state?.selectLang?.direction);
  const { orderDeails } = useSelector(({ orderInformationSlice }) => ({
    ...orderInformationSlice,
  }));

  const { restaurentData } = useSelector(({ restaurentSlice }) => ({
    ...restaurentSlice,
  }));
  const styling = restaurentData?.Menu?.Restaurent?.MenuPageBranding;
  const customTheme = useSelector((state) => state?.theme?.branding);

  //style
  const home_price_text_color = styling?.home_price_text_color;
  const home_webpage_bg_color = styling?.home_webpage_bg_color;
  const [isLoaded, setLoaded] = useState(true);
  const [orderdedItem, setOrderdedItem] = useState([]);
  const intl = useIntl();

  const getOrderDetailsData = async () => {
    setLoaded(true);
    let res = await getOrderDetails(orderDeails.id);
    if (res.status === 200) {
      setOrderdedItem(res.data);
      setLoaded(false);
    } else {
      toast.error(res.message, { theme: "colored" });
      setLoaded(false);
    }
  };

  const {
    total,
    service_tax,
    discount,
    sub_total,
    vat,
    partial_payment_amount,
    specific_payment_amount,
  } = orderdedItem;

  function AmountWithDecimalValue(x) {
    let n = Number(x).toFixed(2);
    if (n) {
      let res = n.split(".");
      if (res) {
        if (Number(res[1]) > 0) {
          return n;
        } else {
          return res[0];
        }
      }
    }
  }

  const totalPayable = useMemo(() => {
    if (orderdedItem?.total) {
      const t = orderdedItem?.total;
      if (orderdedItem?.specific_payment_amount) {
        return t - orderdedItem?.specific_payment_amount;
      }
      if (orderdedItem?.partial_payment_amount) {
        return t - orderdedItem?.partial_payment_amount;
      }
      return t;
    }

    return 0;
  }, [orderdedItem]);
  const tax_charges = service_tax + vat;

  const sendValues = (e) => {
    e.target.addEventListener(
      "wheel",
      function (e) {
        e.preventDefault();
      },
      { passive: false }
    );
    ParticalAmount(e);
  };

  useEffect(() => {
    getOrderDetailsData();
  }, []);

  return (
    <>
      {orderdedItem && (
        <BillDetailStyle dir={Direction}>
          {isLoaded ? (
            <div
              style={{
                width: "100%",
                height: "150px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress sx={{ color: customTheme?.theme_color }} />
            </div>
          ) : (
            <div
              style={{ background: `${customTheme?.theme_color}20` }}
              className="bill-details"
            >
              <h1 style={{ color: customTheme?.theme_color }} >
                <IntlMessage id="cart.billdetails.title" />
              </h1>
              <div className="charges">
                <h2 style={{ color: customTheme?.header_color }}>
                  <IntlMessage id="cart.itemtotal" /> :{" "}
                </h2>
                <h4 style={{ color: customTheme?.text_color }}>
                  QAR {sub_total ? AmountWithDecimalValue(sub_total) : 0}
                </h4>
              </div>

              {type === "Full" && (
                <>
                  {specific_payment_amount > 0 && (
                    <div className="charges">
                      <h2 style={{ color: customTheme?.header_color }}>
                        <IntlMessage id="cart.specificPayment" /> :{" "}
                      </h2>
                      <h4 style={{ color: customTheme?.text_color }}>
                        QAR{" "}
                        {specific_payment_amount
                          ? AmountWithDecimalValue(specific_payment_amount)
                          : 0}
                      </h4>
                    </div>
                  )}

                  {partial_payment_amount > 0 && (
                    <div className="charges">
                      <h2 style={{ color: customTheme?.header_color }}>
                        <IntlMessage id="cart.partialPayment" /> :{" "}
                      </h2>
                      <h4 style={{ color: customTheme?.text_color }}>
                        QAR{" "}
                        {partial_payment_amount
                          ? AmountWithDecimalValue(partial_payment_amount)
                          : 0}
                      </h4>
                    </div>
                  )}
                </>
              )}

              {discount !== 0 && discount !== null && (
                <div className="charges">
                  <h2 style={{ color: customTheme?.header_color }}>
                    <IntlMessage id="cart.discount" /> :{" "}
                  </h2>
                  <h4 style={{ color: customTheme?.text_color }}>
                    - QAR {discount ? AmountWithDecimalValue(discount) : 0}
                  </h4>
                </div>
              )}

              {type === "Full" || type === "SpecificDish" ? (
                orderdedItem?.order_status !== "6" &&
                orderdedItem?.order_status !== "5" && (
                  <>
                    <div className="charges">
                      <h2 style={{ color: customTheme?.header_color }}>
                        <IntlMessage id="cart.totalpayable" /> :{" "}
                      </h2>
                      <h4 style={{ color: customTheme?.text_color }}>
                        QAR{" "}
                        {AmountWithDecimalValue(
                          Number(orderdedItem?.sub_total) -
                            (Number(orderdedItem?.specific_payment_amount) +
                              Number(orderdedItem?.partial_payment_amount) +
                              Number(orderdedItem?.discount))
                        ) || 0}
                      </h4>
                    </div>
                  </>
                )
              ) : type === "OrderDetail" ? (
                <>
                  <div className="charges">
                    <h2 style={{ color: customTheme?.header_color }}>
                      <IntlMessage id="cart.totalpayable" /> :{" "}
                    </h2>
                    <h4 style={{ color: customTheme?.text_color }}>
                      QAR {totalPayable ?? 0}
                    </h4>
                  </div>
                </>
              ) : (
                type === "Partial" && (
                  <>
                    <div className="charges">
                      <h2
                       style={{ color: customTheme?.header_color }}
                        className="bold"
                      >
                        <IntlMessage id="cart.totalpayable" /> :{" "}
                      </h2>
                      <h4
                        style={{ color: customTheme?.text_color }}
                        className="bold"
                      >
                        QAR{" "}
                        {AmountWithDecimalValue(
                          Number(orderdedItem?.sub_total) -
                            (Number(orderdedItem?.specific_payment_amount) +
                              Number(orderdedItem?.partial_payment_amount) +
                              Number(orderdedItem?.discount))
                        ) || 0}
                      </h4>
                    </div>

                    {orderdedItem?.order_status !== "6" &&
                      orderdedItem?.order_status !== "5" && (
                        <>
                          <div className="charges">
                            <h2 style={{ color: customTheme?.header_color }}>
                              <IntlMessage id="payment.partialamount.paidamount" />{" "}
                              :{" "}
                            </h2>
                            <h4 style={{ color: customTheme?.text_color }}>
                              QAR{" "}
                              {AmountWithDecimalValue(partial_payment_amount)}
                            </h4>
                          </div>
                          <div className="charges">
                            <h2 style={{ color: customTheme?.header_color }}>
                              <IntlMessage id="payment.partialamount.pendingamount" />{" "}
                              :{" "}
                            </h2>
                            <h4 style={{ color: customTheme?.text_color }}>
                              {/* QAR {AmountWithDecimalValue(Number(total) - Number(partial_payment_amount))} */}
                              QAR{" "}
                              {AmountWithDecimalValue(
                                Number(sub_total) -
                                  (Number(
                                    orderdedItem?.specific_payment_amount
                                  ) +
                                    Number(
                                      orderdedItem?.partial_payment_amount
                                    ) +
                                    Number(orderdedItem?.discount))
                              ) || 0}
                            </h4>
                          </div>
                        </>
                      )}

                    {orderdedItem?.order_status !== "6" &&
                      orderdedItem?.order_status !== "5" && (
                        <>
                          <h2 style={{ color: customTheme?.header_color }}>
                            <IntlMessage id="payment.partialamount.pleaseenterammount.title" />
                          </h2>
                          <div className="paymentAmount">
                            <input
                              type="number"
                              placeholder={intl.formatMessage({
                                id: "payment.pay.method.partialamount",
                              })}
                              onChange={(e) => sendValues(e)}
                            />
                            <h3 style={{ color: customTheme?.text_color }}>
                              / QAR{" "}
                              {AmountWithDecimalValue(
                                Number(orderdedItem?.sub_total) -
                                  (Number(
                                    orderdedItem?.specific_payment_amount
                                  ) +
                                    Number(
                                      orderdedItem?.partial_payment_amount
                                    ) +
                                    Number(orderdedItem?.discount))
                              )}
                            </h3>
                          </div>
                        </>
                      )}
                  </>
                )
              )}
            </div>
          )}
        </BillDetailStyle>
      )}
    </>
  );
}

export default BillDetail;
