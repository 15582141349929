import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
const style = {
  // background: "red",
  display: "none",
};
function ChatOption({ img, handleChatOption, name, icon, isPaid }) {
  const customTheme = useSelector((state) => state?.theme?.branding);

  return (
    <ChatOptionButton
      disabled={isPaid}
      style={isPaid ? style : null}
      onClick={handleChatOption}
      buttonPrimary={customTheme?.button_primary_color}
      buttonSecondary={customTheme?.button_secondary_color}
    >
      {icon ? icon : <img src={img} alt="img" />}
      <h6 style={{color: customTheme?.text_color}} >{name}</h6>
    </ChatOptionButton>
  );
}
function FullPayment({ img, handleChatOption, name, icon }) {
  const customTheme = useSelector((state) => state?.theme?.branding);

  return (
    <ChatOptionButton
      onClick={handleChatOption}
      buttonPrimary={customTheme?.button_primary_color}
      buttonSecondary={customTheme?.button_secondary_color}
    >
      {icon ? icon : <img src={img} alt="img" />}
      <h6 style={{color: customTheme?.text_color}} >{name}</h6>
    </ChatOptionButton>
  );
}
function SpecificPayment({
  img,
  handleChatOption,
  name,
  icon,
  isSpecificDisabled,
}) {
  const customTheme = useSelector((state) => state?.theme?.branding);

  return (
    <ChatOptionButton
      disabled={isSpecificDisabled}
      style={isSpecificDisabled ? style : null}
      onClick={handleChatOption}
      buttonPrimary={customTheme?.button_primary_color}
      buttonSecondary={customTheme?.button_secondary_color}
    >
      {icon ? icon : <img src={img} alt="img" />}
      <h6 style={{color: customTheme?.text_color}} >{name}</h6>
    </ChatOptionButton>
  );
}
function PartitalPayment({
  img,
  handleChatOption,
  name,
  icon,
  isPartialDisabled,
}) {
  const customTheme = useSelector((state) => state?.theme?.branding);

  return (
    <ChatOptionButton
      disabled={isPartialDisabled}
      style={isPartialDisabled ? style : null}
      onClick={handleChatOption}
      buttonPrimary={customTheme?.button_primary_color}
      buttonSecondary={customTheme?.button_secondary_color}
    >
      {icon ? icon : <img src={img} alt="img" />}
      <h6 style={{color: customTheme?.text_color}} >{name}</h6>
    </ChatOptionButton>
  );
}

function CallWaiter({ img, handleWaiterCall, name, isWaiterCalled, icon }) {
  return (
    <ChatOptionButton
      disabled={isWaiterCalled}
      style={isWaiterCalled ? style : null}
      onClick={handleWaiterCall}
    >
      {icon ? icon : <img src={img} alt="img" />}
      <h6>{name}</h6>
    </ChatOptionButton>
  );
}
export { CallWaiter, FullPayment, PartitalPayment, SpecificPayment };
export default ChatOption;

const ChatOptionButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 70%;
  border: none;
  max-width: 500px;
  outline: none;
  background: ${({ buttonPrimary }) => `${buttonPrimary}20`};
  border-radius: 5.8481px;
  padding: 12px;
  margin: 10px 20px;

  .icon {
    color: ${({ buttonSecondary }) => buttonSecondary};
  }

  h6 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.05em;
    color: #363636;
  }
  &:hover {
    background: ${({ buttonSecondary }) => buttonSecondary};
    h6 {
      color: white;
    }
    svg path {
      fill: #fff;
    }
  }
`;
