/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useLocation, useNavigate } from "react-router-dom";
import "../../style/Header.scss";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useEffect, useState } from "react";
import {
  getUnifiedUserInfo,
  getUpdateOrderDetails,
} from "../../services/Collection";
import { addOrderDetails } from "../../store/orderPayment";
import { GoBackIcon } from "../../Utils/Images";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import {
  loginWithAuraGetCustomerInfo,
  unifiedOrderType,
} from "../../store/UnifiedUser/UnifiedUser";
import MultipleOrderModal from "../../pages/UnifiedMultipleOrders/MultipleOrderModal";
import IntlMessage from "../../Utils/IntlMessage";
import { langSuccess } from "../../store/selectlang";
import {
  isTakeawayDevliveryOrderType,
  swicthingMode,
} from "../../store/DeliveryModule/DeliverySlice";
import { authSucess } from "../../store/userLoginReducer";
import { ArrowIcon, Humburger } from "../../Utils/Icons";
import Sidenav from "../Sidenav/Sidenav";

const Header = ({ redirect, showBackArrow, setSearchData }) => {
  const aqlutstorage = process.env.REACT_APP_IMAGE_BASEURL;
  const containerProfile = process.env.REACT_APP_CONTAINER_PROFILE;

  const userlang = useSelector((state) => state.selectLang.direction);

  const [UnifiedUserOrderType, setUnifiedUserOrderType] = useState(null);
  const selectedType = useSelector((state) => state?.deliveryModule);

  const handleUnifiedOrderType = () => {
    dispatch(unifiedOrderType(UnifiedUserOrderType));
  };

  const getUnifiedOrderType = useSelector(
    (state) => state?.isUnifiedSlice?.orderType
  );

  const { restaurentData } = useSelector(
    ({ notificationsResult, restaurentSlice, orderDeails }) => ({
      ...notificationsResult,
      ...restaurentSlice,
      ...orderDeails,
    })
  );
  const isOrder = useSelector(
    (state) => state?.orderInformationSlice?.orderDeails
  );

  const IsUnifiedUser = useSelector((state) => state?.isUnifiedSlice);
  const getAlreadyScanedID = useSelector((state) => state.StoreForMultiUsers);
  const currentActive =
    getAlreadyScanedID?.getScannedQrcodeAndRestuarent?.slice(-1);

  const isDeliverable = useSelector(
    (state) =>
      state?.restaurentSlice?.restaurentData?.Menu?.Restaurent?.is_deliverable
  );
  const customTheme = useSelector((state) => state?.theme?.branding);

  const dispatch = useDispatch();
  const location = useLocation();
  const name = restaurentData?.name;
  const group_name = restaurentData?.QrCodeGroup?.group_name;
  const group_type = restaurentData?.QrCodeGroup?.group_type;
  const restaurent_data = restaurentData?.Menu?.Restaurent;

  //style
  const restaurent_logo = restaurent_data?.logo;
  const restaurent_name = restaurent_data?.name;
  const restaurent_ar_name = restaurent_data?.ar_name;

  const getInitialLang = useSelector((state) =>
    state.selectLang?.lang == "ar" ? "arb" : "eng"
  );

  const [lang, SelectLang] = useState(getInitialLang);

  let logo_restaurent = aqlutstorage + containerProfile + restaurent_logo;
  const path = window.location.pathname;

  const [multipleOrder, setMultipleOrder] = useState(false);
  const navigate = useNavigate();
  const backToMenu = () => {
    navigate(`/${currentActive?.[0]?.qrcodeid}/addfoodlist`);
  };

  const [loading, setLoading] = useState(false);
  const [loyaltyPoint, setLoyaltyPoint] = useState(null);
  const [loyaltyPointLoader, setLoyaltyPointLoader] = useState(false);
  const [openSidenav, setOpenSidenav] = useState(false);

  const updateOrderDetails = async () => {
    setLoading(true);
    let payload = {
      order_id: isOrder?.id || null,
      qr_code_id: currentActive?.[0]?.qrcodeid,
    };
    let res = await getUpdateOrderDetails(payload);
    if (res.status === 200) {
      if (res?.data?.order_type === "Dine In") {
        const total =
          res?.data?.total -
          (res?.data?.partial_payment_amount +
            res?.data?.specific_payment_amount);
        dispatch(addOrderDetails({ ...res?.data, total: total }));
      } else {
        dispatch(addOrderDetails(res?.data));
      }
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const getUnifiedPoints = async () => {
    let res = await getUnifiedUserInfo();
    if (res.status === 200) {
      setLoyaltyPoint(res?.data?.data);
      dispatch(loginWithAuraGetCustomerInfo(res?.data?.data));
      // setLoyaltyPointLoader(false);
    } else {
      toast.error(res.message, { theme: "colored" });
      // setLoyaltyPointLoader(false);
    }
  };

  const handleOrderTypeToggle = (selectedPayload) => {
    dispatch(swicthingMode(selectedPayload === "takeaway" ? false : true));
    dispatch(isTakeawayDevliveryOrderType(selectedPayload));
  };

  const handleIsTakeawayDeliveryShow = () => {
    let isTakeawayEnable = restaurentData?.Menu?.Restaurent?.enable_takeaway;
    let isDeliveryEnable = restaurentData?.Menu?.Restaurent?.enable_delivery;

    if (restaurentData?.QrCodeGroup?.group_type === "Takeaway") {
      if (isTakeawayEnable && isDeliveryEnable) {
        return true;
      } else {
        if (isTakeawayEnable && !isDeliveryEnable) {
          return false;
        } else if (isDeliveryEnable && !isTakeawayEnable) {
          return false;
        } else {
          dispatch(authSucess([]));
        }
      }
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (lang === "eng") {
      let obj = {
        lang: "en",
        direction: "ltr",
      };
      dispatch(langSuccess(obj));
    } else if (lang === "arb") {
      let obj = {
        lang: "ar",
        direction: "rtl",
      };
      dispatch(langSuccess(obj));
    }
  }, [lang]);


  useEffect(() => {
    if (isDeliverable && getUnifiedOrderType != "Takeaway") {
      dispatch(unifiedOrderType("Delivery"));
    } else {
      dispatch(unifiedOrderType("Takeaway"));
    }
  }, [isDeliverable]);

  useEffect(() => {
    if (IsUnifiedUser?.is_aura_user) {
      getUnifiedPoints();
    } else if (!location?.pathname.includes("guest-menu")) {
      updateOrderDetails();
    }
  }, []);

  useEffect(() => {
    path === `/${currentActive?.[0]?.qrcodeid}/addfoodlist` &&
      handleUnifiedOrderType();
  }, [UnifiedUserOrderType]);

  return (
    <div>
      <HeaderWrapper
        dir={userlang}
        style={{ background: customTheme?.theme_color }}
        isDineQRCode={group_type === "Dine In" ? true : false}
      >
        {path !== `/${currentActive?.[0]?.qrcodeid}/addfoodlist` &&
          path !== `/${currentActive?.[0]?.qrcodeid}/pre-payment` &&
          path !== `/` &&
          path !== `/${currentActive?.[0]?.qrcodeid}/payment-success` &&
          path !== `/${currentActive?.[0]?.qrcodeid}/payment-failed` &&
          path !==
            `/${currentActive?.[0]?.qrcodeid}-${currentActive?.[0]?.restaurentid}/menu` && (
            <img
              className="GoBack"
              src={GoBackIcon}
              alt="goback"
              onClick={backToMenu}
            />
          )}
        {multipleOrder && (
          <MultipleOrderModal
            open={multipleOrder}
            handleClose={() => setMultipleOrder(false)}
          />
        )}
        {openSidenav && <Sidenav open={openSidenav} onClose={()=> setOpenSidenav(false)} applogo={restaurent_logo} />}
        <span onClick={()=> setOpenSidenav(!openSidenav)} >
          <Humburger color={customTheme?.button_text_color}/>
        </span>
        <div className="HeaderLogoAndName">
          <div>
            <section>
              <img src={logo_restaurent} alt="Logo" />
              <div>
                <header style={{ color: customTheme?.button_text_color }}>
                  {userlang === "rtl" ? restaurent_ar_name : restaurent_name}
                </header>

                {IsUnifiedUser?.is_aura_user ? (
                  <>
                    {path ===
                      `/${currentActive?.[0]?.qrcodeid}/addfoodlist` && (
                      <select
                        className="UnifiedOrderMode"
                        onChange={(e) =>
                          setUnifiedUserOrderType(e.target.value)
                        }
                      >
                        {isDeliverable && (
                          <option
                            selected={getUnifiedOrderType == "Delivery" && true}
                            value="Delivery"
                          >
                            <IntlMessage id="unified.Option.Delivery" />
                          </option>
                        )}
                        <option
                          selected={getUnifiedOrderType == "Takeaway" && true}
                          value="Takeaway"
                        >
                          <IntlMessage id="unified.Option.Takeaway" />
                        </option>
                      </select>
                    )}
                  </>
                ) : (
                  <p style={{ color: customTheme?.button_text_color }}>
                    {group_type} {/* - {group_name}  */}- {name}
                  </p>
                )}
              </div>
            </section>
          </div>
          {(path == `/${currentActive?.[0]?.qrcodeid}/addfoodlist` ||
            path == `/` ||
            path ==
              `/${currentActive?.[0]?.qrcodeid}-${currentActive?.[0]?.restaurentid}/menu`) && (
            <div className="selectLangDrpDown">
              <div className="select-wrapper">
                <select
                  className="language-select"
                  style={{
                    color: customTheme?.button_text_color,
                    background: `linear-gradient(91.33deg,${customTheme?.button_primary_color} 0%,${customTheme?.button_secondary_color} 100%)`,
                  }}
                  onChange={(e) => SelectLang(e.target.value || null)}
                  value={lang}
                >
                  <option
                    // selected={getUnifiedOrderType == "Delivery" && true}
                    value="eng"
                  >
                    {/* <IntlMessage id="unified.Option.Delivery" /> */}
                    En
                  </option>

                  <option
                    // selected={getUnifiedOrderType == "Takeaway" && true}
                    value="arb"
                  >
                    {/* <IntlMessage id="unified.Option.Takeaway" /> */}
                    Ar
                  </option>
                </select>
                <ArrowIcon width={9} height={11} color={"transparent"} />
              </div>

              {handleIsTakeawayDeliveryShow() && (
                <div className="select-wrapper">
                  <select
                    className="selectforqrtype"
                    style={{
                      color: customTheme?.button_text_color,
                      background: `linear-gradient(91.33deg,${customTheme?.button_primary_color} 0%,${customTheme?.button_secondary_color} 100%)`,
                    }}
                    onChange={(e) => handleOrderTypeToggle(e.target.value)}
                    value={selectedType?.orderType}
                  >
                    <option value="takeaway">Takeaway</option>
                    <option value="delivery">Delivery</option>
                  </select>
                  <ArrowIcon width={9} height={11} color={"transparent"} />
                </div>
              )}
            </div>
          )}

          {IsUnifiedUser?.is_aura_user &&
            path === `/${currentActive?.[0]?.qrcodeid}/addfoodlist` && (
              <section>
                {loyaltyPointLoader ? (
                  <CircularProgress sx={{ color: "#fc2b6e" }} />
                ) : (
                  <span className="LoyaltyPoint">
                    {loyaltyPoint?.totalPoints}{" "}
                    <IntlMessage id="unified.loyality.totalPoints" />
                  </span>
                )}
              </section>
            )}
        </div>

        {path !== `/${currentActive?.[0]?.qrcodeid}/cartwithdiscount` &&
          path !== `/${currentActive?.[0]?.qrcodeid}/cartwithoutdiscount` &&
          path !== `/${currentActive?.[0]?.qrcodeid}/cart` &&
          path !== `/${currentActive?.[0]?.qrcodeid}/cart-address` &&
          path !== `/${currentActive?.[0]?.qrcodeid}/select-location` &&
          path !== `/${currentActive?.[0]?.qrcodeid}/unified-payment` &&
          path !== `/${currentActive?.[0]?.qrcodeid}/chatoption` &&
          path !== `/${currentActive?.[0]?.qrcodeid}/payment` &&
          path !== `/${currentActive?.[0]?.qrcodeid}/pre-payment` &&
          path !== `/${currentActive?.[0]?.qrcodeid}/select-payment-method` &&
          path !== `/${currentActive?.[0]?.qrcodeid}/payment-card-info` &&
          path !== `/${currentActive?.[0]?.qrcodeid}/payment-card-scan-front` &&
          path !== `/${currentActive?.[0]?.qrcodeid}/payment-success` &&
          path !== `/${currentActive?.[0]?.qrcodeid}/payment-failed` &&
          path !== `/${currentActive?.[0]?.qrcodeid}/feedback` &&
          path !==
            `/${currentActive?.[0]?.qrcodeid}/pre-payment-order-details` &&
          path !== `/${currentActive?.[0]?.qrcodeid}/user-feedback` && (
            // <div className="HeaderSearchBarAndMessageIcon">
            //   <div className="SearchBar">
            //     <input
            //       style={{ border: `1px solid ${customTheme?.theme_color}` }}
            //       type="text"
            //       placeholder={intl.formatMessage({
            //         id: "addfoodlist.header.search.placeholder",
            //       })}
            //       onChange={(e) => setSearchData(e.target.value)}
            //     />
            //     <img src={SearchIcon} alt="SearchIcon" />
            //   </div>

            //   {checkingConditions() && (
            //     <span
            //       className="MessageWrapper"
            //       onClick={() => GotoPaymentOrGotoFeedback()}
            //       style={{ background: `${customTheme?.theme_color}20` }}
            //     >
            //       <MessageIcon SecondaryColor={customTheme?.theme_color} />
            //       {!markedAsRead && <span />}
            //     </span>
            //   )}

            //   {group_type === "Takeaway" && (
            //     <MapDirection
            //       style={{
            //         background: `${customTheme?.button_secondary_color}20`,
            //       }}
            //       onClick={() =>
            //         navigate(`/${currentActive?.[0]?.qrcodeid}/get-direction`)
            //       }
            //     >
            //       <MapIcon
            //         PrimaryColor={customTheme?.button_primary_color}
            //         SecondaryColor={customTheme?.button_secondary_color}
            //       />
            //     </MapDirection>
            //   )}
            // </div>
            <div></div>
          )}
      </HeaderWrapper>
    </div>
  );
};

export { Header };

const MapDirection = styled.div`
  width: 50px;
  height: 40px;
  background: #f9297133;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    width: 16px;
  }
`;

const HeaderWrapper = styled.div`
  width: 100%;
  background: #f9f9f9;
  padding: 16px;
  position: relative;
  display: flex;
  align-items: center;

  .HeaderLogoAndName {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .selectLangDrpDown {
      width: ${({ isDineQRCode }) => (isDineQRCode ? "54px" : "117px")};
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 6px;
      justify-content: center;
      align-items: end;

      .select-wrapper {
        position: relative;
        margin: 0;

        svg {
          position: absolute;
          top: 5px;
          left: ${({ dir }) => dir === "rtl" && "6px"};
          right: ${({ dir }) => dir === "ltr" && "6px"};
          transform: rotate(90deg);
        }
        svg path {
          fill: transparent;
        }
      }

      .language-select {
        min-width: 51px;
        width: 50%;

        border-radius: 30px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 15px;
        color: #ffffff;
        border-style: none;
        padding: 4px 6px;
        -webkit-appearance: none;
        option {
          color: #000000;
        }
        :focus {
          outline: none;
        }
      }

      .selectforqrtype {
        width: 100%;
        min-width: 110px;
        background: linear-gradient(91.33deg, #fc2b6e 0%, #bc00a9 100%);
        border-radius: 30px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 15px;
        color: #ffffff;
        border-style: none;
        padding: 4px 6px;
        -webkit-appearance: none;
        option {
          color: #000000;
        }
        :focus {
          outline: none;
        }
      }
    }

    section {
      display: flex;
      align-items: center;
    }
    img {
      width: 44px;
      height: 44px;
      background: #ffffff;
      border: 0.878571px solid #ffffff;
      border-radius: 5.85714px;
      padding: 5px;
    }
    div {
      margin: 0 10px;
      header {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 27px;
        color: #fc2b6e;
      }
      p {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #b7b7c1;
        span {
          color: #242424;
        }
      }
    }

    .LoyaltyPoint {
      width: 38px;
      height: 38px;
      background: #fc2b6e;
      border-radius: 28.5px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 9.5px;
      line-height: 10px;
      color: #ffffff;
      display: flex;
      align-items: center;
      text-align: center;
      padding: 2px;
    }

    .UnifiedOrderMode {
      width: 100%;
      background: linear-gradient(91.33deg, #fc2b6e 0%, #bc00a9 100%);
      border-radius: 30px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 12px;
      color: #ffffff;
      border-style: none;
      padding: 6px;
      option {
        color: #000000;
      }
    }
  }

  .HeaderSearchBarAndMessageIcon {
    margin: 10px 0;
    display: flex;
    gap: 10px;

    .SearchBar {
      position: relative;
      width: 100%;
      input {
        width: 100%;
        height: 40px;
        background: rgba(0, 0, 0, 0.05);
        border-radius: 30px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #7b7f91;
        padding: 10px 35px;
        border-style: none;
      }
      img {
        width: 15px;
        height: 15px;
        position: absolute;
        top: 12px;
        left: 15px;
      }
    }

    .MessageWrapper {
      width: 46px;
      height: 40px;
      background: #ffffff;
      border-radius: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      ${"" /* margin-left: 10px; */}

      img {
        width: 16px;
        height: 14px;
      }

      span {
        position: absolute;
        width: 6px;
        height: 6px;
        border-radius: 30px;
        background: red;
        top: 10px;
        right: 10px;
      }
    }
  }

  .GoBack {
    position: absolute;
    width: 32px;
    height: 32px;
    left: ${({ dir }) => dir === "rtl" && "10px"};
    right: ${({ dir }) => dir === "ltr" && "10px"};
    top: 10px;
  }
`;

const HeaderIcon = styled.div`
  img {
    position: absolute;
    top: 24px;
    left: ${({ dir }) => dir === "ltr" && "10px"};
    right: ${({ dir }) => dir === "rtl" && "25px"};
    width: 20px;
    height: 20px;
  }
`;

const HeaderCard = styled.div`
  display: flex;

  .backTomenu {
    border: none;
    margin-right: 15px;
    background: none;
  }

  img {
    margin: 0 5px;
    background: #ffffff;
    border: 0.878571px solid #000000;
    border-radius: 5.85714px;
  }
`;
