import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { AddressBackBtn } from "../../Utils/Images";
import { CartAddressBtn } from "../CartWithAddress/CartWithAddressStyle";
import {
  AddNewAddressWrapper,
  ErrorMessageBox,
} from "./AddDeliveryAddressStyle";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { createAddressInfo, editAddressInfo } from "../../services/Collection";
import { toast } from "react-toastify";
import { LoadingWapper } from "../../style/GobalStyle";
import { CircularProgress } from "@mui/material";
import IntlMessage from "../../Utils/IntlMessage";
import { getLatLng } from "../../Utils/CommanFunctions";

export const AddDeliveryAddress = ({ props }) => {
  const navigate = useNavigate();
  const dir = useSelector((state) => state?.selectLang?.direction);
  // const QrCodeID = useSelector(
  //   (state) => state?.StoreForMultiUsers?.workStation?.slice(-1)?.[0]?.id
  // );
  const getAlreadyScanedID = useSelector((state) => state.StoreForMultiUsers);
  const currentActive =
    getAlreadyScanedID?.getScannedQrcodeAndRestuarent?.slice(-1);
  const currentID = currentActive?.[0]?.qrcodeid;
  const customTheme = useSelector((state) => state?.theme?.branding);

  const locationState = useLocation()?.state;
  const [loading, setLoading] = useState(false);

  const initialValues = useMemo(() => {
    if (locationState?.prevScreen === "map-location") {
      return {
        houseNo: locationState?.houseNo || "",
        streetName: locationState?.streetName || "",
        city: locationState?.city,
        // state: locationState?.state,
        country: locationState?.country,
        // pincode: "",
        extraDetails: locationState?.extraDetails || "",
        makeDefault: true,
      };
    } else if (locationState?.prevScreen === "select-location") {
      return {
        houseNo: locationState?.house_number,
        streetName: locationState?.street,
        city: locationState?.city,
        // state: locationState?.state,
        country: locationState?.country,
        // pincode: locationState?.pin_code,
        extraDetails: locationState?.extra_detail,
        makeDefault: locationState?.primary,
      };
    } else {
      return {
        houseNo: "",
        streetName: "",
        city: "",
        // state: "",
        country: "",
        // pincode: "",
        extraDetails: "",
        makeDefault: true,
      };
    }
  }, [locationState]);

  const schema = Yup.object().shape({
    houseNo: Yup.number()
      .typeError("Numbers allowed only")
      .required("House Number is reqiured"),
    streetName: Yup.string().required("Street Name is reqiured"),
    city: Yup.string().required("City is reqiured"),
    // state: Yup.string().required("State is reqiured"),
    country: Yup.string().required("Country is reqiured"),
    // pincode: Yup.number().required("Pincode is reqiured"),
    extraDetails: Yup.string().min(
      8,
      "Details must be greater than 8 words ( Near by places )"
    ),
  });

  const CreateAddress = async (value) => {
    setLoading(true);
    let locationDetail = null,
      notExitLatLng = locationState?.lat === "";
    if (notExitLatLng) {
      let address =
        "houseNo-" +
        value.houseNo +
        "," +
        value.streetName +
        "," +
        value.city +
        "," +
        value.country +
        "," +
        value.extraDetails;
      locationDetail = await getLatLng(address);
      setLoading(false);
      if (locationDetail?.status)
        navigate(`/${currentID}/map-location`, {
          state: {
            fullAddress: locationDetail?.formatted_address,
            ...locationDetail.geometry.location,
            ...value,
          },
        });
      else console.log("something went wrong", locationDetail?.error);
    } else {
      let req = {
        full_address: locationState?.fullAddress,
        house_number: value?.houseNo,
        street: value?.streetName,
        city: value.city,
        // state: value.state,
        country: value.country,
        // pincode: value.pincode,
        extra_detail: value?.extraDetails,
        isPrimary: value?.makeDefault,
        latitude: locationState?.lat,
        longitude: locationState?.lng,
      };
      let res = await createAddressInfo(req);
      if (res.status === 200) {
        setLoading(false);
        navigate(`/${currentActive?.[0]?.qrcodeid}/select-location`);
        toast.success(res.message, { theme: "colored" });
      } else {
        setLoading(false);
        toast.error(res.message, { theme: "colored" });
      }
    }
  };

  const EditAddress = async (value) => {
    setLoading(true);
    let req = {
      full_address: locationState?.full_address,
      house_number: value?.houseNo,
      street: value?.streetName,
      city: value.city,
      // state: value.state,
      country: value.country,
      // pincode: value.pincode,
      extra_detail: value?.extraDetails,
      isPrimary: value?.makeDefault,
      address_id: locationState?.id,
      latitude: locationState?.latitude,
      longitude: locationState?.longitude,
    };
    let res = await editAddressInfo(req);
    if (res.status === 200) {
      setLoading(false);
      navigate(`/${currentActive?.[0]?.qrcodeid}/select-location`);
      toast.success(res.message, { theme: "colored" });
    } else {
      setLoading(false);
      toast.error(res.message, { theme: "colored" });
    }
  };

  return (
    <AddNewAddressWrapper dir={dir}>
      <div
        className="Add-address-title"
        style={{ background: customTheme?.theme_color }}
      >
        <div className="back-btn" onClick={() => navigate(-1)}>
          <img alt="" src={AddressBackBtn} />
        </div>
        <div className="header-title">
          <h3 style={{ color: customTheme?.button_text_color }}>
            {locationState?.prevScreen === "map-location" ? (
              <>
                <IntlMessage id="cartAddress.addAddress.button" />
              </>
            ) : (
              <IntlMessage id="cartAddress.edit.Address.button" />
            )}
          </h3>
        </div>
      </div>

      {loading ? (
        <LoadingWapper
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress sx={{ color: "#fc2b6e" }} />
        </LoadingWapper>
      ) : (
        <div className="Add-address-content">
          <div className="top-label">
            <h2 style={{color:customTheme?.text_color}} >
              {locationState?.prevScreen === "map-location" ? (
                <>
                  <IntlMessage id="addAddress.form.title" />
                </>
              ) : (
                <IntlMessage id="cartAddress.edit.Address.button" />
              )}
            </h2>
          </div>

          <div className="form-wrapper">
            <Formik
              initialValues={initialValues}
              validationSchema={schema}
              onSubmit={
                locationState?.prevScreen === "map-location"
                  ? CreateAddress
                  : EditAddress
              }
            >
              <Form>
                <Field
                  className="input-fields"
                  name="houseNo"
                  type="text"
                  placeholder="House Number"
                />
                <ErrorMessageBox>
                  <ErrorMessage name="houseNo" />
                </ErrorMessageBox>

                <Field
                  className="input-fields"
                  name="streetName"
                  type="text"
                  placeholder="Street Name"
                />
                <ErrorMessageBox>
                  <ErrorMessage name="streetName" />
                </ErrorMessageBox>

                <Field
                  className="input-fields"
                  name="city"
                  type="text"
                  placeholder="City"
                />
                <ErrorMessageBox>
                  <ErrorMessage name="city" />
                </ErrorMessageBox>

                {/* <Field
                  className="input-fields"
                  name="state"
                  type="text"
                  placeholder="State"
                />
                <ErrorMessageBox>
                  <ErrorMessage name="state" />
                </ErrorMessageBox> */}

                <Field
                  className="input-fields"
                  name="country"
                  type="text"
                  placeholder="Country"
                />
                <ErrorMessageBox>
                  <ErrorMessage name="country" />
                </ErrorMessageBox>

                {/* <Field
                  className="input-fields"
                  name="pincode"
                  type="number"
                  placeholder="Pincode"
                />
                <ErrorMessageBox>
                  <ErrorMessage name="pincode" />
                </ErrorMessageBox> */}

                {/* <div className="LocationWrapper">
                <Field
                  className="input-fields"
                  name="location"
                  type="text"
                  placeholder="Location"
                />
                <ErrorMessageBox> 
                  <ErrorMessage name="location" />
                </ErrorMessageBox>

                <img
                  onClick={() => navigate(`/${QrCodeID}/map-location`)}
                  className="location-icon"
                  alt=""
                  src={LocationPlaceholderIcon}
                />
              </div> */}

                <Field
                  className="textarea-field"
                  name="extraDetails"
                  as="textarea"
                  placeholder="Extra Details"
                />
                <ErrorMessageBox>
                  <ErrorMessage name="extraDetails" />
                </ErrorMessageBox>

                <div className="select-defaultAdress">
                  <Field
                    name="makeDefault"
                    type="checkbox"
                    className="checkbox-field"
                    style={{accentColor:customTheme?.theme_color}}
                  />
                  <label className="checkbox-label" style={{color:customTheme?.text_color}} >
                    <IntlMessage id="addAddress.checkbox.label.makeDefault" />
                  </label>
                </div>
                <CartAddressBtn>
                  <div
                    type="submit"
                    className="ReviewOrderButton"
                    style={{
                      color: customTheme?.button_text_color,
                      background: `linear-gradient(91.33deg,${customTheme?.button_primary_color} 0%,${customTheme?.button_secondary_color} 100%)`,
                    }}
                  >
                    <button>
                      <IntlMessage id="verifyotp.button.submit" />
                    </button>
                  </div>
                </CartAddressBtn>
              </Form>
            </Formik>
          </div>
        </div>
      )}
    </AddNewAddressWrapper>
  );
};
