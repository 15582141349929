import React from "react";
import GoogleMapReact from "google-map-react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const GoogleMapDistance = ({ setDistance, customerLat, customerLong }) => {
  const dir = useSelector((state) => state?.selectLang?.direction);
  const GoogleMapApiKey = process.env.REACT_APP_MAP_APIKEY;
  const restaurantData = useSelector(
    (state) => state?.restaurentSlice?.restaurentData?.Menu?.Restaurent
  );
  const calculateDistance = (directionsService, origin, destination) => {
    directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: "DRIVING",
      },
      (result, status) => {
        if (status === "OK") {
          setDistance(result.routes[0].legs[0].distance.text);
          console.log(result.routes[0].legs[0].distance.text, "result.routes[0].legs[0].distance.text")
        } else {
          dir === "ltr"
            ? toast.error(
              "Google map is unavailable right now, Please try again"
            )
            : toast.error("خريطة جوجل غير متاحة الآن ، يرجى المحاولة مرة أخرى");
        }
      }
    );
  };

  const onMapApiLoaded = ({ maps }) => {
    const directionsService = new maps.DirectionsService();
    const origin = new maps.LatLng(
      restaurantData?.latitude,
      restaurantData?.longitude
    );
    const destination = new maps.LatLng(customerLat, customerLong);
    calculateDistance(directionsService, origin, destination);
  };

  return (
    <div style={{ width: 0, height: 0 }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: GoogleMapApiKey }}
        defaultCenter={{ lat: 30.361, lng: 76.8485 }} // San Francisco, CA
        defaultZoom={10}
        onGoogleApiLoaded={onMapApiLoaded}
      />
    </div>
  );
};

export default GoogleMapDistance;
