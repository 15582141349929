import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { WhiteButton } from "../../style/SnapayGobalStyle";
import { CrossDrawerIcon } from "../../Utils/Icons";
import { SnapayGrayBackground } from "../../Utils/Images";
import IntlMessage from "../../Utils/IntlMessage";

const SplitBillDrawer = ({
  Close,
  openDivideEqually,
  openCustomAmount,
  openPayForYourItems,
}) => {
  const customTheme = useSelector((state) => state?.theme?.branding);
  const SplitType = useSelector(
    (state) => state?.orderInformationSlice?.splitBill
  );
  const order = useSelector(
    (state) => state?.orderInformationSlice?.orderDeails
  );
  const lastSplitPayMode = useSelector(
    (state) => state?.orderInformationSlice?.lastPaymentMode
  );
  const langDir = useSelector((state) => state?.selectLang);

  const isHaveToShow = (type) => {
    if (type === "DivideEqually") {
      if (lastSplitPayMode === "2") {
        return false;
      } else if (order?.specific_payment_amount === 0) {
        return true;
      } else {
        return false;
      }
    }

    if (type === "PayforYourItem") {
      if (order?.partial_payment_amount === 0 && order?.discount == 0) {
        return true;
      } else {
        return false;
      }
    }
    if (type === "CustomAmount") {
      if (order?.specific_payment_amount === 0) {
        return true;
      } else {
        return false;
      }
    }
  };

  return (
    <SplitBillDrawerStyle
      textColor={customTheme?.text_color}
      themeColor={customTheme?.theme_color}
      button_text_color={customTheme?.button_text_color}
      button_primary_color={customTheme?.button_primary_color}
      background={SnapayGrayBackground}
      dir={langDir?.direction}
    >
      <div className="heading">
        <span>
          <IntlMessage id="Snapay.SplitTheBillHeading" />
        </span>
        <span onClick={Close}>
          <CrossDrawerIcon />
        </span>
      </div>
      <p>
        <IntlMessage id="Snapay.SplitTheBillSubHeading" />
      </p>
      <div className="buttonWrapper">
        {isHaveToShow("DivideEqually") && (
          <WhiteButton
            button_text_color={customTheme?.button_text_color}
            button_primary_color={customTheme?.button_primary_color}
            button_secondary_color={customTheme?.button_secondary_color}
            onClick={openDivideEqually}
            activeBtn={SplitType?.type === "DivideEqually"}
          >
            <IntlMessage id="Snapay.SplitTypesDivideEquallyBtn" />
          </WhiteButton>
        )}
        {isHaveToShow("PayforYourItem") && (
          <WhiteButton
            button_text_color={customTheme?.button_text_color}
            button_primary_color={customTheme?.button_primary_color}
            button_secondary_color={customTheme?.button_secondary_color}
            onClick={openPayForYourItems}
            activeBtn={SplitType?.type === "PayforYourItem"}
          >
            <IntlMessage id="Snapay.SplitTypesPayForYourItemsBtn" />
          </WhiteButton>
        )}
        {isHaveToShow("CustomAmount") && (
          <WhiteButton
            button_text_color={customTheme?.button_text_color}
            button_primary_color={customTheme?.button_primary_color}
            button_secondary_color={customTheme?.button_secondary_color}
            onClick={openCustomAmount}
            activeBtn={SplitType?.type === "CustomAmount"}
          >
            <IntlMessage id="Snapay.SplitTypesCustomAmountBtn" />
          </WhiteButton>
        )}
      </div>
    </SplitBillDrawerStyle>
  );
};

export default SplitBillDrawer;

const SplitBillDrawerStyle = styled.div`
  padding: 28px 24px;
  background: ${({ background }) => `url(${background})`};
  background-size: contain;

  .heading {
    color: ${({ textColor }) => textColor};
    font-family: "DM Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.42px;
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
  }
  p {
    color: rgba(0, 0, 0, 0.6);
    font-family: "DM Sans", sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.3px;
  }
  .buttonWrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 48px;
  }
`;
