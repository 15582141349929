import React from "react";
import styled from "styled-components";
import {
  BackgroundCover
} from "../../Utils/Images";
import { useDispatch, useSelector } from "react-redux";
import { ArrowIcon } from "../../Utils/Icons";
import { useLocation, useNavigate } from "react-router-dom";
import {
  isTakeawayDevliveryOrderType,
  swicthingMode,
} from "../../store/DeliveryModule/DeliverySlice";
import { loginWithMasterQrCode } from "../../store/userLoginReducer";

export default function SelectQRType() {
  const aqlutstorage = process.env.REACT_APP_IMAGE_BASEURL;
  const containerProfile = process.env.REACT_APP_CONTAINER_PROFILE;
  const containerMenuBranding = process.env.REACT_APP_CONTAINER_MENUBRANDING;

  const userlang = useSelector((state) => state.selectLang.direction);
  const { restaurentData } = useSelector(({ restaurentSlice }) => ({
    ...restaurentSlice,
  }));
  const restaurent_data = restaurentData?.Menu?.Restaurent;
  const restaurent_logo = restaurent_data?.logo;
  let logo_restaurent = aqlutstorage + containerProfile + restaurent_logo;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getAlreadyScanedID = useSelector((state) => state.StoreForMultiUsers);
  const currentActive =
    getAlreadyScanedID?.getScannedQrcodeAndRestuarent?.slice(-1);
  const selectedType = useSelector((state) => state?.deliveryModule);
  const styling = restaurentData?.Menu?.Restaurent?.MenuPageBranding;
  const background_image = styling?.display_image;
  let background_img = aqlutstorage + containerMenuBranding + background_image;
  const location = useLocation();
  console.log(location, "locationnnnnnnn");
  const getMultiUserData = useSelector(
    (state) => state?.userAuth?.IsMasterAuth
  );
  const customTheme = useSelector((state) => state?.theme?.branding);

  const storeTokenForMaster = (payload) => {
    let userDetails = { currentID: currentActive?.[0]?.qrcodeid, ...payload };
    if (getMultiUserData.length > 0) {
      const updatedArray = getMultiUserData?.filter(
        (item) => item.currentID !== userDetails.currentID
      );
      updatedArray.push(userDetails);
      if (updatedArray) dispatch(loginWithMasterQrCode(updatedArray));
    } else {
      let arr = [];
      arr.push(userDetails);
      if (arr && arr.length > 0) dispatch(loginWithMasterQrCode(arr));
    }
  };

  const submit = () => {
    dispatch(
      swicthingMode(selectedType?.orderType === "takeaway" ? false : true)
    );
    dispatch(isTakeawayDevliveryOrderType(selectedType?.orderType));
    storeTokenForMaster(location?.state);
  };
  return (
    // <AppIntroWrapper>
    //     <section className="upper-wrapper">
    //         <TabDiv>
    //             <h1>1</h1>
    //             <div>
    //                 <p>scan</p>
    //                 <p>إمسح</p>
    //             </div>

    //             <img src={Coverscan} alt="scan" />
    //         </TabDiv>

    //         <TabDiv>
    //             <h1>2</h1>
    //             <div>
    //                 <p>order</p>
    //                 <p>أطلب</p>
    //             </div>

    //             <img src={Coverorder} alt="scan" />
    //         </TabDiv>

    //         <TabDiv>
    //             <h1>3</h1>
    //             <div>
    //                 <>
    //                     <p>pay</p>
    //                     <p>ادفع</p>
    //                 </>
    //             </div>

    //             <img src={Coverpayment} alt="scan" />
    //         </TabDiv>
    //     </section>

    //     <section className="lower-wrapper">
    //         <SelectLangWrapper>
    //             <RestaurantLogoBox dir={userlang}>
    //                 <img src={logo_restaurent} alt="logo" />
    //             </RestaurantLogoBox>

    //             <div className="upper" dir={userlang}>
    //                 <header>
    //                     {/* <IntlMessage id="welcomescreen.title" />{" "}
    //                     {userlang === "rtl"
    //                         ? restaurent_data?.ar_name
    //                         : restaurent_data?.name} */}

    //                     Please select the mode of order
    //                 </header>
    //             </div>

    //             <div className="lower">
    //                 <select
    //                     onChange={(e) => dispatch(isTakeawayDevliveryOrderType((e.target.value)))}
    //                     value={selectedType?.orderType || "takeaway"}
    //                 >
    //                     <option value="takeaway">Takeaway</option>
    //                     <option value="delivery">Delivery</option>
    //                 </select>

    //                 <button onClick={submit}>
    //                     <ArrowIcon width={9} height={11} color={'transparent'} />
    //                 </button>
    //             </div>
    //         </SelectLangWrapper>
    //     </section>
    // </AppIntroWrapper>

    <SelectWrapper
      className="VerifyOtp"
      background_image={background_image}
      background_img={background_img}
      dir={userlang}
    >
      <section className="lower-wrapper">
        <SelectLangWrapper
          style={{
            color: customTheme?.button_text_color,
            background: `linear-gradient(91.33deg,${customTheme?.button_primary_color} 0%,${customTheme?.button_secondary_color} 100%)`,
          }}
        >
          <RestaurantLogoBox dir={userlang}>
            <img src={logo_restaurent} alt="logo" />
          </RestaurantLogoBox>

          <div className="upper" dir={userlang}>
            <header style={{ color: customTheme?.header_color }}>
              {/* <IntlMessage id="welcomescreen.title" />{" "}
                            {userlang === "rtl"
                                ? restaurent_data?.ar_name
                                : restaurent_data?.name} */}
              Please select the mode of order
            </header>
          </div>

          <div className="lower">
            <select
              style={{
                color: customTheme?.button_text_color,
                background: `linear-gradient(91.33deg,${customTheme?.button_primary_color} 0%,${customTheme?.button_secondary_color} 100%)`,
              }}
              onChange={(e) =>
                dispatch(isTakeawayDevliveryOrderType(e.target.value))
              }
              value={selectedType?.orderType || "takeaway"}
            >
              <option value="takeaway">Takeaway</option>
              <option value="delivery">Delivery</option>
            </select>

            <button
              onClick={submit}
              style={{
                color: customTheme?.button_text_color,
                background: `linear-gradient(91.33deg,${customTheme?.button_primary_color} 0%,${customTheme?.button_secondary_color} 100%)`,
              }}
            >
              <ArrowIcon width={9} height={11} color={"transparent"} />
            </button>
          </div>
        </SelectLangWrapper>
      </section>
    </SelectWrapper>
  );
}

const SelectWrapper = styled.div`
  position: relative;
  background: ${({ background_image, background_img }) =>
    `${
      background_image !== undefined
        ? `url(${background_img})`
        : `url(${BackgroundCover.toString()})`
    }`};
  background-repeat: no-repeat;
  background-size: 100% 75vh;
`;

// const RestaurantLogoBox = styled.div`
//   position: absolute;
//   width: 100px;
//   height: 100px;
//   left: ${({ dir }) => dir === "ltr" && "30px"};
//   right: ${({ dir }) => dir === "rtl" && "30px"};
//   top: -50px;
//   background: #fc2b6e;
//   border: 1px solid #fc2b6e;
//   border-radius: 100px;
//   padding: 1px;

//   img {
//     width: 100%;
//     height: 100%;
//     border-radius: 100px;
//   }
// `;

const RestaurantLogoBox = styled.div`
  position: absolute;
  width: 100px;
  height: 100px;
  left: ${({ dir }) => dir === "ltr" && "30px"};
  right: ${({ dir }) => dir === "rtl" && "30px"};
  top: -70px;
  background: #ffffff;
  border: 1px solid #c6c6c6;
  border-radius: 20px;
  padding: 20px;

  img {
    width: 100%;
    height: 100%;
  }
`;

const SelectLangWrapper = styled.div`
  width: 100%;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  background: linear-gradient(90deg, #fc2b6e 0%, #bc00a9 100%);
  border-top: 1px solid #ffffff;
  border-radius: 38px 38px 0px 0px;
  border-radius: 30px 30px 0px 0px;
  padding: 33px 20px;
  box-shadow: 4px -1px 16px 1px #afabab99;

  @media (max-height: 660px) {
    margin: 0;
    padding: 15px 20px;
  }

  .upper {
    text-align: center;
    header {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      letter-spacing: 0.02em;
      color: #ffffff;
      margin-top: 40px;
      @media (max-height: 785px) {
        font-size: 20px;
        margin-top: 30px;
      }
    }

    p {
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #7b7f91;
      padding: 10px 0;
    }
  }

  .lower {
    display: flex;
    margin: 20px 0;

    select {
      width: 100%;
      height: 48px;
      background: linear-gradient(90deg, #ff3f7d 0%, #db1ac7 100%);
      border: 1px solid #d2d2d3;
      border-radius: 28px;
      padding: 0 20px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #ffffff;
      -webkit-appearance: none;
      -moz-appearance: none;

      option {
        color: #000000;
      }
    }
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 45px;
      background: #c7079e;
      border: 1px solid #ffffff;
      margin: 0 10px;
      border-radius: 100%;

      @media (max-width: 500px) {
        width: 55px;
        height: 45px;
        margin-right: 0px;
      }
    }
  }

  h1 {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
    letter-spacing: 0.36px;
    color: #ffffff;
    padding: 30px 0;
    text-align: center;
  }
`;

const AppIntroWrapper = styled.div`
  width: 100%;
  // height: 100vh;

  .upper-wrapper {
    width: 100%;
    height: 70vh;
    display: flex;
    flex-direction: column;
    gap: 50px;
    justify-content: center;
    padding: 0 40px 0 20px;

    @media (max-height: 785px) {
      height: auto;
      margin-top: 40px;
    }
    @media (max-height: 710px) {
      height: auto;
      margin-top: 20px;
    }
  }
`;

const TabDiv = styled.div`
  width: 100%;
  height: 89px;
  background: rgba(255, 216, 229, 0.5);
  border-radius: 200px;
  padding: 9px 30px;
  display: flex;
  align-items: center;
  position: relative;

  @media (max-height: 660px) {
    height: 78px;
  }

  img {
    width: 107px;
    height: 107px;
    position: absolute;
    right: -30px;
  }

  h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 70px;
    line-height: 105px;
    color: #bd01a8;
  }

  div {
    margin: 0 9px;

    p {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 23px;
      line-height: 34px;
      text-transform: uppercase;
      color: #bd01a8;
    }
  }
`;
