import React, { useState } from "react";
import "../VerifyOtp/VerifyOtp.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  loginWithMasterQrCode,
} from "../../store/userLoginReducer";
import { toast } from "react-toastify";
import { checkCustomerExists, createUser } from "../../services/Authapi";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { BackgroundCover} from "../../Utils/Images";
import OtpInput from "react-otp-input";
import { LoadingWapper } from "../../style/GobalStyle";
import { CircularProgress, Skeleton } from "@mui/material";
import IntlMessage from "../../Utils/IntlMessage";
import { isTakeawayDevliveryOrderType, swicthingMode } from "../../store/DeliveryModule/DeliverySlice";

export default function VerifyOtp() {
  const aqlutstorage = process.env.REACT_APP_IMAGE_BASEURL;
  const containerProfile = process.env.REACT_APP_CONTAINER_PROFILE;
  const containerMenuBranding = process.env.REACT_APP_CONTAINER_MENUBRANDING;

  const { state } = useLocation();
  const [userInfo] = useState(state?.data);
  const { restaurentData } = useSelector(({ restaurentSlice }) => ({
    ...restaurentSlice,
  }));
  const styling = restaurentData?.Menu?.Restaurent?.MenuPageBranding;
  const restaurent_data = restaurentData?.Menu?.Restaurent;

  const background_image = styling?.display_image;
  const restaurent_logo = restaurent_data?.logo;
  let background_img = aqlutstorage + containerMenuBranding + background_image;
  let logo_restaurent = aqlutstorage + containerProfile + restaurent_logo;

  const [isOtp, setOtp] = useState("");
  const dispatch = useDispatch();
  const getAlreadyScanedID = useSelector((state) => state.StoreForMultiUsers);
  const currentActive =
    getAlreadyScanedID?.getScannedQrcodeAndRestuarent?.slice(-1);

  const [isLoading, setLoading] = useState(false);
  const [resendLoader, setResendLoader] = useState(false);
  const navigate = useNavigate();

  //This function will be triggerd once the otp is input

  const getMultiUserData = useSelector(
    (state) => state?.userAuth?.IsMasterAuth
  );

  const storeTokenForMaster = (payload) => {
    let userDetails = { currentID: currentActive?.[0]?.qrcodeid, ...payload };
    if (getMultiUserData.length > 0) {
      const updatedArray = getMultiUserData?.filter(
        (item) => item.currentID !== userDetails.currentID
      );
      updatedArray.push(userDetails);
      if (updatedArray) dispatch(loginWithMasterQrCode(updatedArray));
    } else {
      let arr = [];
      arr.push(userDetails);
      if (arr && arr.length > 0) dispatch(loginWithMasterQrCode(arr));
    }
  };

  const handleNavigation = (responseData) => {

    console.log('enable_takeaway', restaurentData?.Menu?.Restaurent?.enable_takeaway)
    console.log('enable_delivery', restaurentData?.Menu?.Restaurent?.enable_delivery)
    let isTakeawayEnable = restaurentData?.Menu?.Restaurent?.enable_takeaway;
    let isDeliveryEnable = restaurentData?.Menu?.Restaurent?.enable_delivery;

    if (isTakeawayEnable && isDeliveryEnable) {
      navigate(
        `/${currentActive?.[0]?.qrcodeid}-${currentActive?.[0]?.restaurentid}/select-qrtype`, { state: responseData }
      );
    } else {
      if (isTakeawayEnable && !isDeliveryEnable) {
        dispatch(swicthingMode(isDeliveryEnable))
        dispatch(isTakeawayDevliveryOrderType("takeaway"))
        storeTokenForMaster(responseData);
      } else if (isDeliveryEnable && !isTakeawayEnable) {
        dispatch(swicthingMode(isDeliveryEnable))
        dispatch(isTakeawayDevliveryOrderType("delivery"))
        storeTokenForMaster(responseData);
      } else {
        alert('barcode is disabled, please scan another barcode');
      }
    }
  }

  function submit() {
    const otp = isOtp;
    //Here we will verify the otp from backend and dispatch the success to redux store.
    if (otp && otp?.length == 4) {
      // setLoading(true);
      let data = {
        restaurent_id: currentActive?.[0]?.restaurentid,
        name: userInfo.name,
        country_code: userInfo.country_code,
        mobile_no: userInfo.mobile_no,
        otp: otp,
      };
      //calling the API for save user information on server !Boom coder_0001
      createUser(data)
        .then((res) => {
          if (res.status === 200) {
            if (restaurentData?.QrCodeGroup?.group_type === "Takeaway") {
              handleNavigation(res.data)
            } else {
              storeTokenForMaster(res.data);
            }
            toast.success(res.message, { theme: "colored" });
          } else if (res.response.data.status === 409) {
            setLoading(false);
            toast.error("This phone number is already added!", {
              theme: "colored",
            });
          } else {
            setLoading(false);
            toast.error(res?.response?.data?.message, { theme: "colored" });
          }
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
      toast.error("Please enter a vaild OTP", { theme: "colored" });
    }
  }

  const ResendOTP = async () => {
    setResendLoader(true);
    if (userInfo) {
      let req = {
        restaurent_id: userInfo?.restaurent_id,
        name: userInfo?.name,
        country_code: userInfo?.country_code,
        mobile_no: userInfo?.mobile_no,
      };
      const res = await checkCustomerExists(req);
      if (res.status === 200) {
        setResendLoader(false);
        toast.success("OTP is successfully send", { theme: "colored" });
      } else if (res.status === 201) {
        setResendLoader(false);
        toast.success(res.message, { theme: "colored" });
      }
      else {
        setResendLoader(false);
        toast.error(res.message, { theme: "colored" });
      }
    }
  };

  const userlang = useSelector((state) => state.selectLang.direction);
  return (
    <SelectWrapper
      className="VerifyOtp"
      background_image={background_image}
      background_img={background_img}
      dir={userlang}
    >
      <VerifyOTPWrapper>
        <RestaurantLogoBox dir={userlang}>
          <img src={logo_restaurent} alt="logo" />
        </RestaurantLogoBox>
        <div>
          <header>
            <IntlMessage id="verifyotp.title" />
          </header>

          <div dir="ltr">
            <OtpInput
              value={isOtp}
              onChange={setOtp}
              autoFocus
              OTPLength={4}
              isInputNum={true}
              disabled={false}
              secure
            />
          </div>

          <div style={{ margin: "0 0 20px 0" }}>
            <span>
              <IntlMessage id="verifyotp.notreceiveotp" />
            </span>
            {resendLoader ? (
              <Skeleton
                variant="text"
                sx={{
                  bgcolor: "#fc2b6e",
                  fontSize: "1rem",
                }}
              />
            ) : (
              <label onClick={ResendOTP}>
                <IntlMessage id="verifyotp.resendotp" />
              </label>
            )}
          </div>

          {isLoading ? (
            <LoadingWapper style={{ height: "35px", margin: "20px 0" }}>
              <CircularProgress sx={{ color: "#fc2b6e !important" }} />
            </LoadingWapper>
          ) : (
            <button type="submit" onClick={submit}>
              <IntlMessage id="verifyotp.button.submit" />
            </button>
          )}
        </div>
      </VerifyOTPWrapper>
    </SelectWrapper>
  );
}

const VerifyOTPWrapper = styled.div`
  width: 100%;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  background: #ffffff;
  border-radius: 30px 30px 0px 0px;
  padding: 25px;

  div {
    header {
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 34px;
      letter-spacing: 0.36px;
      color: #202020;
      padding: 10px 0;
    }

    input {
      width: 48px !important;
      height: 48px;
      background: #ffffff;
      border: 1px solid #d2d2d3;
      border-radius: 28px;
      margin: 5px;
    }

    span {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #7b7f91;
    }
    label {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: #fc2b6e;
      padding: 0 5px;
      margin: 10px 0;
    }

    button {
      width: 100%;
      height: 48px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.06px;
      color: #ffffff;
      background: linear-gradient(91.33deg, #fc2b6e 0%, #bc00a9 100%);
      border-radius: 30px;
      border-style: none;
      margin: 10px 0;
    }
  }
`;

const RestaurantLogoBox = styled.div`
  position: absolute;
  width: 100px;
  height: 100px;
  left: ${({ dir }) => dir === "ltr" && "30px"};
  right: ${({ dir }) => dir === "rtl" && "30px"};
  top: -70px;
  background: #ffffff;
  border: 1px solid #c6c6c6;
  border-radius: 20px;
  padding: 20px;

  img {
    width: 100%;
    height: 100%;
  }
`;

const SelectWrapper = styled.div`
  position: relative;
  background: ${({ background_image, background_img }) =>
    `${background_image !== undefined
      ? `url(${background_img})`
      : `url(${BackgroundCover.toString()})`
    }`};
  background-repeat: no-repeat;
  background-size: 100% 70%;
`;
