/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { redeemLoyalityPoints } from "../../store/UnifiedUser/UnifiedUser";
import "./toggle.css";

const ToggleSwitch = ({ name }) => {
  const [isRedeemPoints, setIsRedeemPoints] = useState(false);
  const dispatch = useDispatch();

  const isReedeemStatus = useSelector(
    (state) => state?.isUnifiedSlice?.isRedeemPoints
  );

  const handleChange = (e) => {
    if (name === "RedeemPoints") {
      setIsRedeemPoints(e.target.checked);
    } else {
      setIsRedeemPoints(false);
      toast.error("You didn't have enough points to redeem");
    }
  };
  useEffect(() => {
    dispatch(redeemLoyalityPoints(isRedeemPoints));
  }, [isRedeemPoints]);

  return (
    <div className="toggle-switch">
      <input
        type="checkbox"
        className="checkbox"
        name={name}
        id={name}
        onChange={handleChange}
        checked={name !== "RedeemPoints" ? false : isReedeemStatus}
        defaultChecked={name === "RedeemPoints" ? isReedeemStatus : false}
      />
      <label className="label" htmlFor={name}>
        <span className="inner" />
        <span className="switch" />
      </label>
    </div>
  );
};

export default ToggleSwitch;
