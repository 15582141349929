import styled from "styled-components";

export const SelectMapLocationStyleBox = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;

  .map-wrapper {
    width: 100%;
    height: 74vh;
  }
  .map-btnsWrapper {
    width: 100%;
    height: 26vh;
    padding: 15px;
    position: absolute;
    bottom: 0;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    background: #ffffff;

    .btns-title {
      display: flex;
      width: 100%;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.5px;
      color: #7b7f91;
      mix-blend-mode: normal;
      h3 {
        padding-left: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .back-btn {
        width: 25px;
        height: 25px;
        background: linear-gradient(150deg, #fc2b6e 0%, #bc00a9 100%);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 16px;
        border-radius: 20px;

        img {
          width: 10px;
        }
      }
    }

    .currentLocation-Box {
      width: 100%;
      margin-top: 20px;
      display: flex;
      gap: 10px;
      svg {
        width: 42px;
      }

      h4 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 131.02%;
        color: #242424;
      }
    }

    .maps-btns {
      width: 100%;
      padding: 0px 20px;
      margin-top: 18px;
      padding-bottom: 20px;

      .set-location {
        width: 100%;
        padding: 13px;
        background: linear-gradient(91.33deg, #fc2b6e 0%, #bc00a9 100%);
        border-radius: 30px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #ffffff;
        border: none;
      }

      .add-location {
        width: 100%;
        padding: 13px;
        margin-top: 15px;
        background: #ffffff;
        border-radius: 30px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #fc2b6e;
        border: 1px solid #fc2b6e;
      }
    }
  }
`;
