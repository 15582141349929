import React from 'react'
import { Header } from '../../components';
import CheckoutPrice from './CheckoutPrice';
import MakePayment from './MakePayment';

function CardFrontSideScanner() {
  return (
    <div>
        <Header />
        <CheckoutPrice />
        <MakePayment img={'/assets/images/scanner1.svg'}/>
    </div>
  )
}

export default CardFrontSideScanner