import styled from "styled-components";

export const AddNewAddressWrapper = styled.div`
  width: 100%;
  height: 100%;

  .Add-address-title {
    width: 100%;
    /* height: 100%; */
    padding: 15px 20px;
    display: flex;
    background: linear-gradient(91.33deg, #fc2b6e 0%, #bc00a9 100%);
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

    .back-btn {
      width: 10%;
      display: flex;
      align-items: center;
    }

    .header-title {
      width: 90%;
      text-align: center;

      h3 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: #ffffff;
      }
    }
  }

  .Add-address-content {
    width: 100%;
    height: 100%;
    padding: 20px;

    .top-label {
      h2 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
      }
    }

    .form-wrapper {
      width: 100%;
      height: 100%;

      .LocationWrapper {
        width: 100%;
        position: relative;
      }

      .location-icon {
        cursor: pointer;
        position: absolute;
        right: ${({ dir }) => dir === "ltr" && "17px"};
        left: ${({ dir }) => dir === "rtl" && "17px"};
        top: 27px;
      }

      .input-fields {
        width: 100%;
        padding: 13px;
        padding-left: 20px;
        border-radius: 50px;
        border: 0.878505px solid #d2d2d3;
        margin-top: 18px;

        ::placeholder {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: #7b7f91;
        }

        :focus {
          outline: none;
        }
      }

      .textarea-field {
        width: 100%;
        height: 150px;
        border-radius: 30px;
        margin-top: 20px;
        padding: 20px;
        border: 0.878505px solid #d2d2d3;
        resize: none;

        ::placeholder {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: #7b7f91;
        }
        :focus {
          outline: none;
        }
      }
    }
    .select-defaultAdress {
      width: 100%;
      height: 100%;
      margin-top: 10px;
      display: flex;
      gap: 8px;
      margin: 5px 0;
      justify-content: center;
      .checkbox-field {
        accent-color: #f52675;
        margin-top: 1px;
      }
      .checkbox-label {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
        color: #000000;
      }
    }
  }
`;

export const ErrorMessageBox = styled.div`
  color: red;
  padding-left: 10px;
`;
