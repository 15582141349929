import { postApi, putApi } from "./ApiMethod";
const VERIFY_OTP = "/customer/verifyOtpByCustomer";
const ADD_CUSTOMER = "customer/customerAdded";
const CHECK_CUSTOMER_DETAILS = "customer/checkCustomerExists";

//here we wil add customer in
export function createUser(payload) {
  return postApi(ADD_CUSTOMER, payload);
}
export function checkCustomerExists(payload) {
  return postApi(CHECK_CUSTOMER_DETAILS, payload);
}
export function verifyOtpByCustomer(payload) {
  return postApi(VERIFY_OTP, payload);
}