import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import BillDetail from "../../components/BillDetail";
import ChatResponse from "../../components/ChatResponse";
import SelectedItem from "../../components/SelectedItem";
import {
  getOrderDetails,
  paymentOnSkipcash,
  verifyPosCheckStatus,
} from "../../services/Collection";
import { LoadingWapper } from "../../style/GobalStyle";
import IntlMessage from "../../Utils/IntlMessage";
import Tip from "../../components/Tip";

export default function PayFullAmount() {
  var today = new Date();
  var time = today.getHours() + ":" + today.getMinutes();
  const { orderDeails } = useSelector(({ orderInformationSlice }) => ({
    ...orderInformationSlice,
  }));
  const AuthDetails = useSelector((state) => state?.userAuth?.data);
  // const RestaurentTheme = useSelector(
  //   (state) =>
  //     state?.restaurentSlice?.restaurentData?.Menu?.Restaurent?.MenuPageBranding
  // );
  // const RestaurentTheme = useSelector((state) => state?.theme?.branding);
  const customTheme = useSelector((state) => state?.theme?.branding);

  const [redirectPaymentGatewayURL, setRedirectPaymentGatewayURL] = useState(null);
  const [loading, setLoading] = useState(false);
  var tipAmount = 0;
  const sendValues = (tips) => {
    if (tips.value !== "") {
      tipAmount = tips.value;
    } else {
      tipAmount = 0;
    }
  };
  const [fetchingDataLoader, setFetchingDataLoader] = useState(false);
  const [data, setData] = useState([]);

  const OrderDetails = async () => {
    setFetchingDataLoader(true);
    if (orderDeails) var { id } = orderDeails;

    let res = await getOrderDetails(id);
    if (res.status === 200) {
      let data = res.data;
      setData([data]);
      setFetchingDataLoader(false);
    }
  };

  const delayForPayment = () => {
    setLoading(true);
    setTimeout(() => {
      posCheckStatus();
    }, 6000);
  };

  const posCheckStatus = async () => {
    let params = new URLSearchParams();
    params.append("order_id", orderDeails ? orderDeails.id : null);
    let res = await verifyPosCheckStatus(params.toString());
    if (res.status === 200) {
      skipcashPayment();
    } else {
      toast.error(res?.response?.data?.message || res?.message || res?.error, {
        theme: "colored",
      });
      setLoading(false);
    }
  };

  const skipcashPayment = async () => {
    let req = {
      order_id: orderDeails ? orderDeails.id : null,
      customer_id: AuthDetails?.id,
      restaurent_id: AuthDetails?.restaurent_id,
      amount:
        Number(data?.[0]?.sub_total) -
        (Number(data?.[0]?.partial_payment_amount) +
          Number(data?.[0]?.specific_payment_amount) +
          Number(data?.[0]?.discount)),
      // uid: orderidRef,
      tip: tipAmount,
      //full- 1 , partia; - 2 , spec - 3
      type: "1",
    };
    let res = await paymentOnSkipcash(req);
    if (res.status === 200) {
      setRedirectPaymentGatewayURL(res?.data?.resultObj);
      setLoading(false);
    } else {
      setLoading(false);
      toast.error(res.error || res.response.data.message);
    }
  };

  const openSkipCashPaymentGateway = () => {
    if (redirectPaymentGatewayURL) {
      window.open(redirectPaymentGatewayURL?.payUrl, "_self");
    }
  };
  useEffect(() => {
    openSkipCashPaymentGateway();
  }, [redirectPaymentGatewayURL]);

  useEffect(() => {
    OrderDetails();
  }, []);

  return (
    <>
      {data?.length > 0 && (
        <>
          <ChatResponse
            message={<IntlMessage id="payment.pay.method.fullamount" />}
            time={time}
          />
          {fetchingDataLoader && data?.length > 0 ? (
            <LoadingWapper>
              <CircularProgress sx={{ color: customTheme?.theme_color, my: 2 }} />
            </LoadingWapper>
          ) : (
            <div className="payment-listing" style={{background: customTheme?.background_color , border:`1px solid ${customTheme?.theme_color}`}} >
              <SelectedItem data={data} />
              <BillDetail type={"Full"} data={data} />
              <Tip sendValues={sendValues} />
              <div className="paymentButton">
                {loading ? (
                  <CircularProgress
                    sx={{ color: customTheme?.theme_color, my: 2 }}
                  />
                ) : data?.[0]?.order_status == "5" ||
                  data?.[0]?.order_status == "6" ? (
                  ""
                ) : (
                  <button
                    type="button"
                    onClick={delayForPayment}
                    style={{
                      background: `linear-gradient(91.33deg,${customTheme?.button_primary_color} 0%,${customTheme?.button_secondary_color} 100%)`,
                    }}
                  >
                    <IntlMessage id="payment.button.pay" />
                  </button>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}
