import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  CrossDrawerIcon,
  MinusIcon,
  PeopleIcon,
  PlusIcon,
  UserIcon,
} from "../../Utils/Icons";
import { SnapayGrayBackground } from "../../Utils/Images";
import { WhiteButton, ThemeButton } from "../../style/SnapayGobalStyle";
import { splitBill, wayToPayBillType } from "../../store/orderPayment";
import { CreateSplitBillApi } from "../../services/Collection";
import { toast } from "react-toastify";
import { addCommaToNumbers } from "../../Utils/CommanFunctions";
import IntlMessage from "../../Utils/IntlMessage";

const DivideBillEqualLlyDrawer = ({ Close }) => {
  const dispatch = useDispatch();
  const spilBillData = useSelector(
    (state) => state?.orderInformationSlice?.splitBill
  );
  const OrderDetails = useSelector(
    (state) => state?.orderInformationSlice?.orderDeails
  );
  const customTheme = useSelector((state) => state?.theme?.branding);
  const [totalPeople, setTotalPeople] = useState(
    spilBillData?.noTotalPeople || 1
  );
  const [peopleYouPayFor, setPeopleYouPayFor] = useState(
    spilBillData?.noPeopleYouPayFor || 1
  );
  const [userTotal, setUserTotal] = useState("");
  const langDir = useSelector((state) => state?.selectLang);

  let obj = {
    type: "DivideEqually",
    noTotalPeople: totalPeople,
    noPeopleYouPayFor: peopleYouPayFor,
    total: userTotal,
  };

  const handleTotalPeople = (type) => {
    if (type === "add") {
      setTotalPeople((pre) => pre + 1);
    } else {
      const tempTotal = totalPeople - 1;
      if (totalPeople > 1) {
        // setTotalPeople(totalPeople - 1);
        setTotalPeople(tempTotal);
        if (tempTotal < peopleYouPayFor) {
          setPeopleYouPayFor(tempTotal);
        }
      }
    }
  };
  const handlePeopleYouPayFor = (type) => {
    if (type === "add") {
      if (totalPeople > 1 && peopleYouPayFor < totalPeople) {
        setPeopleYouPayFor((pre) => pre + 1);
      }
    } else {
      if (peopleYouPayFor > 1) {
        setPeopleYouPayFor(peopleYouPayFor - 1);
      }
    }
  };

  const handleCal = () => {
    const num = (OrderDetails?.total / totalPeople) * peopleYouPayFor;
    setUserTotal(num.toFixed(2));
  };

  const handleConfirm = async () => {
    let due_amount = OrderDetails?.total;
    let req = {
      order_id: OrderDetails ? OrderDetails.id : null,
      split_method_customer_count: obj?.noTotalPeople,
      transaction_id: null,
      split_method_customer_paid: obj?.noPeopleYouPayFor,
      total_amount: OrderDetails?.sub_total,
      due_amount: due_amount,
      paid_amount: 0,
      split_method_type: "4",
    };

    let res = await CreateSplitBillApi(req);

    if (res.status === 200) {
    Close();
    dispatch(splitBill(obj));
    dispatch(wayToPayBillType("SplitTheBill"));
    } else {
    Close();
    toast.error("Please try again");
    }
  };

  useEffect(() => {
    handleCal();
  }, [totalPeople, peopleYouPayFor]);

  return (
    <DivideBillEqualLlyDrawerStyled
      textColor={customTheme?.text_color}
      themeColor={customTheme?.theme_color}
      button_text_color={customTheme?.button_text_color}
      button_primary_color={customTheme?.button_primary_color}
      background={SnapayGrayBackground}
      dir={langDir?.direction}
    >
      <div className="heading">
        <span>
          <IntlMessage id="Snapay.SplitTypesDivideEquallyBtn" />
        </span>
        <span onClick={Close}>
          <CrossDrawerIcon />
        </span>
      </div>
      <div className="mainWrapper">
        <div className="totalPeople">
          <span className="totalPeopleOne">
            <PeopleIcon />
          </span>
          <div className="totalPeopleTwo">
            <span className="text">
              <IntlMessage id="Snapay.DivideEquallyTotalPeople" />
            </span>
            <div className="AddBtn">
              <i
                className={`icon ${totalPeople === 1 && "defaultIcon"}`}
                onClick={handleTotalPeople}
              >
                <MinusIcon />
              </i>
              <span className="count">{totalPeople}</span>
              <i className="icon" onClick={() => handleTotalPeople("add")}>
                <PlusIcon />
              </i>
            </div>
          </div>
        </div>
        <div className="totalPeople">
          <span className="totalPeopleOne">
            <UserIcon />
          </span>
          <div className="totalPeopleTwo">
            <span className="text">
              <IntlMessage id="Snapay.DivideEquallyYouPayFor" />
            </span>
            <div className="AddBtn">
              <i
                className={`icon ${peopleYouPayFor === 1 && "defaultIcon"}`}
                onClick={handlePeopleYouPayFor}
              >
                <MinusIcon />
              </i>
              <span className="count">{peopleYouPayFor}</span>
              <i className="icon" onClick={() => handlePeopleYouPayFor("add")}>
                <PlusIcon />
              </i>
            </div>
          </div>
        </div>
      </div>
      <hr></hr>
      <div className="totalComman total">
        <span>
          <IntlMessage id="Snapay.DivideEquallyTotal" />
        </span>
        <span>
          <IntlMessage id="Snapay.QAR" />{" "}
          {addCommaToNumbers(OrderDetails?.total)}
        </span>
      </div>
      <div className="totalComman yourShare">
        <span>
          <IntlMessage id="Snapay.DivideEquallyYourShare" />
        </span>
        <span>
          <IntlMessage id="Snapay.QAR" /> {addCommaToNumbers(userTotal)}
        </span>
      </div>
      <div className="buttonWrapper">
        <WhiteButton
          button_text_color={customTheme?.button_text_color}
          button_primary_color={customTheme?.button_primary_color}
          onClick={Close}
        >
          <IntlMessage id="Snapay.DivideEquallyRemoveSplit" />
        </WhiteButton>
        <ThemeButton
          button_text_color={customTheme?.button_text_color}
          button_primary_color={customTheme?.button_primary_color}
          button_secondary_color={customTheme?.button_secondary_color}
          onClick={handleConfirm}
        >
          <IntlMessage id="Snapay.DivideEquallyConfirm" />
        </ThemeButton>
      </div>
    </DivideBillEqualLlyDrawerStyled>
  );
};

export default DivideBillEqualLlyDrawer;

const DivideBillEqualLlyDrawerStyled = styled.div`
  padding: 26px 24px;
  background: ${({ background }) => `url(${background})`};
  background-size: contain;

  .heading {
    color: ${({ textColor }) => textColor};
    font-family: "DM Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.42px;
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
  }
  .mainWrapper {
    margin: 26px 0 38px 0;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .totalPeople {
      display: flex;
      gap: 5px;
      color: ${({ themeColor }) => themeColor};

      .totalPeopleOne {
        display: flex;
        align-items: center;
      }
      .totalPeopleTwo {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
      }
      .text {
        color: ${({ textColor }) => textColor};
        text-align: center;
        font-family: "DM Sans", sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.36px;
      }
    }
  }
  .AddBtn {
    display: flex;
    align-items: center;
    gap: 5px;

    .count {
      color: ${({ textColor }) => textColor};
      font-family: "DM Sans", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.42px;
    }
    .icon {
      background: ${({ themeColor }) => themeColor};
      width: 17px;
      height: 17px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .defaultIcon {
      background: #acacac;
    }
  }
  .totalComman {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }
  .total {
    color: #646464;
    font-family: "DM Sans", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 14px 0 10px 0;
  }
  .yourShare {
    color: ${({ textColor }) => textColor};
    font-family: "DM Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .buttonWrapper {
    display: flex;
    gap: 10px;
    margin-top: 50px;
  }
`;
