import { createGlobalStyle } from "styled-components"

export const CardInfo = createGlobalStyle`
   .cardInfo{
        label{
            display: inline-block;
            font-family: 'Jost';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 25px;
            color: #121212;
        }

        .form-control {
            display: block;
            width: 100%;
            padding: 15px;
            font-size: 1rem;
            line-height: 1.5;
            color: #495057;
            transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
            margin-top: 5px;
            background: #FFFFFF;
            border: 1px solid #E7E7E7;
            border-radius: 8px;
        }

        .form-control::after,.form-control::before{
            border: none;
        }

        .form-control input{
            padding: 2px;
        }

        form{
            padding:0px 25px 25px 25px;
        }

        .col-6{
            width: 50%;
        }

        .row{
            display: flex;
            margin-top: 15px;;
        }

        .submitButton{
            display: flex;
            justify-content: center;
            button{
                font-family: 'Jost';
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                letter-spacing: 0.03em;
                text-transform: uppercase;
                color: ${(({theme})=>theme?.paymentButtonColor)};
                padding: 12px 29px;
                background: ${(({theme})=>theme?.paymentButtonBg)};
                border-radius: 6.7806px;
                border: none;
                position: relative;
                width: 130px;
            }
        }
    }
`