import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { addToCART } from "../../services/Collection";
import {
  decrementRecommendedQty,
  incrementRecommendedQty,
  updateCartData,
} from "../../store/Cart";
import "../../style/Recommendeditem.scss";
import IntlMessage from "../../Utils/IntlMessage";
import styled from "styled-components";

const CartRecommendedItem = ({ reloadData }) => {
  const aqlutstorage = process.env.REACT_APP_IMAGE_BASEURL;
  const containerItem = process.env.REACT_APP_CONTAINER_ITEM;

  const { direction, cartData, restaurentData, recomendedCartItems } =
    useSelector(({ selectLang, cartSlice, restaurentSlice }) => ({
      ...selectLang,
      ...cartSlice,
      ...restaurentSlice,
    }));
  const POSisEnable = useSelector((state) => state?.posSlice?.isEnable);
  const isDeliveryEnable = useSelector((state) => state?.deliveryModule);
  const customTheme = useSelector((state) => state?.theme?.branding);
  const getAlreadyScanedID = useSelector((state) => state.StoreForMultiUsers);
  const currentActive =
    getAlreadyScanedID?.getScannedQrcodeAndRestuarent?.slice(-1);

  const currentID = currentActive?.[0]?.qrcodeid;
  const styling = restaurentData?.Menu?.Restaurent?.MenuPageBranding;

  //style

  const home_button_bg_color = styling?.home_button_bg_color;
  const home_button_text_color = styling?.home_button_text_color;
  const home_price_text_color = styling?.home_price_text_color;

  const dispatch = useDispatch();

  const handleAddRecommededItem = async (item) => {
    let qrcode = "";

    if (restaurentData?.QrCodeGroup?.group_type === "Dine In") {
      qrcode = "Dine In";
    } else {
      if (isDeliveryEnable?.orderType === "takeaway") {
        qrcode = "Takeaway";
      } else {
        qrcode = "Delivery";
      }
    }

    const obj = {
      qr_code_id: currentID,
      qr_code_type: qrcode,
      item: item?.Item?.id,
      section_id: item?.Item?.sectionId,
      currency: "qar",
      price: POSisEnable
        ? parseFloat(item?.ItemPrices?.[0]?.pos_item_price * 1).toFixed(2)
        : parseFloat(item?.ItemPrices?.[0]?.price * 1).toFixed(2),
      qty: 1,
      price_option_id: item?.ItemPrices?.[0]?.id,
      option_price: POSisEnable
        ? parseFloat(item?.ItemPrices?.[0]?.pos_item_price * 1).toFixed(2)
        : parseFloat(item?.ItemPrices?.[0]?.price * 1).toFixed(2),
      price_option_calories: item?.ItemPrices?.[0]?.calories,
      recommended_item: [],
      addon: [],
      special_notes: "",
      ar_special_notes: "",
    };

    let res = await addToCART(obj);
    if (res.status === 200) {
      toast.success("Item Added Successfully");
      reloadData();
      dispatch(updateCartData(res?.data));
    } else {
      toast.error("Item and Recommended Item should be not same, Try another");
    }
  };

  const updateRCartItem = async (ItemId, type, cartId) => {
    let obj = {
      type,
      item_id: ItemId.toString(),
      cart_id: cartId.toString(),
    };
  };

  const handleRecommendedIncrement = (id, type, cartid) => {
    dispatch(incrementRecommendedQty(id));
    updateRCartItem(id, type, cartid);
  };

  const handleRecommendedDecrement = (id, type, cartid) => {
    dispatch(decrementRecommendedQty(id));
    updateRCartItem(id, type, cartid);
  };

  const findData = (id) => {
    let count = 0;
    cartData?.map((item) => {
      item?.AddToCartRecommendedItems?.map((rItem) => {
        if (id === rItem.recommended_item_id) {
          count = rItem.count;
        }
      });
    });
    return count;
  };

  return (
    <>
      {recomendedCartItems?.length > 0 && (
        <div className="ItemContainer" dir={direction}>
          <div className="FoodDetailsCntainers">
            <div className="RecoomandDetailsWrappers">
              <p style={{ color: customTheme?.header_color }}>
                <IntlMessage id="AddItemDrawer.recommendeditems" />
              </p>
            </div>

            <div className="RecommandMainContainer">
              {recomendedCartItems?.map((data, key) => {
                return (
                  <>
                    {console.log(data, "datadtatadtdaj")}
                    <RecommendInnerContainer
                      boxBackground={customTheme?.placeholder_box_color}
                    >
                      <div className="recommendIndivaualSection">
                        <div className="recommend-left-section">
                          <img
                            src={
                              aqlutstorage + containerItem + data?.Item?.image
                            }
                            alt="food-img"
                          />
                        </div>

                        <div className="recommend-right-section">
                          <div className="name-price-section">
                            <p style={{ color: customTheme?.header_color }}>
                              {direction === "ltr"
                                ? data?.Item?.name || ""
                                : data?.Item?.ar_name || ""}
                            </p>
                            <p>
                              <h5 style={{ color: customTheme?.theme_color }}>
                                QAR{" "}
                                {POSisEnable
                                  ? data?.Item?.ItemPrices[0].pos_item_price
                                  : data?.Item?.ItemPrices[0].price}
                              </h5>
                            </p>
                          </div>

                          <div className="recommendedAddtocart">
                            {POSisEnable ? (
                              <div className="ReSizeContainer">
                                {recomendedCartItems?.includes(
                                  data?.recommended_item_id
                                ) ? (
                                  <div className="ReButtonContainer">
                                    {data.ItemPrices && (
                                      <>
                                        <div className="ReButtonWrapper">
                                          <button
                                            onClick={() =>
                                              handleRecommendedDecrement(
                                                data?.id,
                                                "2",
                                                data.id
                                              )
                                            }
                                          >
                                            -
                                          </button>
                                          <p>{findData(data?.id)}</p>
                                          <button
                                            onClick={() =>
                                              handleRecommendedIncrement(
                                                data.id,
                                                "1",
                                                data.id
                                              )
                                            }
                                          >
                                            +
                                          </button>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                ) : (
                                  <div className="ReButtonContainer">
                                    {data?.ItemPrices && (
                                      <>
                                        <div className="">
                                          <button
                                            className="FoodGet"
                                            style={{
                                              color:
                                                customTheme?.button_text_color,
                                              background: `linear-gradient(91.33deg,${customTheme?.button_primary_color} 0%,${customTheme?.button_secondary_color} 100%)`,
                                            }}
                                            onClick={() => {
                                              handleAddRecommededItem(data);
                                            }}
                                          >
                                            ADD
                                          </button>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div className="ReSizeContainer">
                                {recomendedCartItems?.includes(
                                  data?.recommended_item_id
                                ) ? (
                                  <div className="ReButtonContainer">
                                    {data.ItemPrices && (
                                      <>
                                        <div className="ReButtonWrapper">
                                          <button
                                            onClick={() =>
                                              handleRecommendedDecrement(
                                                data?.id,
                                                "2",
                                                data.id
                                              )
                                            }
                                          >
                                            -
                                          </button>
                                          <p>{findData(data?.id)}</p>
                                          <button
                                            onClick={() =>
                                              handleRecommendedIncrement(
                                                data.id,
                                                "1",
                                                data.id
                                              )
                                            }
                                          >
                                            +
                                          </button>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                ) : (
                                  <div className="ReButtonContainer">
                                    {data?.ItemPrices && (
                                      <>
                                        <div className="">
                                          <button
                                            style={{
                                              color:
                                                customTheme?.button_text_color,
                                              background: `linear-gradient(91.33deg,${customTheme?.button_primary_color} 0%,${customTheme?.button_secondary_color} 100%)`,
                                            }}
                                            className="FoodGet"
                                            onClick={() =>
                                              handleAddRecommededItem(data)
                                            }
                                          >
                                            ADD
                                          </button>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </RecommendInnerContainer>
              
                  </>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CartRecommendedItem;

const RecommendInnerContainer = styled.div`
  margin: 10px 4px;
  .recommendIndivaualSection {
    width: 320px;
    height: 108px;
    padding: 10px;
    display: flex;
    gap: 8px;
    border-radius: 8px;
    box-shadow: 0 3px 8px 4px rgb(0 0 0 / 15%);
    background: ${({ boxBackground }) => boxBackground};

    .recommend-left-section {
      width: 30%;
      height: 100%;
      border-radius: 8px;
      border: 1px solid rgb(0 0 0 / 8%);

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
        background: #fff;
      }
    }

    .recommend-right-section {
      width: 70%;
      height: 100%;
      // background: red;
      display: flex;
      justify-content: space-between;

      .name-price-section {
        p {
          font-family: "Nunito Sans", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 157%;
          margin: 0;
          color: #858992;
          text-transform: capitalize;
        }
        h5 {
          font-family: "Nunito Sans", sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 157%;
          margin: 0;
          color: #858992;
          text-transform: capitalize;
        }
      }

      .recommendedAddtocart {
        display: flex;
        align-items: end;
      }
    }
  }
`;
