/* eslint-disable react-hooks/exhaustive-deps */
import {
  DirectionsRenderer,
  DirectionsService,
  GoogleMap,
  useLoadScript,
} from "@react-google-maps/api";
import React, { useRef, useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { AddressBackBtn, YourLocationIcon } from "../../Utils/Images";
import { useNavigate } from "react-router-dom";
import { SelectMapLocationStyleBox } from "../../pages/SelectLocationFromMap/SelectMapLocationStyle";
import { MapIcon } from "../../Utils/Icons";

export default function GetDirection() {
  const GoogleMapApiKey = process.env.REACT_APP_MAP_APIKEY;
  const { isLoaded, loadError } = useLoadScript({
    id: "google-map-script",
    googleMapsApiKey: GoogleMapApiKey,
    libraries: ["places"],
  });
  const navigate = useNavigate();

  const RestaurentLocation = useSelector(
    (state) => state?.restaurentSlice?.restaurentData?.Menu?.Restaurent
  );
  const customTheme = useSelector((state) => state?.theme?.branding);

  const [directionResponse, setDirectionResponse] = useState(null);
  const [latLong, setLatLong] = useState({
    lat: "",
    lng: "",
    concatValue: null,
  });

  var options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

  const getCurrentLocation = useCallback(() => {
    if (navigator.geolocation)
      navigator.geolocation.getCurrentPosition(
        showPosition,
        showError,
        options
      );
  }, [navigator.geolocation.getCurrentPosition]);

  function showPosition(position) {
    // setShowMarker(true);
    let currentLocation = {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
      concatValue: `${position.coords.latitude},${position.coords.longitude}`,
    };
    console.log(currentLocation?.concatValue, "currentLocation");
    setLatLong(currentLocation);
  }

  function showError(error) {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        alert("Location access permission is denied");
        break;
      case error.POSITION_UNAVAILABLE:
        console.log("Location information is unavailable.");
        break;
      case error.TIMEOUT:
        console.log("The request to get user location timed out.");
        break;
      case error.UNKNOWN_ERROR:
        console.log("An unknown error occurred.");
        break;
      default: {
        console.log("An error occurred.");
      }
    }
  }

  const prevLocation = useRef({
    lat: "",
    lng: "",
  });

  const directionsCallback = (response) => {
    console.log("prevLocation.current", prevLocation.current);
    console.log("latLong", latLong);
    if (response !== null) {
      if (response.status === "OK") {
        if (
          prevLocation.current?.lat === "" &&
          prevLocation.current?.lng === ""
        ) {
          setDirectionResponse(response);
        } else {
          const distance = calculateDistance(
            prevLocation.current?.lat,
            prevLocation.current?.lng,
            latLong?.lat,
            latLong?.lng
          );
          console.log(distance, "distance");

          if (distance > 0.01) {
            setDirectionResponse(response);
          }
        }

        prevLocation.current = {
          lat: latLong?.lat,
          lng: latLong?.lng,
        };

        setTimeout(() => {
          // setDirectionResponse(response);
        }, 10000);
      } else {
        console.log("response: ", response);
      }
    }
  };

  const calculateDistance = (
    lattitude1,
    longittude1,
    lattitude2,
    longittude2
  ) => {
    const toRadian = (n) => (n * Math.PI) / 180;

    let lat2 = lattitude2;
    let lon2 = longittude2;
    let lat1 = lattitude1;
    let lon1 = longittude1;

    let R = 6371; // km
    let x1 = lat2 - lat1;
    let dLat = toRadian(x1);
    let x2 = lon2 - lon1;
    let dLon = toRadian(x2);
    let a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadian(lat1)) *
        Math.cos(toRadian(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let d = R * c;
    // console.log("distance==?", d);
    return d;
  };

  const restaurantLocation = useSelector(
    (state) => state?.restaurentSlice?.restaurentData?.Menu?.Restaurent
  );

  useEffect(() => {
    getCurrentLocation();
  }, []);

  return (
    <div style={{ background: "#f1e9d7" }}>
      {isLoaded ? (
        <SelectMapLocationStyleBox>
          <div className="map-wrapper">
            <GoogleMap
              mapContainerStyle={{
                width: "100%",
                height: "76vh",
              }}
              zoom={12}
              center={{
                lat: 25.2854,
                lng: 51.531,
              }}
            >
              {RestaurentLocation && latLong?.concatValue && (
                <DirectionsService
                  // required
                  options={{
                    destination: `${RestaurentLocation?.latitude},${RestaurentLocation?.longitude}`,
                    origin: `${latLong?.concatValue}`,
                    travelMode: "DRIVING",
                  }}
                  // required
                  callback={directionsCallback}
                  // optional
                  // onLoad={(directionsService) => {
                  //   console.log(
                  //     "DirectionsService onLoad directionsService: ",
                  //     directionsService
                  //   );
                  // }}
                  // // optional
                  // onUnmount={(directionsService) => {
                  //   console.log(
                  //     "DirectionsService onUnmount directionsService: ",
                  //     directionsService
                  //   );
                  // }}
                />
              )}

              {directionResponse && (
                <DirectionsRenderer
                  // required
                  options={{
                    directions: directionResponse,
                  }}
                  // optional
                  // onLoad={(directionsRenderer) => {
                  //   console.log(
                  //     "DirectionsRenderer onLoad directionsRenderer: ",
                  //     directionsRenderer
                  //   );
                  // }}
                  // // optional
                  // onUnmount={(directionsRenderer) => {
                  //   console.log(
                  //     "DirectionsRenderer onUnmount directionsRenderer: ",
                  //     directionsRenderer
                  //   );
                  // }}
                />
              )}
            </GoogleMap>
          </div>
          <div className="map-btnsWrapper">
            <div className="btns-title">
              <div
                className="back-btn"
                onClick={() => navigate(-1)}
                style={{
                  background: `linear-gradient(91.33deg,${customTheme?.button_primary_color} 0%,${customTheme?.button_secondary_color} 100%)`,
                }}
              >
                <img alt="" src={AddressBackBtn} />
              </div>
              <h3>
                {RestaurentLocation?.name} Location
              </h3>
            </div>
            <div className="currentLocation-Box">
              <MapIcon
                PrimaryColor={customTheme?.button_primary_color}
                SecondaryColor={customTheme?.button_secondary_color}
              />
              <h4  >{RestaurentLocation?.address}</h4>
            </div>

            <div className="maps-btns">
              <a
                href={`http://maps.google.com/maps?q=${restaurantLocation?.latitude},${restaurantLocation?.longitude}`}
              >
                <button
                  className="set-location"
                  style={{
                    color: customTheme?.button_text_color,
                    background: `linear-gradient(91.33deg,${customTheme?.button_primary_color} 0%,${customTheme?.button_secondary_color} 100%)`,
                  }}
                >
                  Start Navigation
                </button>
              </a>
            </div>
          </div>
        </SelectMapLocationStyleBox>
      ) : (
        <>Loading...</>
      )}
    </div>
  );
}
