import styled from "styled-components";

export const SelectLocationWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const SelectLocationContentBox = styled.div`
  width: 100%;
  height: 100%;
  padding: 15px;
  margin-top: 5px;

  .Current-Location-box {
    width: 100%;
    // height: 100%;
    display: flex;
    cursor: pointer;
    gap: 20px;

    .Location-icon {
      padding-top: 8px;
    }

    .Location-title {
      width: 90%;
      padding-bottom: 20px;
      border-bottom: 1px solid #282c3f;

      h4 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.383376px;
        color: #f92972;
      }

      h6 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.383376px;
        color: #f92972;
      }
    }
  }
`;

export const SelectAddressParent = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;
  margin-top: 30px;

  .address-title {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .title {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #242424;
    }
  }

  .delete-icon-d {
    position: absolute;
    top: 2px;
    left: ${({ dir }) => dir === "rtl" && "0"};
    right: ${({ dir }) => dir === "ltr" && "0"};
  }

  .Selected-address {
    width: 100%;
    // height: 100%;
    margin-top: 10px;
    background: #f8f8fb;
    border-radius: 10px;
    padding: 15px;

    .SelectedAddress-title {
      width: 100%;
      display: flex;
      gap: 10px;
      position: relative;
      margin: 7px 0;

      input {
        // accent-color: #f62773;
        width: 18px;
      }

      .title-Wrapper {
        width: 55%;
        h3 {
          margin-top: 2px;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 26px;
          color: #242424;
        }
      }
      .default-btn {
        display: flex;
        justify-content: end;
        span {
          cursor: pointer;
          border: none;
          color: #fff;
          padding: 0 14px;
          display: flex;
          align-items: center;
          font-size: 12px;
          background: linear-gradient(91.33deg, #fc2b6e 0%, #bc00a9 100%);
          border-radius: 27.8571px;
        }
      }
      .default-btn-gray {
        display: flex;
        justify-content: end;
        span {
          cursor: pointer;
          border: none;
          color: #2b2b2b;
          font-weight: 600;
          padding: 0 14px;
          display: flex;
          align-items: center;
          font-size: 12px;
          background: #c9c9c9d6;
          border-radius: 27.8571px;
        }
      }
    }
    .SelectedAddress-content {
      width: 100%;
      position: relative;

      p {
        margin: 0;
        padding: 0;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #202020;
        width: 96%;
      }

      .edit-btn {
        position: absolute;
        bottom: 0;
        right: ${({ dir }) => dir === "ltr" && "0"};
        left: ${({ dir }) => dir === "rtl" && "0"};

        p {
          cursor: pointer;
          margin-top: 15px;
          background: transparent;
          border: none;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 0px;
          color: #fc2b6e;
        }
      }
    }
  }
`;
