import React, { useEffect, useRef } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import { GetRestaurantBanners } from "../../services/Collection";
import { useState } from "react";
import { SwipeableDrawer } from "@mui/material";
import { useSelector } from "react-redux";
import Confetti from "react-confetti";

export default function AdvertisementBanner() {
  const aqlutstorage = process.env.REACT_APP_IMAGE_BASEURL;
  const containerAdvertisment = process.env.REACT_APP_CONTAINER_BANNER;

  var settings = {
    dots: false,
    infinite: false,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
  };

  const restaurantid = useSelector(
    (state) => state?.restaurentSlice?.restaurentData
  );
  const direction = useSelector((state) => state?.selectLang?.direction);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [bannerData, setBannerData] = useState();
  const [advertisementBanner, setAdvertisementBanner] = useState([]);
  const fetchingAdvertisement = async () => {
    let res = await GetRestaurantBanners(restaurantid.restaurent_id);
    if (res.status === 200) {
      setAdvertisementBanner(res.data);
    }
  };

  const BannerAction = (banner) => {
    if (banner?.action_type === "2") {
      setOpenDrawer(true);
      setBannerData(banner);
    } else {
      window.open(banner?.action_url, "_blank");
    }
  };

  const CelebrationEffect = () => {
    const [isEffectEnble, setIsEffectEnble] = useState(true);
    const [controlOpacity, setControlOpacity] = useState(1);

    setTimeout(() => {
      setIsEffectEnble(false);
    }, 6000);

    setInterval(() => {
      setControlOpacity(0.4);
    }, 5000);

    return (
      <>
        {isEffectEnble && (
          <Confetti
            numberOfPieces={150}
            width={900}
            height={500}
            gravity={0.1}
            opacity={controlOpacity}
            // colors={['#FC2B6E', '#BC00A9' , '#f8e5f6' , '#380032' , '##d666cb' , '#a9bc00', '#00a9bc']}
          />
        )}
      </>
    );
  };

  useEffect(() => {
    fetchingAdvertisement();
  }, []);
  return (
    <>
      {openDrawer && (
        <div>
          <SwipeableDrawer
            anchor={"bottom"}
            open={openDrawer}
            onClose={() => setOpenDrawer(false)}
            dir={direction}
            style={{ borderRadius: "20px" }}
          >
            <DrawerWrapper>
              <CelebrationEffect />

              <div
                style={{ fontFamily: "Poppins", padding: "0 10px" }}
                dangerouslySetInnerHTML={{
                  __html: bannerData?.action_description,
                }}
              />
            </DrawerWrapper>
          </SwipeableDrawer>
        </div>
      )}
      {advertisementBanner.length > 0 && (
        <BannerWrapepr>
          <Slider {...settings}>
            {advertisementBanner.length > 0 &&
              advertisementBanner?.map((banner) => (
                <AdvertisementBox onClick={() => BannerAction(banner)}>
                  <img
                    src={
                      `${aqlutstorage}` +
                      `${containerAdvertisment}` +
                      `${banner?.image_url}`
                    }
                    alt=""
                  />
                </AdvertisementBox>
              ))}
          </Slider>
        </BannerWrapepr>
      )}
    </>
  );
}

const BannerWrapepr = styled.div`
  width: 100%;
  // height: 220px;
  background: #fff;
  padding: 10px 6px 10px 0;
`;

const AdvertisementBox = styled.div`
  width: 399px !important;
  height: 183px;

  @media (max-width: 399px) {
    width: 100% !important;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 16px;

    @media (min-width: 580px) and (max-width: 1024px) {
      // object-fit: contain;
    }
  }
`;

const DrawerWrapper = styled.div`
  width: 100%;
  height: 400px;
  padding: 20px;
  animation-name: slideInAnimation;
  animation-duration: 0.5s;
  animation-timing-function: ease-in;
  // animation-iteration-count: 1;
  animation-delay: 0s;
  @keyframes slideInAnimation {
    0% {
      width: 80%;
      height: 22px;
      transform: translateY(0);
      opacity: 0.1;
    }

    100% {
      width: 80%;
      height: 400px;
      transform: translateY(100);
      opacity: 1;
    }
  }
`;
