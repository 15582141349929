import React from "react";
import { CircularProgress } from "@mui/material";
import BillDetailPayment from "../../components/BillDetail/OrderDetail";
import ChatResponse from "../../components/ChatResponse";
import SelectedItem from "../../components/SelectedItem";
import { LoadingWapper } from "../../style/GobalStyle";
import IntlMessage from "../../Utils/IntlMessage";
import { useSelector } from "react-redux";

function OrderDetailCustomer({ fetchingDetails, fetchingDataLoader }) {
  var today = new Date();
  var time = today.getHours() + ":" + today.getMinutes();
  const customTheme = useSelector((state) => state?.theme?.branding);

  return (
    <>
      {fetchingDetails.length > 0 && (
        <ChatResponse
          message={<IntlMessage id="payment.button.orderdetails" />}
          time={time}
        />
      )}
      {fetchingDataLoader ? (
        <LoadingWapper>
          <CircularProgress sx={{ color: "#FC2B6E", my: 2 }} />
        </LoadingWapper>
      ) : fetchingDetails.length > 0 ? (
        <>
          <div className="payment-listing" style={{background: customTheme?.background_color , border:`1px solid ${customTheme?.theme_color}`}} >
            <SelectedItem data={fetchingDetails} />
            <BillDetailPayment type={"OrderDetail"} data={fetchingDetails} />
          </div>
        </>
      ) : null}
    </>
  );
}

export default OrderDetailCustomer;
