import styled from "styled-components";

export const BillDetailStyle = styled.div`
margin: 5px 0;
  .bill-details {
    padding: 10px;
    width: 100%;
    background: #f8f8fb;
    border-radius: 10px;
    box-shadow: 0 3px 8px 4px rgb(0 0 0 / 15%);
    border-radius: 8px;

    h1 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      color: #fc2b6e;
      letter-spacing: 0.05em;
    }

    .charges {
      display: flex;
      align-items: center;

      h2 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #202020;
        width: 50%;
      }

      h4 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #202020;
        width: 50%;
        margin-top: 12px;
        text-align: ${({ dir }) => dir === "rtl" && "left"};
        text-align: ${({ dir }) => dir === "ltr" && "right"};
      }
    }
    .bold {
      font-weight: 600 !important;
      font-size: 15px !important;
      line-height: 22px !important;
    }

    h2 {
      font-family: "Jost";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      color: #000000;
      margin-top: 12px;
    }

    .paymentAmount {
      display: flex;
      align-items: center;
      margin-top: 8px;
      input {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #7b7f91;
        border: 1px solid #f0f1f7;
        border-radius: 8px;
        width: 70%;
        padding: 10px 12px;
      }
      h3 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #202020;
        width: 30%;
        padding-left: 6px;
        text-align: end;
      }
    }
  }
`;
