import React, { useState } from 'react'
import { Header } from '../../components';
import CardDatePicker from './CardDatePicker';
import CheckoutPrice from './CheckoutPrice';
import MakePayment from './MakePayment';
import moment from 'moment';
import { useSelector,useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {paymentSuccess} from '../../store/orderPayment'
import {updateChat} from '../../store/ChatHistory'
const first=require('../../assets/first.png');
const second=require('../../assets/second.png');
const third=require('../../assets/third.png');

const  CardInfo=()=> {

  const [dateSelected, setDateSelected] = useState('');
  const dispatch = useDispatch();
  const navigate=useNavigate();
  const getDateValue = (date) => {
    setDateSelected(moment(date).format('DD/MM/YYYY'))
  }
  const handleSuccess=()=>{
    dispatch(paymentSuccess(true))
    const obj1 = {
      type:"boatMessage",
      message:'Order fully paid',
      time:'1:30 pm'
    } 

    const obj2 = {
      type:"ViewReceipt",
      name:'View Receipt',
      img:first
    }

    const obj3 = {
      type:"Email",
      name:'Email',
      img:second
    }

    const obj4 = {
      type:"Givefeedback",
      name:'Give feedback',
      img:third
    }
    dispatch(updateChat([obj1,obj2,obj3,obj4]))
    navigate("/payment-success")
  }
  
  const direction = useSelector((state)=>state.selectLang.direction)
  const enStyle = {
    paddingRight:"10px"
  }

  const arbStyle = {
    paddingLeft:"10px"
  }

  return (
    <>
      <Header />
      <div className='cardInfo' dir={direction}  >
        <CheckoutPrice />
        <MakePayment img={'/assets/images/scanner.svg'}/>
        <form>
          <label>Name</label>
          <input placeholder='Name' className='form-control'/>
          <label style={{marginTop:"15px"}}>Card Number</label>
          <input className='form-control' placeholder='XXXX'/>
          <div className='row'>
            <div className='col-6' style={(direction==='rtl' ? arbStyle : enStyle)}>
              <label>Expiry</label>
              <CardDatePicker onDateTouch={getDateValue} value={dateSelected}/>
            </div>
            <div className='col-6' style={(direction==='rtl' ? enStyle : arbStyle)}>
              <label>CVV</label>
              <input placeholder='---' className='form-control'/>
            </div>
          </div>
        </form>
        <div className='submitButton'>
          <button onClick={handleSuccess}>SUBMIT</button>
        </div>
      </div>
    </>
  )
}

export default CardInfo