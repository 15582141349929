import React from 'react'
import styled from 'styled-components';

function MakePayment({img}) {

  return (
    <MakePaymentStyle>
        <h6>Make Payment</h6>
        <img src={img} alt='make payment'/>
    </MakePaymentStyle>
  )
}

export default MakePayment

const MakePaymentStyle = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  h6{
    font-family: 'Jost';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 124.5%;
    color: #202020;
  }
`;

