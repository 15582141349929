import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "userAuth",
  initialState: {
    data: [],
    IsMasterAuth: [],
    IsMaster: false,
    token: null,
    initialScan: true,
    isOtpVerified: false,
  },

  //This is SelectLang reducers.

  reducers: {
    authSucess: (state, action) => {
      if (action.payload) {
        state.data = action.payload;
      } else {
        state.data = [];
      }
    },
    loginWithMasterQrCode: (state, action) => {
      if (action.payload) {
        state.IsMasterAuth = action.payload;
      }
    },
    checkQrcodeIsMaster: (state, action) => {
      if (action) {
        state.IsMaster = action.payload;
      }
    },
    Logout: (state, action) => {
      state.data = [];
      state.IsMasterAuth = [];
      state.IsMaster = false;
      state.token = null;
      state.initialScan = true;
      state.isOtpVerified = false;
    },
    supertoken: (state, action) => {
      if (action.payload) {
        state.token = action.payload;
      }
    },
    InitialScan: (state, action) => {
      state.initialScan = action.payload;
    },
    IsOtpVerified: (state, action) => {
      state.isOtpVerified = action.payload;
    },
  },
});

//This is Selectlang actions.

export const {
  authSucess,
  loginWithMasterQrCode,
  checkQrcodeIsMaster,
  Logout,
  supertoken,
  InitialScan,
  IsOtpVerified,
} = userSlice.actions;
export default userSlice.reducer;
