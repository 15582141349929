import React from "react";
import Dialog from "@mui/material/Dialog";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import IntlMessage from "../../Utils/IntlMessage";
import { DeleteCrossIcon } from "../../Utils/Images";

export default function Delete({
  open,
  handleClose,
  handleConfirm,
  payload,
  loading,
}) {
  const direction = useSelector((state) => state?.selectLang?.direction);

  return (
    <DeleteParentBox>
      <Dialog
        // fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Cancel onClick={handleClose} dir={direction}>
          <img src={DeleteCrossIcon} alt="" />
        </Cancel>
        <DeleteWrapper dir={direction}>
          <p>
            <IntlMessage id="sure" />?
          </p>

          {loading ? (
            <CircularProgress sx={{ color: "#f55a2c" }} />
          ) : (
            <div style={{ display: "flex" }}>
              <BlackButtonMobile onClick={() => handleClose()}>
                <IntlMessage id="button.close" />
              </BlackButtonMobile>
              <BlackButton onClick={() => handleConfirm(payload)}>
                <IntlMessage id="button.DELETE" />
              </BlackButton>
            </div>
          )}
        </DeleteWrapper>
      </Dialog>
    </DeleteParentBox>
  );
}

const DeleteParentBox = styled.div`
  div {
    overflow: none;
    position: relative;
  }
`;

export const DeleteButton = styled.div`
  width: 40px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #f55a2c;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.25);
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    font-size: 18px;
    color: #f55a2c;
  }
`;

const DeleteWrapper = styled.div`
  width: 100%;
  padding: 20px 56px;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 29px;
    color: #000000;
    margin: 25px 0;
  }

  ${
    "" /* @media (max-width: 599px) {
    ${"" /* width: 100%; */
  }
    display: flex;
    align-items: center;
    justify-content: center;
  } */}
`;

const BlackButton = styled.button`
  background: #000000;
  box-shadow: 0px 2px 16px rgba(61, 107, 192, 0.25);
  border-radius: 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  padding: 10px 20px;
  margin: 5px 10px;
  cursor: pointer;
`;

const BlackButtonMobile = styled.span`
  ${"" /* display: none; */}

  display: unset;
  background: #000000;
  box-shadow: 0px 2px 16px rgba(61, 107, 192, 0.25);
  border-radius: 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  padding: 10px 20px;
  margin: 5px 10px;
  cursor: pointer;

  @media (max-width: 490px) {
    font-size: 12px;
    padding: 10px;
  }
`;

const Cancel = styled.span`
  width: 48px;
  height: 48px;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  top: -18px;
  cursor: pointer;
  z-index: 999;
  right: ${({ dir }) => dir === "ltr" && "-18px"};
  left: ${({ dir }) => dir === "rtl" && "-18px"};
`;
