/* eslint-disable array-callback-return */
import { createSlice } from "@reduxjs/toolkit";

export const cartSlice = createSlice({
  name: "cart",
  initialState: {
    cartData: [],
    subtotalPrice: 0,
    recommendedItems: [],
    totalCount: 0,
    // AddToCartRecommendedItems: [],
    QrData: "",
    totalPrice: 0,
    service_tax: 0,
    vat: 0,
    discount: 0,
    isFreeItem: false,
    isOverallDiscount: false,
    recomendedCartItems: [],
    cartFreeItem: [],
    cartDiscounts: [],
    cartTotalDiscount: [],
    cardValuesSendToDB: {},
  },

  reducers: {
    addData: (state, action) => {
      //after this calculation take place
      // if (state?.cartData?.length > 0) {
      //   if (state?.cartTotalDiscount.length > 0) {
      //     state.cartData = action.payload;
      //   } else if (state?.cartDiscounts.length > 0) {
      //     state.cartData = action.payload;
      //   } else {
      //     if (state?.cartData.toString() === action.payload.toString()) {
      //       console.log("sameeeee 31");
      //     } else {
      //       state.cartData = action.payload;
      //     }
      //   }
      // } else {
      //   state.cartData = action.payload;
      // }

      state.cartData = action.payload;

      // state.cartData = action.payload;
      let temp_vat = state.QrData?.Restaurent?.vat;
      let temp_service = state.QrData?.Restaurent?.service_charge;
      let price = 0;
      let count = 0;
      let temp_discount = 0;
      let tempArrayForDiscountFreeItem = [];
      let tempArrayForDiscountItemAmount = [];

      state?.cartData?.map((item) => {
        item?.AddToCartRecommendedItems?.map((rItem) => {
          price = price + parseInt(rItem?.recommended_price) * rItem?.count;
          count = count + parseInt(rItem?.count);
        });
        item?.Item?.AddToCartAddOns?.map(
          (addOn) =>
            (price = price + parseInt(addOn?.addon_price) * parseInt(item?.qty))
        );
        price =
          price +
          Number(parseFloat(item?.Item?.option_price).toFixed(2)) * parseInt(item?.qty);
        // price = (price - item?.discount);

        // console.log(item , "itemmmmmmmmmmmmmmmmmmmmmm", state.discount)
        // let temp_discount = parseFloat(state.discount) + parseFloat(item?.discount);
        // state.discount = temp_discount.toFixed(2);
        temp_discount = temp_discount + parseFloat(item?.discount);

        count = count + parseInt(item?.qty);
        //Adding discount and freeItem
        if (item?.DiscountItemPrices?.length > 0) {
          item?.DiscountItemPrices?.map((discount) =>
            tempArrayForDiscountItemAmount.push(discount)
          );
          state.cartDiscounts = [...tempArrayForDiscountItemAmount];
        }

        if (item?.DiscountFreeItems?.length > 0 && Number(item?.price) > 0) {
          item?.DiscountFreeItems?.map((freeItem) =>
            tempArrayForDiscountFreeItem.push(freeItem)
          );
          state.cartFreeItem = [...tempArrayForDiscountFreeItem];
        }

        //Calculating discount
        state?.cartTotalDiscount?.map((udx) => {
          const vateno = parseFloat(
            ((state.subtotalPrice * temp_vat) / 100).toFixed(2)
          );
          const service_charge = parseFloat(
            ((state.subtotalPrice * temp_service) / 100).toFixed(2)
          );
          if (
            udx?.minimum_order_amount < state.subtotalPrice &&
            udx?.maximum_order_amount > state.subtotalPrice &&
            state.discount === 0 || udx?.expiry_date
          ) {
            let amount = state.subtotalPrice;
            let discount = parseFloat(udx?.discount);
            let discount_amount = (amount * discount) / 100;
            let discount_value = discount_amount;
            let amount_after_discount = state.subtotalPrice - discount;
            let temp_totalPrice = Number(parseFloat(
              amount_after_discount + vateno + service_charge - discount
            ).toFixed(2));
            state.discount = discount_value;
            state.totalPrice = Number(temp_totalPrice);
          }
        });
      });
      state.cartTotalDiscount.length === 0 &&
        (state.discount = parseFloat(temp_discount));
      state.subtotalPrice = price;

      // calculating price

      const vat = parseFloat(((price * temp_vat) / 100).toFixed(2));

      const service_charge = parseFloat(
        ((price * temp_service) / 100).toFixed(2)
      );
      //after calculation
      state.service_tax = service_charge;
      state.vat = vat;

      price = price + service_charge + vat;
      price = price - state.discount;
      state.totalPrice = Number(parseFloat(price).toFixed(2));

      state.totalCount = count;
      let arr = [];
      state?.cartData?.map((item) =>
        item?.AddToCartRecommendedItems?.map((rItem) =>
          arr.push(rItem?.recommended_item_id)
        )
      );
      state.recommendedItems = arr;

      //This will not repeat recomened item in the cart
      state?.cartData?.map((item) => {
        const temp = item;

        const result = state?.recomendedCartItems?.filter(
          (recom) =>
            !item?.AddToCartRecommendedItems?.some(
              (s) => s?.recommended_item_id === recom?.Item?.id
            )
        );
        if (result?.length > 0) {
          state.recomendedCartItems = result;
        } else {
          state.recomendedCartItems = [];
        }
        return temp;
      });
      state?.cartData?.map((item) => {
        const temp = item;

        const notincart = state?.recomendedCartItems?.filter(
          (recom) => item?.Item?.id !== recom?.Item?.id
        );
        if (notincart?.length > 0) {
          state.recomendedCartItems = notincart;
        } else {
          state.recomendedCartItems = [];
        }
        return temp;
      });

      // state.totalPrice = 0;
      // state.discount = 0;
    },

    addRecomededItemForCart: (state, action) => {
      if (action.payload) {
        state.recomendedCartItems = action.payload;
      }
    },
    incrementQty: (state, action) => {
      let temp_vat = state.QrData?.Restaurent?.vat;
      let temp_service = state.QrData?.Restaurent?.service_charge;
      let price = 0;
      let count = 0;
      let temp_discount = 0;
      state.cartData.map((item) => {
        if (item.id === action.payload.id) {
          item.qty = Number(action.payload.qty);
          item.price = Number(action.payload.price);
        }
      });

      // console.log(state, "actionnnnnnnnnnnnnnnnnnnnnnnnnn");

      state?.cartData?.map((item) => {
        item?.AddToCartRecommendedItems?.map((rItem) => {
          price = price + parseInt(rItem.recommended_price) * rItem?.count;
          count = count + parseInt(rItem?.count);
        });
        item?.Item?.AddToCartAddOns.map((addOn) => {
          price = price + parseInt(addOn.addon_price) * parseInt(item.qty);
        });
        price = price + parseInt(item.Item.option_price) * parseInt(item.qty);
        price = price - item?.discount;
        // state.discount = parseFloat(state.discount + item.discount).toFixed(2);
        temp_discount = temp_discount + parseFloat(item?.discount);

        count = count + parseInt(item?.qty);
      });
      state.discount = parseFloat(temp_discount);
      state.subtotalPrice = price;

      // calculating price
      const vat = parseFloat(((price * temp_vat) / 100).toFixed(2));

      const service_charge = parseFloat(
        ((price * temp_service) / 100).toFixed(2)
      );
      //after calculation
      state.service_tax = service_charge;
      state.vat = vat;

      price = price + service_charge + vat;
      state.totalPrice = Number(parseFloat(price).toFixed(2));
      state.totalCount = count;
    },
    decrementQty: (state, action) => {
      let temp_vat = state.QrData?.Restaurent?.vat;
      let temp_service = state.QrData?.Restaurent?.service_charge;
      let price = 0;
      let count = 0;
      let temp_discount = 0;
      state.cartData.map((item) => {
        if (item.id === action.payload.id) {
          item.qty = Number(action.payload.qty);
          item.price = Number(action.payload.price);
        }
      });

      state?.cartData?.map((item) => {
        item?.AddToCartRecommendedItems?.map((rItem) => {
          price = price + parseInt(rItem.recommended_price) * rItem?.count;
          count = count + parseInt(rItem?.count);
        });
        item?.Item?.AddToCartAddOns.map((addOn) => {
          price = price + parseInt(addOn.addon_price) * parseInt(item.qty);
        });
        price = price + parseInt(item.Item.option_price) * parseInt(item.qty);
        count = count + parseInt(item?.qty);
      });
      state.subtotalPrice = price;
      // calculating price
      const vat = parseFloat(((price * temp_vat) / 100).toFixed(2));

      const service_charge = parseFloat(
        ((price * temp_service) / 100).toFixed(2)
      );
      //after calculation
      state.service_tax = service_charge;
      state.vat = vat;

      price = price + service_charge + vat;
      state.totalPrice = Number(parseFloat(price).toFixed(2));

      state.totalCount = count;
    },
    incrementRecommendedQty: (state, action) => {
      state.cartData.map((item) => {
        return item?.AddToCartRecommendedItems?.map((rItem) => {
          if (rItem.recommended_item_id === action.payload) {
            rItem.count = parseInt(rItem.count) + 1;
          }
        });
      });

      let price = 0;
      let count = 0;
      state?.cartData?.map((item) => {
        item?.AddToCartRecommendedItems?.map((rItem) => {
          price = price + parseInt(rItem.recommended_price) * rItem?.count;
          count = count + parseInt(rItem?.count);
        });
        item?.Item?.AddToCartAddOns.map((addOn) => {
          price = price + parseInt(addOn.addon_price) * parseInt(item.qty);
        });
        price = price + parseInt(item.Item.option_price) * parseInt(item.qty);
        count = count + parseInt(item?.qty);
      });
      state.subtotalPrice = price;

      // calculating price

      const vat = parseFloat(
        ((price * state.QrData?.Restaurent?.vat) / 100).toFixed(2)
      );
      state.vat = vat;

      const service_charge = parseFloat(
        ((price * state.QrData?.Restaurent?.service_charge) / 100).toFixed(2)
      );
      state.service_tax = service_charge;

      price = price + vat + service_charge;
      state.totalPrice = parseFloat(price.toFixed(2));

      state.totalCount = count;
    },

    decrementRecommendedQty: (state, action) => {
      state.cartData.map((item) => {
        return item?.AddToCartRecommendedItems?.map((rItem) => {
          if (rItem.recommended_item_id === action.payload) {
            rItem.count = parseInt(rItem.count) - 1;
          }
        });
      });

      let price = 0;
      let count = 0;
      state?.cartData?.map((item) => {
        item?.AddToCartRecommendedItems?.map((rItem) => {
          price = price + parseInt(rItem.recommended_price) * rItem?.count;
          count = count + parseInt(rItem?.count);
        });
        item?.Item?.AddToCartAddOns.map((addOn) => {
          price = price + parseInt(addOn.addon_price) * parseInt(item.qty);
        });
        price = price + parseInt(item.Item.option_price) * parseInt(item.qty);
        count = count + parseInt(item?.qty);
      });
      state.subtotalPrice = price;

      // calculating price

      const vat = parseFloat(
        ((price * state.QrData?.Restaurent?.vat) / 100).toFixed(2)
      );
      state.vat = vat;

      const service_charge = parseFloat(
        ((price * state.QrData?.Restaurent?.service_charge) / 100).toFixed(2)
      );
      state.service_tax = service_charge;

      price = price + vat + service_charge;
      state.totalPrice = parseFloat(price.toFixed(2));

      state.totalCount = count;
    },
    //Deleting cart item
    DeleteCartItem: (state, action) => {
      if (action.payload.recommended_item_id === -1) {
        state.cartData = state.cartData.filter(
          (item) => item.id !== action.payload.id
        );
      } else {
        state.cartData = state.cartData.map((item) => {
          return item.Item.id !== action.payload.id;
        });
      }
    },

    //Updating
    updateCartData: (state, action) => {
      if (state?.cartData?.length > 0) {
        state?.cartData?.map((item, key) =>
          key === state?.cartData?.length - 1
            ? item.AddToCartRecommendedItems.push(action.payload)
            : ""
        );
        state.recommendedItems = [
          ...state.recommendedItems,
          action.payload.recommended_item_id,
        ];
      }
      //This will not repeat recomened item in the cart  !Boom coder_0001
      state.cartData.map((item) => {
        const temp = item;
        const tempArr = state.recomendedCartItems.filter(
          (udx) =>
            !item.AddToCartRecommendedItems.some(
              ({ recommended_item_id: id2 }) => id2 === udx?.Item?.id
            )
        );
        if (tempArr?.length > 0) {
          state.recomendedCartItems = tempArr;
        } else {
          temp.Item.RecommendedItems = [];
        }
        return temp;
      });
    },
    //Saving cart total discount value
    overAllDiscount: (state, action) => {
      if (action.payload?.length) {
        state.cartTotalDiscount = action.payload;
      } else {
        state.cartTotalDiscount = [];
        state.isOverallDiscount = false;
      }
    },
    //Removing cart items if order is placed succesfully
    emptyCartData: (state, action) => {
      state.cartData = [];
      state.subtotalPrice = 0;
      state.recommendedItems = [];
      state.totalCount = 0;
      state.totalPrice = 0;
      state.service_tax = 0;
      state.vat = 0;
      state.discount = 0;
      state.recomendedCartItems = [];
      state.isFreeItem = false;
      state.isOverallDiscount = false;
    },
    addQrData: (state, action) => {
      if (action.payload) {
        state.QrData = action.payload;
      } else {
        state.QrData = "";
      }
    },
    //Saving the discount items
    addCartDiscounts: (state, action) => {
      if (action.payload) {
        state.cartDiscounts = action.payload;
      } else {
        state.cartDiscounts = [];
      }
    },
    //If Free item added to cart then we will not apply discount and neither show the discount
    changeFreeItemStatus: (state, action) => {
      if (action.payload) {
        state.isFreeItem = action.payload;
      }
    },
    DeleteFreeItem: (state, action) => {
      if (action.payload) {
        state.cartData = state.cartData.filter(
          (item) => item.offer_item_id !== action.payload.offer_item_id
        );
      }
    },
    addCartFreeItems: (state, action) => {
      if (action.payload) {
        state.cartFreeItem = action.payload;
      } else {
        // alert(422)
        state.cartFreeItem = [];
      }
    },
    //Calculate price when discount applied
    calculatePrice: (state, action) => {
      let price = 0;
      let count = 0;

      state?.cartData?.map((item) => {
        if (action.payload.id === item.Item.id) {
          let temp_discount = action.payload.temp_discount;

          item?.Item?.AddToCartAddOns.map((addOn) => {
            price = price + parseInt(addOn.addon_price) * parseInt(item.qty);
          });
          if (temp_discount > 0) {
            price =
              price + parseInt(item.Item.option_price) * parseInt(item.qty);

            let item_discount = Number(parseFloat(
              (price * temp_discount) / 100
            ).toFixed(2));
            price = price - item_discount;
            let temp_dis = state.discount + parseInt(item_discount);
            state.discount = Number(parseFloat(temp_dis).toFixed(2));
            //vat
            let temp_vat = state.QrData?.Restaurent?.vat;
            let temp_service = state.QrData?.Restaurent?.service_charge;
            // calculating price
            const vat = parseFloat(((price * temp_vat) / 100).toFixed(2));

            const service_charge = parseFloat(
              ((price * temp_service) / 100).toFixed(2)
            );
            state.service_tax = service_charge;
            state.vat = vat;
            //after calculation
            price = price + service_charge + vat;

            state.totalPrice = Number(parseFloat(price).toFixed(2));
          }
          //price without any discount
          else if (temp_discount === 0) {
            let temp_vat = state.QrData?.Restaurent?.vat;
            let temp_service = state.QrData?.Restaurent?.service_charge;
            price =
              price + parseInt(item.Item.option_price) * parseInt(item.qty);

            let item_discount = parseFloat(
              (price * temp_discount) / 100
            ).toFixed(2);
            price = price - item_discount;
            let temp_dis = item_discount + parseFloat(state.discount);
            state.discount = Number(parseFloat(temp_dis).toFixed(2));
            const vat = parseFloat(((price * temp_vat) / 100).toFixed(2));

            const service_charge = parseFloat(
              ((price * temp_service) / 100).toFixed(2)
            );
            state.service_tax = Number(service_charge);
            state.vat = Number(vat);
            //after calculation
            price = price + service_charge + vat;

            state.totalPrice = Number(parseFloat(price).toFixed(2));
          }
        }
      });
    },
    UpdateAmount: (state, action) => {
      if (action.payload.type === "1") {
        state.totalPrice = action.payload.totalPrice;
        state.discount = Number(action.payload.discount);
        state.isOverallDiscount = true;
      } else if (action.payload.type === "0") {
        state.totalPrice = action.payload.totalPrice;
        state.isOverallDiscount = false;
        state.discount = 0;
      }
    },

    cardValuesSendToDB: (state, action) => {
      if (action.payload) {
        state.cardValuesSendToDB = action.payload;
      }
    },

    updateActiveDiscount: (state, action) => {
      console.log(action.payload, "updatedActiveDiscount--------------");
      if (action?.payload) {
        if (action?.payload?.DiscountFreeItems) {
          // free discount active
          // Remove itemamountdiscount and toatl discount
          state.cartDiscounts = [];
          state.cartTotalDiscount = [];
        } else if (action?.payload?.DiscountItemPrice) {
          // itemamountdiscount active
          // Remove item free and toatl discount
          state.cartFreeItem = [];
          state.cartTotalDiscount = [];
        } else if (action?.payload?.totalDiscount || action?.payload?.customerMobileDiscount) {
          //toatl discount active
          // Remove item free and itemamount discount
          state.cartFreeItem = [];
          state.cartDiscounts = [];
        } else {
          state.cartFreeItem = [];
          state.cartDiscounts = [];
          state.cartTotalDiscount = [];
          // No discount Active
        }
      }
    },
  },
});

export const {
  addData,
  incrementQty,
  decrementQty,
  incrementRecommendedQty,
  decrementRecommendedQty,
  DeleteCartItem,
  addQrData,
  emptyCartData,
  updateCartData,
  addCartDiscounts,
  addCartFreeItems,
  calculatePrice,
  changeFreeItemStatus,
  cardValuesSendToDB,
  overAllDiscount,
  UpdateAmount,
  DeleteFreeItem,
  addRecomededItemForCart,
  updateActiveDiscount,
} = cartSlice.actions;
export default cartSlice.reducer;
