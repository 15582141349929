import { createSlice } from "@reduxjs/toolkit";

export const restaurentSlice = createSlice({
  name: "resaturentDetail",
  initialState: {
    restaurentData: [],
    restuarentProfileInfo: null,
    delivery_fee: 0,
  },

  //This is SelectLang reducers.
  reducers: {
    addRestaurentData: (state, action) => {
      if (action.payload) {
        state.restaurentData = action.payload;
      } else {
        state.restaurentData = [];
      }
    },
    Restaurentinfo: (state, action) => {
      if (action.payload) {
        state.restuarentProfileInfo = action.payload;
      }
    },
    DeliveryFee: (state, action) => {
      if (action.payload) {
        state.delivery_fee = action.payload;
      }
    },
  },
});

//This is Selectlang actions.

export const { addRestaurentData, Restaurentinfo, DeliveryFee} = restaurentSlice.actions;
export default restaurentSlice.reducer;
