import { CircularProgress, Drawer, SwipeableDrawer } from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import AddTipToBill from "../../app/components/AddTipToBill";
import OrderedItemsBill from "../../app/components/OrderedItemsBill";
import SplitBillDrawer from "../../app/components/SplitBillDrawer";
import {
  SnapayGrayBackground,
  AmexPayIcon,
  ApplePayIcon,
  GooglePayIcon,
  MasterCardPayIcon,
  VisaPayIcon,
} from "../../Utils/Images";
import { useDispatch, useSelector } from "react-redux";
import {
  ActionButton,
  AppleLoaderWapper,
  LoaderWapper,
  ThemeButton,
  WhiteButton,
} from "../../style/SnapayGobalStyle";

import SnapayFooter from "../../app/components/SnapayFooter";
import DivideBillEqualLlyDrawer from "../../app/components/DivideBillEqualLlyDrawer";
import {
  getCheckDetails,
  getOrderDetails,
  GetSplitBillDetailsApi,
  getUpdateOrderDetails,
  syncOrderPayemnt,
  syncPOS,
} from "../../services/Collection";
import { toast } from "react-toastify";
import PayWithCreditCard from "../../app/components/PayWithCreditCard";
import PayCustomAmount from "../../app/components/PayCustomAmount";
import PayForYourItem from "../../app/components/PayForYourItem";
import {
  addOrderDetails,
  updatedOrderFromPos,
  updatelastPaymentMode,
  wayToPayBillType,
} from "../../store/orderPayment";
import { useNavigate } from "react-router-dom";
import { splitBill, updateTip } from "../../store/orderPayment";
import { addCommaToNumbers } from "../../Utils/CommanFunctions";
import IntlMessage from "../../Utils/IntlMessage";
import { PosIsReachable } from "../../store/posStatus";
import { CreditCardIcon, InfoIcon } from "../../Utils/Icons";

const PayBill = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const customTheme = useSelector((state) => state?.theme?.branding);
  const [splitBillDrawer, setSplitBillDrawer] = useState();
  const [divideEquallyDrawer, setdivideEquallyDrawer] = useState();
  const OrderDetails = useSelector(
    (state) => state?.orderInformationSlice?.orderDeails
  );
  const [loading, setLoading] = useState(false);
  const [applePayLoading, setApplePayLoading] = useState(false);
  const [creditCardDrawer, setCreditCardDrawer] = useState();
  const [proceedPayDrawer, setProceedPayDrawer] = useState(false);
  const [customAmount, setCustomAmount] = useState();
  const [payForYourItems, setPayForYourItems] = useState();
  const SplitType = useSelector(
    (state) => state?.orderInformationSlice?.splitBill
  );
  const no_of_person =
    SplitType?.noTotalPeople - SplitType?.noPeopleYouPayFor + 1;
  const getAlreadyScanedID = useSelector((state) => state.StoreForMultiUsers);
  const currentActive =
    getAlreadyScanedID?.getScannedQrcodeAndRestuarent?.slice(-1);
  const activePayType = useSelector(
    (state) => state?.orderInformationSlice?.payBillType
  );
  const [buttonLoading, setButtonLoading] = useState(null);
  const langDir = useSelector((state) => state?.selectLang);
  const restaurantInfo = useSelector(
    (state) => state?.restaurentSlice?.restaurentData?.Menu?.Restaurent
  );

  const handleSplitBillDrawer = (event) => {
    setSplitBillDrawer(true);
  };

  const getOrderDetailsData = async (order, type) => {
    let res = await getOrderDetails(order.id);
    if (res.status === 200) {
      let updatedArr = [];

      for (let index = 0; index < res?.data?.OrderItems?.length; index++) {
        updatedArr.push({
          ...res?.data?.OrderItems[index],
          ischecked: false,
        });
      }
      const total =
        res?.data?.total -
        (res?.data?.partial_payment_amount +
          res?.data?.specific_payment_amount);

      let response = { ...res?.data, total, OrderItems: updatedArr };
      dispatch(updatedOrderFromPos(response));
      if (type === "proceedtopay") {
        setProceedPayDrawer(true);
      }
      setLoading(false);
      setButtonLoading(null);
    } else {
      dispatch(updatedOrderFromPos(null));
      toast.error(res.message, { theme: "colored" });
      setLoading(false);
      setButtonLoading(null);
    }
  };

  const updateOrderDetails = async (qrcode, type) => {
    let payload = {
      order_id: OrderDetails ? OrderDetails.id : null,
      qr_code_id: qrcode,
    };

    let res = await getUpdateOrderDetails(payload);

    if (res.status === 200 && res?.data?.payment_status === "1") {
      if (res?.data?.order_status === "5") {
        dispatch(addOrderDetails(null));
        dispatch(updatedOrderFromPos(null));
        navigate(-1);
        return;
      }
      const total =
        res?.data?.total -
        (res?.data?.partial_payment_amount +
          res?.data?.specific_payment_amount);
      getOrderDetailsData(OrderDetails, type);
      dispatch(addOrderDetails({ ...res?.data, total }));
    } else {
      dispatch(addOrderDetails(null));
      dispatch(updatedOrderFromPos(null));
      navigate(-1);
      toast.error(res.error || res.response.data.message, { theme: "colored" });
      setLoading(false);
      setButtonLoading(null);
    }
  };

  const syncOrderPaymentinDB = async (qrcode, restaurantid, type) => {
    let req = {
      restaurent_id: restaurantid,
      qr_code_id: qrcode,
    };

    await syncOrderPayemnt(req);

    updateOrderDetails(qrcode, type);
    console.log("order payemnt sync");
  };

  const syncposAndsendValuestoDB = async (qrcode, restaurantid, type) => {
    setLoading(true);
    let req = {
      restaurent_id: restaurantid,
      qr_code_id: qrcode,
    };
    let res = await syncPOS(req);
    if (res.status === 200) {
      syncOrderPaymentinDB(qrcode, restaurantid, type);
    } else {
      if (res?.response?.data?.status === 400) {
        updateOrderDetails(qrcode, type);
      }
      if (res?.response?.data?.status === 500) {
        dispatch(PosIsReachable(false));
        dispatch(addOrderDetails(null));
        navigate(-1);
      }
      setLoading(false);
      setButtonLoading(null);
      console.log("pos sync error");
    }
  };

  const updateOrder = (payload) => {
    if (currentActive?.[0]?.qrcodeid && currentActive?.[0]?.restaurentid) {
      if (payload === "refresh") {
        dispatch(splitBill(null));
        if (restaurantInfo?.tip_selector) {
          dispatch(updateTip(null));
        } else {
          dispatch(
            updateTip({ type: 1, percentage: 0, tip: 0, isCustomOpen: false })
          );
        }
        dispatch(wayToPayBillType("PayFullBill"));
        handleGetSplitBillDetails();
      }
      // syncposAndsendValuestoDB(
      //   currentActive?.[0]?.qrcodeid,
      //   currentActive?.[0]?.restaurentid,
      //   payload
      // );
      chekcingOrderDetails(
        currentActive?.[0]?.restaurentid,
        currentActive?.[0]?.qrcodeid,
        payload
      );
    }
  };

  const handleGetSplitBillDetails = async () => {
    let res = await GetSplitBillDetailsApi(OrderDetails?.id);

    console.log(res, "GetSplitBillDetailsApi");
    if (res.status === 200) {
      if (res?.data?.transaction_id === null) {
        const num =
          (OrderDetails?.total / res?.data?.split_method_customer_count) *
          res?.data?.split_method_customer_paid;
        let obj = {
          type: "DivideEqually",
          noTotalPeople: Number(res?.data?.split_method_customer_count),
          noPeopleYouPayFor: Number(res?.data?.split_method_customer_paid),
          total: Number(num.toFixed(2)),
        };
        if (res?.data?.split_method_type === "4") {
          dispatch(splitBill(obj));
          dispatch(wayToPayBillType("SplitTheBill"));
        }
      } else {
        let obj = {
          type: "DivideEqually",
          noTotalPeople:
            Number(res?.data?.split_method_customer_count) -
            Number(res?.data?.split_method_customer_paid),
          noPeopleYouPayFor: 1,
          total:
            Number(res?.data?.due_amount) /
            (Number(res?.data?.split_method_customer_count) -
              Number(res?.data?.split_method_customer_paid)),
        };
        if (res?.data?.split_method_type === "4") {
          dispatch(splitBill(obj));
          dispatch(wayToPayBillType("SplitTheBill"));
        }
        if (res?.data?.split_method_type === "2") {
          dispatch(updatelastPaymentMode("2"));
        }
      }
    } else {
      dispatch(updatelastPaymentMode(""));
      console.log(res?.message);
    }
  };

  const handleProceedToPay = () => {
    if (
      OrderDetails?.id &&
      activePayType === "SplitTheBill" &&
      SplitType?.type === "DivideEqually"
    ) {
      handleGetSplitBillDetails();
    }
    updateOrder("proceedtopay");
  };

  useEffect(() => {
    if (OrderDetails) {
      let due = OrderDetails?.total;
      if (due == 0) {
        navigate(`/?qrcodeid=${currentActive?.[0]?.qrcodeid}`);
        setTimeout(() => {
          toast.info("Your order has been paid", { theme: "colored" });
        }, 1000);
      }
    }
  }, [OrderDetails]);

  const chekcingOrderDetails = async (restaurantid, qrcode, payload) => {
    setLoading(true);
    let req = {
      restaurent_id: restaurantid,
      qr_code_id: qrcode,
    };
    let res = await getCheckDetails(req);
    if (res.status === 200) {
      if (res?.message === "Order not found") {
        syncposAndsendValuestoDB(qrcode, restaurantid, payload);
        return;
      } else {
        if (res?.data?.DUE_AMOUNT == 0) {
          syncposAndsendValuestoDB(qrcode, restaurantid, payload);
          return;
        }
        if (res?.data?.TranferCheck > 0) {
          syncposAndsendValuestoDB(qrcode, restaurantid, payload);
          return;
        }
        if (res?.data?.DUE_AMOUNT != OrderDetails?.total) {
          syncposAndsendValuestoDB(qrcode, restaurantid, payload);
          return;
        }
      }
      updateOrderDetails(qrcode, payload);
      setLoading(false);
      setButtonLoading(false);

      // setIsPOSEnable(true);
      dispatch(PosIsReachable(true));
    } else {
      if (res?.response?.data?.status === 400) {
        dispatch(PosIsReachable(false));
        dispatch(addOrderDetails(null));
      }
      if (res?.response?.data?.status === 500) {
        // setIsPOSEnable(false);
        updateOrderDetails(qrcode, payload);
      }
      setLoading(false);
      setButtonLoading(false);
      // setMenuLoading(false);
    }
  };

  useEffect(() => {
    if (OrderDetails?.id) {
      handleGetSplitBillDetails();
    }
  }, []);

  useEffect(() => {
    if (currentActive?.[0]?.qrcodeid && currentActive?.[0]?.restaurentid) {
      dispatch(splitBill(null));
      dispatch(wayToPayBillType("PayFullBill"));
      chekcingOrderDetails(
        currentActive?.[0]?.restaurentid,
        currentActive?.[0]?.qrcodeid
      );

      // syncposAndsendValuestoDB(
      //   currentActive?.[0]?.qrcodeid,
      //   currentActive?.[0]?.restaurentid
      // );
    }
  }, []);

  if (loading) {
    return (
      <LoaderWapper themeColor={customTheme?.theme_color}>
        <CircularProgress color="inherit" />
      </LoaderWapper>
    );
  }

  return (
    <>
      {splitBillDrawer && (
        <SwipeableDrawer
          anchor={"bottom"}
          open={splitBillDrawer}
          className="mui-item-wrapper"
          onClose={() => {
            setSplitBillDrawer(false);
            setButtonLoading(false);
          }}
        >
          <SplitBillDrawer
            Close={() => {
              setSplitBillDrawer(false);
              setButtonLoading(false);
            }}
            openDivideEqually={() => {
              setSplitBillDrawer(false);
              setButtonLoading(false);
              setdivideEquallyDrawer(true);
            }}
            openCustomAmount={() => {
              setSplitBillDrawer(false);
              setButtonLoading(false);
              setCustomAmount(true);
            }}
            openPayForYourItems={() => {
              setSplitBillDrawer(false);
              setButtonLoading(false);
              setPayForYourItems(true);
            }}
          />
        </SwipeableDrawer>
      )}

      {divideEquallyDrawer && (
        <SwipeableDrawer
          anchor={"bottom"}
          open={divideEquallyDrawer}
          className="mui-item-wrapper"
          onClose={() => setdivideEquallyDrawer(false)}
        >
          <DivideBillEqualLlyDrawer
            Close={() => setdivideEquallyDrawer(false)}
          />
        </SwipeableDrawer>
      )}

      {proceedPayDrawer && (
        <Drawer
          anchor={"bottom"}
          open={proceedPayDrawer}
          onClose={() => setProceedPayDrawer(false)}
          className="mui-item-wrapper"
          // disableSwipeToOpen
        >
          {/* <PayWithCreditCard Close={() => setCreditCardDrawer(false)} /> */}
          <SnapayFooter
            CreditCard={setCreditCardDrawer}
            setApplePayLoading={setApplePayLoading}
          />
        </Drawer>
      )}

      {creditCardDrawer && (
        <Drawer
          anchor={"bottom"}
          open={creditCardDrawer}
          className="mui-item-wrapper"
          disableSwipeToOpen
        >
          <PayWithCreditCard Close={() => setCreditCardDrawer(false)} />
        </Drawer>
      )}

      {customAmount && (
        <SwipeableDrawer
          anchor={"bottom"}
          open={customAmount}
          className="mui-item-wrapper"
          onClose={() => setCustomAmount(false)}
        >
          <PayCustomAmount Close={() => setCustomAmount(false)} />
        </SwipeableDrawer>
      )}

      {payForYourItems && (
        <SwipeableDrawer
          anchor={"bottom"}
          open={payForYourItems}
          className="mui-item-wrapper"
          onClose={() => setPayForYourItems(false)}
        >
          <PayForYourItem Close={() => setPayForYourItems(false)} />
        </SwipeableDrawer>
      )}

      <PayBillStyle dir={langDir?.direction}>
        {/* {false && (
          <AppleLoaderWapper themeColor={customTheme?.theme_color}>
            <img src={SnapayLogo} alt="snapay" />
            <CircularProgress color="inherit" />
            <p>Your payment is being processed</p>
          </AppleLoaderWapper>
        )} */}

        <OrderedItemsBill updateOrder={updateOrder} />

        <ActionButton background={SnapayGrayBackground} direction={true}>
          {SplitType && (
            <div className="editData">
              <div className="heading">
                <span>
                  <b>
                    <IntlMessage id="Snapay.PayTypeYourShare" />
                  </b>
                </span>
                <span>
                  <IntlMessage id="Snapay.QAR" />{" "}
                  {addCommaToNumbers(SplitType?.total)}
                </span>
              </div>
              {SplitType?.type === "DivideEqually" && (
                <p className="para">
                  <IntlMessage id="Snapay.PayTypeSpiltEqually" /> {no_of_person}{" "}
                  <IntlMessage id="Snapay.PayTypeSplitEquallyPeople" />
                </p>
              )}
            </div>
          )}
          <div className="WrapBtns">
            <WhiteButton
              button_text_color={customTheme?.button_text_color}
              button_primary_color={customTheme?.button_primary_color}
              button_secondary_color={customTheme?.button_secondary_color}
              activeBtn={activePayType === "SplitTheBill"}
              onClick={() => {
                setButtonLoading({ type: "split", active: true });
                updateOrder("split");
                handleSplitBillDrawer();
                handleGetSplitBillDetails();
              }}
            >
              {buttonLoading?.type === "split" && buttonLoading?.active ? (
                "loading"
              ) : (
                <>
                  {SplitType ? (
                    <IntlMessage id="Snapay.PayTypeEditTheSplitBtn" />
                  ) : (
                    <IntlMessage id="Snapay.PayTypeSplitTheBillBtn" />
                  )}
                </>
              )}
            </WhiteButton>

            <WhiteButton
              button_text_color={customTheme?.button_text_color}
              button_primary_color={customTheme?.button_primary_color}
              button_secondary_color={customTheme?.button_secondary_color}
              activeBtn={activePayType === "PayFullBill"}
              onClick={() => {
                setButtonLoading({ type: "payfull", active: true });
                updateOrder("payfull");
                dispatch(wayToPayBillType("PayFullBill"));
                dispatch(splitBill(null));
              }}
            >
              {buttonLoading?.type === "payfull" && buttonLoading?.active ? (
                <IntlMessage id="Snapay.Loading" />
              ) : (
                <IntlMessage id="Snapay.PayTypePayFullBillBtn" />
              )}
            </WhiteButton>
          </div>
        </ActionButton>
        <AddTipToBill />
        <WhiteButton
          button_text_color={customTheme?.button_text_color}
          button_primary_color={customTheme?.button_primary_color}
          button_secondary_color={customTheme?.button_secondary_color}
          onClick={handleProceedToPay}
        >
          {/* <CreditCardIcon color={customTheme?.button_primary_color} /> */}
          <IntlMessage id="Snapay.Proceedpay" />
          {/* Proceed to pay */}
        </WhiteButton>

        <InfoPaymentAccepted themeColor={customTheme?.theme_color}>
          <p>
            <InfoIcon button_secondary_color={customTheme?.theme_color} />
            Payment methods accepted
          </p>
          <div>
            <img src={VisaPayIcon} alt="payment" width={40} />
            <img src={MasterCardPayIcon} alt="payment" width={40} />
            <img src={AmexPayIcon} alt="payment" width={35} />
            <img src={ApplePayIcon} alt="payment" width={40} />
            <img src={GooglePayIcon} alt="payment" width={40} />
          </div>
        </InfoPaymentAccepted>

        {/* <SnapayFooter
          CreditCard={setCreditCardDrawer}
          setApplePayLoading={setApplePayLoading}
        /> */}
      </PayBillStyle>
    </>
  );
};

export default PayBill;

const InfoPaymentAccepted = styled.div`
  width: 100%;
  margin: auto;

  p {
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    color: ${({ themeColor }) => themeColor};
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1px;
  }

  div {
    width: 60%;
    margin: auto;
    display: flex;
    justify-content: space-between;
  }
`;

const PayBillStyle = styled.div`
  padding: 32px 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .heading {
    display: block;
    color: ${({ textColor }) => textColor};
    font-family: "DM Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.42px;
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
  }
  .para {
    color: ${({ textColor }) => textColor};
    font-family: "DM Sans", sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.3px;
    margin-bottom: 25px;
  }

  .WrapBtns {
    display: flex;
    gap: 10px;

    @media (max-width: 380px) {
      flex-direction: column;
    }
  }
`;
